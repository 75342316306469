import { Divider, Empty, Typography } from "antd";
import Utils from "common/Utils";
import styles from "../Booking.module.css";
import { Tour } from "./Tour";
import { useSelectorRoot } from "store/store";
import { TourSelect } from "../TourSelect";
//[14-12-2023][Phuc Thinh][Feature/14901]
//Change Tour to TourCombo
export const TicketsContainer = () => {
  // const selectComboTours = useSelectorRoot((state) => state.booking.comboTours);
  const selectComboToursSelected = useSelectorRoot(
    (state) => state.booking.selectedComboTours
  );

  return (
    <div className={styles.ticketsContainer}>
      <div className={styles.ticketsWrapper}>
        <div className={styles.ticketsWrapper_Header}>
          <Typography.Title
            level={4}
            style={{
              display: "block",
              margin: 0,
              padding: 0,
            }}
          >
            1. Thông tin khách hàng
          </Typography.Title>
          <TourSelect />
        </div>
        <div className={`${styles.tourList} customScroll`}>
          {selectComboToursSelected && selectComboToursSelected.length > 0 ? (
            selectComboToursSelected.map((tour, index) => (
              <Tour tour={tour} key={index} />
            ))
          ) : (
            <div className={styles.emptyContainer}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={"No ticket"}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.totalWrapper}>
        <Divider
          style={{
            marginTop: 12,
            borderBlockStart: "1px solid rgba(5, 5, 5, 0.26)",
          }}
        />
        <div className={styles.priceWrapper}>
          <Typography.Text
            className={styles.priceLabel}
          >{`Tổng `}</Typography.Text>
          <Typography.Text className={styles.priceLabel}>
            {Utils.formatCurrency(
              Utils.calculateTotal(selectComboToursSelected) +
                Utils.calculateBusTotal(selectComboToursSelected) +
                Utils.calculateExtraServiceTotal(selectComboToursSelected)
            )}
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
