import styles from "../TicketOperator.module.css";
import { DatePicker, Input, Select, Typography } from "antd";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { setFilterValue } from "store/slice/TIcketOperatorSlice";
import { ChangeEvent, useState } from "react";
import Utils from "common/Utils";
import { ThuHoStatus } from "common/define-types";
import { AgentSelect } from "components/Selects/AgentSelect";
import TicketStatusSelect from "./TicketStatusSelect";
dayjs.extend(utc);
type RangeValue = [Dayjs | null, Dayjs | null] | null;
const { RangePicker } = DatePicker;
const options = [
  {
    label: "Không cần Jasmine thu hộ",
    value: ThuHoStatus.NONE,
  },
  {
    label: "Cần Jasmine thu hộ",
    value: ThuHoStatus.NOT_YET,
  },
  {
    label: "Đã trả tiền thu hộ",
    value: ThuHoStatus.DONE,
  },
];
const TicketFilterBar = () => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const [rangePickerOpen, setRangePickerOpen] = useState<boolean>(false);
  const dispatch = useDispatchRoot();
  const selectOpTicketFilterValue = useSelectorRoot(
    (state) => state.operatorTicket.filterValue
  );

  const handleChangeDate = (values: RangeValue) => {
    if (values) {
      dispatch(
        setFilterValue({
          ...selectOpTicketFilterValue,
          startDate: values[0]?.startOf("day")?.toISOString(),
          endDate: values[1]?.endOf("day").toISOString(),
        })
      );
    }
  };
  const handleChangeFilterValue = (
    key: keyof typeof selectOpTicketFilterValue,
    value: any
  ) => {
    dispatch(
      setFilterValue({
        ...selectOpTicketFilterValue,
        [key]: value,
      })
    );
  };
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(
        setFilterValue({
          ...selectOpTicketFilterValue,
          search: query,
        })
      );
    }, 800);
    setTimer(timeoutId);
  };

  return (
    <div className={styles.filterContainer}>
      <div className={styles.filterItemContainer}>
        <Typography.Text>Vé có ngày đi tour từ:</Typography.Text>
        <RangePicker
          allowClear={false}
          open={rangePickerOpen}
          onOpenChange={(open) => setRangePickerOpen(open)}
          placeholder={["Từ ngày", "Đến ngày"]}
          format={Utils.dateFormat}
          value={[
            dayjs.utc(selectOpTicketFilterValue.startDate),
            dayjs.utc(selectOpTicketFilterValue.endDate),
          ]}
          onChange={(values) => values && handleChangeDate(values)}
        />
      </div>
      <div className={styles.filterItemContainer}>
        <Typography.Text>Lọc thu hộ:</Typography.Text>
        <Select
          style={{ minWidth: 180 }}
          options={options}
          allowClear
          placeholder={"-- Lọc thu hộ --"}
          value={selectOpTicketFilterValue.StatusThuHo}
          onChange={(value) =>
            dispatch(
              setFilterValue({
                ...selectOpTicketFilterValue,
                StatusThuHo: value,
              })
            )
          }
        />
      </div>
      <div className={styles.filterItemContainer}>
        <Typography.Text>Ngày bán vé:</Typography.Text>
        <DatePicker
          allowClear
          placeholder={"Ngày bán vé"}
          format={Utils.dateFormat}
          value={
            selectOpTicketFilterValue.BookingDate
              ? dayjs.utc(selectOpTicketFilterValue.BookingDate)
              : null
          }
          onChange={(value) =>
            handleChangeFilterValue(
              "BookingDate",
              value ? 
              dayjs.utc(
                value
                .set('hour', 0)
                .set('minute', 0)
                .set('second', 0)
              ).toISOString() : null
            )
          }
        />
      </div>
      <div className={styles.filterItemContainer}>
        <Typography.Text>Đại lý:</Typography.Text>
        <AgentSelect
          style={{ minWidth: 180 }}
          value={selectOpTicketFilterValue.AgentId}
          onChange={(value) => handleChangeFilterValue("AgentId", value)}
        />
      </div>
      <div className={styles.filterItemContainer}>
        <Typography.Text>Ngày bus đón:</Typography.Text>
        <DatePicker
          allowClear
          placeholder={"Ngày bus đón"}
          format={Utils.dateFormat}
          value={
            selectOpTicketFilterValue.BusStartDate
              ? dayjs.utc(selectOpTicketFilterValue.BusStartDate)
              : null
          }
          onChange={(value) =>
            handleChangeFilterValue(
              "BusStartDate",
              value ? value.toISOString() : null
            )
          }
        />
      </div>
      <div className={styles.filterItemContainer}>
        <Typography.Text>Ngày bus trả:</Typography.Text>
        <DatePicker
          allowClear
          placeholder={"Ngày bus trả"}
          format={Utils.dateFormat}
          value={
            selectOpTicketFilterValue.BusEndDate
              ? dayjs.utc(selectOpTicketFilterValue.BusEndDate)
              : null
          }
          onChange={(value) =>
            handleChangeFilterValue(
              "BusEndDate",
              value ? value.toISOString() : null
            )
          }
        />
      </div>
      <div className={styles.filterItemContainer}>
        <Typography.Text>Trạng thái vé:</Typography.Text>
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          {/* [13-12-2023][Phuc Thinh]
          update new component for filter ticket date status */}
          <TicketStatusSelect handleChangeFilter={handleChangeFilterValue} />
        </div>
      </div>
      <div className={styles.filterItemContainer}>
        <Typography.Text>Tìm kiếm:</Typography.Text>
        <Input.Search
          onChange={handleSearch}
          style={{ maxWidth: 230 }}
          placeholder="Tìm kiếm vé"
          allowClear
        />
      </div>
    </div>
  );
};

export default TicketFilterBar;
