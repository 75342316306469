import {
  Typography,
  Button,
  Input,
  Pagination,
  Tooltip,
  PaginationProps,
  Skeleton,
  notification,
} from "antd";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import styles from "../Agents.module.css";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { AgentAccountItem } from "./AgentAccountItem";
import { useForm } from "antd/es/form/Form";
import {
  createAgentAccount,
  fetchAgentAccounts,
  setAgentAccountsResults,
} from "store/slice/AgentsSlice";
import { FormModal } from "./FormModal";
import { ChangeEvent, useState } from "react";
import { AgentAccountForm } from "./AgentAccountForm";
import { RoleEnum } from "common/define-types";

export const AgentAccountList = () => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const selectAgentAccounts = useSelectorRoot(
    (state) => state.agents.agentSelectedAccounts
  );
  const selectAgentSelected = useSelectorRoot(
    (state) => state.agents.agentSelected
  );
  const selectAgentAccountsResult = useSelectorRoot(
    (state) => state.agents.agentAccountsResult
  );
  const selectAgentAccountsLoading = useSelectorRoot(
    (state) => state.agents.isAgentAccountLoading
  );
  const userRole = useSelectorRoot((state) => state.login.role);
  const [addAccountForm] = useForm();
  const dispatch = useDispatchRoot();
  const [addAccModalOpen, setAddAccModalOpen] = useState(false);

  const handleCreateAccount = async () => {
    const agentAccountValue = await addAccountForm
      .validateFields()
      .catch((err) => {
        console.log(err);
        notification.error({ message: "Vui lòng nhập đủ thông tin" });
      });
    if (!agentAccountValue) return;
    const employeeName = agentAccountValue.employeeName.trim();
    const indexOfFirstSpaceChar = employeeName.lastIndexOf(" ");
    const lastName = employeeName.slice(0, indexOfFirstSpaceChar);
    const firstName = employeeName.slice(
      indexOfFirstSpaceChar,
      employeeName.length
    );
    if (selectAgentSelected) {
      dispatch(
        createAgentAccount({
          account: {
            firstname: indexOfFirstSpaceChar > 0 ? firstName : employeeName,
            lastname: indexOfFirstSpaceChar > 0 ? lastName : "",
            email: agentAccountValue.email,
            phoneNumber: agentAccountValue.phoneNumber,
            password: agentAccountValue.password,
            userName: agentAccountValue.userName,
          },
          agent: selectAgentSelected,
        })
      );
    }
  };

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectAgentAccountsResult) {
      dispatch(
        fetchAgentAccounts({
          ...selectAgentAccountsResult,
          page,
        })
      );
    } else {
      dispatch(fetchAgentAccounts());
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      if (selectAgentAccountsResult) {
        dispatch(
          fetchAgentAccounts({
            ...selectAgentAccountsResult,
            search: query,
          })
        );
        dispatch(
          setAgentAccountsResults({
            ...selectAgentAccountsResult,
            search: query,
          })
        );
      }
    }, 800);
    setTimer(timeoutId);
  };

  return (
    <div className={styles.listContainer}>
      <div className={styles.listContainer_header}>
        <Typography.Text strong style={{ fontSize: 20 }}>
          Danh sách tài khoản
        </Typography.Text>
        <div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled={
              selectAgentSelected?.isErase ||
              userRole !== RoleEnum.ADMIN ||
              selectAgentSelected === null
            }
            onClick={() => {
              addAccountForm.resetFields();
              setAddAccModalOpen(true);
            }}
          >
            Thêm tài khoản
          </Button>
          {userRole !== RoleEnum.ADMIN && (
            <Tooltip title={"Bạn không được cấp quyền tạo tài khoản"}>
              <InfoCircleOutlined
                style={{
                  fontSize: 14,
                  marginLeft: 8,
                  color: "#7dccfc",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          )}
        </div>
      </div>
      <div className={styles.searchContainer}>
        {selectAgentAccountsLoading ? (
          <Skeleton.Input block={true} />
        ) : (
          <>
            <Typography.Text>{`Số lượng tài khoản: ${
              selectAgentAccounts?.length || 0
            }`}</Typography.Text>
            <Input.Search
              allowClear
              style={{ flex: 1 }}
              placeholder="Tìm kiếm tài khoản"
              onChange={handleSearch}
            />
          </>
        )}
      </div>
      <div className={styles.listWrapper}>
        <div className={`${styles.listWrapper_List} customScroll`}>
          {selectAgentAccountsLoading ? (
            <>
              <Skeleton paragraph={{ rows: 3 }} />
              <Skeleton paragraph={{ rows: 3 }} />
              <Skeleton paragraph={{ rows: 3 }} />
            </>
          ) : selectAgentAccounts && selectAgentAccounts.length > 0 ? (
            [...selectAgentAccounts].map((account) => (
              <AgentAccountItem account={account} key={`Agent-${account.id}`} />
            ))
          ) : (
            <EmptyHolder message="Chưa có tài khoản" />
          )}
        </div>
      </div>
      <div className={styles.listContainer_footer}>
        <Pagination
          total={
            selectAgentAccountsResult ? selectAgentAccountsResult.queryCount : 0
          }
          pageSize={
            selectAgentAccountsResult ? selectAgentAccountsResult.pageSize : 10
          }
          current={
            selectAgentAccountsResult ? selectAgentAccountsResult.page : 1
          }
          onChange={handleChangePage}
          showTotal={(total, range) =>
            `Hiển thị ${
              range[1] === 0 && range[1] - range[0] === 0
                ? 0
                : range[1] - range[0] + 1
            }/${total} tài khoản`
          }
        />
      </div>
      {addAccModalOpen && selectAgentSelected && (
        <FormModal
          isOpen={addAccModalOpen}
          title={`Tài khoản của ${selectAgentSelected.name} `}
          okText="Thêm mới"
          handleClose={() => {
            setAddAccModalOpen(false);
          }}
          handleOk={async () => {
            await handleCreateAccount();
            setAddAccModalOpen(false);
          }}
        >
          <AgentAccountForm form={addAccountForm} isEdit={false} />
        </FormModal>
      )}
    </div>
  );
};
