import { Typography, Input, Pagination } from "antd";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import styles from "../BookingRevenue.module.css";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { setTicketQuery } from "store/slice/BookingRevenueSlice";
import { TicketWithQRBill } from "pages/Booking/components/Billing/TicketWithQRBill";

export const TicketsList = () => {
  const [currentPagi, setCurrentPagi] = useState(1);
  const PAGE_SIZE = 10;
  const dispatch = useDispatchRoot();
  const selectBookingSelected = useSelectorRoot(
    (state) => state.bookingRevenue.bookingSelected
  );
  const selectBookingSelectedTickets = useSelectorRoot(
    (state) => state.bookingRevenue.bookingSelectedTickets
  );
  const selectQueryTicket = useSelectorRoot(
    (state) => state.bookingRevenue.ticketQuery
  );
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (listRef && listRef.current)
      listRef.current.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPagi]);
  const { width: dimensionWidth } = useWindowDimensions();
  return (
    <div className={styles.listContainer}>
      <div
        className={styles.listContainer_header}
        style={{ justifyContent: "flex-start" }}
      >
        <Typography.Text strong style={{ fontSize: 20, marginRight: 10 }}>
          {`Danh sách khách: `}
        </Typography.Text>
        <Typography.Text
          strong
          style={{ fontSize: 20, color: "#1890FF", whiteSpace: "nowrap" }}
        >
          {selectBookingSelected
            ? selectBookingSelected.code
              ? `${selectBookingSelected.code}`
              : `Booking_${moment(selectBookingSelected.createdTime).format(
                  "DDMMYYYY"
                )}_${selectBookingSelected.index}`
            : ""}
        </Typography.Text>
      </div>
      <div className={styles.searchContainer}>
        <Input.Search
          allowClear
          style={{ flex: 1 }}
          placeholder="Tìm kiếm khách"
          value={selectQueryTicket}
          onChange={(e) => dispatch(setTicketQuery(e.target.value))}
        />
      </div>
      <div
        className={styles.listWrapper}
        style={
          dimensionWidth <= 520
            ? {
                minHeight:
                  selectBookingSelected &&
                  selectBookingSelectedTickets.length > 0
                    ? selectBookingSelectedTickets.length >= 3
                      ? 500 * 3
                      : 500 * selectBookingSelectedTickets.length
                    : 180,
              }
            : {}
        }
      >
        <div
          className={`${styles.listWrapper_List} customScroll`}
          style={{ gap: "25px" }}
          ref={listRef}
        >
          {selectBookingSelected && selectBookingSelectedTickets.length > 0 ? (
            selectBookingSelectedTickets
              .slice(PAGE_SIZE * (currentPagi - 1), PAGE_SIZE * currentPagi)
              .map((ticket, index) => (
                <TicketWithQRBill
                  booking={selectBookingSelected}
                  ticket={ticket}
                  key={`ticket-${ticket.id}`}
                  index={index + 1 + PAGE_SIZE * (currentPagi - 1)}
                  // borderStyle={"1px solid #D9D9D9"}
                  isErase={ticket.isErase === true}
                  searchQuery={selectQueryTicket}
                />
              ))
          ) : (
            <EmptyHolder message="No ticket yet" />
          )}
        </div>
      </div>
      <div className={styles.listContainer_footer}>
        <Pagination
          total={selectBookingSelectedTickets.length}
          defaultPageSize={PAGE_SIZE}
          onChange={(page) => setCurrentPagi(page)}
          current={currentPagi}
          showTotal={(total, range) =>
            `Hiển thị ${
              range[1] === 0 && range[1] - range[0] === 0
                ? 0
                : range[1] - range[0] + 1
            }/${total} khách`
          }
        />
      </div>
    </div>
  );
};
