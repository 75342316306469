import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import { ICreateReward } from "common/define-types";

const api = `${SYSTEM_CONSTANTS.API_URL}/Reward`;

export const createDriverReward = (body: ICreateReward): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
