import { RouteProps } from "react-router-dom";
import { Tours } from "./Tour/Tours";
import { Group } from "./Admin/pages/Group/Group";
import { Operation } from "./Admin/pages/Operation/Operation";
import { BookingRevenue } from "./BookingRevenue/BookingRevenue";
import { Agents } from "./Admin/pages/Agents/Agents";
import { NightAuditor } from "./Admin/pages/NightAuditor/NightAuditor";
import { RoleEnum } from "common/define-types";
import { Driver } from "./Admin/pages/Driver/Driver";
import { Motorbike } from "./Admin/pages/Motorbike/Motorbike";
import { DriverOperator } from "./Admin/pages/DriverOperator/DriverOperator";
// import { MotorbikeOperator } from "./Admin/pages/MotorbikeOperator/MotorbikeOperator";
import { AgentOperator } from "./Admin/pages/AgentOperator/AgentOperator";
import { Dashboard } from "./Admin/pages/Dashboard/Dashboard";
import { BookingDashboard } from "./BookingDashboard/BookingDashboard";
import { MotorbikeOperator } from "./Admin/pages/MotorbikeOperator/MotorbikeOperator";
import NightAuditorBooking from "./Admin/pages/NightAuditorBooking/NightAuditorBooking";
import TicketOperator from "./Admin/pages/TicketOperator/TicketOperator";

export interface RouteObj {
  label: string;
  icon?: string;
  rootPermission?: RoleEnum[];
  routeProps?: RouteProps;
  documentTitle?: string;
  items?: RouteObj[];
  color?: string;
  isRoot?: boolean;
}

export const Routes: RouteObj[] = [
  {
    label: "Dashboard",
    routeProps: {
      path: "/jasmine/dashboard",
      children: <Dashboard />,
    },
    isRoot: true,
    rootPermission: [RoleEnum.ADMIN, RoleEnum.SYSTEM],
  },
  {
    label: "Quản lý và vận hành",
    // isRoot: true,
    routeProps: {
      path: "/jasmine/operation",
    },
    items: [
      {
        label: "Quản lý vé",
        routeProps: {
          path: "/jasmine/ticket-operator",
          children: <TicketOperator />,
        },
        rootPermission: [RoleEnum.ADMIN],
      },
      {
        label: "Tạo và quản lý group",
        routeProps: {
          path: "/jasmine/groups",
          children: <Group />,
        },
        rootPermission: [RoleEnum.ADMIN],
      },
      {
        label: "Chi tiết vận hành tour",
        routeProps: {
          path: "/jasmine/operate",
          children: <Operation />,
        },
        rootPermission: [RoleEnum.ADMIN],
      },
      {
        label: "Kiểm toán group",
        routeProps: {
          path: "/jasmine/night-auditor",
          children: <NightAuditor />,
        },
        rootPermission: [RoleEnum.NIGHTAUDITOR],
      },
      {
        label: "Kiểm toán vé",
        routeProps: {
          path: "/jasmine/booking-night-auditor",
          children: <NightAuditorBooking />,
        },
        rootPermission: [RoleEnum.NIGHTAUDITOR],
      },
      {
        label: "Tài xế",
        routeProps: {
          path: "/jasmine/driver-operator",
          children: <DriverOperator />,
        },
        rootPermission: [RoleEnum.ADMIN],
      },
      {
        label: "Motors",
        routeProps: {
          path: "/jasmine/motorbike-operator",
          children: <MotorbikeOperator />,
        },
        rootPermission: [RoleEnum.ADMIN],
      },
      {
        label: "Đại lý",
        routeProps: {
          path: "/jasmine/agent-operator",
          children: <AgentOperator />,
        },
        rootPermission: [RoleEnum.ADMIN],
      },
    ],
  },
  {
    label: "Danh mục",
    routeProps: {
      path: "/jasmine/catalog",
    },
    items: [
      // {
      //   label: "Tours",
      //   routeProps: {
      //     path: "/jasmine/tours",
      //     // children: <UserManager></UserManager>,
      //   },
      //   rootPermission: [RoleEnum.All],
      // },
      {
        label: "Tài xế",
        routeProps: {
          path: "/jasmine/drivers",
          children: <Driver />,
        },
        rootPermission: [RoleEnum.ADMIN],
      },
      {
        label: "Motors",
        routeProps: {
          path: "/jasmine/motors",
          children: <Motorbike />,
        },
        rootPermission: [RoleEnum.ADMIN],
      },
      {
        label: "Đại lý",
        routeProps: {
          path: "/jasmine/agents",
          children: <Agents />,
        },
        rootPermission: [RoleEnum.ADMIN],
      },
    ],
  },
  // {
  //   label: "Báo cáo",
  //   routeProps: {
  //     path: "/jasmine/report",
  //   },
  //   items: [
  //     {
  //       label: "Quản lý quản trị viên",
  //       routeProps: {
  //         path: "/jasmine/admin",
  //         // children: <Admin></Admin>,
  //       },
  //       rootPermission: [RoleEnum.ADMIN],
  //     },
  //   ],
  // },
];
export const AgentRoutes: RouteObj[] = [
  {
    label: "Bán vé",
    routeProps: {
      path: "/tours",
      children: <Tours />,
    },
    isRoot: true,
    rootPermission: [RoleEnum.AGENT],
  },
  {
    label: "Dashboard",
    routeProps: {
      path: "/dashboard",
      children: <BookingDashboard />,
    },
    isRoot: true,
    rootPermission: [RoleEnum.AGENT],
  },
  {
    label: "Quản lý bookings",
    routeProps: {
      path: "/bookings",
      children: <BookingRevenue />,
    },
    rootPermission: [RoleEnum.AGENT],
    isRoot: true,
  },
];
