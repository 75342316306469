import { AnyAction } from "@reduxjs/toolkit";
import { Epic } from "redux-observable";
import { RootState } from "../store/reducers";
import { IAgent } from "api/types/agent";
import { IIssue } from "api/types/issue";
import { IUpdatePaymentMethodBooking } from "api/types/booking";

export type RootEpic = Epic<AnyAction, AnyAction, RootState>;
export interface SystemConfig {
  hostIdentity: string;
}
export interface User {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  createdAt: Date;
  updatedAt: Date;
  role: RoleEnum;
  phone: null | number;
  fullName: string;
}
export interface UserData {
  userOrgId: string;
  organizationRoles: string[];
  id: string;
  invitationStatus: string;
  userName: string;
  email: string;
  phoneNumber: string | null;
  firstname: string;
  lastname: string;
  gender: string | null;
  avatarUrl: string | null;
  profile: any;
  forcePasswordChange: boolean;
}
export interface ForgotType {
  email: string;
}
export interface UserResponse {
  amr: string[];
  aud: string;
  auth_time: number;
  client_id: string;
  exp: number;
  iat: number;
  idp: string;
  iss: string;
  nbf: number;
  profile: string;
  role: RoleEnum;
  scope: string[];
  sub: string;
  orgRoles: string;
}

export interface ResponseToken {
  error: string;
  jwt: string;
  user: User;
}

export interface Token_ResponseToken {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
  scope?: string;
}
export interface LoginInfo {
  username: string;
  password: string;
  captchaId?: string;
  captcha?: string;
  remember: boolean;
}

export interface IOrganization {
  orgid: string;
  orgdesc: string;
  orgidParent: string | null;
  orgord: number | null;
  isccsl: number | null;
  orgcode: string;
  active: number;
  orlevel: number;
  listChild: any;
}

export interface ReqInfo {
  maGn: string;
  thang: number;
  nam: number;
  userid: string;
}

export interface OrgReq {
  orgId?: string | null;
  thang?: number | null;
  nam?: number | null;
  rid?: number | null;
}

export interface ErrorResponse {
  message?: string;
  status?: string;
  data?: any;
}

interface IDetailDataValue {
  label?: string | null;
  value?: string | null | number;
}

export interface ITitleAndDetailData {
  title?: string | null;
  data?: IDetailDataValue[] | null;
}

export interface ItemData {
  id?: number | null | string;
  name: string | null;
  detail?: ITitleAndDetailData[] | null;
}
export interface DataSource {
  id?: number | null | string;
  name?: string | null;
  children?: ItemData[] | null;
  detail?: ITitleAndDetailData[] | null;
}
export interface Profile {
  Id: string;
  UserName: string;
  Firstname: string;
  Lastname: string;
  Email: string;
  PhoneNumber: string;
  InvitationStatus: string;
  ForcePasswordChange: boolean;
}
// export interface Profile {
//     createdAt:string|null;
//     updatedAt:string|null;
//     userid:string|null,
//     orgid:string|null,
//     email:string|null,
//     username:string|null,
//     password:string|null,
//     enable:boolean|null,
//     roles:{id:string|null,
//     role:string|null}[],
//     organizations:any[],
//     tel:string|null,
//     desript:string|null,
//     groupUserId:string|null,
//     urlDefault:string|null,
//     expiration:number|null,
//     enabled:boolean|null,
//     accountNonLocked:boolean|null,
//     authorities:{authority:string|null}[],
//     accountNonExpired:boolean|null,
//     credentialsNonExpired:boolean|null,
//     emailVerified:boolean|null
// }

export enum RoleEnum {
  ALL = "All",
  ADMIN = "Admin",
  SYSTEM = "System",
  DRIVER = "Driver",
  AGENT = "Agent",
  NIGHTAUDITOR = "NightAuditor",
}
// export interface RoleData  {
//     id: 1,
//     name: string,
//     description: string,
//     type: string,
//     createdAt: Date,
//     updatedAt: Date,
//     nb_users: number
// }
export interface ITour {
  id: string;
  pathFile: string;
  description: string;
  status: string;
  countDay: number;
  driverPriceDto: IPriceReq;
}
export interface IPriceReq {
  id: string;
  price: string;
  isDriver: IsDriverType;
  description: string;
  type: string;
  countDay: string;
}

export enum IsDriverType {
  YES = "YES",
  NO = "NO",
}
export enum SelfMotor {
  YES = "YES",
  NO = "NO",
}
export interface ITicket {
  id: string;
  name: string;
  fullName: string | null;
  phone: string | null;
  passportId?: string | null;
  startDate: string | null;
  arrivalDate?: string;
  departDate?: string;
  from?: string;
  destination?: string;
  email?: string;
  driverPriceId?: string;
  driverId?: string;
  busTicketC1?: IBusTicket | null;
  busTicketC2?: IBusTicket | null;
  busTicketIdC1?: string | null;
  busTicketIdC2?: string | null;
  createdTime?: string;
  dockerId?: string;
  price?: number;
  isSpecial?: boolean;
  datA_TOUR_CHHangHoaDichVuKhacs?: ITicket_ExtraService[];
  comboTourId: string;
  selfMotor?: SelfMotor;
  dmuC_MotorPriceId?: string;
  tourId?: string;
}

export interface ITicket_ExtraService {
  price: number;
  ghiChu?: string;
  dmuC_HangHoaId?: string;
  roomTemplateId?: string;
  dmuC_CHHangHoaDichVuKhacId: string;
  soLuong: number;
}

export interface IBusTicket {
  dateTime: string | null;
  routerStationBusId: string;
  routerStationId?: string;
  ticketId: string;
  price: number;
  vitriDon?: string;
  linkGGMap?: string;
}
export interface ITicketWithPrice extends ITicket {}
// export interface ISelectedTour extends ITour {
//   tickets?: ITicket[] | null;
// }
export interface ISelectedComboTour extends IComboTour {
  tickets?: ITicket[] | null;
}
export interface ISelectedTicket extends ITicket {
  comboTour: IComboTour;
}

export enum TourStepEnum {
  TOURS = "tours",
  DETAILS = "detail",
}
export enum BookingStepEnum {
  BOOKING_DETAIL = 0,
  PAYMENT = 1,
  COMPLETED = 2,
}
export enum GroupStatusEnum {
  NOT_RUNNING = 0,
  ERASE = 1,
  RUNNING = 3,
  END = 4,
}

export interface IGroup {
  name: string;
  prices: string;
  description: string;
  leaderId: string;
  tourId: string;
  status: GroupStatusEnum;
  id: string;
  countTicket: number;
  countDriver?: number;
  countMotor?: number;
  dmuC_Tour?: any | null;
  endDate: string | null;
  startDate: string | null;
  advance_Money: string;
  petrol_Money: string;
  refund_Money: string;
  roomChange_Money: string;
  businessDate: string;
}
export interface INightAuditGroup {
  name: string;
  description: string;
  leaderId: string;
  tourId: string;
  status: number;
  advance_Money: string;
  petrol_Money: string;
  startDate: Date;
  roomChange_Money: number;
  jobs: IJob[];
  tickets: IGroupTicket[];
  issues: IGroupIssue[];
  driver: IDriver[];
}

export interface IGroupTicket {
  driverName?: string;
  motoBikeNumber?: string;
  driver_Tickets?: IDriverTicket[];
  motorbike_Ticket?: IMotorbikeTicket[];
  driverPrice?: IPriceReq;
  busTicketC1?: IBusTicket;
  busTicketC2?: IBusTicket;
  id: string;
  tourId: string;
  fullName: string;
  name: string;
  email: string;
  bookingId: string;
  argentId?: string;
  passportId: string;
  driverPriceId: string;
  status: JobTicketStatusEnum;
  phone: string;
  isErase: boolean;
  startDate: string | null;
  endDate: string | null;
  busTicketIdC1: string;
  busTicketIdC2: string;
  dockerId?: string;
  isSpecial?: boolean;
  agentName: string;
  agentCode: string;
  comboTourId: string;
  selfMotor?: SelfMotor;
  dmuC_MotorPriceId?: string;
}

export enum GroupTicketStatus {
  Init = 0,
  ComfirmByAdmin = 2,
  Erase = 1,
  Approve = 3,
}
export interface IGroupIssue extends IIssue {
  touR_Ticket?: IBookingTicket;
}
export enum ThanhToanIssueStatus {
  NOT_YET = 0,
  PAID_BY_LEADER = 1,
  PAID_BY_ADMIN = 2,
}

export interface IParams {
  filter?: string;
  expand?: string;
  select?: string;
  entitySetName?: string;
  paging?: boolean;
  orderBy?: string;
  page?: number;
  pageSize?: number;
  offset?: number;
  take?: number;
  startDate?: string | null;
  endDate?: string | null;
  isAvailable?: boolean;
  isOnlyStartDate?: boolean;
  groupId?: string;
  search?: string;
  dateTime?: string | null;
  topDay?: number;
  results?: any;
  typeJobItem?: number;
  typeRoom?: number;
  typeHangHoa?: number;
}
export interface IGetGroupsParams extends IParams {
  status?: GroupStatusEnum;
  isNotRunNight?: boolean;
  isEndDay?: boolean;
}

export interface IJobTicket {
  id: string;
  ticketId: string;
  timeCheckin?: string;
  status: number;
  note?: string;
  jobTemplateId: string;
  groupId: string;
  jobCustomId: string;
  isCheck: boolean;
  motorbikeId?: string | null;
  driverId?: string | null | undefined;
  type: JobTicketType;
  isDriver: "YES" | "NO";
}
export enum JobTicketType {
  Tickets = "tickets",
  Driver = "driver",
  Motor = "motor",
  Oil = "oil",
}
export enum JobTicketStatusEnum {
  INIT = 0,
  PASS = 1,
  CONFIRM = 2,
  BREAKUPTOUR = 3,
}
export enum GroupItemType {
  Child = "child",
  Parent = "parent",
}
export interface IGroupJob {
  id: string;
  name: string;
  description?: string;
  type: GroupItemType;
  status: number;
  parentId: string;
  jobs: IJob[];
  stt: string;
  day: number;
}
export interface IJob {
  id: string;
  price: number;
  stt: string;
  name: string;
  tourId: string;
  jobItemId: string;
  jobItem: IJobItem;
  startTime: string;
  endTime: string;
  day: string;
  status: string;
  supplierId: string;
  nameSupplier: string;
  jobTickets: IJobTicket[];
  sumarizedMoney: number;
  datA_TOUR_Issues: IIssue[];
}
export interface IJobItem {
  id: string;
  name: string;
  supplierId: string;
  type: JobTicketType;
  rate: number;
}
export interface ISelectJob {
  jobGroup: IGroupJob | null;
  jobTour: IJob | null;
}
export enum JobDateEnum {
  ALL = 0,
  DAY1 = 1,
  DAY2 = 2,
  DAY3 = 3,
  DAY4 = 4,
}
export enum GroupRunningDateEnum {
  ALL = 0,
  DAY1 = 1,
  DAY2 = 2,
  DAY3 = 3,
  DAY4 = 4,
  END_DAY = 5,
}

export interface IBooking {
  argentId: string;
  code: string;
  note: string;
  userPayName: string;
  userPayEmail: string;
  userPayPhone: string;
  userPayCCCD: string;
  status: string;
  isErase: true;
  createDate: string;
  id: string;
  tickets: IBookingTicket[];
  paymentMethodBookings: IPaymentMethodBooking[];
  createdTime?: string;
  modifiedTime?: string;
  index?: number;
  moneyJasmineKeep: number;
}

export interface IBookingTicket {
  id: string;
  name: string;
  tourId: string;
  driverId: string;
  fullName: string;
  email: string;
  bookingId: string;
  driverPriceId: string;
  status: string;
  isErase: true;
  startDate: string | null;
  price?: number;
  driverPriceDto?: {
    price: string;
    isDriver: string;
    description: string;
    type: string;
  } | null;
  driverName?: string | null;
  motoNum?: string | null;
  driver_Tickets?: null;
  motorbike_Ticket?: null;
  passportId?: string;
  phone?: string;
  groupId?: string;
  busTicketC1?: IBusTicket | null;
  busTicketC2?: IBusTicket | null;
  busTicketIdC1?: string;
  busTicketIdC2?: string;
  createdTime?: string;
  dockerId?: string;
  isSpecial?: boolean;
  datA_TOUR_CHHangHoaDichVuKhacs?: ITicket_ExtraService[];
  comboTourId: string;
  selfMotor?: SelfMotor;
  dmuC_MotorPriceId?: string;
}

export interface IDeleteBooking {
  bookingId: string;
  paymentMethodBookings?: IUpdatePaymentMethodBooking[];
}

export interface IBus {
  name: string;
  description: string;
  id: string;
}

export interface ILocation {
  id: string;
  name: string;
  description: string;
}

export interface ICodePay {
  id: string;
  // [4-12-2023] [Phuc Thinh] [Update paymentMethodBooking interface]
  ma: string;
  ten: string;
  ghiChu: string;
}

export interface IRouterStationBus {
  price: number;
  giaHienThi: number;
  busId: string;
  routerStationId: string;
  id: string;
  date?: string;
  dockerId: string | null;
  dmuC_Bus: {
    name: string;
    description: string;
    id: string;
  };
  dmuC_Router_Station?: {
    stationId: string;
    routerId: string;
    id: string;
    dmuC_Router: IRouter;
    dmuC_Station: IStation;
  } | null;
}

export interface IStation {
  id: string;
  name: string;
  description: string;
  address: string | null;
  phone: string;
}
export interface IRouter {
  id: string;
  dmuC_LocationStart: null;
  dmuC_LocationEnd: null;
  localtionStartId: string;
  localtionEndId: string;
}
export interface IStation_CapitalCase {
  Id: string;
  Name: string;
  Description: string;
  Address: string | null;
  Phone: string;
}
export interface IRouter_CapitalCase {
  Id: string;
  DMUC_LocationStart: null;
  DMUC_LocationEnd: null;
  LocaltionStartId: string;
  LocaltionEndId: string;
}

export interface IDriver {
  id: string;
  name: string;
  phone: string;
  salaryDate: number;
  age: number;
  sex: string;
  accountId: string;
  address: string;
  zalo: string;
  fb: string;
  twitter: string;
  avatar: string;
  cccD1: string;
  cccD2: string;
  driveLisence1: string;
  driveLisence2: string;
  status: number;
  statusRec: number;
  tgtn: Date;
  nguoiDung: string;
  tgtN2: Date;
  nguoiDung2: string;
  note: string;
  dateStartWork: string;
  pathImageCCCD: string;
  pathImageLicense: string;
}
export interface IDriverTicket {
  id: string;
  driverId: string;
  ticketId: string;
  salaryDate: number;
  day: number;
  date: string | null;
  giaTR: string;
  status: number;
  driver: IDriver;
  type: DriverTicketType;
  isDriverMotor: boolean;
  carSalary: number;
}
export enum DriverTicketType {
  HAS_TICKET = 0,
  NOT_TICKET = 1,
}
export interface IMotorbike {
  id: string;
  name: string;
  motorNumber: string;
  status: number;
  seriNumber: string;
  machineNumber: string | null;
  registrationDate: string;
}
export interface IMotorbikeTicket {
  ticketId: string;
  motorbikeId: string;
  date: string;
  status: number;
  id: string;
  motorbike: IMotorbike;
  typeMotor: MotorTicketType;
}
export enum MotorTicketType {
  Jasmine = 0,
  /// <summary>
  /// Xe Nhân viên có vé
  /// </summary>
  NhanVienVe = 1,
  /// <summary>
  /// Xe Nhân viên ko vé
  /// </summary>
  NhanVien = 2,
  /// <summary>
  /// Xe chung với khách cùng group
  /// </summary>
  Chung = 3,
  /// <summary>
  /// Xe riêng của khách
  /// </summary>
  Rieng = 4,
  /// <summary>
  /// Xe thuê ngoài
  /// </summary>
  ThueNgoai = 5,
}
export interface IOperatorDriver extends IDriver {
  datA_Reward_Drivers: IRewardDriver[];
  monthSalary?: number;
}
export interface IOperatorMotorbike extends IMotorbike {
  datA_Note_MotorBikes: INoteMotorbike[];
}
export interface IUpdateOperatorMotorbike {
  motorBikeId: string;
  date: string | null;
  note: string;
  id?: string;
}
export interface INoteMotorbike {
  createdTime: string;
  modifiedTime: string;
  createBy: string;
  modifiedBy: string;
  motorBikeId: string;
  date: string | null;
  note: string;
  id: string;
}
export enum IOperatorMotorbikeStatus {
  AVAILABLE = 0,
  START = 1,
  RUNNING = 2,
  END = 3,
  UNAVAILABLE = 4,
}
export interface IRewardDriver {
  reward: number;
  note: string;
  fine: number;
}
export enum IssueType {
  BREAKDOWN_MOTORBIKE = 0,
  CHANGEROOM = 1,
  CANCELTOUR = 2,
  FUEL = 3,
  OUTER_FUEL = 4,
  EXTRA_MEAL = 5,
  EXTRA_SLEPT = 6,
  CHANGE_TOUR = 7,
  CHANGE_DRIVER = 8,
  CHANGE_ROOM_DVT = 9,
  EXTRA_SLEPT_DVT = 10,
}
export interface ISaveDriverTicket {
  ticketId: string | undefined;
  driverId: string;
  driverTicketId?: string;
}
export interface ISaveMotorbikeTicket {
  ticketId: string | undefined;
  motorbikeId: string | null;
  motorbikeTicketId?: string;
  typeMotor: MotorTicketType;
}

export enum StatusEnum {
  AVAILABLE = 0,
  USING = 1,
  UNAVAILABLE = 2,
}

export interface ICommonResult {
  firstRowIndex: number;
  lastRowIndex: number;
  page: number;
  pageCount: number;
  pageSize: number;
  queryCount: number;
}

export interface IGroupsResult extends ICommonResult {
  results: IGroup[];
}
export interface IGroupTicketsResult extends ICommonResult {
  results: IGroupTicket[];
}
export interface IDriversResult extends ICommonResult {
  results: IDriver[];
}
export interface IMotorbikesResult extends ICommonResult {
  results: IMotorbike[];
}
export interface IIssuesResult extends ICommonResult {
  results: IIssue[];
}
export interface IAgentsResult extends ICommonResult {
  results: IAgent[];
  search?: string;
}
export interface IAgentAccountsResult extends Omit<ICommonResult, "results"> {
  results: IAccount[];
  search?: string;
}
export interface IBookingsResult extends ICommonResult {
  results: IBooking[];
  search?: string;
}
export interface ITicketsOperatorResult extends ICommonResult {
  results: IBookingTicket[];
}

export interface ICreateDriver {
  firstName: string;
  lastName: string;
  phone: string;
  age: 0;
  sex: string;
  accountId: string;
  address: string;
  zalo: string;
  cccD1: string;
  driveLisence1: string;
  userName: string;
  password: string;
  email: string;
  note?: string;
  dateStartWork: string;
  pathImageCCCD?: string;
  pathImageLicense?: string;
}

export interface IUpdateDriver extends ICreateDriver {
  id: string;
}
export interface ICreateMotorbike {
  name: string;
  motorNumber: string;
  machineNumber?: string;
  registrationDate: string;
}

export interface IUpdateMotorbike extends ICreateMotorbike {
  id: string;
}

export interface IAssignRole {
  roleName: RoleEnum;
  userId: string;
}

export interface IPayMethod extends Omit<ICodePay, "id"> {}
export interface IPaymentMethodBooking {
  paymentCode: string;
  paymentName?: string;
  amount: number;
  // paymethod?: IPayMethod | null;
  status?: PaymentMethodBookingStatus;
}

export enum PaymentMethodBookingStatus {
  Pay = 0,
  Refund = 2,
}
export enum IssueStatus {
  Init = 0,
  ComfirmByAdmin = 2,
  Erase = 1,
  Approve = 3,
  Reject = 4,
}

export interface IConfirmNightAudit {
  driver: {
    id: string;
    driverId: string;
    ticketId: string;
    day: 0;
  }[];
}
export interface IDriverFilterValue extends IParams {}
export interface IMotorbikeFilterValue extends IParams {}
// export interface IDriverFilterValue {
//   startDate: string;
//   endDate: string;
//   isAvailable?: boolean;
// }
export interface IOperatorDriverFilterValue extends IParams {
  dateTime?: string | null;
}
export interface IOperatorMotorbikeFilterValue extends IParams {
  dateTime?: string | null;
  statusBike?: IOperatorMotorbikeStatus;
}
export interface IOperatorTicketFilterValue extends IParams {
  BookingDate?: string;
  TourStartDate?: string;
  BusStartDate?: string;
  BusEndDate?: string;
  hasJasmineKeep?: boolean;
  StatusThuHo?: ThuHoStatus;
  AgentId?: string;
  isEndTour?: boolean;
}
export interface IExportOpTicket extends IParams {
  AgentId?: string;
  TourStatus?: TourStatus;
  isGroupByAgent?: boolean;
  sortBy?: number;
}

export interface INightBookingFilterValue extends IParams {
  onlyThuHo?: boolean;
}
export enum ThuHoStatus {
  NONE = 0, // khong can thu ho
  NOT_YET = 3, // co thu ho nhung chua tra
  DONE = 4, // co thu ho nhung da tra
}
export interface IOperatorMotorbikeCountValue {
  countAvailable: number;
  countEnd: number;
  countRunning: number;
  countStart: number;
  countUnavailable: number;
}
export interface IDriverParams extends IParams {
  startDate?: string | null;
  endDate?: string | null;
  isAvailable?: boolean;
}
export interface IGroupTicketsParams extends IParams {
  tourId?: string;
  agentId?: string;
  selfDrive?: IsDriverType;
}
export interface ICreateReward {
  date: string | null;
  reward: number;
  note: string;
  fine: number;
  driverId: string;
}
export interface IAgentWithSummarized extends IAgent {
  summarizedAgentMonthlyDto: {
    revenue: number;
    ticketRevenue: number;
    busRevenue: number;
    munberOfTicket: number;
    refund: number;
    bonus: number;
  };
}
export interface IExtraService {
  id: string;
  name: string;
  code: string;
  accountCode: string;
  description: string | null;
}
export enum ExtraServiceCode {
  PARKING = "DV1",
  LUGGAGE = "DV2",
  CHANGE_VEHICLE = "DV3",
  CHANGE_ROOM = "DV4",
}
export interface ISupplierJobItem {
  id: string;
  createdTime?: string;
  modifiedTime?: string;
  createBy?: string | null;
  modifiedBy?: string | null;
  name: string;
  supplierId: string;
  type: JobTicketType;
  typeItem: number;
  rate: number;
}
export enum RoomTemplateType {
  EXTRA = 0,
  CHANGE_ROOM = 1,
}
export interface IHangHoaItem {
  id: string;
  supplierId: string | null;
  name: string;
  price: number;
  type: number;
  description: string | null;
}
export interface ITicketOperatorItem extends IGroupTicket {
  moneyBooking: number;
  moneyBookingDebt: number;
  moneyBookingPaid: number;
  jasmineKeepMoney: number;
  sellerFirstName: string | null;
  sellerLastName: string | null;
  busStartDate: string;
  busStartName: string | null;
  busEndDate: string;
  busEndName: string | null;
  bookingCode: string | null;
  status: number;
  isErase: boolean;
  groupId: string | null;
  driver_Tickets: IDriverTicket[];
  motorbike_Ticket: IMotorbikeTicket[];
  dmuC_Tour: ITour;
  createdTime: string;
  modifiedTime: string;
  datA_TOUR_CHHangHoaDichVuKhacs: ITicket_ExtraService[];
  id: string;
  name: string;
  tourId: string;
  fullName: string;
  email: string;
  dockerId: string;
  bookingId: string;
  agentId: string | null;
  passportId: string;
  driverPriceId: string;
  phone: string;
  startDate: string | null;
  endDate: string | null;
  price: number;
  statusThuHo: ThuHoStatus;
  tourStatus: TourStatus;
  daThanhToanIssue?: boolean;
  comboTourId: string;
  selfMotor?: SelfMotor;
  dmuC_MotorPriceId?: string;
}
export enum TourStatus {
  Init = 0,
  ConfirmByNCC = 3,
  CheckIn = 4,
  Running = 5,
  EndTour = 6,
}
export interface NightBookingRevenue {
  totalBookings: number;
  totalTicket: number;
  ticketRevenue: number;
  arrivedBusRevenue: number;
  leaveBusRevenue: number;
  dichVuThemRevenue: number;
  thuHoRevenue: number;
  modifiedRevenue: number;
}
export interface INightBookingTicket extends IBookingTicket {
  bookingCode: string | null;
  agentCode: string | null;
  sellerFirstName: string | null;
  sellerLastName: string | null;
  jasmineKeepMoney: number;
  statusThuHo: ThuHoStatus;
}
export interface IComboTourParams extends IParams {
  dateTime?: string | null;
  typeCombo?: number;
}

export enum ComboTourItemType {
  HangHoa = 0,
  RoomTemplate = 1,
  DriverPrice = 2,
  Bus = 3,
  Tour = 4,
  MotorPrice = 5,
}

// export interface IComboTourItem_Driver {
//   IsDriver: IsDriverType;
//   Discount_Money?: string;
//   CreatedTime: string;
//   ModifiedTime: string;
//   CreateBy: string | null;
//   ModifiedBy: string | null;
//   Id: string;
//   Description?: string;
//   CountDay: string;
//   ItemId: string;
//   Price: number;
//   ItemType: ComboTourItemType.DriverPrice;
// }
// export interface IComboTourItem_Tour {
//   CreatedTime: string;
//   ModifiedTime: string;
//   CreateBy: string | null;
//   ModifiedBy: string | null;
//   Id: string;
//   Name?: string;
//   Description?: string;
//   Status?: string;
//   CountDay: number;
//   AccountCode?: string | null;
//   PathFile?: string;
//   ItemId: string;
//   Price: number;
//   ItemType: ComboTourItemType.Tour;
// }
// export interface IComboTourItem_Motor {
//   CreatedTime: string;
//   ModifiedTime: string;
//   CreateBy: string | null;
//   ModifiedBy: string | null;
//   Id: string;
//   Value?: SelfMotor;
//   Description?: string;
//   CountDay: string;
//   AccountCode?: string | null;
//   ItemId: string;
//   Price: number;
//   ItemType: ComboTourItemType.MotorPrice;
// }
export interface IComboTourItem {
  IsDriver: IsDriverType;
  Discount_Money?: string;
  CreatedTime: string;
  ModifiedTime: string;
  CreateBy: string | null;
  ModifiedBy: string | null;
  Id: string;
  Name?: string;
  Description?: string;
  Status?: string;
  CountDay: number | string;
  AccountCode?: string | null;
  PathFile?: string;
  ItemId: string;
  Price: number;
  Value?: SelfMotor;
  ItemType: ComboTourItemType;
  BusId?: string;
  RouterStationId?: string;
  GiaHienThi?: number;
  DMUC_Router_Station: {
    StationId: string;
    RouterId: string;
    Id: string;
    DMUC_Router: IRouter_CapitalCase;
    DMUC_Station: IStation_CapitalCase;
  } | null;
}
export interface IComboTour {
  Id: string;
  Status: number;
  ListItem: IComboTourItem[];
  Name: string;
  Description: string;
  AccCode: string | null;
  Type: ComboTourType;
  Title: string;
  SubTitle: string;
  Price: number;
  GiaHienThi: number;
  CreatedTime: string;
  ModifiedTime: string;
  CreateBy: string | null;
  ModifiedBy: string | null;
  Thumbnail: string;
}
export enum ComboTourType {
  NO_COMBO = 0,
  TWO_WAY_COMBO = 1,
  CABIN_COMBO = 2,
}
