import { PaginationProps, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { getAllExtraServices } from "api/extraService.api";
import { getAllRouterStationBuses } from "api/routerStationBus.api";
import Utils from "common/Utils";
import {
  IExtraService,
  INightBookingTicket,
  IRouterStationBus,
  ThuHoStatus,
} from "common/define-types";
import moment from "moment";
import { useEffect, useState } from "react";
import { fetchNightBookings } from "store/slice/NightBooking.slice";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "../NightAuditorBooking.module.css";

const NightAuditorBookingTable = () => {
  const dispatch = useDispatchRoot();
  const [buses, setBuses] = useState<IRouterStationBus[]>([]);
  const [extraServices, setExtraServices] = useState<IExtraService[]>([]);

  const selectLoading = useSelectorRoot(
    (state) => state.nightBooking.isLoading
  );
  const selectNightBookingTickets = useSelectorRoot(
    (state) => state.nightBooking.nightBookingTickets
  );
  const selectNightBookingResult = useSelectorRoot(
    (state) => state.nightBooking.nightBookingResult
  );

  useEffect(() => {
    getAllRouterStationBuses({
      dateTime: moment.utc().toISOString(),
    }).subscribe(
      (res) => {
        if (res && res.length) setBuses(res);
      },
      (err) => console.log(err)
    );
    getAllExtraServices().subscribe(
      (res) => {
        if (res && res.length) setExtraServices(res);
      },
      (err) => console.log(err)
    );
  }, []);

  const getMessageThuHo = (statusThuho: ThuHoStatus) => {
    switch (statusThuho) {
      case ThuHoStatus.NOT_YET:
        return " - Chưa trả";
      case ThuHoStatus.DONE:
        return " - Đã trả";

      default:
        return "";
    }
  };

  const columns: ColumnsType<INightBookingTicket> = [
    {
      title: "Mã booking",
      dataIndex: "bookingCode",
      width: 180,
    },
    {
      title: "Jasmine thu hộ",
      width: 150,
      render: (_, record) => (
        <>{`${Utils.formatCurrency(record.jasmineKeepMoney)}${getMessageThuHo(
          record.statusThuHo
        )}`}</>
      ),
    },
    {
      title: "Mã vé",
      dataIndex: "name",
      width: 150,
    },
    {
      title: "Tên khách hàng",
      dataIndex: "fullName",
      width: 200,
    },
    {
      title: "Mã đại lý",
      dataIndex: "agentCode",
      width: 130,
    },
    {
      title: "Người bán vé",
      ellipsis: true,
      width: 130,
      render: (_, record) => (
        <>{(record.sellerLastName || "") + (record.sellerFirstName || "")}</>
      ),
    },
    {
      title: "Tiền vé",
      width: 130,
      dataIndex: "price",
      render: (value) => value && Utils.formatCurrency(value),
    },
    {
      title: "Ngày đi tour",
      dataIndex: "startDate",
      width: 130,
      render: (value) => !!value && moment(value).format(Utils.dateFormat),
    },
    {
      title: "Tiền Bus đến",
      width: 150,
      render: (_, record) =>
        record.busTicketC1 && Utils.formatCurrency(record.busTicketC1.price),
    },
    {
      title: "NCC Bus đến",
      width: 150,
      render(_, record) {
        if (record.busTicketC1) {
          const foundRouterStationBus = buses.find(
            (bus) => bus.id === record.busTicketC1?.routerStationBusId
          );
          return foundRouterStationBus?.dmuC_Router_Station?.dmuC_Station.name;
        }
        return <></>;
      },
    },
    {
      title: "Tiền Bus đi",
      width: 150,
      render: (_, record) =>
        record.busTicketC2 && Utils.formatCurrency(record.busTicketC2.price),
    },
    {
      title: "NCC Bus đi",
      width: 150,
      render(_, record) {
        if (record.busTicketC2) {
          const foundRouterStationBus = buses.find(
            (bus) => bus.id === record.busTicketC2?.routerStationBusId
          );
          return foundRouterStationBus?.dmuC_Router_Station?.dmuC_Station.name;
        }
        return <></>;
      },
    },
    {
      title: "Dịch vụ thêm",
      width: 150,
      render(_, record) {
        if (
          record.datA_TOUR_CHHangHoaDichVuKhacs &&
          record.datA_TOUR_CHHangHoaDichVuKhacs.length > 0
        ) {
          const filteredService = extraServices.filter((service) => {
            return record.datA_TOUR_CHHangHoaDichVuKhacs?.some(
              (item) => item.dmuC_CHHangHoaDichVuKhacId === service.id
            );
          });
          return (
            <>{filteredService.map((service) => service.name).join(", ")}</>
          );
        }
        return <></>;
      },
    },
  ];

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectNightBookingResult) {
      dispatch(
        fetchNightBookings({
          ...selectNightBookingResult,
          page,
        })
      );
    }
  };

  return (
    <Table
      dataSource={selectNightBookingTickets || []}
      columns={columns}
      scroll={{ x: 1050 }}
      className={`${styles.table} ticketTable horizontalDragScroll`}
      pagination={{
        current: selectNightBookingResult ? selectNightBookingResult.page : 1,
        pageSize: selectNightBookingResult
          ? selectNightBookingResult.pageSize
          : 10,
        total: selectNightBookingResult
          ? selectNightBookingResult.queryCount
          : 0,
        // total: selectNightBookingTickets.length,
        showSizeChanger: false,
        onChange: handleChangePage,
      }}
      loading={selectLoading}
      onChange={(_, filters) => console.log(filters)}
      rowKey={"id"}
    />
  );
};

export default NightAuditorBookingTable;
