import { Form, FormInstance, Input } from "antd";
// import { countriesData } from "DummyData/countries";
import styles from "./Billing.module.css";
import { CustomPhoneInput } from "components/Input/CustomPhoneInput";

interface IProps {
  form: FormInstance<any>;
  readOnly?: boolean;
}
export const PayeeForm = ({ form, readOnly = false }: IProps) => {
  return (
    <Form
      className={`${styles.payeeForm} noMargin_form-item customScroll`}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      form={form}
    >
      <Form.Item
        label={"Họ đệm"}
        name={"lastName"}
        // rules={[
        //   {
        //     required: true,
        //     message: "Không được bỏ trống",
        //   },
        // ]}
      >
        <Input readOnly={readOnly} />
      </Form.Item>
      <Form.Item
        label={"Tên"}
        name={"firstName"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input readOnly={readOnly} />
      </Form.Item>
      <Form.Item
        label={"Số điện thoại"}
        name={"userPayPhone"}
        // rules={[
        //   {
        //     required: true,
        //     message: "Không được bỏ trống",
        //   },
        // ]}
      >
        {readOnly ? <Input readOnly={readOnly} /> : <CustomPhoneInput />}
      </Form.Item>
      <Form.Item
        label={"Email"}
        name={"userPayEmail"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
          {
            type: "email",
            message: "Email không hợp lệ",
          },
        ]}
      >
        <Input readOnly={readOnly} />
      </Form.Item>
      <Form.Item label={"Ghi chú"} name={"note"}>
        <Input.TextArea autoSize={{ maxRows: 4 }} readOnly={readOnly} />
      </Form.Item>
    </Form>
  );
};
