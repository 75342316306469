import { Select } from "antd";
import { RoomTemplateType } from "common/define-types";
import React, { useEffect } from "react";
import { fetchRoomTemplates } from "store/slice/RoomTemplateSlice";
import { useSelectorRoot, useDispatchRoot } from "store/store";

interface IProps {
  value?: string | string[] | null;
  onChange?: (arg: string | string[]) => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  valuePropName?: "supplierId" | "id" | "name";
  acceptType?: RoomTemplateType[];
  multiple?: boolean;
}
export const RoomTemplateSelect = ({
  value,
  onChange = () => {},
  style,
  readOnly,
  valuePropName = "id",
  acceptType,
  multiple = false,
}: IProps) => {
  const selectRoomTemplates = useSelectorRoot(
    (state) => state.roomTemplate.roomTemplates
  );
  const selectIsRefreshing = useSelectorRoot(
    (state) => state.login.isRefreshing
  );
  const dispatch = useDispatchRoot();
  useEffect(() => {
    !selectIsRefreshing && dispatch(fetchRoomTemplates());
  }, [dispatch, selectIsRefreshing]);
  return (
    <Select
      options={selectRoomTemplates
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t[valuePropName] === value[valuePropName])
        )
        .filter(
          (room) => !acceptType || acceptType.some((type) => type === room.type)
        )
        .map((roomTemplate) => ({
          label: roomTemplate.name,
          value: roomTemplate[valuePropName],
        }))}
      mode={multiple ? "multiple" : undefined}
      value={value}
      style={style}
      onChange={(value) => {
        onChange(value);
      }}
      showSearch
      disabled={readOnly}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};
