import {
  Button,
  Divider,
  // InputNumber,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
} from "antd";
import { IOperatorTicketFilterValue } from "common/define-types";
import { useState } from "react";

interface IProps {
  handleChangeFilter?: (
    key: keyof IOperatorTicketFilterValue,
    value: any
  ) => void;
}
enum OptionValue {
  END_DAY = "END_DAY",
  ORDINAL_DAY = "DAY",
}
const selectLabels = {
  [OptionValue.END_DAY]: "Về trong hôm nay",
  [OptionValue.ORDINAL_DAY]: "Ngày",
};
const TicketStatusSelect = ({ handleChangeFilter }: IProps) => {
  const [open, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState<string | null>(null);
  const [radioValue, setRadioValue] = useState(null);
  // const [ordinalDay, setOrdinalDay] = useState(1);

  const onChangeRadio = (e: RadioChangeEvent) => {
    const value = e.target.value;
    setRadioValue(value);
  };
  const handleApply = () => {
    if (radioValue === OptionValue.ORDINAL_DAY) {
      // setSelectValue(`${selectLabels[radioValue]} ${ordinalDay}`);

      handleChangeFilter && handleChangeFilter("isEndTour", false);
    } else if (radioValue === OptionValue.END_DAY) {
      setSelectValue(selectLabels[radioValue]);
      handleChangeFilter && handleChangeFilter("isEndTour", true);
    }
    setOpen(false);
  };
  //[14-12-2023][Phuc Thinh]
  // allow clear select
  const handleClear = () => {
    setRadioValue(null);
    setSelectValue(null);
    setOpen(false);
    handleChangeFilter && handleChangeFilter("isEndTour", undefined);
  };

  return (
    <Select
      value={selectValue}
      open={open}
      onDropdownVisibleChange={(visible) => visible && setOpen(visible)}
      style={{ minWidth: 200 }}
      allowClear
      placeholder={"-- Chọn trạng thái --"}
      onClear={handleClear}
      dropdownRender={(menu) => (
        <Space
          direction="vertical"
          style={{ width: "100%", padding: 8, boxSizing: "border-box" }}
        >
          <Radio.Group onChange={onChangeRadio} value={radioValue}>
            <Space direction="vertical">
              {/* <Radio value={OptionValue.ORDINAL_DAY}>
                Ngày:
                {OptionValue.ORDINAL_DAY === radioValue ? (
                  <InputNumber
                    style={{ marginLeft: 10 }}
                    required
                    min={1}
                    value={ordinalDay}
                    onChange={(value) => value && setOrdinalDay(value)}
                  />
                ) : null}
              </Radio> */}
              <Radio value={OptionValue.END_DAY}>Về trong hôm nay</Radio>
            </Space>
          </Radio.Group>
          <Divider style={{ marginBlock: 4 }} />
          <Space style={{ justifyContent: "flex-end", width: "100%" }}>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="primary" onClick={handleApply}>
              Apply
            </Button>
          </Space>
        </Space>
      )}
    />
  );
};

export default TicketStatusSelect;
