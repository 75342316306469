import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import { ICreateGroup, IUpdateGroup } from "./types/group";
import { IGetGroupsParams, IParams } from "common/define-types";
import { IUpdateGroupTicket } from "store/slice/GroupSlice";
import Utils from "common/Utils";

const api = `${SYSTEM_CONSTANTS.API_URL}/Group`;

export const getGroupById = (id: string | null): Observable<any> => {
  return HttpClient.get(`${api}/${id || null}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const getAllGroups = (params?: IGetGroupsParams): Observable<any> => {
  if (params) {
    return HttpClient.get(`${api}?${Utils.getParamsString(params)}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(`${api}`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};

export const getCurrentGroups = (
  currentPage: number,
  pageSize: number
): Observable<any> => {
  return HttpClient.get(
    `${api}/groups/current?page=${currentPage}&pageSize=${pageSize}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const createGroup = (body: ICreateGroup): Observable<any> => {
  return HttpClient.post(`${api}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const updateGroup = (body: IUpdateGroup): Observable<any> => {
  return HttpClient.put(`${api}/${body.id}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
    },
  }).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const deleteGroupById = (groupId: string): Observable<any> => {
  return HttpClient.delete(`${api}/${groupId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};

export const getIssuesByGroupId = (
  groupId: string | null,
  params?: IParams
): Observable<any> => {
  if (params) {
    return HttpClient.get(
      `${api}/groups/${groupId}/issues?${Utils.getParamsString(params)}`
    ).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(`${api}/groups/${groupId}/issues`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};
export const getDriversByGroupId = (
  groupId: string | null,
  params?: IParams
): Observable<any> => {
  if (params) {
    return HttpClient.get(
      `${api}/groups/${groupId}/driver?${Utils.getParamsString(params)}`
    ).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(`${api}/groups/${groupId}/driver`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};
export const getMotorbikesByGroupId = (
  groupId: string | null,
  params?: IParams
): Observable<any> => {
  if (params) {
    return HttpClient.get(
      `${api}/groups/${groupId}/motorbike?${Utils.getParamsString(params)}`
    ).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  } else {
    return HttpClient.get(`${api}/groups/${groupId}/motorbike`).pipe(
      map(
        (res) => (res as any) || null,
        catchError((e: AjaxError) => throwError(e))
      )
    );
  }
};
// export const getTicketsByGroupId = (groupId: string | null, params?: IParams): Observable<any> => {
//     if (params) {
//         const { page, pageSize } = params;
//         return HttpClient.get(`${api}/groups/${groupId}/tickets?
//         ${ page ? `page=${page}&` : ''}
//         ${ pageSize ? `size=${pageSize}&` : ''}`).pipe(
//             map(
//                 res => (res as any) || null,
//                 catchError((e: AjaxError) => throwError(e)),
//             ),
//         );
//     }
//     else {
//         return HttpClient.get(`${api}/groups/${groupId}/tickets`).pipe(
//             map(
//                 res => (res as any) || null,
//                 catchError((e: AjaxError) => throwError(e)),
//             ),
//         );
//     }
// };

export const assignGroupIdForTickets = (
  body: IUpdateGroupTicket
): Observable<any> => {
  return HttpClient.put(
    `${api}/groups/${body.group.id}/tickets`,
    JSON.stringify(body.tickets.map((ticket) => ticket.id)),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
// [07-12-2023][Phuc Thinh][Feature/14882]
// Add API unassign Ticket
export const unassignTicketOnGroupId = (params: {
  groupId: string;
  ticketId: string;
}): Observable<any> => {
  return HttpClient.delete(
    `${api}/${params.groupId}/ticket/${params.ticketId}`
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getJobTourOnGroupId = (
  groupId: string,
  jobItemId: string
): Observable<any> => {
  return HttpClient.get(`${api}/groups/${groupId}/jobTour/${jobItemId}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const turnOnGroup = (groupId: any): Observable<any> => {
  return HttpClient.put(
    `${api}/groups/${groupId}/status`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
