import { useSelectorRoot } from "store/store";
import styles from "./Billing.module.css";
import { TourDetailItem } from "./TourDetailItem";
import { Collapse, Divider, Typography } from "antd";
import Utils from "common/Utils";
//[14-12-2023][Phuc Thinh][Feature/14901]
//Change Tour to TourCombo
export const TourDetail = () => {
  const selectComboToursSelected = useSelectorRoot(
    (state) => state.booking.selectedComboTours
  );

  return (
    <section className={styles.bigCard}>
      <Typography.Title level={4} className={styles.bigCard_title}>
        Thông tin hóa đơn
      </Typography.Title>
      <div className={`${styles.toursWrapper}`}>
        <div className={`${styles.tours} customScroll`}>
          {selectComboToursSelected && selectComboToursSelected.length > 0 ? (
            <Collapse
              ghost
              expandIconPosition="end"
              items={selectComboToursSelected.map((selectedComboTour) => ({
                key: `${selectedComboTour.Id}`,
                label: (
                  <Typography.Text strong style={{ fontSize: 16 }}>
                    {`Hà Giang Tour: ${selectedComboTour.Title || ""} ${
                      selectedComboTour.SubTitle || ""
                    } - `}
                    <Typography.Text style={{ color: 'grey', fontSize: 14 }} italic>{selectedComboTour.Description}</Typography.Text>
                  </Typography.Text>
                ),
                children: (
                  <TourDetailItem
                    tour={selectedComboTour}
                    key={selectedComboTour.Id}
                  />
                ),
              }))}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={styles.totalWrapper}>
        <div className={styles.totalItem}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            Tổng tiền vé
          </Typography.Text>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {Utils.formatCurrency(
              Utils.calculateTotal(selectComboToursSelected)
            )}
          </Typography.Text>
        </div>
        <div className={styles.totalItem}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            Tổng tiền Bus
          </Typography.Text>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {Utils.formatCurrency(
              Utils.calculateBusTotal(selectComboToursSelected)
            )}
          </Typography.Text>
        </div>
        <div className={styles.totalItem}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            Tổng tiền dịch vụ thêm
          </Typography.Text>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {Utils.formatCurrency(
              Utils.calculateExtraServiceTotal(selectComboToursSelected)
            )}
          </Typography.Text>
        </div>
        <Divider style={{ marginBlock: 12 }} />
        <div className={styles.totalItem}>
          <Typography.Text strong style={{ fontSize: 20 }}>
            {"Tổng"}
          </Typography.Text>
          <Typography.Text strong style={{ fontSize: 20 }}>
            {Utils.formatCurrency(
              Utils.calculateTotal(selectComboToursSelected) +
                Utils.calculateBusTotal(selectComboToursSelected) +
                Utils.calculateExtraServiceTotal(selectComboToursSelected)
            )}
          </Typography.Text>
        </div>
      </div>
    </section>
  );
};
