import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";

const api = `${SYSTEM_CONSTANTS.API_URL}/DMUC_CHHangHoaDichVuKhac`;

export const getAllExtraServices = (): Observable<any> => {
  return HttpClient.get(api).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
