import {
  DatePicker,
  Input,
  Pagination,
  PaginationProps,
  Skeleton,
  Typography,
} from "antd";
import styles from "../BookingRevenue.module.css";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { BookingItem } from "./BookingItem";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import {
  fetchBookings,
  setBookingQuery,
  setSelectedDate,
} from "store/slice/BookingRevenueSlice";
import dayjs from "dayjs";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Utils from "common/Utils";

export const BookingsList = () => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const selectBookingsResult = useSelectorRoot(
    (state) => state.bookingRevenue.bookingsResult
  );
  const selectBookings = useSelectorRoot(
    (state) => state.bookingRevenue.bookings
  );
  const selectBookingSelected = useSelectorRoot(
    (state) => state.bookingRevenue.bookingSelected
  );
  const selectIsBookingsLoading = useSelectorRoot(
    (state) => state.bookingRevenue.isLoading
  );
  const selectSelectedDate = useSelectorRoot(
    (state) => state.bookingRevenue.selectedDate
  );
  const { width: dimensionWidth } = useWindowDimensions();
  const dispatch = useDispatchRoot();

  const bookingsRefs = useRef<HTMLDivElement[]>([]);
  bookingsRefs.current = [];

  const addToRefs = (el: HTMLDivElement) => {
    if (el && !bookingsRefs.current.includes(el)) {
      bookingsRefs.current.push(el);
    }
  };
  useEffect(() => {
    const selectedBookingRef = bookingsRefs.current.find(
      (ref) => ref.ariaSelected === "true"
    );
    if (selectedBookingRef)
      selectedBookingRef.scrollIntoView({ block: "center" });
    // console.log(bookingsRefs.current.map((ref) => ref.ariaSelected === "true"));
  }, [selectBookingSelected]);

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    dispatch(
      fetchBookings({
        ...selectBookingsResult,
        page,
      })
    );
  };

  useEffect(() => {
    dispatch(setBookingQuery(""));
  }, [dispatch]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      const value = e.target.value;

      dispatch(setBookingQuery(value));
    }, 800);
    setTimer(timeoutId);
  };

  return (
    <div className={styles.listContainer}>
      <div
        className={styles.listContainer_header}
        style={{ justifyContent: "flex-start" }}
      >
        <Typography.Text strong style={{ fontSize: 20 }}>
          Danh sách Bookings
        </Typography.Text>
      </div>
      <div className={styles.searchContainer}>
        <DatePicker
          style={{ width: 150 }}
          format={Utils.dateFormat}
          onChange={(date) =>
            date && dispatch(setSelectedDate(date.toISOString()))
          }
          value={dayjs(selectSelectedDate)}
          allowClear={false}
        />
        <Input.Search
          allowClear
          onChange={handleSearch}
          style={{ flex: 1 }}
          placeholder="Tìm kiếm Booking"
        />
      </div>
      <div
        className={styles.listWrapper}
        style={
          dimensionWidth <= 520
            ? {
                minHeight:
                  selectBookings.length > 0
                    ? selectBookings.length >= 3
                      ? 180 * 3
                      : 180 * selectBookings.length
                    : 180,
              }
            : {}
        }
      >
        <div className={`${styles.listWrapper_List} customScroll`}>
          {selectIsBookingsLoading ? (
            <>
              <Skeleton avatar paragraph={{ rows: 4 }} />
              <Skeleton avatar paragraph={{ rows: 4 }} />
              <Skeleton avatar paragraph={{ rows: 4 }} />
              <Skeleton avatar paragraph={{ rows: 4 }} />
              <Skeleton avatar paragraph={{ rows: 4 }} />
            </>
          ) : selectBookings && selectBookings.length > 0 ? (
            selectBookings.map((booking) => {
              return (
                <BookingItem
                  booking={booking}
                  key={`Booking-${booking.id}`}
                  isActive={selectBookingSelected?.id === booking.id}
                  index={booking.index}
                  ref={addToRefs}
                />
              );
            })
          ) : (
            <EmptyHolder message="No booking yet" />
          )}
        </div>
      </div>
      <div className={styles.listContainer_footer}>
        <Pagination
          total={selectBookingsResult ? selectBookingsResult.queryCount : 0}
          pageSize={selectBookingsResult ? selectBookingsResult.pageSize : 10}
          current={selectBookingsResult ? selectBookingsResult.page : 1}
          showTotal={(total, range) =>
            `Hiển thị ${
              range[1] === 0 && range[1] - range[0] === 0
                ? 0
                : range[1] - range[0] + 1
            }/${total} booking`
          }
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};
