import { Button, Popconfirm, Typography, notification } from "antd";
import {
  DeleteOutlined,
  UserOutlined,
  PhoneOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import styles from "../Booking.module.css";
import moment from "moment";
import BusIcon from "image/icon/bus-icon.svg";
import {
  IComboTour,
  ITicket,
  PaymentMethodBookingStatus,
} from "common/define-types";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { selectTicket } from "store/slice/BookingSlice";
import Utils from "common/Utils";

interface IProps {
  ticket: ITicket;
  tour: IComboTour;
  isActive?: boolean;
  isValid?: boolean;
  handleDelete: (id: string) => void;
}
export const TourTicket = ({
  ticket,
  tour,
  handleDelete,
  isActive = false,
  isValid,
}: IProps) => {
  const dispatch = useDispatchRoot();
  const selectEditingBooking = useSelectorRoot(
    (state) => state.booking.editingBooking
  );
  const isTicketDeparted = Utils.checkDepartedTicket(ticket);

  const isTicketOncoming = Utils.checkOncomingTicket(ticket);

  const hasMoneyJasmineKeep =
    !!selectEditingBooking &&
    (selectEditingBooking.moneyJasmineKeep > 0 ||
      selectEditingBooking.paymentMethodBookings
        .filter(
          (paymethod) => paymethod.status === PaymentMethodBookingStatus.Pay
        )
        .some(
          (paymethod) =>
            // [4-12-2023] [Phuc Thinh] [Update paymentMethodBooking interface]
            paymethod.paymentCode === "90015"
        ));

  const handleSelectTicket = () => {
    if (ticket && tour && !isActive) {
      dispatch(
        selectTicket({
          ...ticket,
          comboTour: tour,
        })
      );
    }
  };

  return (
    <div
      className={`${styles.tourTicket} ${
        isActive
          ? styles.tourTicketActive
          : `${isValid ? `` : styles.tourTicketInvalid}`
      }`}
      onClick={handleSelectTicket}
    >
      <div className={styles.ticketInforWrapper}>
        <div className={styles.inforColumn}>
          <Typography.Text
            style={{ fontWeight: 700 }}
          >{`Khách: ${ticket.name}`}</Typography.Text>
          <Typography.Text>
            {moment.utc(ticket.startDate).format(Utils.dateFormat)}
          </Typography.Text>
        </div>
        <div className={styles.inforColumn}>
          <div className={styles.inforWithIcon}>
            <UserOutlined className={styles.inforIcon} />
            <Typography.Text>{ticket.fullName ?? "Tên"}</Typography.Text>
          </div>
          <div className={styles.inforWithIcon}>
            <PhoneOutlined className={styles.inforIcon} rotate={90} />
            <Typography.Text>{ticket.phone ?? "Số điện thoại"}</Typography.Text>
          </div>
        </div>
        <div className={styles.busColumn}>
          {(ticket.busTicketC1 || ticket.busTicketC2) && (
            <img src={BusIcon} alt="bus" />
          )}
          {!!ticket.dockerId && (
            <UsergroupAddOutlined
              style={{ fontSize: 22, color: "#2D3648", marginLeft: 10 }}
            />
          )}
        </div>
      </div>
      <div>
        {isValid ? (
          <Popconfirm
            title="Xóa vé"
            description={
              !!selectEditingBooking && isTicketOncoming
                ? "Nếu hủy vé sát giờ sẽ chịu phí phạt 20% tiền vé, bus, dịch vụ thêm,.."
                : "Bạn chắc chắc muốn xóa vé này ?"
            }
            onConfirm={(e: any) => {
              e?.stopPropagation();
              handleDelete(ticket.id);
            }}
            onCancel={(e: any) => e?.stopPropagation()}
            disabled={
              isTicketDeparted || (isTicketOncoming && hasMoneyJasmineKeep)
            }
          >
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              onClick={(e: any) => {
                e.stopPropagation();
                if (isTicketOncoming && hasMoneyJasmineKeep) {
                  notification.info({
                    message:
                      "Booking còn tiền Jasmine thu hộ nên không thể xóa vé này",
                  });
                }
              }}
              disabled={isTicketDeparted}
            />
          </Popconfirm>
        ) : (
          <Button
            type="text"
            danger
            icon={<DeleteOutlined />}
            onClick={(e: any) => {
              e.stopPropagation();
              handleDelete(ticket.id);
            }}
            disabled={
              isTicketDeparted || (isTicketOncoming && hasMoneyJasmineKeep)
            }
          />
        )}
      </div>
    </div>
  );
};
