import { Select, notification } from "antd";
import { getSupplierJobItems } from "api/supplier.api";
import { ISupplierJobItem, JobTicketType } from "common/define-types";
import { useEffect, useState } from "react";

interface IProps {
  value?: string | null;
  onChange?: (arg: string) => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  acceptType?: JobTicketType[];
  supplierJobItems?: ISupplierJobItem[];
}
const JobItemSelect = ({
  value,
  onChange = () => {},
  style,
  readOnly,
  acceptType,
  supplierJobItems,
}: IProps) => {
  const [jobItems, setJobItems] = useState<any>([]);
  useEffect(() => {
    if (!supplierJobItems) {
      getSupplierJobItems({ typeJobItem: 1 }).subscribe(
        (res) => {
          if (res && res.results) {
            setJobItems(
              res.results.filter((item: any) =>
                acceptType?.some((type) => type === item.type)
              )
            );
          }
        },
        (err) => {
          console.log(err);
          notification.error({ message: "Có lỗi xảy ra khi lấy danh sách" });
        }
      );
    }
  }, [acceptType, supplierJobItems]);

  const options = supplierJobItems || jobItems;
  return (
    <Select
      options={options.map((option: any) => ({
        label: option.name,
        value: option.id,
      }))}
      value={value}
      style={style}
      disabled={readOnly}
      onChange={(value) => onChange(value)}
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? "")
          .toString()
          .toLowerCase()
          .includes(input.toLowerCase())
      }
    />
  );
};

export default JobItemSelect;
