import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IOperatorTicketFilterValue,
  ITicketsOperatorResult,
  RootEpic,
  ITicketOperatorItem,
  IPaymentMethodBooking,
  ISelectedTicket,
  IGroupTicket,
} from "common/define-types";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { checkIn, getQuanLyTickets, uncheckIn } from "api/ticket.api";
import {
  updateBookingCollectionFee,
  updateBookingTicket,
} from "api/booking.api";
import { updatePaymentIssue } from "api/issue.api";
import { bookingSlice } from "./BookingSlice";
dayjs.extend(utc);
export interface TicketOperatorState {
  isLoading: boolean;
  isSubmitting: boolean;
  operator_tickets: ITicketOperatorItem[];
  errMsg: string | null;
  ticketResult: ITicketsOperatorResult | null;
  collectFeeBooking: ITicketOperatorItem | null;
  collectIssueFeeTicket: ITicketOperatorItem | null;
  filterValue: IOperatorTicketFilterValue;
}

const initialState: TicketOperatorState = {
  isLoading: false,
  isSubmitting: false,
  operator_tickets: [],
  errMsg: null,
  ticketResult: null,
  collectFeeBooking: null,
  collectIssueFeeTicket: null,
  filterValue: {
    startDate: dayjs.utc().startOf("day").toISOString(),
    endDate: dayjs.utc().add(5, "days").endOf("day").toISOString(),
    isEndTour: false,
  },
};

export const operatorTicketSlice = createSlice({
  name: "operatorTicketOperator",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchOperatorTickets: (
      state,
      action: PayloadAction<IOperatorTicketFilterValue | undefined>
    ) => {
      state.isLoading = true;
      state.isSubmitting = false;
      state.collectFeeBooking = null;
      state.collectIssueFeeTicket = null;
      state.errMsg = null;
    },
    setTickets: (state, action: PayloadAction<ITicketOperatorItem[]>) => {
      state.operator_tickets = action.payload;
      state.isLoading = false;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
    setTicketsResult: (
      state,
      action: PayloadAction<ITicketsOperatorResult>
    ) => {
      state.ticketResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    setFilterValue: (
      state,
      action: PayloadAction<IOperatorTicketFilterValue>
    ) => {
      state.filterValue = action.payload;
    },
    setCollectFeeBooking: (
      state,
      action: PayloadAction<ITicketOperatorItem | null>
    ) => {
      state.collectFeeBooking = action.payload;
    },
    setCollectIssueFeeTicket: (
      state,
      action: PayloadAction<ITicketOperatorItem | null>
    ) => {
      state.collectIssueFeeTicket = action.payload;
    },
    saveBookingCollectionFee: (
      state,
      action: PayloadAction<{
        bookingId: string;
        payload: IPaymentMethodBooking[];
        ticketId: string;
      }>
    ) => {
      state.isSubmitting = true;
    },
    //[12-12-2023][Phuc Thinh][Feature/14880]
    //Add feature collect issue feee
    saveIssueCollectionFee: (
      state,
      action: PayloadAction<{
        paymentMethods: IPaymentMethodBooking[];
        ticketId: string;
      }>
    ) => {
      state.isSubmitting = true;
    },
    //[15-12-2023][Phuc Thinh][Feature/14861]
    //Add feature update booking ticket
    saveBookingTicket: (
      state,
      action: PayloadAction<{
        paymentMethodBookingsConnect?: IPaymentMethodBooking[];
        paymentMethodBookingsDisable?: IPaymentMethodBooking[];
        ticketsUpdate: ISelectedTicket | ITicketOperatorItem | IGroupTicket;
      }>
    ) => {
      state.isSubmitting = true;
    },
    checkInTicket: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
    },
    uncheckInTicket: (state, action: PayloadAction<string>) => {
      state.isLoading = true;
    },
    clearFilterValue: (state) => {
      state.filterValue = {
        ...state.filterValue,
        search: "",
        BookingDate: undefined,
        BusStartDate: undefined,
        BusEndDate: undefined,
        StatusThuHo: undefined,
      };
    },
  },
});
const fetchOperatorTickets$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchOperatorTickets.match),
    switchMap((re) => {
      return getQuanLyTickets({
        // ...state$.value.operatorTicketOperator.ticketOperatorsResult,
        ...state$.value.operatorTicket.filterValue,
        ...re.payload,
        pageSize: re.payload?.pageSize || 25,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const { results, ...rest } = res;
            return [
              operatorTicketSlice.actions.setTickets(results ?? []),
              operatorTicketSlice.actions.setTicketsResult(rest),
            ];
          } else {
            return [
              operatorTicketSlice.actions.setTickets([]),
              operatorTicketSlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          operatorTicketSlice.actions.setTickets([]),
          operatorTicketSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi thông tin các vé"
          ),
        ])
      );
    })
  );
const fetchWhenFilter$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setFilterValue.match),
    switchMap((re) => {
      return [operatorTicketSlice.actions.fetchOperatorTickets(re.payload)];
    })
  );

const saveBookingCollectionFee$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveBookingCollectionFee.match),
    switchMap((re) => {
      return updateBookingCollectionFee(
        re.payload.bookingId,
        re.payload.payload,
        re.payload.ticketId
      ).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [
              operatorTicketSlice.actions.fetchOperatorTickets({
                ...state$.value.operatorTicket.filterValue,
                ...state$.value.operatorTicket.ticketResult,
              }),
            ];
          } else {
            return [operatorTicketSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          operatorTicketSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi lưu thông tin tiền thu hộ"
          ),
        ])
      );
    })
  );
const saveIssueCollectionFee$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveIssueCollectionFee.match),
    switchMap((re) => {
      return updatePaymentIssue(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [
              operatorTicketSlice.actions.fetchOperatorTickets({
                ...state$.value.operatorTicket.filterValue,
                ...state$.value.operatorTicket.ticketResult,
              }),
            ];
          } else {
            return [operatorTicketSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          operatorTicketSlice.actions.setErrMsg(
            e.response?.Message ||
              "Có lỗi xảy ra khi lưu thông tin tiền phát sinh"
          ),
        ])
      );
    })
  );
const saveBookingTicket$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveBookingTicket.match),
    switchMap((re) => {
      return updateBookingTicket({
        ticketId: re.payload.ticketsUpdate.id,
        body: re.payload,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [
              operatorTicketSlice.actions.fetchOperatorTickets({
                ...state$.value.operatorTicket.filterValue,
                ...state$.value.operatorTicket.ticketResult,
              }),
              bookingSlice.actions.selectTicket(null),
            ];
          } else {
            return [operatorTicketSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          operatorTicketSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi lưu thông tin vé"
          ),
        ])
      );
    })
  );
// [5-12-2023][Phuc Thinh] [add checkbox for checkin ticket]
// add epic to check in ticket
const checkInTicket$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(checkInTicket.match),
    switchMap((re) => {
      return checkIn(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [
              operatorTicketSlice.actions.fetchOperatorTickets({
                ...state$.value.operatorTicket.filterValue,
                ...state$.value.operatorTicket.ticketResult,
              }),
            ];
          } else {
            return [operatorTicketSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          operatorTicketSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi check in"
          ),
        ])
      );
    })
  );
// [15-12-2023][Phuc Thinh] [add checkbox for checkin ticket]
// add epic to uncheck in ticket
const uncheckInTicket$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(uncheckInTicket.match),
    switchMap((re) => {
      return uncheckIn(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [
              operatorTicketSlice.actions.fetchOperatorTickets({
                ...state$.value.operatorTicket.filterValue,
                ...state$.value.operatorTicket.ticketResult,
              }),
            ];
          } else {
            return [operatorTicketSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          operatorTicketSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi uncheck in"
          ),
        ])
      );
    })
  );

export const {
  fetchOperatorTickets,
  setTickets,
  setErrMsg,
  setTicketsResult,
  setFilterValue,
  clearFilterValue,
  saveBookingCollectionFee,
  setCollectFeeBooking,
  setCollectIssueFeeTicket,
  saveIssueCollectionFee,
  saveBookingTicket,
  checkInTicket,
  uncheckInTicket,
} = operatorTicketSlice.actions;

export const OperatorTicketEpics = [
  fetchOperatorTickets$,
  fetchWhenFilter$,
  saveBookingCollectionFee$,
  saveIssueCollectionFee$,
  checkInTicket$,
  uncheckInTicket$,
  saveBookingTicket$,
];

export const operatorTicketReducer = operatorTicketSlice.reducer;
