import { Modal, Spin, Typography, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import Utils from "common/Utils";
import {
  IBooking,
  IPaymentMethodBooking,
  ITicketOperatorItem,
  ThuHoStatus,
} from "common/define-types";
import { MethodForm } from "pages/Booking/components/Billing/MethodForm";
import { useEffect, useState } from "react";
import styles from "../TicketOperator.module.css";
import { getBookingById } from "api/booking.api";
import { BookingItem } from "pages/BookingRevenue/components/BookingItem";
import { EmptyHolder } from "components/Empty/EmptyHolder";

interface IProps {
  collectFeeBooking: ITicketOperatorItem;
  handleClose: () => void;
  handleSubmit: (payload: IPaymentMethodBooking[]) => void;
}
const CollectionFeeModal = ({
  collectFeeBooking,
  handleClose,
  handleSubmit,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const [payAmount, setPayAmount] = useState(0);
  const [methodForm] = useForm();
  const [booking, setBooking] = useState<IBooking | null>(null);
  const total = collectFeeBooking.jasmineKeepMoney;
  const hasNotPaidCollectionFee =
    collectFeeBooking.statusThuHo === ThuHoStatus.NOT_YET;
  const handleOk = async () => {
    if (total - payAmount > 0) {
      notification.info({
        message: `Bạn phải nhập đủ số tiền cần trả`,
      });
      return;
    }
    const paymentMethodBookingsValue = await methodForm.validateFields();
    const paymentMethodBookings: IPaymentMethodBooking[] = [];
    for (const key in paymentMethodBookingsValue) {
      if (
        paymentMethodBookingsValue[key] &&
        paymentMethodBookingsValue[key] > 0
      ) {
        paymentMethodBookings.push({
          // [4-12-2023] [Phuc Thinh] [Update paymentMethodBooking interface]
          paymentCode: key,
          amount: paymentMethodBookingsValue[key],
        });
      }
    }
    // if (total !== 0 && paymentMethodBookings.length === 0) {
    //   notification.info({
    //     message: "Bạn chưa nhập các phương thức thanh toán",
    //   });
    //   return;
    // }
    handleSubmit(paymentMethodBookings);
  };
  useEffect(() => {
    if (collectFeeBooking) {
      setLoading(true);
      getBookingById(collectFeeBooking.bookingId).subscribe(
        (res) => {
          if (res) {
            setBooking(res);
          }
        },
        (err) => {
          console.log(err);
          setLoading(false);
        },
        () => setLoading(false)
      );
    }
  }, [collectFeeBooking]);
  return (
    <Modal
      width={"clamp(20rem, 90vw, 60rem)"}
      centered
      okText="Lưu"
      cancelText="Hủy"
      open={!!collectFeeBooking}
      okButtonProps={{
        style: { display: hasNotPaidCollectionFee ? "inline-block" : "none" },
      }}
      cancelButtonProps={{
        style: { display: hasNotPaidCollectionFee ? "inline-block" : "none" },
      }}
      onCancel={handleClose}
      onOk={handleOk}
      title={
        <>
          {`Chi tiết booking: `}
          <Typography.Text strong style={{ fontSize: 18, color: "#1890ff" }}>
            {collectFeeBooking.bookingCode}
          </Typography.Text>
        </>
      }
    >
      {/* [06-12-2023][Phuc Thinh] 
            Add loading */}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 200,
          }}
        >
          <Spin />
        </div>
      ) : (
        <div className={`${styles.collectionFee_modal_container} customScroll`}>
          <div>
            {booking ? (
              <BookingItem booking={booking} controls={false} />
            ) : (
              <div style={{ height: 400 }}>
                <EmptyHolder message="Thông tin booking trống" />
              </div>
            )}
          </div>
          {hasNotPaidCollectionFee && (
            <div className={styles.collectionFee_modal_methodForm_container}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography.Text
                  strong
                  style={{ fontSize: 20 }}
                >{`Tổng: ${Utils.formatCurrency(total)}`}</Typography.Text>
                <Typography.Text
                  strong
                  style={{ fontSize: 20 }}
                >{`Còn lại: ${Utils.formatCurrency(
                  total - payAmount
                )}`}</Typography.Text>
              </div>
              <Typography.Text>Chọn phương thức trả</Typography.Text>

              <MethodForm
                totalAmount={total > 0 ? total : -total}
                setPayAmount={(amount) => setPayAmount(amount)}
                payAmount={payAmount}
                paymentForm={methodForm}
                hasDebtItem={false}
              />
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default CollectionFeeModal;
