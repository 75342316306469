import {
  Badge,
  Button,
  DatePicker,
  Form,
  FormInstance,
  InputNumber,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { BellOutlined } from "@ant-design/icons";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import TicketIcon from "image/icon/ticket-icon-white.svg";
import DriverIcon from "image/icon/driver-icon-white.svg";
import MotorbikeIcon from "image/icon/motorbike-icon-white.svg";
import styles from "../Operation.module.css";
import Utils from "common/Utils";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { CheckOutlined } from "@ant-design/icons";
import { resetIssueState, saveGroup } from "store/slice/GroupSlice";
import { IssueModal } from "components/Modal/IssueModal";
import {
  GroupStatusEnum,
  IGroup,
  IssueStatus,
  IssueType,
  JobDateEnum,
} from "common/define-types";
import { GroupDriverSelect } from "components/Selects/GroupDriverSelect";
import moment from "moment";
import { Dayjs } from "dayjs";
import { IIssue } from "api/types/issue";

interface IProps {
  form?: FormInstance<any>;
  readOnly?: boolean;
  group: IGroup | null;
  isSubmitting?: boolean;
  // drivers?: IDriver[] | IDriverTicket[];
  // motorbikes?: IMotorbike[];
  countDriver?: number;
  countMotor?: number;
  countTicket?: number;
  issues?: IIssue[];
}
export const GroupDetailView = ({
  form,
  readOnly = false,
  group,
  isSubmitting = false,
  countDriver,
  countMotor,
  countTicket,
  // drivers,
  // motorbikes,
  issues,
}: IProps) => {
  const dispatch = useDispatchRoot();
  const [issueModalOpen, setIssueModalOpen] = useState(false);
  const selectIsIssueLoading = useSelectorRoot(
    (state) => state.group.isIssueLoading
  );
  const selectDrivers = useSelectorRoot((state) => state.group.allDrivers);
  const selectJobDateSelected = useSelectorRoot(
    (state) => state.group.jobDateSelected
  );

  useEffect(() => {
    if (group) {
      form?.setFields(
        Utils.objectToAntFormData({
          ...group,
          startDate: dayjs(group.startDate),
          advance_Money: group.advance_Money || "0",
          petrol_Money: group.petrol_Money || "0",
          refund_Money: group.refund_Money || "0",
          roomChange_Money: (issues || [])
            .filter(
              (issue) =>
                issue.type === IssueType.CHANGEROOM &&
                (issue.status === IssueStatus.Approve ||
                  issue.status === IssueStatus.ComfirmByAdmin)
            )
            .reduce(
              (accumulator, currentValue) =>
                accumulator + parseInt(currentValue.amount_Money),
              0
            ),
        })
      );
    } else {
      form?.resetFields();
    }
  }, [group, form, issues]);

  const handleUpdateGroup = async () => {
    if (group) {
      const groupValue = await form?.validateFields().catch((err) => {
        console.log(err);
        notification.error({ message: "Vui lòng nhập đủ thông tin" });
      });
      if (!groupValue) return;
      const leaderInfor = selectDrivers.find(
        (driver) => driver.accountId === groupValue.leaderId
      );
      const groupCreateDate = group.name.slice(
        group.name.indexOf("_") + 1,
        group.name.length
      );
      const isGroupHappened = group.status !== GroupStatusEnum.NOT_RUNNING;
      // const isGroupHappened = moment(group.startDate)
      //   .set({ ...Utils.startDateOfGroup })
      //   .isBefore(moment());
      dispatch(
        saveGroup({
          ...group,
          ...groupValue,
          name: isGroupHappened
            ? group.name
            : leaderInfor
            ? `${Utils.getName(leaderInfor.name).firstName}_${groupCreateDate}`
            : // ? `${Utils.getName(leaderInfor.name).firstName}_${moment(
              //     group.startDate
              //   ).format("DDMMYYYY-HHmm")}`
              group.name,
          startDate: dayjs(groupValue.startDate)
            .startOf("day")
            .add(7, "hours")
            .toISOString(),
        })
      );
    }
  };

  return (
    <div className={styles.detailView} style={{ minHeight: 365 }}>
      {issueModalOpen && (
        <IssueModal
          open={issueModalOpen}
          handleClose={() => {
            setIssueModalOpen(false);
            dispatch(resetIssueState());
          }}
        />
      )}
      <div
        className={styles.detailView_title}
        style={{
          justifyContent: readOnly ? "flex-start" : "space-between",
          gap: 5,
        }}
      >
        <Typography.Title
          level={4}
          style={{
            marginBlock: 0,
            minHeight: 28,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: readOnly ? "100%" : 170,
          }}
          title={group?.name ?? ``}
        >
          {group?.name ?? ``}
        </Typography.Title>
        {!readOnly && (
          <Button
            loading={isSubmitting}
            type="primary"
            icon={<CheckOutlined />}
            onClick={handleUpdateGroup}
            disabled={
              group !== null &&
              (Utils.isGroupEnded(group) ||
                group.status === GroupStatusEnum.END)
            }
          >
            Cập nhật chung
          </Button>
        )}
      </div>
      <div className={styles.detailView_wrapper}>
        <div
          className={`${styles.scrollContainer} customScroll`}
          style={{
            padding: "4px 24px",
          }}
        >
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className={`noMargin_form-item`}
            form={form}
          >
            <div className={styles.dateWrapper}>
              <div className={styles.dateWrapper_item}>
                <Form.Item name={"startDate"}>
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    disabled={
                      readOnly || group?.status !== GroupStatusEnum.NOT_RUNNING
                    }
                    allowClear={false}
                    disabledDate={(current: Dayjs) => {
                      return (
                        !!dayjs().isAfter(current.add(4, "day")) ||
                        current.isAfter(
                          dayjs(group?.startDate).add(
                            group?.dmuC_Tour.countDay - 1,
                            "day"
                          )
                        )
                      );
                    }}
                  />
                </Form.Item>
              </div>
              {!readOnly && group && (
                <div className={styles.dateWrapper_item}>
                  <Tooltip title={"Danh sách phát sinh"}>
                    <Badge
                      count={
                        (issues || []).filter((issue) => {
                          if (group) {
                            const startDate = moment.utc(group.startDate).startOf('day').subtract(1, 'second');
                            const endDate = moment.utc(group.startDate).add(
                              group.dmuC_Tour.countDay - 1,
                              "day"
                            ).endOf('day').add(1, 'second');
                            const issueDate = moment.utc(issue.createdTime);
                            // const issueDate = [
                            //   IssueType.CHANGEROOM,
                            //   IssueType.EXTRA_SLEPT,
                            // ].some((type) => type === issue.type)
                            //   ? moment.utc(issue.date)
                            //   : moment.utc(issue.createdTime);
                            if (issueDate.isBetween(startDate, endDate)) {
                              if (selectJobDateSelected === JobDateEnum.ALL)
                                return true;
                              else {
                                return (
                                  issueDate.diff(startDate, "days") + 1 ===
                                  selectJobDateSelected
                                );
                              }
                            }
                          }
                          return false;
                        }).length
                      }
                      showZero
                      offset={[5, 10]}
                    >
                      <Button
                        type="text"
                        style={{ marginBottom: 5 }}
                        disabled={
                          selectIsIssueLoading ||
                          [GroupStatusEnum.RUNNING, GroupStatusEnum.END].every(
                            (status) => status !== group.status
                          )
                        }
                        onClick={() => setIssueModalOpen(true)}
                        icon={
                          <BellOutlined
                            style={{ fontSize: 22, color: "#FAAD14" }}
                          />
                        }
                      />
                    </Badge>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className={styles.btnGroup}>
              <Button type="primary" className={styles.btn}>
                <img src={TicketIcon} alt="Ticket" /> {countTicket ?? 0}
              </Button>
              <Button type="primary" className={styles.btn}>
                <img src={DriverIcon} alt="driver" />
                {/* {drivers ? drivers.length : 0} */}
                {countDriver || 0}
              </Button>
              <Button type="primary" className={styles.btn}>
                <img src={MotorbikeIcon} alt="motorbike" />
                {/* {motorbikes ? motorbikes.length : 0} */}
                {countMotor || 0}
              </Button>
            </div>
            <Form.Item label={"Tourleader"} name={"leaderId"}>
              <GroupDriverSelect
                defaultOptions={selectDrivers}
                testId="Leader-Group-Form"
                style={{ width: "100%" }}
                readOnly={readOnly}
                valuePropName="accountId"
                groupId={group?.id}
                onChange={(driver) =>
                  form?.setFieldValue("leaderId", driver.accountId)
                }
                params={{
                  startDate: moment(group?.startDate)
                    .startOf("day")
                    .add(7, "hours")
                    .toISOString(),
                  endDate: moment(group?.endDate)
                    .startOf("day")
                    .add(7, "hours")
                    .toISOString(),
                  isAvailable: true,
                }}
              />
            </Form.Item>
            <div className={styles.inputGroup}>
              <Form.Item label={"Tạm ứng leader"} name={"advance_Money"}>
                <InputNumber
                  stringMode={true}
                  controls={false}
                  style={{ width: "100%" }}
                  readOnly={readOnly}
                  formatter={(value) =>
                    `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                  }
                  parser={(value) => (value ? value?.replaceAll(".", "") : "0")}
                />
              </Form.Item>
              {/* <Form.Item label={"Tạm ứng đổ xăng"} name={"petrol_Money"}>
                <InputNumber
                  stringMode={true}
                  controls={false}
                  style={{ width: "100%" }}
                  readOnly={readOnly}
                  formatter={(value) =>
                    `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                  }
                  parser={(value) => (value ? value?.replaceAll(".", "") : "0")}
                />
              </Form.Item> */}
            </div>
            <div className={styles.inputGroup}>
              <Form.Item
                label={
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Tiền đổi phòng ngủ
                  </span>
                }
                name={"roomChange_Money"}
              >
                <InputNumber
                  stringMode={true}
                  controls={false}
                  style={{ width: "100%" }}
                  readOnly
                  formatter={(value) =>
                    `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                  }
                  parser={(value) => (value ? value?.replaceAll(".", "") : "0")}
                />
              </Form.Item>
              <Form.Item label={"Tiền hoàn ứng"} name={"refund_Money"}>
                <InputNumber
                  stringMode={true}
                  controls={false}
                  style={{ width: "100%" }}
                  readOnly={readOnly}
                  formatter={(value) =>
                    `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                  }
                  parser={(value) => (value ? value?.replaceAll(".", "") : "0")}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
