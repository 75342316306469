import { useEffect } from "react";
import styles from "./BookingResult/BookingResult.module.css";
import { BookingResultTickets } from "./BookingResult/BookingResultTickets";
import { PayeeInfor } from "./BookingResult/PayeeInfor";
import { notification } from "antd";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { setBookingResultMsg } from "store/slice/BookingSlice";

export const BookingResult = () => {
  const selectBookingResultMsg = useSelectorRoot(
    (state) => state.booking.bookingResultMsg
  );

  const dispatch = useDispatchRoot();
  useEffect(() => {
    if (selectBookingResultMsg) {
      notification.destroy();
      notification.success({
        message: selectBookingResultMsg,
      });
      dispatch(setBookingResultMsg(null));
    }
  }, [selectBookingResultMsg, dispatch]);
  return (
    <div className={styles.bookingResultContainer}>
      <PayeeInfor />
      <BookingResultTickets />
    </div>
  );
};
