import { Form, FormInstance, Input, InputNumber, Typography } from "antd";
import ExtraServiceSelect from "components/Selects/ExtraServiceSelect";
import { useSelectorRoot } from "store/store";
import ExtraServiceItem from "./ExtraServiceItem";
import styles from "./ExtraService.module.css";
import { RoomTemplateSelect } from "components/Selects/RoomTemplateSelect";
import {
  IExtraService,
  ExtraServiceCode,
  RoomTemplateType,
  ComboTourItemType,
} from "common/define-types";
import HangHoaSelect from "components/Selects/HangHoaSelect";
import moment from "moment";
import Utils from "common/Utils";

interface IProps {
  form: FormInstance<any>;
  ticketStartDate: string;
}
const ExtraServiceTab = ({ form, ticketStartDate }: IProps) => {
  const selectExtraService = useSelectorRoot(
    (state) => state.booking.extraServices
  );
  const selectSelectedService = useSelectorRoot(
    (state) => state.booking.selectedExtraServices
  );
  const selectTicketSelected = useSelectorRoot(
    (state) => state.booking.selectedTicket
  )
  const foundDriverPrice = selectTicketSelected?.comboTour.ListItem.find(item => item.ItemType === ComboTourItemType.DriverPrice)
  const renderFormItem = (service: IExtraService) => {
    switch (service.code) {
      case ExtraServiceCode.LUGGAGE:
        return (
          <div className={styles.formItem_group}>
            <Form.Item
              label="Số tiền (đ)"
              name={`${ExtraServiceCode.LUGGAGE}_price`}
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: "Không được bỏ trống",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                stringMode={true}
                controls={false}
                formatter={(value) =>
                  `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                }
                parser={(value) =>
                  parseInt(value ? value?.replaceAll(".", "") : "0")
                }
              />
            </Form.Item>
            <Form.Item
              label="Ghi chú"
              name={`${ExtraServiceCode.LUGGAGE}_note`}
              className={styles.formItem}
            >
              <Input.TextArea rows={1} />
            </Form.Item>
          </div>
        );
      case ExtraServiceCode.PARKING:
        return (
          <div className={styles.formItem_group}>
            <Form.Item
              label="Số tiền (đ)"
              name={`${ExtraServiceCode.PARKING}_price`}
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: "Không được bỏ trống",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                stringMode={true}
                formatter={(value) =>
                  `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                }
                parser={(value) =>
                  parseInt(value ? value?.replaceAll(".", "") : "0")
                }
                controls={false}
              />
            </Form.Item>
            <Form.Item
              label="Ghi chú"
              name={`${ExtraServiceCode.PARKING}_note`}
              className={styles.formItem}
            >
              <Input.TextArea rows={1} />
            </Form.Item>
          </div>
        );
      case ExtraServiceCode.CHANGE_ROOM:
        return (
          <Form.Item
            className={styles.formItem}
            name={"roomTemplateIds"}
            rules={[
              {
                required: true,
                message: "Không được bỏ trống",
              },
            ]}
          >
            {/* [8-12-2023][Phuc Thinh] 
            add roomtype */}
            <RoomTemplateSelect
              multiple
              style={{ width: "100%", marginBottom: "10px", marginTop: 8 }}
              acceptType={[
                RoomTemplateType.CHANGE_ROOM,
                RoomTemplateType.EXTRA,
              ]}
            />
          </Form.Item>
        );
      case ExtraServiceCode.CHANGE_VEHICLE:
        return (
          <>
            <Form.Item
              className={styles.formItem}
              name={"dmuC_HangHoaId"}
              rules={[
                {
                  required: true,
                  message: "Không được bỏ trống",
                },
              ]}
            >
              <HangHoaSelect
                testId="DoiXe_Dropdown"
                filterValue={{
                  date:
                    ticketStartDate ||
                    moment.utc().add(2, "days").toISOString(),
                }}
                style={{ width: "100%", marginBottom: "10px", marginTop: 8 }}
                onChange={(motorbike) => {
                  form.setFieldValue("dmuC_HangHoaId", motorbike?.id);
                  form.setFieldValue("dmuC_HangHoa_price", (motorbike?.price || 0) * Utils.toNumber(foundDriverPrice?.CountDay, 1));
                }}
              />
            </Form.Item>
            <Form.Item style={{ display: "none" }} name={"dmuC_HangHoa_price"}>
              <Input style={{ display: "none" }} />
            </Form.Item>
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography.Text className={styles.header_title}>
          Dịch vụ thêm
        </Typography.Text>
        <ExtraServiceSelect />
      </div>
      <Form form={form} labelCol={{ span: 24 }} className="ticket-card_form">
        {selectExtraService.map(
          (service) =>
            selectSelectedService[service.code] && (
              <ExtraServiceItem
                key={service.id}
                service={service}
                renderFormItem={() => renderFormItem(service)}
              />
            )
        )}
      </Form>
    </div>
  );
};

export default ExtraServiceTab;
