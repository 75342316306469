import { Button, Segmented, SegmentedProps } from "antd";

import styles from "../NightAuditor.module.css";
import { GroupDetailView } from "../../Operation/components/GroupDetailView";
import { JobDateEnum } from "common/define-types";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { auditGroup, setJobDateSelected } from "store/slice/NightAuditorSlice";
import { useForm } from "antd/es/form/Form";
import { IssuesContainer } from "./IssuesContainer";
import { Journey } from "./Journey";
import moment from "moment";

const jobDateOptions = [
  {
    value: JobDateEnum.DAY1,
    label: `Ngày 1`,
  },
  {
    value: JobDateEnum.DAY2,
    label: `Ngày 2`,
  },
  {
    value: JobDateEnum.DAY3,
    label: `Ngày 3`,
  },
  {
    value: JobDateEnum.DAY4,
    label: `Ngày 4`,
  },
];
export const GroupDetailContainer = () => {
  const selectGroupSelected = useSelectorRoot(
    (state) => state.nightAuditor.groupSelected
  );
  const selectIsNightAuditing = useSelectorRoot(
    (state) => state.nightAuditor.isSubmitting
  );
  const selectIsRunNight = useSelectorRoot(
    (state) => state.nightAuditor.groupSelectedIsRunNight
  );
  const selectJobTours = useSelectorRoot(
    (state) => state.nightAuditor.jobTours
  );
  const selectJobDateSelected = useSelectorRoot(
    (state) => state.nightAuditor.jobDateSelected
  );
  const selectGroupDriverTickets = useSelectorRoot(
    (state) => state.nightAuditor.groupSelectedDriverTickets
  );
  const selectGroupIssues = useSelectorRoot(
    (state) => state.nightAuditor.groupSelectedIssues
  );
  const selectGroupSelectedCount = useSelectorRoot(
    (state) => state.nightAuditor.groupSelectedCount
  );
  const dispatch = useDispatchRoot();
  const options: SegmentedProps["options"] = selectGroupSelected?.dmuC_Tour
    ? jobDateOptions.filter(
        (jobDate) => jobDate.value === selectJobDateSelected
      )
    : [...jobDateOptions];
  const [groupInfoForm] = useForm();

  const handleAudit = () => {
    dispatch(
      auditGroup({
        driver: selectGroupDriverTickets
          .filter((dTicket) =>
            moment(dTicket.date).isBetween(
              moment
                .utc(selectGroupSelected?.businessDate)
                .startOf("day")
                .subtract(1, "second"),
              moment().endOf("day")
            )
          )
          .map((dTicket) => ({
            id: dTicket.id,
            driverId: dTicket.driverId,
            ticketId: dTicket.ticketId,
            day: dTicket.day,
            carSalary: dTicket.carSalary,
            date: dTicket.date,
          })),
      })
    );
  };

  return (
    <div
      className={styles.listContainer}
      style={{ width: "clamp(26rem, 100%, 60rem)", marginBottom: "1rem" }}
    >
      <div className={`${styles.listContainer_header} customScroll`}>
        {!!selectGroupSelected && (
          <Segmented
            options={options}
            value={selectJobDateSelected}
            onChange={(value) => dispatch(setJobDateSelected(value))}
            style={{ marginRight: 4 }}
          />
        )}
        {selectJobTours &&
          selectJobTours.length > 0 &&
          (typeof selectJobDateSelected === "number"
            ? selectJobDateSelected
            : parseInt(selectJobDateSelected)) <=
            moment().diff(moment(selectGroupSelected?.startDate), "days") +
              1 && (
            <Button
              type="primary"
              onClick={handleAudit}
              disabled={!selectGroupSelected || selectIsRunNight !== false}
              loading={selectIsNightAuditing}
            >
              Kiểm toán
            </Button>
          )}
      </div>
      <div className={styles.detailContainer}>
        <div className={styles.detailWrapper}>
          <div className={styles.detailWrapper_column}>
            <div
              className={styles.detailWrapper_item}
              style={{ minHeight: 365 }}
            >
              <GroupDetailView
                form={groupInfoForm}
                readOnly={true}
                group={selectGroupSelected}
                // drivers={selectGroupDriverTickets.filter(
                //   (dTicket) =>
                //     moment(dTicket.date).diff(
                //       moment(selectGroupSelected?.startDate),
                //       "days"
                //     ) +
                //       1 ===
                //     selectJobDateSelected
                // )}
                countDriver={selectGroupSelectedCount?.countDriver || 0}
                countMotor={selectGroupSelectedCount?.countMotor || 0}
                countTicket={selectGroupSelectedCount?.countTicket || 0}
                issues={selectGroupIssues}
              />
            </div>
            <div className={styles.detailWrapper_item}>
              <div className={styles.detailView_wrapper}>
                <IssuesContainer />
              </div>
            </div>
          </div>
          <div
            className={styles.detailWrapper_column}
            style={{ minWidth: 300 }}
          >
            <div className={styles.detailWrapper_item}>
              <Journey />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
