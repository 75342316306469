const SYSTEM_CONSTANTS = {
    IDENTITY: {
        HOST: 'https://sit.srv.anybim.vn',
        LOGIN: 'connect/token',
        FORGOT: 'users',
        PROFILE: 'users/1?populate=role',
        ROLE: 'users-permissions/roles',
        CAPTCHA: 'clients/captcha'
    },
    API_URL: "https://dev.web.tingconnect.vn",
    LICENSE_MANAGER_API: "https://sit.stratusvue.anybim.vn"
};

export default SYSTEM_CONSTANTS;
