import {
  Form,
  Modal,
  DatePicker,
  Select,
  Radio,
  Space,
  Button,
  Typography,
  Spin,
  notification,
} from "antd";
import styles from "../TicketOperator.module.css";
import Utils from "common/Utils";
import { AgentSelect } from "components/Selects/AgentSelect";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { IExportOpTicket, TourStatus } from "common/define-types";
import { useState } from "react";
import { exportQuanLyTickets } from "api/ticket.api";
dayjs.extend(utc);
interface IProps {
  open: boolean;
  handleClose?: () => void;
  handleOk?: () => void;
}
//[7-12-2023][Phuc Thinh][Feature/14836]
// create Modal for Export QuanLyTickets
enum OptionValue {
  GroupByAgent = 2,
  OrderByCreatedDate = 1,
  OrderByStartDate = 0,
}
const { RangePicker } = DatePicker;
const PrintTicketModal = ({ open, handleClose, handleOk }: IProps) => {
  const [paramsForm] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    const paramsValue = await paramsForm
      .validateFields()
      .catch((err) => console.log(err));
    let params: IExportOpTicket = {
      startDate: paramsValue?.rangeDate[0]?.startOf("day").toISOString(),
      endDate: paramsValue?.rangeDate[1]?.endOf("day").toISOString(),
      AgentId: paramsValue?.AgentId,
      TourStatus: paramsValue?.TourStatus,
      isGroupByAgent: paramsValue.OptionValue
        ? paramsValue.OptionValue === OptionValue.GroupByAgent
        : undefined,
      sortBy:
        paramsValue.OptionValue &&
        (paramsValue.OptionValue === OptionValue.OrderByCreatedDate ||
          paramsValue.OptionValue === OptionValue.OrderByStartDate)
          ? paramsValue.OptionValue
          : -1,
    };
    setLoading(true);
    //[8-12-2023][Phuc Thinh][Feature/14836]
    // show pdf file after click print
    exportQuanLyTickets(params).subscribe(
      (res: Blob) => {
        if (res) {
          const url = window.URL || window.webkitURL;
          const link = url.createObjectURL(res);
          window.open(link, "_blank");
          // handleClose && handleClose();
        }
      },
      (err) => {
        console.log(err);
        setLoading(false);
        notification.error({ message: "Có lỗi xảy ra" });
      },
      () => setLoading(false)
    );
  };

  return (
    <Modal
      open={open}
      onCancel={loading ? undefined : handleClose}
      onOk={handleOk}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      destroyOnClose
    >
      <Spin spinning={loading}>
        <Typography.Title
          level={4}
          style={{ marginTop: 0, textAlign: "center" }}
        >
          Tải xuống danh sách vé
        </Typography.Title>
        <Form
          form={paramsForm}
          labelCol={{ span: 24 }}
          initialValues={{
            rangeDate: [
              dayjs.utc().startOf("day"),
              dayjs.utc().add(5, "days").endOf("day"),
            ],
            OptionValue: OptionValue.GroupByAgent,
          }}
        >
          <Form.Item name={"rangeDate"} label="Vé xuất phát từ ngày">
            <RangePicker
              allowClear={false}
              placeholder={["Từ ngày", "Đến ngày"]}
              format={Utils.dateFormat}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <div className={styles.formItemGroup}>
            <Form.Item label="Trạng thái vé" name={"TourStatus"}>
              <Select
                options={[
                  {
                    label: "Chưa đến",
                    value: TourStatus.Init,
                  },
                  {
                    label: "Xác nhận nhà cung cấp",
                    value: TourStatus.ConfirmByNCC,
                  },
                  {
                    label: "Đã đến",
                    value: TourStatus.CheckIn,
                  },
                  {
                    label: "Đang đi",
                    value: TourStatus.Running,
                  },
                  {
                    label: "Kết thúc",
                    value: TourStatus.EndTour,
                  },
                ]}
                allowClear
                placeholder="-- Trạng thái vé --"
              />
            </Form.Item>
            <Form.Item label="Chọn đại lý" name={"AgentId"}>
              <AgentSelect />
            </Form.Item>
          </div>
          <Form.Item name={"OptionValue"}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={OptionValue.GroupByAgent}>Nhóm theo đại lý</Radio>
                <Radio value={OptionValue.OrderByCreatedDate}>
                  Sắp xếp theo ngày giờ bán vé
                </Radio>
                <Radio value={OptionValue.OrderByStartDate}>
                  Sắp xếp theo ngày giờ xuất phát
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
        <div className={styles.buttonsContainer}>
          <Button>Cancel</Button>
          <Button type="primary" onClick={handleSubmit}>
            Tải xuống
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export default PrintTicketModal;
