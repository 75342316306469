import { Typography } from "antd";
import styles from "../NightAuditorBooking.module.css";
interface IProps {
  label: string;
  value: number;
  isActive?: boolean;
  onClick?: () => void;
}
const SumarizeItem = ({ label, value, isActive = false, onClick }: IProps) => {
  return (
    <div 
    className={`${styles.sumarizeItem} 
      ${isActive ? styles.active : ''}
      ${onClick ? styles.clickable : 'auto'}
    `} onClick={onClick}>
      <Typography.Text
        style={{
          color: "#8C8C8C",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: 13,
        }}
        title={label}
      >
        {label}
      </Typography.Text>
      <Typography.Text strong style={{ fontSize: 22, whiteSpace: "nowrap" }}>
        {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
      </Typography.Text>
    </div>
  );
};

export default SumarizeItem;
