import { Button, Input, Tooltip, notification } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import SumarizeSection from "./components/SumarizeSection";
import NightAuditorBookingTable from "./components/NightAuditorBookingTable";
import styles from "./NightAuditorBooking.module.css";
import { CheckOutlined } from "@ant-design/icons";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { ChangeEvent, useEffect, useState } from "react";
import { auditNightBooking, fetchAll, setErrMsg, setFilterValue, setWarningModalOpen } from "store/slice/NightBooking.slice";
import useModal from "antd/es/modal/useModal";
import { useHistory } from "react-router-dom";

const NightAuditorBooking = () => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const [modal, contextHolder] = useModal();
  const dispatch = useDispatchRoot();
  const selectFilterValue = useSelectorRoot(state => state.nightBooking.filterValue)
  const selectNightBookingTickets = useSelectorRoot(state => state.nightBooking.nightBookingTickets)
  const selectIsSubmitting = useSelectorRoot(state => state.nightBooking.isSubmitting)
  const selectErrMsg = useSelectorRoot(state => state.nightBooking.errMsg)
  const selectWarningModalOpen = useSelectorRoot(state => state.nightBooking.warningModalOpen)
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch])

  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({ message: selectErrMsg });
      dispatch(setErrMsg(null));
    }
  }, [dispatch, selectErrMsg])

  
  useEffect(() => {
    const handleCloseModal = () => {
      dispatch(setWarningModalOpen(false));
    }
    if (selectWarningModalOpen) {
      modal.confirm({
        title: 'Cảnh báo chốt night group',
        content: 'Group chưa được chốt night !',
        okText: 'OK',
        cancelText: 'Hủy',
        onOk: () => history.push('/jasmine/night-auditor'),
        onCancel: () => handleCloseModal()
      })
    }
  }, [dispatch, selectWarningModalOpen, history, modal])

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(
        setFilterValue({
          ...selectFilterValue,
          search: query,
        })
      );
    }, 800);
    setTimer(timeoutId);
  };

  const handleAuditNightBooking = () => {
    dispatch(auditNightBooking())
  }
  return (
    <div className={styles.container}>
      <div>{contextHolder}</div>
      <div className={styles.header}>
        <div>
          <Input.Search
            onChange={handleSearch}
            style={{ maxWidth: 230 }}
            placeholder="Tìm kiếm vé"
            allowClear
          />
          <Tooltip title='Reload'>
            <Button 
              onClick={() => dispatch(fetchAll())}
              type="text" 
              icon={<RedoOutlined />} 
              style={{ marginLeft: 10 }}
            />
          </Tooltip>
        </div>
        <Button disabled={!selectNightBookingTickets || selectNightBookingTickets.length === 0} loading={selectIsSubmitting} onClick={handleAuditNightBooking} type="primary" icon={<CheckOutlined />}>
          Kiểm toán
        </Button>
      </div>
      <SumarizeSection />
      <NightAuditorBookingTable />
    </div>
  );
};

export default NightAuditorBooking;
