import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Utils from "../../common/Utils";
import {
  filter,
  switchMap,
  mergeMap,
  catchError,
  map,
  OperatorFunction,
} from "rxjs";
import jwt from "jwt-decode";
import {
  LoginInfo,
  Profile,
  RoleEnum,
  RootEpic,
  Token_ResponseToken,
  User,
  UserResponse,
} from "../../common/define-types";
import IdentityApi from "../../api/identity/identity.api";
import { AjaxError } from "rxjs/ajax";
import { getAvatar, getProfile } from "api/account.api";
import { bookingSlice } from "store/slice/BookingSlice";

type MessageLogin = {
  content: string;
  errorCode?: number;
  error?: any;
};
type MessageForgot = {
  ErrorCode?: number;
  Message: string;
};
interface LoginState {
  loading: boolean;
  isRefreshing: boolean;
  isSuccess: boolean;
  user: User | undefined;
  message: MessageLogin | undefined;
  messageForgot: MessageForgot | undefined;
  departmentId: number;
  refresh_token: string;
  profile: IAccount | null;
  role: RoleEnum | null;
}

const initState: LoginState = {
  loading: false,
  isRefreshing: false,
  isSuccess: false,
  user: undefined,
  departmentId: 1,
  message: undefined,
  messageForgot: undefined,
  refresh_token: "",
  profile: null,
  role: null,
};
const loginSlice = createSlice({
  name: "login",
  initialState: initState,
  reducers: {
    loginRequest(state, action: PayloadAction<LoginInfo>) {
      state.loading = true;
    },
    loginSuccess(
      state,
      action: PayloadAction<{
        user: UserResponse;
        token: Token_ResponseToken;
        remember: boolean;
      }>
    ) {
      var profile: Profile = action.payload.user
        ? JSON.parse(action.payload.user.profile)
        : null;
      if (action.payload.remember) {
        Utils.setLocalStorage(Utils.constant.token, action.payload.token);
        Utils.setLocalStorage(Utils.constant.username, profile?.UserName);
        Utils.setLocalStorage(Utils.constant.email, profile?.Email);
        Utils.setLocalStorage(Utils.constant.user, action.payload.user);
      }
      state.loading = false;
      state.profile = {
        id: profile.Id,
        userName: profile.UserName,
        email: profile.Email,
        firstname: profile.Firstname,
        lastname: profile.Lastname,
        forcePasswordChange: profile.ForcePasswordChange,
      };
      state.isSuccess = true;
      state.role = action.payload.user.role;
      state.refresh_token = action.payload.token.refresh_token;
      Utils.token = action.payload.token.access_token;
      Utils.refresh = action.payload.token.refresh_token;
      Utils.role = action.payload.user.role;
      Utils.profile = profile;
    },
    setIsRefreshing(state, action: PayloadAction<boolean>) {
      state.isRefreshing = action.payload;
    },
    getInfoUser(state, action: PayloadAction<User | null>) {
      if (action.payload) {
        state.user = action.payload;
        state.role = action.payload.role;
      }
    },
    forgotRequest(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    sendMailSuccess(state, action: PayloadAction<{ message: MessageLogin }>) {
      state.message = action.payload.message;
      state.loading = false;
      state.isSuccess = true;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setLoginSuccess(
      state,
      action: PayloadAction<{
        status: boolean;
        token: string;
        user: any;
        refresh_token: string;
      }>
    ) {
      // const EXPIRED_TOKEN = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImUyZGYyYzI2LWZiOGItNDllNC05ZmYzLWVkNDYwZDUxYWJmYSIsInR5cCI6ImF0K2p3dCJ9.eyJuYmYiOjE2ODk4MzExMDAsImV4cCI6MTY4OTgzNDcwMCwiaXNzIjoiaHR0cDovL3NpdC5zcnYuYW55YmltLnZuIiwiYXVkIjoiQVBJIiwiY2xpZW50X2lkIjoiamFzbWluZSIsInN1YiI6IjljMmQ1ZjA1LTE3MDItNGE2Yy1iZGFkLWE0MzJiMDBlOGM5ZCIsImF1dGhfdGltZSI6MTY4OTgyMTQ1OSwiaWRwIjoibG9jYWwiLCJwcm9maWxlIjoie1wiSWRcIjpcIjljMmQ1ZjA1LTE3MDItNGE2Yy1iZGFkLWE0MzJiMDBlOGM5ZFwiLFwiSW52aXRhdGlvblN0YXR1c1wiOlwiQWNjZXB0ZWRcIixcIlVzZXJOYW1lXCI6XCJjYXRiYTFcIixcIkVtYWlsXCI6XCJjYXRiYTFAZ21haWwuY29tXCIsXCJQaG9uZU51bWJlclwiOlwiMDkwNTM0NjkxM1wiLFwiRmlyc3RuYW1lXCI6XCIgMVwiLFwiTGFzdG5hbWVcIjpcIkNhdCBCYVwiLFwiRm9yY2VQYXNzd29yZENoYW5nZVwiOmZhbHNlfSIsInJvbGUiOiJBZ2VudCIsImp0aSI6IkQzQzc4NzM2QkY5NkU0NTU1OEFFMDM3RDk1RDA1RDBEIiwiaWF0IjoxNjg5ODMxMTAwLCJzY29wZSI6WyJBUEkiLCJvZmZsaW5lX2FjY2VzcyJdLCJhbXIiOlsicHdkIl19.C0X52rm7CkdMVADeCnYh9r-guZAErmkWw7eGUu6o26DRg2ryzkbdu6b2M183gKvGp1d7WcO_wbaSO1DZinVzyjrTmt8lLnWfxXKBHJf8NRhnYWxUXhAYOlwlozxVEsAUm6IG5hVwWUztFiO-RqeOfu8LqBYw0xCSqGrhsOzct3Ub4wVuDGH2y4wkykMTkuXvrlqAfUmylY4nXw3Lw3Cy03VtahS9tg4EvcXjtwA2NNS6gHaro5woi2M-MEvLiIGe3q1IKkr2R3ZQ9SDwR_m5Z6CslTNRcItfXm1VCMiT5JGHu6_78nq_R8XGXhbdMjsmAKf54G1jqvRBN11a2iaueQ'
      const profile: Profile = action.payload.user
        ? JSON.parse(action.payload.user.profile)
        : null;
      state.isSuccess = action.payload.status;
      state.user = action.payload.user;
      state.profile = {
        id: profile.Id,
        userName: profile.UserName,
        email: profile.Email,
        firstname: profile.Firstname,
        lastname: profile.Lastname,
        forcePasswordChange: profile.ForcePasswordChange,
      };
      state.loading = false;
      state.role = action.payload.user.role;
      state.refresh_token = action.payload.refresh_token;
      // Utils.token = EXPIRED_TOKEN;
      Utils.token = action.payload.token;
      Utils.refresh = action.payload.refresh_token;
      Utils.role = action.payload.user.role;
    },
    fetchProfile(state) {
      state.loading = true;
    },
    setProfile(state, action: PayloadAction<IAccount | null>) {
      state.profile = action.payload;
      state.loading = false;
    },
    message(state, action: PayloadAction<MessageLogin>) {
      state.message = action.payload;
      state.loading = false;
    },
    messageForgot(state, action: PayloadAction<MessageForgot>) {
      state.messageForgot = action.payload;
      state.loading = false;
    },
    clearMessageRequests(state) {
      state.loading = true;
    },
    clearMessage(state) {
      state.messageForgot = undefined;
      state.message = undefined;
      state.loading = false;
    },
    logout(state) {
      state.messageForgot = undefined;
      state.message = undefined;
      state.loading = false;
      state.user = undefined;
      state.isSuccess = false;
      Utils.clear();
    },
    getRoles: (state, action: PayloadAction<void>) => {
      // state.loading = true;
    },
    getRoleSuccess: (state, action: PayloadAction<{ role: RoleEnum }>) => {
      state.loading = false;
      state.role = action.payload.role;
      Utils.role = action.payload.role;
    },
  },
});

const login$: RootEpic = (action$) =>
  action$.pipe(
    filter(loginRequest.match),
    switchMap((re) => {
      return IdentityApi.login({
        username: re.payload.username,
        password: re.payload.password,
        CaptchaId: re.payload.captchaId ?? "",
        Captcha: re.payload.captcha ?? "",
        scope: "offline_access API",
        grant_type: "password",
        client_id: "jasmine",
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const newRes = res as Token_ResponseToken;
            const token = newRes.access_token;
            const userRes: UserResponse = jwt(token);
            Utils.token = token;
            Utils.refresh = newRes.refresh_token;

            return [
              loginSlice.actions.loginSuccess({
                user: userRes,
                token: newRes,
                remember: re.payload.remember,
              }),
              loginSlice.actions.fetchProfile(),
            ];
          } else {
            return [
              loginSlice.actions.message({
                content: res?.response.error,
                error: res,
              }),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          loginSlice.actions.message({
            content:
              e?.response?.error_description ??
              "Tên đăng nhập hoặc mật khẩu không đúng",
            error: e,
          }),
        ])
      );
    })
  );
const logout$: RootEpic = (action$) =>
  action$.pipe(
    filter(logout.match),
    switchMap((re) => {
      return [bookingSlice.actions.clearBooking()];
    })
  );

const forgot$: RootEpic = (action$: {
  pipe: (
    arg0: OperatorFunction<
      Action<unknown>,
      { payload: string; type: "login/forgotRequest" }
    >,
    arg1: OperatorFunction<
      unknown,
      { payload: MessageForgot; type: "login/messageForgot" }
    >
  ) => any;
}) =>
  action$.pipe(
    filter(forgotRequest.match),
    switchMap((re: any) => {
      return IdentityApi.forgotPassword(re.payload).pipe(
        map(() => {
          return loginSlice.actions.messageForgot({
            Message: "success",
          });
        }),
        catchError((err) => [loginSlice.actions.messageForgot(err.response)])
      );
    })
  );
const clearMessage$: RootEpic = (action$: {
  pipe: (
    arg0: OperatorFunction<
      Action<unknown>,
      { payload: undefined; type: "login/clearMessageRequests" }
    >,
    arg1: OperatorFunction<
      unknown,
      { payload: undefined; type: "login/clearMessage" }
    >
  ) => any;
}) =>
  action$.pipe(
    filter(clearMessageResquest.match),
    map(() => {
      return loginSlice.actions.clearMessage();
    })
  );
const getRoles$: RootEpic = (action$) =>
  action$.pipe(
    filter(getRoles.match),
    switchMap((re) => {
      return IdentityApi.getProfile().pipe(
        mergeMap((profile: any) => {
          return [loginSlice.actions.getInfoUser(profile)];
        }),
        catchError((e: AjaxError) => [loginSlice.actions.getInfoUser(null)])
      );
    })
  );
const fetchProfile$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchProfile.match),
    switchMap((re) => {
      return getProfile().pipe(
        mergeMap((profile: any) => {
          return getAvatar().pipe(
            mergeMap((res: any) => {
              const avatarUrl = res.result.avatarUrl;
              return [
                loginSlice.actions.setProfile({
                  ...profile,
                  avatarUrl,
                }),
              ];
            }),
            catchError((e: AjaxError) => [
              loginSlice.actions.setProfile(profile),
            ])
          );
        }),
        catchError((e: AjaxError) => [loginSlice.actions.setProfile(null)])
      );
    })
  );
export const LoginEpics = [
  login$,
  getRoles$,
  forgot$,
  clearMessage$,
  fetchProfile$,
  logout$,
];
export const {
  loginRequest,
  forgotRequest,
  clearMessageRequests: clearMessageResquest,
  getInfoUser,
  setProfile,
  fetchProfile,
  logout,
  getRoles,
  setLoginSuccess,
  setIsRefreshing,
} = loginSlice.actions;
export const loginReducer = loginSlice.reducer;
