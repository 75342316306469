import { Select, notification } from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { IHangHoaItem } from "common/define-types";
import { getAllHangHoa } from "api/hanghoa.api";
import Utils from "common/Utils";
interface IProps {
  value?: string;
  onChange?: (arg: IHangHoaItem | undefined) => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  allowClear?: boolean;
  filterValue: {
    date: string | null | null;
  };
  testId: string;
}
const HangHoaSelect = ({
  value,
  onChange = () => {},
  style,
  readOnly,
  filterValue,
  allowClear = true,
  testId,
}: IProps) => {
  const [motorbikes, setMotorbikes] = useState<IHangHoaItem[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllHangHoa({
      dateTime: dayjs(filterValue.date).toISOString(),
      typeHangHoa: 1,
    }).subscribe(
      (res) => {
        if (res) {
          const motorbikesRes = res;
          setMotorbikes(motorbikesRes.results);
        }
      },
      (err) => {
        notification.error({ message: "Không thể lấy danh sách xe" });
        setMotorbikes([]);
        setLoading(false);
      },
      () => {
        // finally
        setLoading(false);
      }
    );
  }, [filterValue]);

  return (
    <Select
      options={motorbikes.map((motor) => ({
        label: `${motor.name} - ${Utils.formatCurrency(motor.price)}`,
        value: motor.id,
      }))}
      data-test-id={testId}
      loading={loading}
      value={value}
      style={style}
      onChange={(value) =>
        onChange(motorbikes.find((motor) => motor.id === value))
      }
      optionLabelProp="label"
      allowClear={allowClear}
      open={modalOpen}
      onDropdownVisibleChange={(open) => setModalOpen(open)}
      notFoundContent={"Chưa có xe"}
      disabled={readOnly}
    />
  );
};

export default HangHoaSelect;
