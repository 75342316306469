import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootEpic } from "common/define-types";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { getAllRoomTemplates } from "api/roomTemplate.api";
import { IRoomTemplate } from "api/types/roomTemplate";

export interface RoomTemplateState {
  isLoading: boolean;
  roomTemplates: IRoomTemplate[] | [];
  errMsg: string | null;
}

const initialState: RoomTemplateState = {
  isLoading: false,
  roomTemplates: [],
  errMsg: null,
};

export const roomTemplateSlice = createSlice({
  name: "roomTemplate",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchRoomTemplates: (state, action: PayloadAction) => {
      state.isLoading = true;
    },
    setRoomTemplates: (state, action: PayloadAction<IRoomTemplate[]>) => {
      state.roomTemplates = action.payload;
      state.isLoading = false;
    },
    setErrMsg: (state, action: PayloadAction<string>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
    },
  },
});
const getRoomTemplates$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchRoomTemplates.match),
    switchMap((re) => {
      return getAllRoomTemplates().pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.length) {
            const roomTemplates = res;
            return [
              roomTemplateSlice.actions.setRoomTemplates(roomTemplates ?? []),
            ];
          } else {
            return [roomTemplateSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          roomTemplateSlice.actions.setErrMsg("Có lỗi xảy ra"),
        ])
      );
    })
  );

export const { fetchRoomTemplates, setRoomTemplates, setErrMsg } =
  roomTemplateSlice.actions;

export const RoomTemplateEpics = [getRoomTemplates$];

export const roomTemplateReducer = roomTemplateSlice.reducer;
