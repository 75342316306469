import { Button, FormInstance, Typography } from "antd";
import styles from "./Billing.module.css";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import {
  clearBooking,
  setBookingStep,
  setTourStep,
} from "store/slice/BookingSlice";
import { BookingStepEnum, TourStepEnum } from "common/define-types";

import { PayeeForm } from "./PayeeForm";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import Utils from "common/Utils";

interface IProps {
  payeeForm: FormInstance<any>;
}
export const PayeeProfile = ({ payeeForm }: IProps) => {
  const dispatch = useDispatchRoot();
  const selectEditingBooking = useSelectorRoot(
    (state) => state.booking.editingBooking
  );
  const history = useHistory();

  useEffect(() => {
    if (selectEditingBooking !== null) {
      const payName = selectEditingBooking.userPayName;
      const indexOfFirstSpaceChar = payName.lastIndexOf(" ");
      const lastName = payName.slice(0, indexOfFirstSpaceChar);
      const firstName = payName.slice(indexOfFirstSpaceChar, payName.length);
      payeeForm.setFields(
        Utils.objectToAntFormData({
          ...selectEditingBooking,
          firstName,
          lastName,
        })
      );
    }
  }, [selectEditingBooking, payeeForm]);
  return (
    <section className={styles.bigCard}>
      <Typography.Title level={4} className={styles.bigCard_title}>
        Thông tin người thanh toán
      </Typography.Title>
      <div className={styles.payeeContainer}>
        <PayeeForm form={payeeForm} />
        <div className={styles.payeeBtnContainer}>
          <Button
            onClick={() =>
              dispatch(setBookingStep(BookingStepEnum.BOOKING_DETAIL))
            }
            icon={<ArrowLeftOutlined />}
            className={styles.commonBtn}
          >
            Quay lại
          </Button>
          <Button
            onClick={() => {
              if (selectEditingBooking === null) {
                dispatch(setTourStep(TourStepEnum.TOURS));
                dispatch(setBookingStep(BookingStepEnum.BOOKING_DETAIL));
              } else {
                dispatch(clearBooking());
                history.push("/bookings");
              }
            }}
            className={styles.commonBtn}
          >
            {selectEditingBooking === null ? "Hủy đặt vé" : "Hủy cập nhật"}
            <CloseOutlined />
          </Button>
        </div>
      </div>
    </section>
  );
};
