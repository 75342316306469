import { Button, Typography } from "antd";
// import Utils from "common/Utils";
import styles from "../NightAuditor.module.css";
import { useSelectorRoot } from "store/store";
import { IssueStatus } from "common/define-types";
import { IIssue } from "api/types/issue";
import Utils from "common/Utils";

interface IProps {
  readOnly?: boolean;
  issue: IIssue;
  handleConfirm?: (issue: IIssue) => void;
  handleReject?: (issue: IIssue) => void;
  handleViewDetail?: (issue: IIssue) => void;
}
const OuterGasItem = ({
  issue,
  readOnly,
  handleConfirm,
  handleReject,
  handleViewDetail,
}: IProps) => {
  const selectIsSubmittingIssueId = useSelectorRoot(
    (state) => state.group.isSubmittingIsuseId
  );

  return (
    <div className={styles.issueItem}>
      <div className={styles.issueItem_wrapper}>
        <div className={styles.issueItem_item}>
          <Typography.Text
            strong
            className={styles.issueItem_issueName}
          >{`Đổ xăng lẻ`}</Typography.Text>
          <Typography.Text
            style={{
              textAlign: "right",
              color: Utils.getIssueTime(issue).color,
            }}
            title={Utils.getIssueTime(issue).time.format("DD/MM/YYYY HH:mm:ss")}
          >
            {Utils.getIssueTime(issue).text}
          </Typography.Text>
        </div>
        <div className={styles.issueItem_item}>
          <Typography.Text>{issue.motobikeNumber}</Typography.Text>
          <Typography.Text>
            {Utils.formatCurrency(issue.amount_Money)}
          </Typography.Text>
        </div>
      </div>
      {!readOnly && (
        <div className={styles.issueItem_btnGroup}>
          <div className={styles.issueItem_btns}>
            <Button
              className={styles.issueItem_confirmBtn}
              onClick={() => handleConfirm && handleConfirm(issue)}
              disabled={issue.status !== IssueStatus.Init}
              loading={
                selectIsSubmittingIssueId !== null &&
                selectIsSubmittingIssueId === issue.id
              }
            >
              {issue.status === IssueStatus.Approve ||
              issue.status === IssueStatus.ComfirmByAdmin
                ? "Đã duyệt"
                : "Duyệt"}
            </Button>
            <Button
              danger
              disabled={issue.status !== IssueStatus.Init}
              type="primary"
              onClick={() => handleReject && handleReject(issue)}
              loading={
                selectIsSubmittingIssueId !== null &&
                selectIsSubmittingIssueId === issue.id
              }
            >
              {issue.status === IssueStatus.Reject ? "Đã từ chối" : "Từ chối"}
            </Button>
          </div>
          <Button
            type="primary"
            onClick={() => handleViewDetail && handleViewDetail(issue)}
          >
            Chi tiết
          </Button>
        </div>
      )}
    </div>
  );
};

export default OuterGasItem;
