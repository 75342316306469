import styles from "./Clock.module.css";
interface IProps {
  time: {
    hour: number | string;
    minute: number | string;
    second?: number | string;
  };
  bgColor?: string;
  timeColor?: string;
}
const Clock = ({
  time,
  bgColor = "#005ba5",
  timeColor = "#f2f7fa",
}: IProps) => {
  const { hour, minute, second } = time;
  const formatUnit = (value: number | string) => {
    const input = typeof value === "string" ? parseInt(value) : value;
    if (input < 10) {
      return `0${input}`;
    }
    return value;
  };
  return (
    <div
      className={styles.container}
      style={{ backgroundColor: bgColor, color: timeColor }}
    >
      <div className={styles.unitBlock}>{formatUnit(hour)}</div>
      <div className={styles.unitSeperator}>:</div>
      <div className={styles.unitBlock}>{formatUnit(minute)}</div>
      {second && (
        <>
          <div className={styles.unitSeperator}>:</div>
          <div className={styles.unitBlock}>{formatUnit(second)}</div>
        </>
      )}
    </div>
  );
};

export default Clock;
