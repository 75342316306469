import { ExtraServiceCode, ITicket_ExtraService } from "common/define-types";
import { useSelectorRoot } from "store/store";
import styles from "./Billing.module.css";
import { Typography } from "antd";
import Utils from "common/Utils";

interface IProps {
  tickets_extraService: ITicket_ExtraService[];
}
const ExtraServiceTicket = ({ tickets_extraService }: IProps) => {
  const selectExtraServices = useSelectorRoot(
    (state) => state.booking.extraServices
  );
  const selectRoomTemplates = useSelectorRoot(
    (state) => state.roomTemplate.roomTemplates
  );
  const selectAllHangHoa = useSelectorRoot((state) => state.hangHoa.motorbikes);
  // [14:57 05/12/2023][minh_nq]
  // update translate 
  const renderExtraService = (
    ticket_extraServices: ITicket_ExtraService[],
    code: string
  ) => {
    switch (code) {
      case ExtraServiceCode.PARKING:
        return (
          <div className={styles.extraServiceItem} style={{ marginLeft: "10px" }}>
            <Typography.Text
              strong
              className="size12"
            >Fee shipment motorbike:</Typography.Text>
            <Typography.Text
              // style={{ whiteSpace: "nowrap" }} 
              strong
              className="size12"
            >
              {Utils.formatCurrency(ticket_extraServices[0].price)}
            </Typography.Text>
          </div>
        );
      case ExtraServiceCode.LUGGAGE:
        return (
          <div className={styles.extraServiceItem} style={{ marginLeft: "10px" }}>
            {/*
              [14:57 05/12/2023][minh_nq]
              update translate  
            */}
            <Typography.Text
              strong
              className="size12"
            >Fee shipment commodity:</Typography.Text>
            <Typography.Text
              strong
              // style={{ whiteSpace: "nowrap" }}
              className="size12"
            >
              {Utils.formatCurrency(ticket_extraServices[0].price)}
            </Typography.Text>
          </div>
        );
      case ExtraServiceCode.CHANGE_VEHICLE:
        const foundMotor = selectAllHangHoa.find(
          (hanghoa) => hanghoa.id === ticket_extraServices[0].dmuC_HangHoaId
        );
        return (
          <div className={styles.extraServiceItem_container} style={{ marginLeft: "10px" }}>
            <Typography.Text
              strong
              // style={{ whiteSpace: "nowrap" }}
              className="size12"
            >
              {/*
                [14:57 05/12/2023][minh_nq]
                update translate  
              */}
              Upgrade motorbike:
            </Typography.Text>
            {foundMotor && (
              <div className={styles.extraServiceItem} style={{ marginLeft: "10px" }}>
                <Typography.Text
                  className="size12"
                >{`${foundMotor.name}`}</Typography.Text>
                <Typography.Text
                  strong
                  // style={{ whiteSpace: "nowrap" }}
                  className="size12"
                >
                  {Utils.formatCurrency(ticket_extraServices[0].price)}
                </Typography.Text>
              </div>
            )}
          </div>
        );
      case ExtraServiceCode.CHANGE_ROOM:
        return (
          <div className={styles.extraServiceItem_container} style={{ marginLeft: "10px" }}>
            {/*
              [14:57 05/12/2023][minh_nq]
              update translate  
            */}
            <Typography.Text
              strong
              className="size12"
            >Upgrade room:</Typography.Text>
            {selectRoomTemplates &&
              ticket_extraServices.length > 0 &&
              ticket_extraServices.map((ticket_extraService) => {
                const foundRoom = selectRoomTemplates.find(room => room.id === ticket_extraService.roomTemplateId)
                return foundRoom && (
                  <div
                    className={styles.extraServiceItem}
                    style={{ paddingBlock: 4, marginLeft: "10px" }}
                  >
                    <Typography.Text
                      // style={{
                      //   whiteSpace: "nowrap",
                      //   overflow: "hidden",
                      //   textOverflow: "ellipsis",
                      // }}
                      title={foundRoom.name}
                      className="size12"
                    >
                      {foundRoom.name}
                    </Typography.Text>
                    <div style={{ width: '45%', display: 'flex', justifyContent: 'flex-end' }}>
                      <Typography.Text
                        // style={{ whiteSpace: "nowrap" }} 
                        strong
                        className="size12"
                      >
                        {Utils.formatCurrency(foundRoom.price)}
                      </Typography.Text>
                    </div>
                  </div>
                )
              })}
          </div>
        );

      default:
        break;
    }
  };
  return (
    <div className={styles.extraServiceContainer} style={{ gap: "0px" }}>
      <Typography.Text 
        strong 
        className="size12"
        // style={{ fontSize: 16, marginBottom: 4 }}
      >
        {/*
          [14:57 05/12/2023][minh_nq]
          update translate  
        */}
        Extra service
      </Typography.Text>
      {selectExtraServices.map((service) => {
        if (
          tickets_extraService.some(
            (extService) => extService.dmuC_CHHangHoaDichVuKhacId === service.id
          )
        )
          return renderExtraService(
            tickets_extraService.filter(
              (ticket) => ticket.dmuC_CHHangHoaDichVuKhacId === service.id
            ),
            service.code
          );
        else return <></>;
      })}
    </div>
  );
};

export default ExtraServiceTicket;
