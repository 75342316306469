import { Button, Modal, Typography, notification } from "antd";
import styles from "./EditTicket.module.css";
import {
  BookingStepEnum,
  ComboTourItemType,
  IPaymentMethodBooking,
  ISelectedTicket,
  ITicket,
  ITicketOperatorItem,
} from "common/define-types";
import { TicketCard } from "pages/Booking/components/TicketCard";
import { useState } from "react";
import { MethodForm } from "pages/Booking/components/Billing/MethodForm";
import { useForm } from "antd/es/form/Form";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import Utils from "common/Utils";
import { updateTicketSelected } from "store/slice/BookingSlice";
import ComboTourSelect from "components/Selects/ComboTourSelect";

interface IProps {
  open: boolean;
  currentTicket: ITicket | ITicketOperatorItem | undefined;
  handleClose?: () => void;
  handleSubmit: (
    ticketUpdated: ISelectedTicket,
    paymentMethodBookingsConnect?: IPaymentMethodBooking[],
    paymentMethodBookingsDisable?: IPaymentMethodBooking[]
  ) => void;
}

const EditTicketModal = ({
  open,
  currentTicket,
  handleClose,
  handleSubmit,
}: IProps) => {
  const [payAmount, setPayAmount] = useState(0);
  const [payForm] = useForm();
  const [currentStep, setCurrentStep] = useState(
    BookingStepEnum.BOOKING_DETAIL
  );
  const dispatch = useDispatchRoot();
  const ticketSelected = useSelectorRoot(
    (state) => state.booking.selectedTicket
  );
  const isSubmitting = useSelectorRoot(
    (state) => state.operatorTicket.isSubmitting
  );
  const selectComboTours = useSelectorRoot(
    (state) => state.booking.comboTours
  )
  const foundComboTourOfTicket = selectComboTours.find(combo => combo.Id === ticketSelected?.comboTourId);
  const newPrice = ticketSelected
    ? (ticketSelected.price || 0) +
      Utils.getBusPrice(ticketSelected) +
      Utils.getExtraServicePrice(ticketSelected)
    : 0;
  const paidPrice = currentTicket
    ? (currentTicket.price || 0) +
      Utils.getBusPrice(currentTicket) +
      Utils.getExtraServicePrice(currentTicket)
    : 0;

  const HAS_TO_PAY_AMOUNT = newPrice - paidPrice;

  const renderBillResult = () => {
    let leftAmount =
      HAS_TO_PAY_AMOUNT >= 0
        ? HAS_TO_PAY_AMOUNT - payAmount
        : HAS_TO_PAY_AMOUNT + payAmount;
    if (leftAmount === 0)
      return (
        <>
          Còn lại <br /> {Utils.formatCurrency(0)}
        </>
      );
    if (leftAmount > 0)
      return (
        <>
          Còn thiếu <br /> {Utils.formatCurrency(leftAmount)}
        </>
      );
    if (leftAmount < 0)
      return (
        <>
          Còn thừa <br /> {Utils.formatCurrency(-leftAmount)}
        </>
      );
  };

  const onSaveUpdate = async () => {
    if (!ticketSelected) return;
    if (HAS_TO_PAY_AMOUNT - payAmount > 0) {
      notification.info({
        message: `Bạn phải nhập đủ số tiền cần trả`,
      });
      return;
    }
    const paymentMethodBookingsValue = await payForm.validateFields();
    const paymentMethodBookings: IPaymentMethodBooking[] = [];
    for (const key in paymentMethodBookingsValue) {
      if (
        paymentMethodBookingsValue[key] &&
        paymentMethodBookingsValue[key] > 0
      ) {
        paymentMethodBookings.push({
          paymentCode: key,
          amount: paymentMethodBookingsValue[key],
        });
      }
    }
    if (HAS_TO_PAY_AMOUNT !== 0 && paymentMethodBookings.length === 0) {
      notification.info({
        message: "Bạn chưa nhập các phương thức thanh toán",
      });
      return;
    }
    if (HAS_TO_PAY_AMOUNT >= 0) {
      handleSubmit(ticketSelected, paymentMethodBookings, undefined);
    }
    else {
      handleSubmit(ticketSelected, undefined, paymentMethodBookings);
    }
  };
  const renderByStep = (step: BookingStepEnum) => {
    switch (step) {
      case BookingStepEnum.BOOKING_DETAIL:
        return (
          <>
            <div className={styles.header}>
              <div></div>
              {
                foundComboTourOfTicket &&
                  <ComboTourSelect 
                    testId="select-ComboTour" 
                    defaultOptions={selectComboTours}
                    filterValue={{
                      dateTime: ticketSelected?.startDate
                    }}
                    acceptTypes={[foundComboTourOfTicket.Type]}
                    style={{ minWidth: 320 }}
                    value={ticketSelected?.comboTourId} 
                    onChange={(comboTour) => {
                      if (!ticketSelected || !comboTour) return;
                      dispatch(
                        updateTicketSelected({ 
                          ticket: { 
                            ...ticketSelected,
                            comboTour: comboTour,
                            comboTourId: comboTour?.Id,
                            driverPriceId: comboTour?.ListItem.find(item => item.ItemType === ComboTourItemType.DriverPrice)?.Id,
                            tourId: comboTour?.ListItem.find(item => item.ItemType === ComboTourItemType.Tour)?.Id,
                            price: comboTour.Price
                          }, 
                          isApplyAll: false, 
                          onlyUpdateTicket: true, 
                        })
                      )
                    }}
                  />
              }
              
            </div>
            <div
              style={{
                display: "flex",
                minHeight: 700,
                justifyContent: "center",
              }}
            >
              <TicketCard
              hasInGroupCheck={false}
                onSave={(args) =>
                  dispatch(
                    updateTicketSelected({ ...args, onlyUpdateTicket: true })
                  )
                }
              />
            </div>
            <div className={styles.buttonsContainer} style={{ marginTop: 20 }}>
              <Button
                type="primary"
                onClick={() => setCurrentStep(BookingStepEnum.PAYMENT)}
              >
                Thanh toán
              </Button>
            </div>
          </>
        );
      case BookingStepEnum.PAYMENT:
        return (
          <>
            <div
              className={styles.justifyBetween}
              style={{ padding: "0 30px 12px" }}
            >
              <Typography.Text strong className={styles.billPrice}>
                Tổng <br /> {Utils.formatCurrency(newPrice)}
              </Typography.Text>
              <Typography.Text strong className={styles.billPrice}>
                {renderBillResult()}
              </Typography.Text>
            </div>
            <MethodForm
              paymentForm={payForm}
              payAmount={payAmount}
              setPayAmount={(value) => setPayAmount(value)}
              totalAmount={
                HAS_TO_PAY_AMOUNT >= 0 ? HAS_TO_PAY_AMOUNT : -HAS_TO_PAY_AMOUNT
              }
              hasDebtItem={false}
            />
            <div
              className={styles.buttonsContainer}
              style={{ justifyContent: "space-between" }}
            >
              <Button
                onClick={() => setCurrentStep(BookingStepEnum.BOOKING_DETAIL)}
              >
                Quay lại
              </Button>
              <Button
                type="primary"
                loading={isSubmitting}
                onClick={onSaveUpdate}
              >
                Cập nhật
              </Button>
            </div>
          </>
        );

      default:
        break;
    }
  };
  return (
    <Modal
      title={"Chỉnh sửa thông tin vé"}
      open={open}
      onCancel={handleClose}
      //   onCancel={() => dispatch(selectTicket(null))}
      centered
      width={470}
      style={{ marginBlock: 8 }}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <div style={{ minHeight: 600 }}>{renderByStep(currentStep)}</div>
    </Modal>
  );
};

export default EditTicketModal;
