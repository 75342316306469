import { Skeleton, Typography } from "antd";
import styles from "../Dashboard.module.css";
import Utils from "common/Utils";

interface IProps {
  label: string;
  value: number;
  isMoney?: boolean;
  isLoading?: boolean;
  bgColor?: string;
}
export const PerformanceItem = ({
  label,
  value,
  isMoney = false,
  isLoading = false,
  bgColor = "white",
}: IProps) => {
  // const getDifColor = (dif: number) => {
  //   if (dif > 0) {
  //     return "rgba(38, 173, 95, 1)";
  //   } else if (dif < 0) {
  //     return "red";
  //   }
  //   return "rgba(128, 147, 147, 1)";
  // };
  return (
    <>
      {isLoading ? (
        <Skeleton active paragraph={{ rows: 2 }} />
      ) : (
        <div
          className={styles.performanceItem}
          style={{ backgroundColor: bgColor }}
          // style={{ "--color": getDifColor(item.dif) } as any}
        >
          <Typography.Text>{label}</Typography.Text>
          <Typography.Text style={{ fontSize: 22 }} strong>
            {/* {isMoney && "$"} */}
            {isMoney ? Utils.formatCurrency(value) : value}
          </Typography.Text>
          {/* <Typography.Text className={styles.performanceDif}>
            {`${item.dif === 0 ? "" : item.dif > 0 ? "▲" : "▼"} ${item.dif}`}
          </Typography.Text> */}
        </div>
      )}
    </>
  );
};
