import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { useSelectorRoot } from "store/store";
import styles from "../NightAuditor.module.css";
import { BrokeMotorbikeItem } from "./BrokeMotorbikeItem";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import { DriverItem } from "./DriverItem";
import { IDriverTicket, IGroupIssue, IssueType, ThanhToanIssueStatus } from "common/define-types";
import { ChangeRoomItem } from "./ChangeRoomItem";
import moment from "moment";
import { CancelTourItem } from "./CancelTourItem";
import ExtraMealItem from "./ExtraMealItem";
import OuterGasItem from "./OuterGasItem";
import ExtraSleptItem from "./ExtraSleptItem";
import { useEffect } from "react";

export const IssuesContainer = () => {
  const selectGroupTickets = useSelectorRoot(
    (state) => state.nightAuditor.groupSelectedTickets
  );
  // const selectJobDateSelected = useSelectorRoot(
  //   (state) => state.nightAuditor.jobDateSelected
  // );
  const selectGroupSelected = useSelectorRoot(
    (state) => state.nightAuditor.groupSelected
  );
  const selectGroupDriverTickets = useSelectorRoot(
    (state) => state.nightAuditor.groupSelectedDriverTickets
  ).filter((dTicket) => {
    return moment(dTicket.date).isBetween(
      moment
        .utc(selectGroupSelected?.businessDate)
        .startOf("day")
        .subtract(1, "second"),
      moment().endOf("day")
    );
  });
  const selectGroupIssues = useSelectorRoot(
    (state) => state.nightAuditor.groupSelectedIssues
  ).filter(
    (issue) =>
      moment
        .utc(issue.createdTime)
        .isBetween(
          moment
            .utc(selectGroupSelected?.businessDate)
            .endOf("day"),
          moment.utc().endOf("day")
        )
    //   .diff(moment(selectGroupSelected?.startDate), "days") +
    //   1 ===
    // selectJobDateSelected
  );

  const tabs = [
    {
      label: "Hỏng xe",
      data: selectGroupIssues.filter(
        (issue) => issue.type === IssueType.BREAKDOWN_MOTORBIKE
      ),
      renderChildren: (item: IGroupIssue) => (
        <BrokeMotorbikeItem issue={item} readOnly={true} />
      ),
    },
    {
      label: "Đổi ngủ",
      data: selectGroupIssues.filter(
        (issue) => issue.type === IssueType.CHANGEROOM
      ),
      renderChildren: (item: IGroupIssue) => (
        <ChangeRoomItem
          issue={item}
          readOnly={true}
          groupTickets={selectGroupTickets}
          isPaid={item.thanhToanIssueStatus !== ThanhToanIssueStatus.NOT_YET}
        />
      ),
    },
    {
      label: "Bỏ tour",
      data: selectGroupIssues.filter(
        (issue) => issue.type === IssueType.CANCELTOUR
      ),
      renderChildren: (item: IGroupIssue) => (
        <CancelTourItem issue={item} readOnly={true} />
      ),
    },
    {
      label: "Ăn thêm",
      data: selectGroupIssues.filter(
        (issue) => issue.type === IssueType.EXTRA_MEAL
      ),
      renderChildren: (item: IGroupIssue) => (
        <ExtraMealItem
          issue={item}
          readOnly={true}
          groupTickets={selectGroupTickets}
        />
      ),
    },
    {
      label: "Đổ xăng lẻ",
      data: selectGroupIssues.filter(
        (issue) => issue.type === IssueType.OUTER_FUEL
      ),
      renderChildren: (item: IGroupIssue) => (
        <OuterGasItem issue={item} readOnly={true} />
      ),
    },
    {
      label: "Ngủ thêm",
      data: selectGroupIssues.filter(
        (issue) => issue.type === IssueType.EXTRA_SLEPT
      ),
      renderChildren: (item: IGroupIssue) => (
        <ExtraSleptItem
          issue={item}
          readOnly={true}
          groupTickets={selectGroupTickets}
          isPaid={item.thanhToanIssueStatus !== ThanhToanIssueStatus.NOT_YET}
        />
      ),
    },
    {
      label: "Tài xế",
      data: selectGroupDriverTickets,
      renderChildren: (item: IDriverTicket) => (
        <DriverItem driverTicket={item} readOnly={true} />
      ),
    },
  ];
  const items: TabsProps["items"] = tabs.map((tab: any, index) => ({
    key: (index + 1).toString(),
    label: `${tab.label} (${tab.data.length})`,
    children: (
      <div
        className={`${styles.listWrapper_List} customScroll`}
        style={{ maxHeight: "calc(calc(100dvh - 340px) / 2)" }}
      >
        {tab.data.length > 0 ? (
          tab.data.map(tab.renderChildren)
        ) : (
          <EmptyHolder message="No data" />
        )}
      </div>
    ),
  }));

  useEffect(() => {
    const ele: HTMLElement | null = document.querySelector(
      ".issues-tabs .ant-tabs-nav-list"
    );
    if (ele) {
      ele.classList.add("customScroll");
      ele.style.overflowX = "auto";
      ele.style.padding = "10px";
      ele.style.cursor = "grab";

      let pos = { top: 0, left: 0, x: 0, y: 0 };

      const mouseDownHandler = function (e: any) {
        ele.style.cursor = "grabbing";
        ele.style.userSelect = "none";

        pos = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY,
        };

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      };

      const mouseMoveHandler = function (e: any) {
        // How far the mouse has been moved
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        // Scroll the element
        ele.scrollTop = pos.top - dy;
        ele.scrollLeft = pos.left - dx;
      };

      const mouseUpHandler = function () {
        ele.style.cursor = "grab";
        ele.style.removeProperty("user-select");

        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
      };

      // Attach the handler
      ele.addEventListener("mousedown", mouseDownHandler);
    }
  }, []);

  return (
    <Tabs
      defaultActiveKey="0"
      items={items}
      renderTabBar={(props, TabNavList) => (
        <TabNavList {...props} mobile={true} className="horizontalScroll" />
      )}
      className="issues-tabs"
      centered
      style={{ maxHeight: "100%" }}
    />
  );
};
