import { Button, Skeleton, Typography, notification } from "antd";
import { Tour } from "./Tour";
import styles from "./Tour.module.css";
import { useEffect, useState } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Booking } from "pages/Booking/Booking";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { IComboTour, TourStepEnum } from "common/define-types";
import {
  bookTour,
  selectTicket,
  // clearBooking,
  setErrMsg,
  setTourStep,
  unbookTour,
} from "store/slice/BookingSlice";
import { EmptyHolder } from "components/Empty/EmptyHolder";
//[14-12-2023][Phuc Thinh][Feature/14901]
//Change Tour to TourCombo
export const Tours = () => {
  const [isHover, setIsHover] = useState(false);
  const selectActiveTourStep = useSelectorRoot(
    (state) => state.booking.tourStep
  );
  const selectComboTours = useSelectorRoot((state) => state.booking.comboTours);
  const selectComboToursSelected = useSelectorRoot(
    (state) => state.booking.selectedComboTours
  );
  // const selectTours = useSelectorRoot((state) => state.booking.tours);
  // const selectToursSelected = useSelectorRoot(
  //   (state) => state.booking.selectedTours
  // );
  // const tours = Utils.mapArray(
  //   selectTours,
  //   selectPriceReqs,
  //   "countDay",
  //   "countDay",
  //   "driverPriceDto"
  // );
  const selectErrMsg = useSelectorRoot((state) => state.booking.errMsg);
  const selectCurrentAgent = useSelectorRoot(
    (state) => state.booking.currentAgent
  );

  const isToursLoading = useSelectorRoot((state) => state.booking.isLoading);

  const dispatch = useDispatchRoot();

  const handleBookTour = (comboTour: IComboTour) => {
    if (comboTour) {
      dispatch(bookTour(comboTour));
    }
  };
  const handleUnbookTour = (comboTour: IComboTour) => {
    if (comboTour) {
      dispatch(unbookTour(comboTour));
    }
  };
  const toggleClickBookTour = (comboTour: IComboTour, isBooked: boolean) => {
    if (comboTour) {
      if (isBooked) {
        handleUnbookTour(comboTour);
      } else {
        handleBookTour(comboTour);
      }
    }
  };

  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({
        message: selectErrMsg,
      });
    }
    dispatch(setErrMsg(null));
  }, [selectErrMsg, dispatch]);

  useEffect(() => {
    if (selectActiveTourStep === TourStepEnum.TOURS) {
      dispatch(selectTicket(null));
    }
  }, [dispatch, selectActiveTourStep]);

  return selectActiveTourStep === TourStepEnum.TOURS ? (
    <div className={styles.toursContainer}>
      <div className={styles.toursWrapper}>
        <Typography.Text
          style={{
            color: "#005BA5",
            fontSize: 24,
            paddingInline: 5,
            fontWeight: 500,
          }}
        >
          Chọn loại tour
        </Typography.Text>
        <div
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className={`${styles.tours} ${isHover && styles.scrollAble}`}
        >
          {isToursLoading ? (
            <>
              <Skeleton
                avatar={{ size: 80, shape: "square" }}
                paragraph={{ rows: 3 }}
                active
              />
              <Skeleton
                avatar={{ size: 80, shape: "square" }}
                paragraph={{ rows: 3 }}
                active
              />
              <Skeleton
                avatar={{ size: 80, shape: "square" }}
                paragraph={{ rows: 3 }}
                active
              />
            </>
          ) : selectComboTours.length > 0 &&
            selectCurrentAgent &&
            selectCurrentAgent.code ? (
            selectComboTours
              .filter(
                (comboTour) =>
                  comboTour.ListItem && comboTour.ListItem.length > 0
              )
              .map((comboTour, index) => (
                <Tour
                  key={index}
                  tour={comboTour}
                  isBooked={selectComboToursSelected.some(
                    (selectedComboTour) => comboTour.Id === selectedComboTour.Id
                  )}
                  handleClick={toggleClickBookTour}
                />
              ))
          ) : (
            <EmptyHolder message="Hiện chưa có tour" />
          )}
        </div>
      </div>
      {selectComboTours.length > 0 && !isToursLoading && (
        <Button
          className={styles.buttonTourDetail}
          disabled={!selectCurrentAgent || !selectCurrentAgent.code}
          block
          onClick={() => {
            if (
              !selectComboToursSelected ||
              selectComboToursSelected.length <= 0
            ) {
              notification.error({
                message: "Click vào tour để chọn các loại tour trước",
              });
              return;
            }
            dispatch(setTourStep(TourStepEnum.DETAILS));
          }}
        >
          Đặt vé <ArrowRightOutlined />
        </Button>
      )}
    </div>
  ) : (
    <Booking />
  );
};
