import { Button, Typography } from "antd";
import styles from "./BookingResult.module.css";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import TicketIconWhite from "image/icon/ticket-icon-white.svg";
import { PrinterOutlined } from "@ant-design/icons";
import { clearBooking } from "store/slice/BookingSlice";
import moment from "moment";
import { IBookingTicket } from "common/define-types";
import { useRef } from "react";
import { TicketWithQRBill } from "../Billing/TicketWithQRBill";
import { useReactToPrint } from "react-to-print";

export const BookingResultTickets = () => {
  // const [isDownloading, setIsDownloading] = useState(false);
  const selectBookingResult = useSelectorRoot(
    (state) => state.booking.bookingResult
  );

  const componentRef = useRef<null>(null);

  const dispatch = useDispatchRoot();

  const handleNewTicket = () => {
    dispatch(clearBooking());
  };
  const ticketRefs = useRef([]);
  ticketRefs.current = [];

  const addToRefs = (el: never) => {
    if (el && !ticketRefs.current.includes(el)) {
      ticketRefs.current.push(el);
    }
  };

  // const handleDownloadTickets = () => {
  //   setIsDownloading(true);
  //   const downloadTicket = async (el: HTMLElement, ticket: ITicket) => {
  //     // const canvas = await html2canvas(el, { allowTaint: true });

  //     // const data = canvas.toDataURL("image/png");
  //     // const link = document.createElement("a");

  //     // if (typeof link.download === "string") {
  //     //   link.href = data;
  //     //   link.download = `jasmine-${ticket.name}.png`;

  //     //   document.body.appendChild(link);
  //     //   link.click();
  //     //   document.body.removeChild(link);
  //     // } else {
  //     //   window.open(data);
  //     // }

  //     if (el) {
  //       el.style.display = "flex";
  //       html2canvas(el, { scale: 2 }).then((canvas) => {
  //         el.style.display = "none";
  //         const imgData = canvas.toDataURL("image/png");

  //         const pdf = new jsPDF("p", "mm", "a5");

  //         const imgWidth = 120;
  //         const imgHeight = (canvas.height * imgWidth) / canvas.width;

  //         const marginLeft = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
  //         const marginTop = (pdf.internal.pageSize.getHeight() - imgHeight) / 2;

  //         pdf.addImage(
  //           imgData,
  //           "PNG",
  //           marginLeft,
  //           marginTop,
  //           imgWidth,
  //           imgHeight
  //         );
  //         pdf.save(`jasmine-${ticket.name}.pdf`);
  //       });
  //     } else {
  //       console.error("Element not found.");
  //     }
  //   };
  //   const elements = ticketRefs.current.map((ref) => ref);

  //   if (elements && elements.length > 0) {
  //     elements.forEach((el, index) => {
  //       setTimeout(() => {
  //         downloadTicket(el, selectBookingResult.tickets[index]);
  //         if (index + 1 === elements.length) {
  //           setIsDownloading(false);
  //         }
  //       }, 500 * (index + 1) * 2);
  //     });
  //   } else {
  //     setIsDownloading(false);
  //   }
  // };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      :root {
        --page-width: 80mm; /* Thay đổi theo chiều rộng mong muốn */
        --page-height: 297mm; /* Thay đổi theo chiều dài mong muốn */
      }
      body {
        width: var(--page-width);
        height: var(--page-height);
      }
      .print-element {
        background-color: red;
        display: flex;
        margin: 2px;
        page-break-after: always;
      }
      .print-element-container {
        gap: 0px;
      }
      .print-element-header {
        margin-top: 0px;
      }
      @media print {
        html, body {
          height: initial !important;
          overflow: initial !important;
          -webkit-print-color-adjust: exact;
        }
        .print-element {
          display: block;
          font-size: 9px;
        }
        .print-element .ant-typography[style] {
          /* Disable all inline styles */
          font-size: unset !important;
        }
        .print-element span[style] {
          /* Disable inline font size styles */
          font-size: unset !important;
        }
        .print-element .ant-typography {
          font-size: 9px;
        }
        .print-element img {
          width: 12px;
          height: 12px;
        }
        .print-element .size12 {
          font-size: 12px;
        }
        .print-element .iconStyle {
          font-size: 9px;
        }
      }
      @media print {
        @page { 
          size: var(--page-width) var(--page-height);
          margin: 0;
        }
      }
    `,
  });

  return (
    <div
      className={styles.bigCard}
      style={{ minWidth: 350, flex: 1, maxWidth: 650 }}
    >
      <Typography.Title level={4} className={styles.bigCard_title}>
        {`Booking_${moment
          .utc(selectBookingResult.createdTime)
          .local()
          .format("DDMMYYYY")}`}
      </Typography.Title>
      <div className={styles.scrollWrapper}>
        <div
          className={`${styles.scrollContainer} customScroll`}
          style={{ gap: "25px" }}
          ref={componentRef}
        >
          {selectBookingResult &&
            selectBookingResult.tickets &&
            selectBookingResult.tickets.length > 0 &&
            selectBookingResult.tickets
              .filter((ticket: IBookingTicket) => ticket.isErase !== true)
              .map((ticket: any, index: number) => {
                // {/* <TicketWithQR
                //   booking={selectBookingResult}
                //   ticket={ticket}
                //   index={index + 1}
                //   key={index}
                // /> */}
                return (
                  <TicketWithQRBill
                    booking={selectBookingResult}
                    ticket={ticket}
                    index={index + 1}
                    key={`Bill_${index}`}
                    ref={addToRefs}
                  />
                );
              })}
        </div>
      </div>
      <div className={`${styles.spaceBetween} ${styles.btnsContainer}`}>
        <Button
          // loading={isDownloading}
          className={styles.checkoutBtn}
          // onClick={handleDownloadTickets}
          onClick={handlePrint}
        >
          {`Xuất vé`}
          <PrinterOutlined />
        </Button>
        <Button className={styles.checkoutBtn} onClick={handleNewTicket}>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <span>Vé mới</span>
            <img src={TicketIconWhite} alt="ticket" />
          </div>
        </Button>
      </div>
    </div>
  );
};
