import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ICreateReward,
  IDriversResult,
  IOperatorDriver,
  IOperatorDriverFilterValue,
  RootEpic,
} from "common/define-types";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { getDriversByDay } from "api/driver.api";
import { createDriverReward } from "api/reward.api";
import moment from "moment";

export interface OperatorDriverState {
  isLoading: boolean;
  isSubmitting: boolean;
  addModalOpen: boolean;
  drivers: IOperatorDriver[] | [];
  errMsg: string | null;
  driversResult: IDriversResult | null;
  editingDriver: IOperatorDriver | null;
  filterValue: IOperatorDriverFilterValue | null;
}

const initialState: OperatorDriverState = {
  isLoading: false,
  isSubmitting: false,
  addModalOpen: false,
  drivers: [],
  errMsg: null,
  driversResult: null,
  editingDriver: null,
  filterValue: {
    dateTime: new Date().toISOString(),
  },
};

export const operatorDriverSlice = createSlice({
  name: "operatorDriver",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchDrivers: (
      state,
      action: PayloadAction<IOperatorDriverFilterValue | null>
    ) => {
      state.isLoading = true;
      state.addModalOpen = false;
      state.editingDriver = null;
      state.errMsg = null;
    },
    setDrivers: (state, action: PayloadAction<IOperatorDriver[]>) => {
      state.drivers = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
      state.editingDriver = null;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
    setDriversResult: (state, action: PayloadAction<IDriversResult>) => {
      state.driversResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    editDriver: (state, action: PayloadAction<IOperatorDriver | null>) => {
      state.editingDriver = action.payload;
    },
    saveDriverReward: (state, action: PayloadAction<ICreateReward>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    setDriverFilterValue: (
      state,
      action: PayloadAction<IOperatorDriverFilterValue>
    ) => {
      state.filterValue = {
        ...state.filterValue,
        ...action.payload,
      };
    },
    clearSearch: (state) => {
      state.filterValue = {
        ...state.filterValue,
        search: "",
      };
    },
  },
});
const getDrivers$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchDrivers.match),
    switchMap((re) => {
      // const driverResult = state$.value.operatorDriver.driversResult;
      const filterValue = state$.value.operatorDriver.filterValue;
      let payload = {};
      // if (driverResult) {
      //   payload = {
      //     ...driverResult,
      //   };
      // }
      payload = {
        ...payload,
        ...filterValue,
        ...re.payload,
        pageSize: re.payload?.pageSize || 25,
        dateTime: moment(re.payload?.dateTime)
          .startOf("day")
          .add(7, "hours")
          .add(1, "second")
          .toISOString(),
      };
      return getDriversByDay(payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const drivers = res.results;
            return [
              operatorDriverSlice.actions.setDrivers(drivers ?? []),
              operatorDriverSlice.actions.setDriversResult(res),
            ];
          } else {
            return [operatorDriverSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          operatorDriverSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách"
          ),
        ])
      );
    })
  );
const filterDrivers$: RootEpic = (action$) =>
  action$.pipe(
    filter(setDriverFilterValue.match),
    switchMap((re) => {
      return [operatorDriverSlice.actions.fetchDrivers(re.payload)];
    })
  );
const createOrUpdateDriverReward$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveDriverReward.match),
    switchMap((re) => {
      return createDriverReward(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [
              operatorDriverSlice.actions.fetchDrivers({
                ...state$.value.operatorDriver.filterValue,
                ...state$.value.operatorDriver.driversResult,
              }),
              operatorDriverSlice.actions.editDriver(null),
            ];
          } else {
            return [operatorDriverSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          operatorDriverSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lưu thông tin"
          ),
        ])
      );
    })
  );

export const {
  fetchDrivers,
  setDrivers,
  setErrMsg,
  editDriver,
  saveDriverReward,
  setDriverFilterValue,
  clearSearch,
} = operatorDriverSlice.actions;

export const OperatorDriverEpics = [
  getDrivers$,
  filterDrivers$,
  createOrUpdateDriverReward$,
];

export const operatorDriverReducer = operatorDriverSlice.reducer;
