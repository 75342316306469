import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "./BookingRevenue.module.css";
import { BookingsWrapper } from "./components/BookingsWrapper";
// import { BusinessPerfomance } from "./components/BusinessPerfomance";
import { useEffect } from "react";
import { fetchBookings, setErrMsg } from "store/slice/BookingRevenueSlice";
import { notification } from "antd";

export const BookingRevenue = () => {
  const dispatch = useDispatchRoot();
  const selectIsLoginSuccess = useSelectorRoot(
    (state) => state.login.isSuccess
  );
  const selectErrMsg = useSelectorRoot((state) => state.bookingRevenue.errMsg);

  useEffect(() => {
    if (selectIsLoginSuccess) {
      dispatch(fetchBookings());
    }
  }, [dispatch, selectIsLoginSuccess]);

  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({ message: selectErrMsg });
      dispatch(setErrMsg(null));
    }
  }, [selectErrMsg, dispatch]);

  return (
    <section className={styles.bookingRevenueContainer}>
      {/* <div className={styles.perfomanceContainer}>
        <BusinessPerfomance />
      </div> */}
      <div className={styles.bookingRevenueWrapper}>
        <BookingsWrapper />
      </div>
    </section>
  );
};
