import { Select } from "antd";
import Utils from "common/Utils";
// import { useEffect } from "react";
// import { fetchLocation } from "store/slice/BookingSlice";
import { useSelectorRoot } from "store/store";

interface IProps {
  value?: string;
  onChange?: (arg: string) => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  allowClear?: boolean;
  testId: string;
}
export const LocationSelect = ({
  value,
  onChange = () => {},
  style,
  readOnly,
  allowClear = true,
  testId,
}: IProps) => {
  const selectLocations = useSelectorRoot((state) => state.booking.locations);
  // const selectIsRefreshing = useSelectorRoot(
  //   (state) => state.login.isRefreshing
  // );
  // const dispatch = useDispatchRoot();

  // useEffect(() => {
  //   if (!selectIsRefreshing) dispatch(fetchLocation());
  // }, [dispatch, selectIsRefreshing]);

  return (
    <Select
      //  remove Ha Giang Location
      data-test-id={testId}
      options={selectLocations
        .filter((location) => location.id !== Utils.HaGiangLocationId)
        .map((location) => ({
          label: location.name,
          value: location.id,
        }))}
      value={value}
      style={style}
      onChange={(value) => onChange(value)}
      allowClear={allowClear}
      disabled={readOnly}
    />
  );
};
