import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "./Agents.module.css";
import { AgentAccountList } from "./components/AgentAccountList";
import { AgentList } from "./components/AgentList";
import { FormModal } from "./components/FormModal";
import {
  editAgent,
  editAgentAccount,
  fetchAgents,
  saveAgent,
  saveAgentAccount,
  setErrMsg,
} from "store/slice/AgentsSlice";
import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";
import Utils from "common/Utils";
import { AgentForm } from "./components/AgentForm";
import { AgentAccountForm } from "./components/AgentAccountForm";
import { notification } from "antd";

export const Agents = () => {
  const selectAgentEditing = useSelectorRoot(
    (state) => state.agents.editingAgent
  );
  const selectAgentAccountEditing = useSelectorRoot(
    (state) => state.agents.editingAgentAccount
  );
  const selectAgentSelected = useSelectorRoot(
    (state) => state.agents.agentSelected
  );
  const selectIsRefreshing = useSelectorRoot(
    (state) => state.login.isRefreshing
  );
  const selectErrMsg = useSelectorRoot((state) => state.agents.errMsg);
  const [agentForm] = useForm();
  const [agentAccountForm] = useForm();
  const dispatch = useDispatchRoot();

  useEffect(() => {
    if (selectAgentEditing) {
      agentForm.setFields(Utils.objectToAntFormData(selectAgentEditing));
    }
  }, [selectAgentEditing, agentForm]);

  useEffect(() => {
    if (selectAgentAccountEditing) {
      agentAccountForm.setFields(
        Utils.objectToAntFormData({
          ...selectAgentAccountEditing,
          employeeName: `${selectAgentAccountEditing.lastname} ${selectAgentAccountEditing.firstname}`,
        })
      );
    }
  }, [selectAgentAccountEditing, agentAccountForm]);

  useEffect(() => {
    !selectIsRefreshing && dispatch(fetchAgents());
  }, [dispatch, selectIsRefreshing]);

  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({
        message: selectErrMsg,
      });
      dispatch(setErrMsg(null));
    }
  }, [selectErrMsg, dispatch]);

  const handleSaveAgent = async () => {
    const agentValue = await agentForm.validateFields().catch((err) => {
      console.log(err);
      notification.error({ message: "Vui lòng nhập đủ thông tin" });
    });
    if (selectAgentEditing && !!agentValue) {
      dispatch(
        saveAgent({
          ...selectAgentEditing,
          name: agentValue.name,
          address: agentValue.address,
          phone: agentValue.phone,
          city: agentValue.city,
          email: agentValue.email,
          agentId: selectAgentEditing.id,
        })
      );
    }
  };

  const handleSaveAgentAccount = async () => {
    const agentAccountValue = await agentAccountForm
      .validateFields()
      .catch((err) => {
        console.log(err);
        notification.error({ message: "Vui lòng nhập đủ thông tin" });
      });
    if (!agentAccountValue) return;
    const employeeName = agentAccountValue.employeeName.trim();
    const indexOfFirstSpaceChar = employeeName.lastIndexOf(" ");
    const lastName = employeeName.slice(0, indexOfFirstSpaceChar);
    const firstName = employeeName.slice(
      indexOfFirstSpaceChar,
      employeeName.length
    );
    if (selectAgentAccountEditing && selectAgentSelected) {
      dispatch(
        saveAgentAccount({
          account: {
            ...selectAgentAccountEditing,
            firstname: indexOfFirstSpaceChar > 0 ? firstName : employeeName,
            lastname: indexOfFirstSpaceChar > 0 ? lastName : "",
            email: agentAccountValue.email,
            phoneNumber: agentAccountValue.phoneNumber,
            password: agentAccountValue.password,
            userName: agentAccountValue.userName,
          },
        })
      );
    }
  };

  return (
    <div className={styles.agentsContainer}>
      <div className={styles.container}>
        <AgentList />
        <AgentAccountList />
      </div>
      {selectAgentEditing !== null && (
        <FormModal
          isOpen={selectAgentEditing !== null}
          title="Thông tin đại lý"
          handleClose={() => {
            dispatch(editAgent(null));
            agentForm.resetFields();
          }}
          handleOk={async () => {
            await handleSaveAgent();
            dispatch(editAgent(null));
            agentForm.resetFields();
          }}
        >
          <AgentForm form={agentForm} />
        </FormModal>
      )}
      {selectAgentAccountEditing !== null && selectAgentSelected && (
        <FormModal
          isOpen={selectAgentAccountEditing !== null}
          title={`Tài khoản của ${selectAgentSelected.name} `}
          handleClose={() => {
            dispatch(editAgentAccount(null));
            agentAccountForm.resetFields();
          }}
          handleOk={async () => {
            await handleSaveAgentAccount();
            dispatch(editAgentAccount(null));
            agentAccountForm.resetFields();
          }}
        >
          <AgentAccountForm form={agentAccountForm} isEdit={true} />
        </FormModal>
      )}
    </div>
  );
};
