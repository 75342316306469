import { useDispatchRoot } from "store/store";
import styles from "./NightAuditor.module.css";
import { GroupDetailContainer } from "./components/GroupDetailContainer";
import { GroupList } from "./components/GroupList";
import { fetchNightAuditGroupData } from "store/slice/NightAuditorSlice";
import { useEffect } from "react";

export const NightAuditor = () => {
  const dispatch = useDispatchRoot();
  useEffect(() => {
    dispatch(fetchNightAuditGroupData());
  }, [dispatch]);
  return (
    <div className={styles.wrapper}>
      <GroupList />
      <GroupDetailContainer />
    </div>
  );
};
