import { Button, PaginationProps, Table, notification } from "antd";
import styles from "../DriverOperator.module.css";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { ColumnsType } from "antd/es/table";
import { IOperatorDriver } from "common/define-types";
import { EditOutlined } from "@ant-design/icons";
import {
  editDriver,
  fetchDrivers,
  saveDriverReward,
} from "store/slice/DriverOperatorSlice";
import Utils from "common/Utils";
import useForm from "antd/es/form/hooks/useForm";
import { NoteDriverModal } from "./NoteDriverModal";
import { useEffect } from "react";
import moment from "moment";
// import { useForm } from "antd/es/form/Form";

export const DriverTable = () => {
  const selectLoading = useSelectorRoot(
    (state) => state.operatorDriver.isLoading
  );
  const selectEditingDriver = useSelectorRoot(
    (state) => state.operatorDriver.editingDriver
  );
  const columns: ColumnsType<IOperatorDriver> = [
    {
      title: "Tên group",
      dataIndex: "groupName",
      width: 150,
    },
    {
      title: "Tên tài xế",
      dataIndex: "name",
      width: 180,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name, "vi"),
        multiple: 2,
      },
    },
    {
      title: "Phone tài xế",
      dataIndex: "phone",
      width: 130,
    },
    {
      title: "Tên khách",
      dataIndex: "clientName",
      width: 180,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name, "vi"),
        multiple: 1,
      },
    },
    {
      title: "Phone khách",
      dataIndex: "clientPhone",
      width: 130,
    },
    {
      title: "Công/ngày (đ)",
      ellipsis: true,
      width: 130,
      dataIndex: "salaryDate",
      render: (value) => (
        <>
          {value !== undefined && value !== null && Utils.formatCurrency(value)}
        </>
      ),
      // sorter: {
      //   compare: (a, b) => a.salaryDate - b.salaryDate,
      //   multiple: 5,
      // },
    },
    {
      title: "Thưởng xe (đ)",
      width: 130,
      ellipsis: true,
      dataIndex: "carSalary",
      render: (value) => (
        <>
          {value !== undefined && value !== null && Utils.formatCurrency(value)}
        </>
      ),
      sorter: {
        compare: (a, b) => {
          if (!a.monthSalary || !b.monthSalary) return 0;
          return a?.monthSalary - b?.monthSalary;
        },
        multiple: 5,
      },
    },
    {
      title: "Thưởng (đ)",
      ellipsis: true,
      render: (_, record) => {
        return (
          <>
            {record.datA_Reward_Drivers[0]
              ? Utils.formatCurrency(record.datA_Reward_Drivers[0]?.reward)
              : undefined}
          </>
        );
      },
      // sorter: {
      //   compare: (a, b) =>
      //     a.datA_Reward_Drivers[0]?.reward - b.datA_Reward_Drivers[0]?.reward,
      //   multiple: 3,
      // },
    },
    {
      title: "Phạt (đ)",
      ellipsis: true,
      render: (_, record) => {
        return (
          <>
            {record.datA_Reward_Drivers[0]
              ? Utils.formatCurrency(record.datA_Reward_Drivers[0]?.fine)
              : undefined}
          </>
        );
      },
      // sorter: {
      //   compare: (a, b) => a.datA_Reward_Drivers[0]?.fine - b.datA_Reward_Drivers[0]?.fine,
      //   multiple: 4,
      // },
    },
    {
      title: "Tổng thu nhập/tháng",
      dataIndex: "monthSalary",
      ellipsis: true,
      width: 130,
      render: (value) => <>{Utils.formatCurrency(value)}</>,
      sorter: {
        compare: (a, b) => {
          if (!a.monthSalary || !b.monthSalary) return 0;
          return a?.monthSalary - b?.monthSalary;
        },
        multiple: 4,
      },
    },
    {
      title: "Ghi chú",
      render: (_, record) => {
        return <>{record.datA_Reward_Drivers[0]?.note}</>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 60,
      render: (_, record) => (
        // <div className={styles.btnContainer}>
        <Button
          type="text"
          icon={<EditOutlined style={{ fontSize: 20, color: "#1890FF" }} />}
          onClick={() => handleEditDriverReward(record)}
        />
        // </div>
      ),
    },
  ];

  const selectDrivers = useSelectorRoot(
    (state) => state.operatorDriver.drivers
  );
  const selectDriversResult = useSelectorRoot(
    (state) => state.operatorDriver.driversResult
  );
  const selectDriverFilterValue = useSelectorRoot(
    (state) => state.operatorDriver.filterValue
  );
  const dispatch = useDispatchRoot();
  const [editDriverForm] = useForm();

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectDriversResult) {
      dispatch(
        fetchDrivers({
          ...selectDriversResult,
          page,
        })
      );
    }
  };

  const handleEditDriverReward = (driver: IOperatorDriver) => {
    dispatch(editDriver(driver));
  };

  const handleSaveDriverReward = async () => {
    if (selectEditingDriver) {
      const formValue = await editDriverForm.validateFields().catch((err) => {
        console.log(err);
        notification.error({ message: "Vui lòng nhập đủ thông tin" });
      });
      if (!formValue) return;
      if (Object.keys(formValue).every((k) => formValue[k] === undefined)) {
        return;
      }
      dispatch(
        saveDriverReward({
          date: moment(selectDriverFilterValue?.dateTime)
            .startOf("day")
            .add(7, "hours")
            .add(1, "second")
            .toISOString(),
          reward: formValue.reward,
          fine: formValue.fine,
          note: formValue.note,
          driverId: selectEditingDriver.id,
        })
      );
    }
  };

  useEffect(() => {
    if (selectEditingDriver) {
      editDriverForm.setFields(
        Utils.objectToAntFormData(selectEditingDriver.datA_Reward_Drivers[0])
      );
    } else {
      editDriverForm.resetFields();
    }
  }, [selectEditingDriver, editDriverForm]);

  return (
    <>
      <Table
        dataSource={selectDrivers}
        columns={columns}
        //[1-12-2023] [Phuc Thinh]
        //Thay đổi thanh scroll
        className={`${styles.table} customScrollTable`}
        scroll={{ x: 1500 }}
        pagination={{
          current: selectDriversResult ? selectDriversResult.page : 1,
          pageSize: selectDriversResult ? selectDriversResult.pageSize : 10,
          total: selectDriversResult ? selectDriversResult.queryCount : 0,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
        rowKey={"id"}
        loading={selectLoading}
      />
      <NoteDriverModal
        form={editDriverForm}
        open={!!selectEditingDriver}
        handleClose={() => dispatch(editDriver(null))}
        handleOk={handleSaveDriverReward}
      />
    </>
  );
};
