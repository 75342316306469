import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IBooking,
  IBookingsResult,
  IBookingTicket,
  IDeleteBooking,
  IParams,
  RootEpic,
} from "common/define-types";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { deleteBookingById, getCurrentBooking } from "api/booking.api";
import moment from "moment";
import Utils from "common/Utils";

export interface BookingRevenueState {
  isLoading: boolean;
  bookings: IBooking[] | [];
  bookingSelected: IBooking | null;
  deletingBookingId: string | null;
  bookingSelectedTickets: IBookingTicket[];
  errMsg: string | null;
  bookingsResult: IBookingsResult | null;
  selectedDate: string;
  ticketQuery: string;
}

const initialState: BookingRevenueState = {
  isLoading: false,
  bookings: [],
  bookingSelected: null,
  deletingBookingId: null,
  bookingSelectedTickets: [],
  errMsg: null,
  bookingsResult: null,
  selectedDate: new Date().toISOString(),
  ticketQuery: "",
};

export const bookingRevenueSlice = createSlice({
  name: "bookingRevenue",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchBookings: (state, action: PayloadAction<IParams | undefined>) => {
      state.isLoading = true;
      state.deletingBookingId = null;
      state.errMsg = null;
    },
    setBookings: (state, action: PayloadAction<IBooking[]>) => {
      state.bookings = Utils.addIndexToBooking([...action.payload]);
      state.isLoading = false;
    },
    setBookingsResult: (state, action: PayloadAction<IBookingsResult>) => {
      state.bookingsResult = {
        ...state.bookingsResult,
        ...action.payload,
      };
      state.isLoading = false;
    },
    setBookingSelected: (state, action: PayloadAction<IBooking | null>) => {
      state.bookingSelected = action.payload;
      if (action.payload) {
        state.bookingSelectedTickets = action.payload.tickets;
      } else {
        state.bookingSelectedTickets = [];
      }
      state.ticketQuery = "";
    },
    deleteBooking: (state, action: PayloadAction<IDeleteBooking>) => {
      state.deletingBookingId = action.payload.bookingId;
    },
    deleteBookingFullfilled: (state, action: PayloadAction<number>) => {
      if (action.payload === 1) {
        const newBookings = state.bookings.filter(
          (booking) => booking.id !== state.deletingBookingId
        );
        state.bookings = newBookings;
        if (
          !newBookings.some(
            (booking) => booking.id === state.bookingSelected?.id
          )
        ) {
          state.bookingSelected = newBookings[0];
        }
      } else {
        state.errMsg = "Có lỗi xảy ra";
      }
      state.deletingBookingId = null;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.deletingBookingId = null;
    },
    setSelectedDate: (state, action: PayloadAction<string>) => {
      state.selectedDate = action.payload;
    },
    setTicketQuery: (state, action: PayloadAction<string>) => {
      state.ticketQuery = action.payload;
    },
    setBookingSelectedTickets: (
      state,
      action: PayloadAction<IBookingTicket[]>
    ) => {
      state.bookingSelectedTickets = action.payload;
    },
    setBookingQuery: (state, action: PayloadAction<string | undefined>) => {
      if (state.bookingsResult) {
        state.bookingsResult = {
          ...state.bookingsResult,
          search: action.payload,
        };
      }
    },
  },
});
const getBookings$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchBookings.match),
    switchMap((re) => {
      const startDate = moment(state$.value.bookingRevenue.selectedDate)
        .startOf("day")
        .toISOString();
      const endDate = moment(state$.value.bookingRevenue.selectedDate)
        .endOf("day")
        .toISOString();
      const params = {
        startDate,
        endDate,
        orderBy: "CreatedTime",
        search: re.payload?.search ?? "",
        ...re.payload,
      };
      return getCurrentBooking(params).pipe(
        mergeMap((res: any) => {
          const actionsWhenFailed = [
            bookingRevenueSlice.actions.setBookings([]),
            bookingRevenueSlice.actions.setBookingSelected(null),
            bookingRevenueSlice.actions.setBookingSelectedTickets([]),
          ];
          if (res && !res?.response?.error && res.results) {
            const bookings = res.results;
            if (bookings && bookings.length > 0) {
              const selectBooking = bookings.some(
                (booking: IBooking) =>
                  booking.id === state$.value.bookingRevenue.bookingSelected?.id
              )
                ? {
                    ...bookings.find(
                      (booking: IBooking) =>
                        booking.id ===
                        state$.value.bookingRevenue.bookingSelected?.id
                    ),
                    index: state$.value.bookingRevenue.bookingSelected?.index,
                  }
                : { ...bookings[0], index: 1 };
              return [
                bookingRevenueSlice.actions.setBookings(bookings),
                bookingRevenueSlice.actions.setBookingsResult(res),
                bookingRevenueSlice.actions.setBookingSelected(selectBooking),
              ];
            }
            return [
              ...actionsWhenFailed,
              bookingRevenueSlice.actions.setBookingsResult(res),
            ];
          } else {
            return [
              ...actionsWhenFailed,
              bookingRevenueSlice.actions.setErrMsg(res?.response.error),
              bookingRevenueSlice.actions.setBookingsResult(res),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          bookingRevenueSlice.actions.setErrMsg("Có lỗi xảy ra"),
          bookingRevenueSlice.actions.setBookings([]),
          bookingRevenueSlice.actions.setBookingSelected(null),
          bookingRevenueSlice.actions.setBookingSelectedTickets([]),
        ])
      );
    })
  );

const deleteBooking$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteBooking.match),
    switchMap((re) => {
      return deleteBookingById(
        re.payload.bookingId,
        re.payload.paymentMethodBookings
      ).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.bookingRevenue.bookingsResult) {
              return [
                bookingRevenueSlice.actions.fetchBookings(
                  state$.value.bookingRevenue.bookingsResult
                ),
              ];
            }
            return [bookingRevenueSlice.actions.fetchBookings()];
          } else {
            return [bookingRevenueSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          bookingRevenueSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi xóa booking"
          ),
        ])
      );
    })
  );
const fetchWhenSelectDate$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setSelectedDate.match),
    mergeMap((re) => {
      const startDate = moment(re.payload).startOf("day").toISOString();
      const endDate = moment(re.payload).endOf("day").toISOString();
      return [
        bookingRevenueSlice.actions.fetchBookings({
          // ...state$.value.bookingRevenue.bookingsResult,
          startDate,
          endDate,
        }),
      ];
    })
  );
const queryBookingTicket$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setTicketQuery.match),
    mergeMap((re) => {
      const query = re.payload.toLowerCase();
      const oldTickets =
        state$.value.bookingRevenue.bookingSelected?.tickets ?? [];
      const newTickets = oldTickets.filter(
        (ticket) =>
          ticket.fullName.toLowerCase().includes(query) ||
          ticket.name.toLowerCase().includes(query) ||
          ticket.email.toLowerCase().includes(query) ||
          ticket.phone?.includes(re.payload)
      );
      return [
        bookingRevenueSlice.actions.setBookingSelectedTickets(newTickets),
      ];
    })
  );
const queryBookings$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setBookingQuery.match),
    mergeMap((re) => {
      return [
        bookingRevenueSlice.actions.fetchBookings({
          // ...state$.value.bookingRevenue.bookingsResult,
          search: re.payload,
        }),
      ];
    })
  );
export const {
  fetchBookings,
  setBookingSelected,
  setBookings,
  deleteBooking,
  setErrMsg,
  setSelectedDate,
  setTicketQuery,
  setBookingSelectedTickets,
  setBookingQuery,
} = bookingRevenueSlice.actions;

export const BookingRevenueEpics = [
  getBookings$,
  deleteBooking$,
  fetchWhenSelectDate$,
  queryBookingTicket$,
  queryBookings$,
];

export const bookingRevenueReducer = bookingRevenueSlice.reducer;
