import {
  // Checkbox,
  Form,
  FormInstance,
  Input,
  // InputNumber,
  Typography,
} from "antd";
import { CustomPhoneInput } from "components/Input/CustomPhoneInput";
import styles from "../Booking.module.css";

interface IProps {
  form: FormInstance<any>;
  readOnly?: boolean;
}
const CustomerForm = ({ form, readOnly }: IProps) => {
  // const isSpecialTicket = Form.useWatch("isSpecial", form);
  return (
    <Form
      name="customerForm"
      className="ticket-card_form"
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Typography.Title level={4} className={styles.formTitle}>
        Thông tin cá nhân
      </Typography.Title>
      <Form.Item
        label={"Tên"}
        name={"fullName"}
        className={styles.formItem}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input readOnly={readOnly} placeholder="Nhập tên" />
      </Form.Item>
      <Form.Item
        label={"Số điện thoại"}
        name={"phone"}
        className={styles.formItem}
        // rules={[
        //   {
        //     required: true,
        //     message: "Không được bỏ trống",
        //   },
        // ]}
      >
        {readOnly ? <Input readOnly={readOnly} /> : <CustomPhoneInput />}
      </Form.Item>
      <Form.Item
        label={"Số hộ chiếu"}
        name={"passportId"}
        className={styles.formItem}
        // rules={[
        //   {
        //     required: true,
        //     message: "Không được bỏ trống",
        //   },
        // ]}
      >
        <Input readOnly={readOnly} placeholder="Nhập số hộ chiếu" />
      </Form.Item>
      <Form.Item
        label={"Email"}
        name={"email"}
        className={styles.formItem}
        rules={[
          // {
          //   required: true,
          //   message: "Không được bỏ trống",
          // },
          {
            type: "email",
            message: "Email không hợp lệ",
          },
        ]}
      >
        <Input readOnly={readOnly} placeholder="Nhập email" />
      </Form.Item>
      {/* [05-12-2023][Phuc Thinh] [Special Ticket has controlable price]
      [add input for check special and price] */}
      {/* <div>
        <Form.Item
          name={"isSpecial"}
          className={styles.formItem}
          valuePropName="checked"
        >
          <Checkbox disabled={readOnly}>Vé đặc biệt</Checkbox>
        </Form.Item>
        {isSpecialTicket === true && (
          <Form.Item
            name={"specialPrice"}
            className={styles.formItem}
            rules={[
              {
                required: true,
                message: "Không được bỏ trống",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Nhập giá vé"
              controls={false}
              formatter={(value) =>
                `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
              }
              parser={(value) =>
                parseInt(value ? value?.replaceAll(".", "") : "0")
              }
            />
          </Form.Item>
        )}
      </div> */}
    </Form>
  );
};

export default CustomerForm;
