import React from "react";
import { Header } from "antd/es/layout/layout";
import HeaderLogo from "image/icon/jasminelogo.svg";
import AgentHeaderLogo from "image/icon/jasmin-agent-logo.png";
import styles from "./HeaderSection.module.css";
import { ProfileSection } from "./ProfileSection";
import { useSelectorRoot } from "store/store";
// import { SearchInput } from "components/Search/SearchInput";

interface IProps {
  isAgentNav?: boolean;
}
export const HeaderSection = ({ isAgentNav = false }: IProps) => {
  const selectTitle = useSelectorRoot((state) => state.control.title);
  const headerStyle: React.CSSProperties = isAgentNav
    ? {
        minHeight: 65,
        backgroundColor: "rgba(0, 91, 165, 1)",
      }
    : {
        minHeight: 70,
        backgroundColor: "rgba(235, 239, 240, 1)",
      };
  return (
    <Header className={styles.header} style={headerStyle}>
      <div className={styles.headerContainer}>
        <div className={styles.logoContainer}>
          <img
            src={isAgentNav ? AgentHeaderLogo : HeaderLogo}
            alt="logo"
            className={styles.logo}
            style={{ maxWidth: isAgentNav ? 250 : 188 }}
          ></img>
          {!isAgentNav && (
            <>
              <span className={styles.logoName}>{selectTitle}</span>
              {/* <SearchInput /> */}
            </>
          )}
        </div>
        <ProfileSection isAgentNav={isAgentNav} />
      </div>
    </Header>
  );
};
