import { FC, ReactNode } from "react";
import { Route, RouteProps } from "react-router-dom";
import { NotAllowed } from "./NotAllowed";
import { useSelectorRoot } from "../../store/store";
import { Layout } from "antd";
import { Page } from "../page/Page";
import { RoleEnum } from "common/define-types";

interface PrivateRouteProps extends RouteProps {
  allowedRoles: Array<RoleEnum>;
  label: string;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({
  children,
  allowedRoles,
  label,
  ...rest
}) => {
  const userRole = useSelectorRoot((state) => state.login.role);
  const roles = allowedRoles.filter((r) => userRole === r);

  return (
    <Route
      {...rest}
      render={
        roles.length > 0
          ? () => (
              <Layout>
                <Page title={label}>{children as ReactNode}</Page>
              </Layout>
            )
          : () => <NotAllowed />
      }
    />
  );
};
