import { Typography } from "antd";
import Utils from "common/Utils";
import { IRouterStationBus } from "common/define-types";
import styles from "../Bus.module.css";
import Clock from "components/Clock/Clock";
import moment from "moment";

interface IProps {
  bus: IRouterStationBus;
}
export const BusOption = ({ bus }: IProps) => {
  const getTime = (bus: IRouterStationBus) => {
    if (bus.date) {
      const date = moment.utc(bus.date);

      return {
        hour: date.hours().toString(),
        minute: date.minutes().toString(),
      };
    }
    return {
      hour: 0,
      minute: 0,
    };
  };
  return (
    <div className={styles.busOption}>
      <div className={styles.busInfo}>
        <span>
          <Typography.Text strong>{bus.dmuC_Bus.name}</Typography.Text>
          <span style={{ fontSize: 12 }}>{`-${bus.dmuC_Bus.description}`}</span>
        </span>
        <Typography.Text>{`Nhà xe: ${bus.dmuC_Router_Station?.dmuC_Station.name}`}</Typography.Text>
      </div>
      <div className={styles.busInfo}>
        {bus.date ? (
          <Clock
            time={{
              hour: getTime(bus).hour,
              minute: getTime(bus).minute,
            }}
          />
        ) : (
          <></>
        )}
        <Typography.Text strong className={styles.busPrice}>
          {Utils.formatCurrency(bus.giaHienThi)}
        </Typography.Text>
      </div>
    </div>
  );
};
