import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import Utils from "common/Utils";

const api = `${SYSTEM_CONSTANTS.API_URL}/RouterStationBus`;

export const getRouterStationBusById = (id: string): Observable<any> => {
  return HttpClient.get(`${api}/${id}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
export const getAllRouterStationBuses = (props: {
  dateTime: string | null;
  dockerId?: string | null;
}): Observable<any> => {
  return HttpClient.get(`${api}?${Utils.getParamsString(props)}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
