import { Button, notification } from "antd";
import styles from "../Booking.module.css";
import { TicketCard } from "./TicketCard";
import { TicketsContainer } from "./TicketsContainer";
import {
  ArrowRightOutlined,
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import {
  clearBooking,
  selectTicket,
  setBookingStep,
  setTourStep,
  setComboToursSelected,
  updateTicketSelected,
} from "store/slice/BookingSlice";
import { BookingStepEnum, TourStepEnum } from "common/define-types";
import Utils from "common/Utils";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
//[14-12-2023][Phuc Thinh][Feature/14901]
//Change Tour to TourCombo
export const BookingDetail = () => {
  const dispatch = useDispatchRoot();
  const selectComboToursSelected = useSelectorRoot(
    (state) => state.booking.selectedComboTours
  );
  const selectTicketSelected = useSelectorRoot(
    (state) => state.booking.selectedTicket
  );
  const selectEditingBooking = useSelectorRoot(
    (state) => state.booking.editingBooking
  ); // if selectEditingBooking not null -> edit booking
  const history = useHistory();
  const handleCheckoutClick = () => {
    if (selectComboToursSelected) {
      const newSelectedComboTours = selectComboToursSelected.filter(
        (tour) => tour.tickets && tour.tickets.length > 0
      );
      if (!!selectEditingBooking || newSelectedComboTours.length > 0) {
        let hasInvalidTicket = false;
        newSelectedComboTours.forEach((tour) => {
          if (tour.tickets) {
            tour.tickets.forEach((ticket) => {
              if (
                // !Utils.isValid(ticket.email) ||
                !Utils.isValid(ticket.fullName) ||
                // !Utils.isValid(ticket.phone) ||
                // !Utils.isValid(ticket.passportId) ||
                !Utils.isValid(ticket.startDate)
              ) {
                hasInvalidTicket = true;
              }
            });
          }
        });
        if (hasInvalidTicket) {
          notification.error({
            message: "Mọi vé phải bao gồm đầy đủ thông tin !!",
          });
        } else {
          dispatch(setComboToursSelected(newSelectedComboTours));
          dispatch(setBookingStep(BookingStepEnum.PAYMENT));
        }
      } else {
        notification.error({
          message: "Danh sách vé không được bỏ trống",
        });
      }
    }
  };

  useEffect(() => {
    if (!selectTicketSelected && selectComboToursSelected.length > 0) {
      dispatch(
        selectTicket(
          selectComboToursSelected[0].tickets
            ? {
                ...selectComboToursSelected[0].tickets[0],
                comboTour: selectComboToursSelected[0],
              }
            : null
        )
      );
    }
  }, [dispatch, selectTicketSelected, selectComboToursSelected]);

  const checkReadOnlyTicket = () => {
    if (!selectEditingBooking) return false;
    if (
      selectTicketSelected &&
      selectEditingBooking?.tickets.some(
        (ticket) => ticket.id === selectTicketSelected.id
      ) &&
      moment
        .utc(selectTicketSelected.startDate)
        .endOf("day")
        .isBefore(moment.utc().startOf("day"))
    )
      return true;

    return false;
  };

  return (
    <div className={styles.tourTicketWrapper}>
      <div className={styles.customerContainer}>
        <TicketsContainer />
        <div className={styles.checkoutBtnContainer}>
          {selectEditingBooking !== null ? (
            <>
              <Button
                className={styles.checkoutBtn}
                onClick={() => {
                  dispatch(clearBooking());
                  history.push("/bookings");
                }}
              >
                Hủy cập nhật <CloseOutlined />
              </Button>
              <Button
                className={styles.checkoutBtn}
                onClick={handleCheckoutClick}
              >
                Cập nhật booking <SaveOutlined />
              </Button>
            </>
          ) : (
            <>
              <Button
                className={styles.checkoutBtn}
                onClick={() => {
                  dispatch(setTourStep(TourStepEnum.TOURS));
                  dispatch(selectTicket(null));
                }}
              >
                Hủy đặt vé <CloseOutlined />
              </Button>
              <Button
                className={styles.checkoutBtn}
                onClick={handleCheckoutClick}
              >
                Thanh toán <ArrowRightOutlined />
              </Button>
            </>
          )}
        </div>
      </div>
      <TicketCard
        readOnly={checkReadOnlyTicket()}
        onSave={(args) => dispatch(updateTicketSelected(args))}
      />
    </div>
  );
};
