import { Button, Typography } from "antd";
import styles from "../NightAuditor.module.css";
import { useSelectorRoot } from "store/store";
import { IIssue } from "api/types/issue";
import { IssueStatus } from "common/define-types";
import Utils from "common/Utils";

interface IProps {
  readOnly?: boolean;
  issue: IIssue;
  handleConfirm?: (issue: IIssue) => void;
  handleViewDetail?: (issue: IIssue) => void;
}
export const CancelTourItem = ({
  issue,
  readOnly,
  handleConfirm,
  handleViewDetail,
}: IProps) => {
  const selectGroupTickets = useSelectorRoot(
    (state) => state.group.groupTickets
  );
  const ticketInfo = selectGroupTickets.find(
    (ticket) => ticket.id === issue.ticketId
  );
  const selectIsSubmittingIssueId = useSelectorRoot(
    (state) => state.group.isSubmittingIsuseId
  );
  return (
    <div className={styles.issueItem}>
      <div className={styles.issueItem_wrapper}>
        <div className={styles.issueItem_item}>
          <Typography.Text
            strong
            className={styles.issueItem_issueName}
          >{`Khách bỏ tour`}</Typography.Text>
          <Typography.Text
            style={{
              textAlign: "right",
              color: Utils.getIssueTime(issue).color,
            }}
            title={Utils.getIssueTime(issue).time.format("DD/MM/YYYY HH:mm:ss")}
          >
            {Utils.getIssueTime(issue).text}
          </Typography.Text>
        </div>
        <div
          className={styles.issueItem_item}
          style={{ justifyContent: "flex-start" }}
        >
          <Typography.Text>
            {ticketInfo ? ticketInfo.fullName : " "}
          </Typography.Text>
        </div>
      </div>
      {!readOnly && (
        <div className={styles.issueItem_btnGroup}>
          <div>
            <Button
              className={styles.issueItem_confirmBtn}
              onClick={() => handleConfirm && handleConfirm(issue)}
              disabled={issue.status !== IssueStatus.Init}
              loading={
                selectIsSubmittingIssueId !== null &&
                selectIsSubmittingIssueId === issue.id
              }
            >
              {issue.status === IssueStatus.Approve || issue.status === IssueStatus.ComfirmByAdmin ? "Đã duyệt" : "Duyệt"}
            </Button>
          </div>
          <Button
            type="primary"
            onClick={() => handleViewDetail && handleViewDetail(issue)}
          >
            Chi tiết
          </Button>
        </div>
      )}
    </div>
  );
};
