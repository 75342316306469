import { Form, FormInstance, Input, Typography } from "antd";
import { IIssue } from "api/types/issue";
import Utils from "common/Utils";
import SYSTEM_CONSTANTS from "common/constants";
import { JobTicketStatusEnum, RoomTemplateType } from "common/define-types";
import { RoomTemplateSelect } from "components/Selects/RoomTemplateSelect";
import { TicketSelect } from "components/Selects/TicketSelect";
import { useEffect } from "react";
import { useSelectorRoot } from "store/store";

interface IProps {
  form: FormInstance<any>;
  detailIssue?: IIssue;
}
export const ChangeRoomForm = ({ form, detailIssue }: IProps) => {
  const selectGroupTickets = useSelectorRoot(
    (state) => state.group.groupTickets
  );
  useEffect(() => {
    if (!!detailIssue) {
      form.setFields(Utils.objectToAntFormData(detailIssue));
    }
  }, [form, detailIssue]);
  return (
    <Form form={form} labelCol={{ span: 24 }} className="noMargin_form-item">
      <Form.Item
        label={"Khách"}
        name={"ticketId"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <TicketSelect
          options={selectGroupTickets}
          onChange={(value) => form.setFieldValue("ticketId", value)}
          readOnly={!!detailIssue}
          acceptStatus={[
            JobTicketStatusEnum.INIT,
            JobTicketStatusEnum.CONFIRM,
            JobTicketStatusEnum.PASS,
          ]}
        />
      </Form.Item>
      <Form.Item
        label={"Chọn loại phòng ngủ"}
        name={"roomTemplateId"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <RoomTemplateSelect
          valuePropName="id"
          readOnly={!!detailIssue}
          acceptType={[RoomTemplateType.CHANGE_ROOM]}
        />
      </Form.Item>
      <Form.Item label={"Ghi chú"} name={"note"}>
        <Input.TextArea readOnly={!!detailIssue} />
      </Form.Item>
      {!!detailIssue && detailIssue.pathFile && (
        <>
          <Typography.Text strong style={{ paddingBlock: 16 }}>
            Ảnh:
          </Typography.Text>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={`${SYSTEM_CONSTANTS.API_URL}${detailIssue.pathFile}`}
              alt="issue"
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
          </div>
        </>
      )}
    </Form>
  );
};
