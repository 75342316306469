import { DatePicker, Skeleton, Typography } from "antd";
import styles from "../BookingDashboard.module.css";
import { PerformanceItem } from "pages/Admin/pages/Dashboard/components/PerformanceItem";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { getCurrentBookingSumarize } from "api/booking.api";
import { PieChart } from "../../../components/Chart/PieChart";

interface IStatisticalData {
  revenue: number;
  ticketRevenue: number;
  busRevenue: number;
  munberOfTicket: number;
  refund: number;
  bonus: number;
  dichVuThem: number;
  jasmineKeepMoney: number;
}
interface IPieData {
  title?: string;
  data: {
    label: string;
    name: keyof IStatisticalData;
    bgColor?: string;
  }[];
}
export const BusinessPerfomance = () => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [statisticalData, setStatisticalData] = useState<IStatisticalData>();
  const [isLoading, setIsLoading] = useState(false);
  const statisticalItems: {
    label: string;
    name: keyof IStatisticalData;
    isMoney?: boolean;
    bgColor?: string;
  }[] = [
    {
      label: "Tổng số khách đã bán",
      name: "munberOfTicket",
      bgColor: "#EEE0C9",
    },
  ];
  const piesData: IPieData[] = [
    {
      title: "Tổng doanh thu",
      data: [
        {
          label: "Doanh thu vé",
          name: "ticketRevenue",
          bgColor: "#337CCF",
        },
        {
          label: "Doanh thu bus",
          name: "busRevenue",
          bgColor: "#FF9B50",
        },
        {
          label: "Dịch vụ thêm",
          name: "dichVuThem",
          bgColor: "#00aa7e",
        },
      ],
    },
    {
      title: "Jasmine nhận được và hoa hồng trả đại lý",
      data: [
        {
          label: "Jasmine nhận được",
          name: "refund",
          bgColor: "#337CCF",
        },
        {
          label: "Hoa hồng trả đại lý",
          name: "bonus",
          bgColor: "#FF9B50",
        },
      ],
    },
  ];

  useEffect(() => {
    if (selectedDate) {
      setIsLoading(true);
      getCurrentBookingSumarize({
        startDate: selectedDate.startOf("month").toISOString(),
        endDate: selectedDate.endOf("month").toISOString(),
      }).subscribe(
        (res) => {
          if (res) setStatisticalData(res);
          setIsLoading(false);
        },
        (err) => setIsLoading(false)
      );
    }
  }, [selectedDate]);
  return (
    <section
      className={styles.container}
      style={{
        border: "1px solid rgba(216, 220, 221, 1)",
      }}
    >
      <div className={styles.containerHead}>
        <div className={styles.titleWrapper}>
          <Typography.Text strong style={{ whiteSpace: "nowrap" }}>
            Hiệu suất kinh doanh
          </Typography.Text>
          <DatePicker
            picker="month"
            value={selectedDate}
            onChange={(value) => setSelectedDate(value)}
            format={"MM-YYYY"}
            allowClear={false}
          />
        </div>
      </div>
      <div className={`${styles.performanceList} customScroll`}>
        {statisticalItems.map((item, index) => (
          <PerformanceItem
            label={item.label}
            value={statisticalData ? statisticalData[item.name] || 0 : 0}
            isMoney={!!item.isMoney}
            bgColor={item.bgColor}
            key={`performance-${index}`}
            isLoading={isLoading}
          />
        ))}
        {isLoading ? (
          <>
            <Skeleton active paragraph={{ rows: 2 }} />
            <Skeleton active paragraph={{ rows: 2 }} />
            <Skeleton active paragraph={{ rows: 2 }} />
          </>
        ) : (
          statisticalData &&
          piesData.map((pieData) =>
            pieData.data.every(
              (item) =>
                !statisticalData[item.name] || statisticalData[item.name] === 0
            ) ? (
              <>
                {pieData.data.map((item, index) => (
                  <PerformanceItem
                    label={item.label}
                    value={
                      statisticalData ? statisticalData[item.name] || 0 : 0
                    }
                    bgColor={"#EEE0C9"}
                    key={`performance-${index}`}
                    isLoading={isLoading}
                  />
                ))}
              </>
            ) : (
              <PieChart
                pieData={{
                  ...pieData,
                  data: pieData.data.map((item) => ({
                    ...item,
                    value: statisticalData
                      ? statisticalData[item.name] || 0
                      : 0,
                  })),
                }}
              />
            )
          )
        )}
      </div>
    </section>
  );
};
