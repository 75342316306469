import { Collapse, Skeleton, StepProps, Steps, Typography } from "antd";
import styles from "../Operation.module.css";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { EmptyHolder } from "components/Empty/EmptyHolder";
// import { setJobTourSelected } from "store/slice/GroupSlice";
// import { useEffect } from "react";
import {
  GroupItemType,
  IGroupJob,
  IJobTicket,
  // IJobTicket,
  JobDateEnum,
  JobTicketStatusEnum,
  // JobTicketStatusEnum,
} from "common/define-types";
import { setJobTourSelected } from "store/slice/GroupSlice";
import { useEffect } from "react";

const { Panel } = Collapse;
export const Journey = () => {
  const dispatch = useDispatchRoot();
  const selectJobTours = useSelectorRoot((state) => state.group.jobTours);
  const selectJobGroupSelected = useSelectorRoot(
    (state) => state.group.jobGroupSelected
  );
  const selectJobTourSelected = useSelectorRoot(
    (state) => state.group.jobTourSelected
  );
  const selectIsJobLoading = useSelectorRoot(
    (state) => state.group.isJobLoading
  );
  const selectJobDateSelected = useSelectorRoot(
    (state) => state.group.jobDateSelected
  );
  const filteredAndSortedJobGroups = selectJobTours
    .filter((job) => {
      if (selectJobDateSelected === JobDateEnum.ALL) return true;
      return job.day === selectJobDateSelected;
    })
    .sort((a, b) => {
      if (a.stt === "" || a.stt === null) return 1;
      if (b.stt === "" || b.stt === null) return -1;
      if (a.stt === b.stt) return 0;
      return parseInt(a.stt) < parseInt(b.stt) ? -1 : 1;
    });

  useEffect(() => {
    if (filteredAndSortedJobGroups && filteredAndSortedJobGroups.length > 0) {
      if (
        !filteredAndSortedJobGroups.some(
          (job) => job.id === selectJobGroupSelected?.id
        )
      ) {
        const jobTours = [...filteredAndSortedJobGroups[0].jobs];
        dispatch(
          setJobTourSelected({
            jobGroup: filteredAndSortedJobGroups[0],
            jobTour: jobTours.sort((a, b) => {
              if (a.stt === "" || a.stt === null) return 1;
              if (b.stt === "" || b.stt === null) return -1;
              if (a.stt === b.stt) return 0;
              return parseInt(a.stt) < parseInt(b.stt) ? -1 : 1;
            })[0],
          })
        );
      }
    } else {
      dispatch(setJobTourSelected({ jobGroup: null, jobTour: null }));
    }
  }, [
    filteredAndSortedJobGroups,
    dispatch,
    selectJobDateSelected,
    selectJobGroupSelected,
  ]);

  const handleChangeJourney = (value: number) => {
    if (filteredAndSortedJobGroups[value].type === GroupItemType.Child)
      dispatch(
        setJobTourSelected({
          jobGroup: filteredAndSortedJobGroups[value],
          jobTour: filteredAndSortedJobGroups[value].jobs[0],
        })
      );
  };

  const handleChangeSubJourney = (value: number, job: IGroupJob) => {
    dispatch(setJobTourSelected({ jobGroup: job, jobTour: job.jobs[value] }));
  };

  const getStatusOfJob = (tickets: IJobTicket[]): StepProps["status"] => {
    if (tickets.length === 0) return "wait";
    if (tickets.every((ticket) => ticket.status === JobTicketStatusEnum.INIT))
      return "wait";
    if (
      tickets
        .filter((ticket) => ticket.status !== JobTicketStatusEnum.BREAKUPTOUR)
        .some((ticket) => ticket.status === JobTicketStatusEnum.PASS) ||
      tickets
        .filter((ticket) => ticket.status !== JobTicketStatusEnum.BREAKUPTOUR)
        .some((ticket) => ticket.status === JobTicketStatusEnum.CONFIRM)
    )
      return "finish";
    return "wait";
  };

  const renderJobIcon = (job: IGroupJob) => {
    if (
      job.type === GroupItemType.Parent &&
      job.jobs.some((subJob) => getStatusOfJob(subJob.jobTickets) === "finish")
    )
      return undefined;
    if (
      job.type === GroupItemType.Child &&
      getStatusOfJob(job.jobs[0].jobTickets) === "finish"
    )
      return undefined;
    return <div className={styles.journeyIcon}>{job.stt}</div>;
  };

  return (
    <div className={styles.detailView}>
      <div className={styles.detailView_title}>
        <Typography.Title level={4} style={{ marginBlock: 0 }}>
          Hành trình
        </Typography.Title>
      </div>
      <div className={styles.detailView_wrapper}>
        <div className={`${styles.scrollContainer} customScroll`}>
          {selectIsJobLoading ? (
            <>
              <Skeleton
                avatar={{ size: "small" }}
                paragraph={{ rows: 1 }}
                active
              />
              <Skeleton
                avatar={{ size: "small" }}
                paragraph={{ rows: 1 }}
                active
              />
              <Skeleton
                avatar={{ size: "small" }}
                paragraph={{ rows: 1 }}
                active
              />
            </>
          ) : filteredAndSortedJobGroups &&
            filteredAndSortedJobGroups.length > 0 ? (
            <Steps
              direction="vertical"
              className="journey-step"
              current={-1}
              onChange={handleChangeJourney}
              items={[...filteredAndSortedJobGroups].map((job, index) => ({
                title:
                  job.type === GroupItemType.Child ? (
                    <>
                      <span
                        style={
                          job.jobs[0].id === selectJobTourSelected?.id
                            ? {
                                fontWeight: "500",
                                color: "#1677ff",
                              }
                            : {}
                        }
                      >
                        {job.name}
                      </span>
                    </>
                  ) : (
                    <Collapse
                      bordered={false}
                      expandIconPosition="end"
                      style={{
                        backgroundColor: "white",
                        width: "100%",
                      }}
                      defaultActiveKey={
                        job.jobs.some(
                          (subJob) => subJob.id === selectJobTourSelected?.id
                        )
                          ? [job.id]
                          : []
                      }
                    >
                      <Panel
                        key={job.id}
                        header={
                          <Typography.Text
                            strong
                            style={{
                              fontSize: 16,
                              color:
                                selectJobGroupSelected?.id === job.id
                                  ? "#1677ff"
                                  : "#000000a8",
                            }}
                          >
                            {job.name}
                          </Typography.Text>
                        }
                        style={{
                          marginBottom: 12,
                          border: "none",
                          // backgroundColor: "#f5f5f5",
                        }}
                      >
                        <Steps
                          direction="vertical"
                          current={-1}
                          onChange={(value) =>
                            handleChangeSubJourney(value, job)
                          }
                          items={job.jobs.map((subJob, index) => ({
                            title: (
                              <>
                                <span
                                  style={
                                    subJob.id === selectJobTourSelected?.id
                                      ? {
                                          fontWeight: "500",
                                          color: "#1677ff",
                                        }
                                      : {}
                                  }
                                  className={styles.jobItem}
                                >
                                  {subJob.name}
                                </span>
                              </>
                            ),
                            icon: getStatusOfJob(subJob.jobTickets) !==
                              "finish" && (
                              <div className={styles.journeyIcon}>
                                {index + 1}
                              </div>
                            ),
                            status: getStatusOfJob(subJob.jobTickets),
                          }))}
                        />
                      </Panel>
                    </Collapse>
                  ), // TODO: convert job.status to StepProps['status']
                icon: renderJobIcon(job),
                status:
                  job.type === GroupItemType.Parent
                    ? job.jobs.some(
                        (subJob) =>
                          getStatusOfJob(subJob.jobTickets) === "finish"
                      )
                      ? "finish"
                      : "error"
                    : getStatusOfJob(job.jobs[0].jobTickets),
              }))}
            />
          ) : (
            <EmptyHolder message="No job yet" />
          )}
        </div>
      </div>
    </div>
  );
};
