import { Modal, Select, Typography, message, notification } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { ChangeRoomForm } from "components/Form/ChangeRoomForm";
import { LeftTourForm } from "components/Form/LeftTourForm";
import { VehicleBreakForm } from "components/Form/VehicleBreakForm";
import styles from "./Modal.module.css";
import {
  IGroupTicket,
  IssueStatus,
  IssueType,
  ThanhToanIssueStatus,
} from "common/define-types";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { createNewIssue } from "store/slice/GroupSlice";
import OuterGasPumpForm from "components/Form/OuterGasPumpForm";
import ExtraMealForm from "components/Form/ExtraMealForm";
import ExtraSleptForm from "components/Form/ExtraSleptForm";
import moment from "moment";
import { IRoomTemplate } from "api/types/roomTemplate";
interface IProps {
  open: boolean;
  handleClose: () => void;
}
export const AddIssueModal = ({ open, handleClose }: IProps) => {
  const [pathFile, setPathFile] = useState<string | null>(null);
  const selectIsCreating = useSelectorRoot(
    (state) => state.group.isCreatingIssue
  );
  const selectHasCreated = useSelectorRoot(
    (state) => state.group.isIssueCreated
  );
  const selectCreateErrMsg = useSelectorRoot(
    (state) => state.group.issueErrMsg
  );
  const dispatch = useDispatchRoot();
  const selectGroupSelected = useSelectorRoot(
    (state) => state.group.groupSelected
  );
  const selectGroupTickets = useSelectorRoot(
    (state) => state.group.groupTickets
  );
  const selectRoomTemplates = useSelectorRoot(
    (state) => state.roomTemplate.roomTemplates
  );
  const selectMotorbikes = useSelectorRoot(
    (state) => state.group.groupMotorbikes
  );
  const selectJobDateSelected = useSelectorRoot(
    (state) => state.group.jobDateSelected
  );

  const [issueTypeActive, setIssueTypeActive] = useState(
    IssueType.BREAKDOWN_MOTORBIKE
  );
  const options = [
    {
      label: "Hỏng xe",
      value: IssueType.BREAKDOWN_MOTORBIKE,
    },
    {
      label: "Khách bỏ tour",
      value: IssueType.CANCELTOUR,
    },
    {
      label: "Đổi phòng ngủ",
      value: IssueType.CHANGEROOM,
    },
    {
      label: "Đổ xăng lẻ",
      value: IssueType.OUTER_FUEL,
    },
    {
      label: "Khách ăn thêm",
      value: IssueType.EXTRA_MEAL,
    },
    {
      label: "Ngủ thêm đêm cuối",
      value: IssueType.EXTRA_SLEPT,
    },
  ];
  const [issueForm] = useForm();
  const renderIssueForm = () => {
    switch (issueTypeActive) {
      case IssueType.CHANGEROOM:
        return <ChangeRoomForm form={issueForm} />;
      case IssueType.CANCELTOUR:
        return (
          <LeftTourForm
            form={issueForm}
            setPathFile={(value) => setPathFile(value)}
          />
        );
      case IssueType.BREAKDOWN_MOTORBIKE:
        return (
          <VehicleBreakForm
            form={issueForm}
            setPathFile={(value) => setPathFile(value)}
          />
        );
      case IssueType.OUTER_FUEL:
        return (
          <OuterGasPumpForm
            form={issueForm}
            setPathFile={(value) => setPathFile(value)}
          />
        );
      case IssueType.EXTRA_MEAL:
        return (
          <ExtraMealForm
            form={issueForm}
            setPathFile={(value) => setPathFile(value)}
          />
        );
      case IssueType.EXTRA_SLEPT:
        return <ExtraSleptForm form={issueForm} />;

      default:
        return <></>;
    }
  };

  const getIssueDate = (
    issueType: IssueType,
    additionalParams: { 
      roomTemplate?: IRoomTemplate, 
      groupTicket?: IGroupTicket 
    },
  ) => {
    const { roomTemplate, groupTicket } = additionalParams;
    switch (issueType) {
      case IssueType.CHANGEROOM:
        return moment
          .utc(groupTicket?.startDate)
          .add(
            roomTemplate
              ? roomTemplate?.date - 1
              : typeof selectJobDateSelected === "string"
              ? parseInt(selectJobDateSelected) - 1
              : selectJobDateSelected - 1,
            "days"
          ).toISOString();

      case IssueType.EXTRA_SLEPT:
        return groupTicket?.endDate;

      default:
        return undefined;
    }
  };

  useEffect(() => {
    if (selectCreateErrMsg) {
      message.error("Tạo phát sinh không thành công");
    }
  }, [selectCreateErrMsg]);

  useEffect(() => {
    if (selectHasCreated) {
      message.success("Tạo phát sinh thành công");
    }
  }, [selectHasCreated]);

  const handleCreateIssue = async () => {
    const issueValue = await issueForm.validateFields().catch((err) => {
      console.log(err);
      notification.error({ message: "Vui lòng nhập đủ thông tin" });
    });
    if (!issueValue) return;
    const amount_Money =
      issueTypeActive === IssueType.CHANGEROOM ||
      issueTypeActive === IssueType.EXTRA_SLEPT
        ? selectRoomTemplates
            .filter((room) => room.id === issueValue.roomTemplateId)[0]
            .price.toString()
        : issueValue.amount_Money || undefined;
    const foundMotorbike = selectMotorbikes.find(
      (motorbike) => motorbike.motorNumber === issueValue?.motobikeNumber
    );
    dispatch(
      createNewIssue({
        ...issueValue,
        amount_Money: amount_Money,
        pathFile: pathFile,
        type: issueTypeActive,
        groupId: selectGroupSelected?.id,
        status: IssueStatus.Approve,
        motobikeId: foundMotorbike ? foundMotorbike.id : undefined,
        date: getIssueDate(issueTypeActive, {
          roomTemplate: selectRoomTemplates.find(
            (room) => room.id === issueValue.roomTemplateId
          ),
          groupTicket: selectGroupTickets.find(gTicket => gTicket.id === issueValue?.ticketId)
        }),
        thanhToanIssueStatus: ThanhToanIssueStatus.PAID_BY_LEADER,
      })
    );
    handleClose();
    issueForm.resetFields();
    setPathFile(null);
  };
  return (
    <Modal
      open={open}
      // cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ loading: selectIsCreating }}
      okText="Thêm phát sinh"
      onOk={handleCreateIssue}
      destroyOnClose={true}
      onCancel={() => {
        handleClose();
        issueForm.resetFields();
        setPathFile(null);
      }}
      centered
      title={
        <Typography.Text strong style={{ fontSize: 20 }}>
          Tạo phát sinh
        </Typography.Text>
      }
    >
      <div className={styles.addIssueModal}>
        <Typography.Text strong>Loại phát sinh</Typography.Text>
        <Select
          options={options}
          value={issueTypeActive}
          onChange={(value) => setIssueTypeActive(value)}
          style={{ width: "100%" }}
        />
        {renderIssueForm()}
      </div>
    </Modal>
  );
};
