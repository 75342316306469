import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { IRouterStationBus, RootEpic } from "common/define-types";
import { AjaxError } from "rxjs/ajax";
import { getAllRouterStationBuses } from "api/routerStationBus.api";

export interface RouterStationBusState {
  isLoading: boolean;
  isSubmitting: boolean;
  routerStationBuses: IRouterStationBus[];
  errMsg: string | null;
}

const initialState: RouterStationBusState = {
  isLoading: false,
  isSubmitting: false,
  errMsg: null,
  routerStationBuses: [],
};

export const routerStationBusSlice = createSlice({
  name: "routerStationBus",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchRouterStationBusData: (
      state,
      action: PayloadAction<{ dateTime: string | null }>
    ) => {
      state.isLoading = true;
      state.errMsg = null;
    },
    setRouterStationBuses: (
      state,
      action: PayloadAction<IRouterStationBus[]>
    ) => {
      if (action.payload.length) {
        state.routerStationBuses = [...action.payload];
      }
      state.isLoading = false;
      state.errMsg = null;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
  },
});

const fetchRouterStationBus$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchRouterStationBusData.match),
    switchMap((re) => {
      return getAllRouterStationBuses(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.length) {
            const buses = res;
            return [
              routerStationBusSlice.actions.setRouterStationBuses(buses ?? []),
            ];
          } else {
            return [
              routerStationBusSlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          routerStationBusSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách xe bus"
          ),
        ])
      );
    })
  );

export const { fetchRouterStationBusData, setRouterStationBuses } =
  routerStationBusSlice.actions;

export const RouterStationBusEpics = [fetchRouterStationBus$];

export const routerStationBusReducer = routerStationBusSlice.reducer;
