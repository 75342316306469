import { Table } from "antd";
import styles from "../AgentOperator.module.css";
import { ColumnsType } from "antd/es/table";
import { IAgentWithSummarized, ICommonResult } from "common/define-types";
import Utils from "common/Utils";

interface IProps {
  dataSource: IAgentWithSummarized[];
  handleChangePage: (page: number) => void;
  agentResult: ICommonResult | null;
}
export const AgentTable = ({
  dataSource,
  handleChangePage,
  agentResult,
}: IProps) => {
  const columns: ColumnsType<IAgentWithSummarized> = [
    {
      title: "Mã đại lý",
      dataIndex: "code",
      width: 50,
      align: "center",
    },
    {
      title: "Tên đại lý",
      dataIndex: "name",
      width: 150,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name, "vi"),
        multiple: 1,
      },
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      width: 60,
      render(value) {
        return <>{value ? value.replaceAll(" ", "") : "Trống"}</>;
      },
    },
    {
      title: "Số vé đã bán",
      width: 90,
      render(_, record) {
        return <>{record.summarizedAgentMonthlyDto.munberOfTicket}</>;
      },
      sorter: {
        compare: (a, b) =>
          a.summarizedAgentMonthlyDto.munberOfTicket -
          b.summarizedAgentMonthlyDto.munberOfTicket,
        multiple: 2,
      },
    },
    {
      title: "Doanh thu tổng",
      width: 100,
      render(_, record) {
        return (
          <>{Utils.formatCurrency(record.summarizedAgentMonthlyDto.revenue)}</>
        );
      },
      sorter: {
        compare: (a, b) =>
          a.summarizedAgentMonthlyDto.revenue -
          b.summarizedAgentMonthlyDto.revenue,
        multiple: 5,
      },
    },
    {
      title: "Doanh thu vé",
      width: 100,
      render(_, record) {
        return (
          <>
            {Utils.formatCurrency(
              record.summarizedAgentMonthlyDto.ticketRevenue
            )}
          </>
        );
      },
      sorter: {
        compare: (a, b) =>
          a.summarizedAgentMonthlyDto.ticketRevenue -
          b.summarizedAgentMonthlyDto.ticketRevenue,
        multiple: 3,
      },
    },
    {
      title: "Doanh thu bus",
      render(_, record) {
        return (
          <>
            {Utils.formatCurrency(record.summarizedAgentMonthlyDto.busRevenue)}
          </>
        );
      },
      width: 100,
      sorter: {
        compare: (a, b) =>
          a.summarizedAgentMonthlyDto.busRevenue -
          b.summarizedAgentMonthlyDto.busRevenue,
        multiple: 4,
      },
    },
    {
      title: "Tiền trả Jasmine",
      render(_, record) {
        return (
          <>{Utils.formatCurrency(record.summarizedAgentMonthlyDto.refund)}</>
        );
      },
      width: 100,
    },
    {
      title: "Hoa hồng",
      render(_, record) {
        return (
          <>{Utils.formatCurrency(record.summarizedAgentMonthlyDto.bonus)}</>
        );
      },
      width: 100,
    },
  ];

  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        className={`${styles.table} driverTable`}
        scroll={{ x: 1500 }}
        pagination={{
          current: agentResult ? agentResult.page : 1,
          pageSize: agentResult ? agentResult.pageSize : 10,
          total: agentResult ? agentResult.queryCount : 0,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
        rowKey={"id"}
      />
    </>
  );
};
