import { Typography } from "antd";
import styles from "../Group.module.css";
import { IGroupTicket } from "common/define-types";

interface IProps {
  ticket: IGroupTicket;
}
const TicketSelectedItem = ({ ticket }: IProps) => {
  return (
    <div className={styles.ticketSelectedItem}>
      <div className={styles.ticketSelectedItem_name}>
        <Typography.Text strong>{ticket.fullName}</Typography.Text>
        <Typography.Text style={{ fontSize: 13 }}>
          {ticket.name}
        </Typography.Text>
      </div>
      <div>
        <Typography.Text
          strong
          italic
          style={{
            color: "rgb(140, 140, 140)",
            whiteSpace: "nowrap",
          }}
        >
          {ticket.agentCode}
        </Typography.Text>
      </div>
    </div>
  );
};

export default TicketSelectedItem;
