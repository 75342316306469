import { DatePicker, Form, FormInstance, Input } from "antd";
import styles from "../Motorbike.module.css";
import Utils from "common/Utils";

interface IProps {
  form: FormInstance<any>;
}
export const MotorbikeForm = ({ form }: IProps) => {
  return (
    <Form labelCol={{ span: 24 }} form={form}>
      <Form.Item
        label={"Tên xe máy"}
        name={"name"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <div className={styles.formItemGroup}>
        <Form.Item
          label={"Biển số xe"}
          name={"motorNumber"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Ngày đăng ký"}
          name={"registrationDate"}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <DatePicker
            format={Utils.dateFormat}
            style={{ width: "100%" }}
            allowClear={false}
          />
        </Form.Item>
      </div>
      <div className={styles.formItemGroup}>
        <Form.Item label={"Số khung"} name={"seriNumber"}>
          <Input />
        </Form.Item>
        <Form.Item label={"Số máy"} name={"machineNumber"}>
          <Input />
        </Form.Item>
      </div>
      <Form.Item label={"Ghi chú"} name={"note"}>
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
};
