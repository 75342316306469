import { Select } from "antd";
import { IMotorbike, StatusEnum } from "common/define-types";
import { useEffect } from "react";
import { fetchMotorbikes } from "store/slice/MotorbikeSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";

interface IProps {
  value?: string | null;
  onChange?: (arg: string) => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  valuePropName?: "motorNumber" | "id";
  motorBikesOptions?: IMotorbike[];
  acceptStatus?: StatusEnum[];
}
export const VehicleSelect = ({
  value,
  onChange = () => {},
  style,
  readOnly,
  valuePropName = "motorNumber",
  motorBikesOptions,
  acceptStatus,
}: IProps) => {
  const selectMotorbikes = useSelectorRoot(
    (state) => state.motorbike.motorbikes
  );
  const dispatch = useDispatchRoot();
  useEffect(() => {
    if (!motorBikesOptions) dispatch(fetchMotorbikes({ pageSize: 330 }));
  }, [dispatch, motorBikesOptions]);

  const options = motorBikesOptions || selectMotorbikes;
  return (
    <Select
      options={
        acceptStatus && acceptStatus.length > 0
          ? options
              .filter((motorbike) =>
                acceptStatus.some(
                  (acptSts) => !motorbike.status || acptSts === motorbike.status
                )
              )
              .filter(
                (value, index, self) =>
                  index ===
                  self.findIndex(
                    (t) => t[valuePropName] === value[valuePropName]
                  )
              )
              .map((motorbike) => ({
                label: motorbike.motorNumber,
                value: motorbike[valuePropName],
              }))
          : options
              .filter(
                (value, index, self) =>
                  index ===
                  self.findIndex(
                    (t) => t[valuePropName] === value[valuePropName]
                  )
              )
              .map((motorbike) => ({
                label: motorbike.motorNumber,
                value: motorbike[valuePropName],
              }))
      }
      value={value}
      style={style}
      disabled={readOnly}
      onChange={(value) => onChange(value)}
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};
