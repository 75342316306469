import { DatePicker } from "antd";
import styles from "./Bar.module.css";
// import { PrinterOutlined, SyncOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import Utils from "common/Utils";
interface IProps {
  value?: string;
  handleFilterDate?: (arg: Dayjs | null) => void;
  disabledDate?: (current: Dayjs) => boolean;
}
export const FilterBar = ({
  value = new Date().toISOString(),
  handleFilterDate = () => {},
  disabledDate,
}: IProps) => {
  return (
    <div style={{ display: "flex", gap: 30 }}>
      <DatePicker
        format={Utils.dateFormat}
        onChange={(date) => handleFilterDate(date)}
        value={dayjs(value)}
        disabledDate={disabledDate}
        allowClear={false}
      />
      <div className={styles.icons}>
        {/* <div className={styles.iconContainer}>
          <SyncOutlined className={styles.formIcon} />
          <span className={styles.iconText}>Clear</span>
        </div>
        <div className={styles.iconContainer}>
          <PrinterOutlined className={styles.formIcon} />
          <span className={styles.iconText}>Print</span>
        </div> */}
      </div>
    </div>
  );
};
