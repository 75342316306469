import styles from "../BookingRevenue.module.css";
import { BookingsList } from "./BookingsList";
import { TicketsList } from "./TicketsList";

export const BookingsWrapper = () => {
  return (
    <div
      className={`${styles.container}`}
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 13,
        backgroundColor: "transparent",
        flexWrap: "wrap",
      }}
    >
      <BookingsList />
      <TicketsList />
    </div>
  );
};
