import { FC, useEffect } from "react";
import { Layout, LayoutProps, Result } from "antd";

import { useDispatch } from "react-redux";
import { setTitle } from "../../store/slice/controlSlice";
import styles from "./Page.module.css";

const { Content } = Layout;

interface PageProps {
  title?: string;
}

export const Page: FC<LayoutProps & PageProps> = ({
  children,
  title,
  className,
  ...props
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (title) {
      dispatch(setTitle(title));
    }
  }, [title, dispatch]);

  // useEffect(() => {
  //   const tableContentEles = Array.from(
  //     document.getElementsByClassName(
  //       "ant-table-content"
  //     ) as HTMLCollectionOf<HTMLElement>
  //   );

  //   tableContentEles.forEach((ele) => {
  //     ele.style.cursor = "grab";

  //     let pos = { top: 0, left: 0, x: 0, y: 0 };

  //     const mouseDownHandler = function (e: any) {
  //       ele.style.cursor = "grabbing";
  //       ele.style.userSelect = "none";

  //       pos = {
  //         left: ele.scrollLeft,
  //         top: ele.scrollTop,
  //         // Get the current mouse position
  //         x: e.clientX,
  //         y: e.clientY,
  //       };

  //       ele.addEventListener("mousemove", mouseMoveHandler);
  //       ele.addEventListener("mouseup", mouseUpHandler);
  //     };

  //     const mouseMoveHandler = function (e: any) {
  //       // How far the mouse has been moved
  //       const dx = e.clientX - pos.x;
  //       const dy = e.clientY - pos.y;

  //       // Scroll the element
  //       ele.scrollTop = pos.top - dy;
  //       ele.scrollLeft = pos.left - dx;
  //     };

  //     const mouseUpHandler = function () {
  //       ele.style.cursor = "grab";
  //       ele.style.removeProperty("user-select");

  //       ele.removeEventListener("mousemove", mouseMoveHandler);
  //       ele.removeEventListener("mouseup", mouseUpHandler);
  //     };

  //     // Attach the handler
  //     ele.addEventListener("mousedown", mouseDownHandler);
  //     return () => {};
  //   });
  // });
  return (
    <>
      <Layout {...props}>
        <Content>
          {/* <Row style={{ padding: "27px 0px 0px 30px" }}>
            <Col span={18}>
              <Text style={{ fontSize: 30, color: "#005BA5", fontWeight: 500 }}>
                {title}
              </Text>
            </Col>
          </Row> */}
          {/* <Row>
            <LinkBreadcrum></LinkBreadcrum>
          </Row> */}
          <div className={`${styles.mainContent} customScroll`}>
            {children ?? (
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
              />
            )}
          </div>
        </Content>
      </Layout>
    </>
  );
};
