import { useSelectorRoot } from "store/store";
import styles from "../Group.module.css";
import { Typography } from "antd";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import TicketSelectedItem from "./TicketSelectedItem";

const PreviewTicketsContainer = () => {
  const selectTicketsSelected = useSelectorRoot(
    (state) => state.group.groupTicketsSelected
  );
  // [5-12-2023][Phuc Thinh] [show preview ticket selected]
  // add component show ticket selected
  return (
    <div className={styles.listContainer} style={{ minWidth: 300, flex: 1 }}>
      <div className={styles.listContainer_header}>
        <Typography.Text strong style={{ fontSize: 20 }}>
          Danh sách đã chọn
        </Typography.Text>
      </div>
      <div className={styles.listWrapper}>
        <div className={styles.listWrapper_List}>
          {selectTicketsSelected.length > 0 ? (
            selectTicketsSelected.map((ticket) => (
              <TicketSelectedItem ticket={ticket} key={ticket.id} />
            ))
          ) : (
            <EmptyHolder message="No tickets selected" />
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewTicketsContainer;
