import { Button, Typography, notification } from "antd";
import styles from "./Billing.module.css";
import { CheckOutlined } from "@ant-design/icons";
import Utils from "common/Utils";
import { useEffect, useState } from "react";
import { MethodForm } from "./MethodForm";
import { useSelectorRoot } from "store/store";
import { IBooking, IPaymentMethodBooking, ITicket } from "common/define-types";
import { useForm } from "antd/es/form/Form";
import { IUpdatePaymentMethodBooking } from "api/types/booking";

interface IProps {
  handleSubmit: (paymentMethodBookings: IPaymentMethodBooking[]) => void;
  handleUpdateBooking: (
    paymentMethodBookingsConnect?: IUpdatePaymentMethodBooking[],
    paymentMethodBookingsDisable?: IUpdatePaymentMethodBooking[]
  ) => void;
}
//[14-12-2023][Phuc Thinh][Feature/14901]
//Change Tour to TourCombo
export const PaymentMethod = ({
  handleSubmit,
  handleUpdateBooking,
}: IProps) => {
  const selectComboToursSelected = useSelectorRoot(
    (state) => state.booking.selectedComboTours
  );
  const [payAmount, setPayAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [methodForm] = useForm();
  const selectIsSubmitting = useSelectorRoot(
    (state) => state.booking.isSubmitting
  );
  const selectEditingBooking = useSelectorRoot(
    (state) => state.booking.editingBooking
  );
  const hasMoneyJasmineKeep =
    !!selectEditingBooking && selectEditingBooking.moneyJasmineKeep > 0;
  const TOTAL_AMOUNT =
    Utils.calculateTotal(selectComboToursSelected) +
    Utils.calculateBusTotal(selectComboToursSelected) +
    Utils.calculateExtraServiceTotal(selectComboToursSelected);
  const HAS_TO_PAY_AMOUNT = !!selectEditingBooking
    ? TOTAL_AMOUNT - paidAmount
    : TOTAL_AMOUNT;
  const handleBeforeSubmit = async () => {
    const totalAmount = calculateTotalAmount();
    if (totalAmount - payAmount > 0) {
      notification.info({
        message: `Bạn phải nhập đủ số tiền cần ${
          HAS_TO_PAY_AMOUNT > 0 ? "trả" : "hoàn"
        }`,
      });
      return;
    }
    const paymentMethodBookingsValue = await methodForm.validateFields();
    const paymentMethodBookings: IPaymentMethodBooking[] = [];
    for (const key in paymentMethodBookingsValue) {
      if (
        paymentMethodBookingsValue[key] &&
        paymentMethodBookingsValue[key] > 0
      ) {
        paymentMethodBookings.push({
          // [4-12-2023] [Phuc Thinh] [Update paymentMethodBooking interface]
          paymentCode: key,
          amount: paymentMethodBookingsValue[key],
        });
      }
    }

    if (!!selectEditingBooking) {
      if (totalAmount === 0) {
        handleUpdateBooking();
      } else {
        if (HAS_TO_PAY_AMOUNT < 0) {
          // refund
          handleUpdateBooking(undefined, paymentMethodBookings);
        } else {
          // pay up debt
          handleUpdateBooking(paymentMethodBookings, undefined);
        }
      }
    } else {
      handleSubmit(paymentMethodBookings);
    }
  };

  useEffect(() => {
    if (selectEditingBooking) {
      let paidAmount =
        Utils.calculateTotalBookingPrice(selectEditingBooking) +
        Utils.calculateBookingBusTotal(selectEditingBooking) +
        Utils.calculateBookingExtraServiceTotal(selectEditingBooking);
      const bookingTickets: ITicket[] = [];
      selectComboToursSelected.forEach((tour) => {
        if (tour.tickets && tour.tickets.length > 0) {
          bookingTickets.push(...tour.tickets);
        }
      });
      // 20% on delete oncoming ticket
      let finedAmount = 0;
      const filterDisabledOncomingTicketsBooking: IBooking = {
        ...selectEditingBooking,
        tickets: selectEditingBooking.tickets.filter((ticket) => {
          return (
            ticket.isErase !== true &&
            bookingTickets.every((bTicket) => bTicket.id !== ticket.id) &&
            Utils.checkOncomingTicket(ticket)
          );
        }),
      };

      if (filterDisabledOncomingTicketsBooking.tickets.length > 0) {
        finedAmount +=
          Utils.calculateTotalBookingPrice(
            filterDisabledOncomingTicketsBooking
          ) +
          Utils.calculateBookingBusTotal(filterDisabledOncomingTicketsBooking) +
          Utils.calculateBookingExtraServiceTotal(
            filterDisabledOncomingTicketsBooking
          );
      }

      if (finedAmount > 0) {
        paidAmount -= Math.round((finedAmount * Utils.refundPercent) / 100);
      }

      setPaidAmount(paidAmount);
    }
  }, [selectEditingBooking, selectComboToursSelected]);

  const renderBillResult = () => {
    let leftAmount = 0;
    if (hasMoneyJasmineKeep && HAS_TO_PAY_AMOUNT < 0) {
      if (-HAS_TO_PAY_AMOUNT <= selectEditingBooking.moneyJasmineKeep) {
        leftAmount = 0;
      } else {
        leftAmount =
          HAS_TO_PAY_AMOUNT + selectEditingBooking.moneyJasmineKeep + payAmount;
      }
    } else {
      leftAmount =
        HAS_TO_PAY_AMOUNT >= 0
          ? HAS_TO_PAY_AMOUNT - payAmount
          : HAS_TO_PAY_AMOUNT + payAmount;
    }
    if (leftAmount === 0)
      return (
        <>
          Còn lại <br /> {Utils.formatCurrency(0)}
        </>
      );
    if (leftAmount > 0)
      return (
        <>
          Còn thiếu <br /> {Utils.formatCurrency(leftAmount)}
        </>
      );
    if (leftAmount < 0)
      return (
        <>
          Còn thừa <br /> {Utils.formatCurrency(-leftAmount)}
        </>
      );
  };

  const calculateTotalAmount = () => {
    // HAS_TO_PAY_AMOUNT < 0 === refund
    if (hasMoneyJasmineKeep && HAS_TO_PAY_AMOUNT < 0) {
      if (-HAS_TO_PAY_AMOUNT <= selectEditingBooking.moneyJasmineKeep) return 0;
      else return -HAS_TO_PAY_AMOUNT - selectEditingBooking.moneyJasmineKeep;
    }
    return HAS_TO_PAY_AMOUNT >= 0 ? HAS_TO_PAY_AMOUNT : -HAS_TO_PAY_AMOUNT;
  };

  return (
    <section className={styles.bigCard}>
      {/* {paymentDetailModalOpen ? (
        <PaymentDetailModal
          total={TOTAL_AMOUNT - payAmount}
          open={paymentDetailModalOpen}
          handleClose={() => setPaymentDetailModalOpen(false)}
          handleSubmit={handleUpdateBooking}
          isRefundModal={TOTAL_AMOUNT - payAmount < 0}
        />
      ) : (
        <></>
      )} */}
      <Typography.Title level={4} className={styles.bigCard_title}>
        Thông tin hóa đơn
      </Typography.Title>
      <div className={styles.paymentContainer}>
        <div className={`${styles.paymentWrapper}`}>
          <div
            className={styles.justifyBetween}
            style={{ padding: "0 30px 12px" }}
          >
            <Typography.Text strong className={styles.billPrice}>
              Tổng <br /> {Utils.formatCurrency(TOTAL_AMOUNT)}
            </Typography.Text>
            <Typography.Text strong className={styles.billPrice}>
              {!!selectEditingBooking ? (
                renderBillResult()
              ) : (
                <>
                  Còn lại <br />{" "}
                  {Utils.formatCurrency(TOTAL_AMOUNT - payAmount)}
                </>
              )}
            </Typography.Text>
          </div>
          <MethodForm
            totalAmount={calculateTotalAmount()}
            setPayAmount={(amount) => setPayAmount(amount)}
            payAmount={payAmount}
            paymentForm={methodForm}
            hasDebtItem={HAS_TO_PAY_AMOUNT >= 0}
          />
        </div>
        <div className={styles.paymentBtnContainer}>
          {/* {!!selectEditingBooking ? (
            <Button
              className={styles.commonBtn}
              onClick={() => setPaymentDetailModalOpen(true)}
            >
              {TOTAL_AMOUNT - payAmount >= 0 ? "Thanh toán" : "Hoàn tiền"}
              <CheckOutlined />
            </Button>
          ) : ( */}
          <Button
            className={styles.commonBtn}
            onClick={handleBeforeSubmit}
            loading={selectIsSubmitting}
          >
            Hoàn thành <CheckOutlined />
          </Button>
          {/* )} */}
        </div>
      </div>
    </section>
  );
};
