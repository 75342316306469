import { Select } from "antd";
import Utils from "common/Utils";
import { IDriver, StatusEnum } from "common/define-types";
import { useEffect } from "react";
import { fetchDrivers } from "store/slice/DriverSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";

interface IProps {
  value?: string | null;
  onChange?: (arg: string) => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  driversOptions?: IDriver[];
  acceptStatus?: StatusEnum[];
  valuePropName?: "accountId" | "id";
}
export const DriverSelect = ({
  value,
  onChange = () => {},
  style,
  readOnly = false,
  driversOptions,
  acceptStatus,
  valuePropName = "id",
}: IProps) => {
  const selectDrivers = useSelectorRoot((state) => state.driver.drivers);
  const selectIsRefreshing = useSelectorRoot(
    (state) => state.login.isRefreshing
  );
  const dispatch = useDispatchRoot();
  useEffect(() => {
    if (!driversOptions && !selectIsRefreshing) {
      dispatch(fetchDrivers({ pageSize: Utils.RANGE_PAGESIZE.MAX_INTERGER }));
    }
  }, [dispatch, driversOptions, selectIsRefreshing]);
  const options = driversOptions || selectDrivers;
  return (
    <Select
      options={
        acceptStatus && acceptStatus.length > 0
          ? options
              .filter((driver) =>
                acceptStatus.some((acptSts) => acptSts === driver.status)
              )
              .map((driver) => ({
                label: driver.name,
                value: driver[valuePropName],
              }))
          : options.map((driver) => ({
              label: driver.name,
              value: driver[valuePropName],
            }))
      }
      value={value}
      style={{
        minWidth: 100,
        ...style,
      }}
      onChange={(value) => onChange(value)}
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      disabled={readOnly}
      onClick={(e: any) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    />
  );
};
