import SYSTEM_CONSTANTS from "common/constants";
import HttpClient from "./http-client";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import { IParams } from "common/define-types";
import Utils from "common/Utils";

const api = `${SYSTEM_CONSTANTS.API_URL}/HangHoa`;

export const getAllHangHoa = (params: IParams): Observable<any> => {
  return HttpClient.get(`${api}?${Utils.getParamsString(params)}`).pipe(
    map(
      (res) => (res as any) || null,
      catchError((e: AjaxError) => throwError(e))
    )
  );
};
