import { FilterBar } from "components/Bar/FilterBar";
import styles from "./Operation.module.css";
import { GroupList } from "../Group/components/GroupList";
import { GroupDetailContainer } from "./components/GroupDetailContainer";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { useEffect } from "react";
import {
  fetchGroupData,
  selectGroup,
  setIsOnlyStartDate,
  setSelectedDate,
} from "store/slice/GroupSlice";
import { notification } from "antd";

export const Operation = () => {
  const selectGroupSelected = useSelectorRoot(
    (state) => state.group.groupSelected
  );
  const selectGroups = useSelectorRoot((state) => state.group.groups);
  const selectOperationErrMsg = useSelectorRoot(
    (state) => state.group.operationErrMsg
  );
  const selectSelectedDate = useSelectorRoot(
    (state) => state.group.selectedDate
  );
  const dispatch = useDispatchRoot();

  useEffect(() => {
    if (selectOperationErrMsg) {
      notification.destroy();
      notification.error({
        message: selectOperationErrMsg,
      });
    }
  }, [selectOperationErrMsg]);

  useEffect(() => {
    dispatch(setIsOnlyStartDate(false));
    dispatch(fetchGroupData({ isOnlyStartDate: false }));
  }, [dispatch]);

  useEffect(() => {
    if (!selectGroupSelected && selectGroups.length > 0) {
      dispatch(selectGroup(selectGroups[0]));
    }
  }, [selectGroupSelected, dispatch, selectGroups]);
  return (
    <div className={styles.container}>
      <FilterBar
        value={selectSelectedDate}
        handleFilterDate={(value) => {
          if (value) dispatch(setSelectedDate(value.toISOString()));
        }}
      />
      <div className={styles.wrapper}>
        <GroupList groupOnly={true} isResponsive={true} />
        <GroupDetailContainer />
      </div>
    </div>
  );
};
