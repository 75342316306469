import { BarChart, IBarData } from "components/Chart/BarChart";
import styles from "./BookingDashboard.module.css";
import { BusinessPerfomance } from "./components/BusinessPerfomance";
import { useEffect, useState } from "react";
import moment from "moment";
import { notification } from "antd";
import { getAllAgentsOfTopDays } from "api/agent.api";
import Utils from "common/Utils";
import { IAgentWithSummarized } from "common/define-types";
import { useSelectorRoot } from "store/store";

export const BookingDashboard = () => {
  const selectCurrentAgent = useSelectorRoot(
    (state) => state.booking.currentAgent
  );
  const [barData, setBarData] = useState<IBarData | null>(null);

  useEffect(() => {
    const topDay = 10;
    getAllAgentsOfTopDays({
      dateTime: moment.utc().toISOString(),
      topDay,
    }).subscribe(
      (res) => {
        if (res) {
          const orderedRes = [...res].sort((a: any, b: any) => {
            if (!a.dateTime || !a.dateTime) return 0;
            if (moment(a.dateTime).isBefore(b.dateTime)) return -1;
            return 1;
          });
          const topDayBbar = {
            title: `Doanh thu ${topDay} ngày gần nhất`,
            data: {
              labels: orderedRes.map((item: any) =>
                moment.utc(item.dateTime).format(Utils.dateFormat)
              ),
              datasets: [
                {
                  label: "Tổng doanh thu",
                  data: orderedRes.map((item: any) => {
                    const results: IAgentWithSummarized[] =
                      item.agentDtos.results.filter(
                        (agent: IAgentWithSummarized) =>
                          agent.id === selectCurrentAgent?.id
                      );
                    return results.reduce(
                      (prev, currentAgent) =>
                        prev + currentAgent.summarizedAgentMonthlyDto.revenue,
                      0
                    );
                  }),
                },
              ],
            },
          };
          setBarData(topDayBbar);
        }
      },
      (err) => notification.error({ message: "Có lỗi xảy ra" })
    );
  }, [selectCurrentAgent]);

  return (
    <section className={styles.bookingDashboardContainer}>
      <div className={styles.perfomanceContainer}>
        <BusinessPerfomance />
      </div>
      {barData && selectCurrentAgent && (
        <div
          style={{
            flex: 1,
            minWidth: 0,
            width: "100%",
            maxWidth: "100%",
            paddingBottom: 20,
          }}
        >
          <BarChart barData={barData} />
        </div>
      )}
    </section>
  );
};
