import {
  Button,
  Form,
  Image,
  Input,
  Select,
  Typography,
  Upload,
  UploadProps,
  message,
} from "antd";
import { CameraFilled } from "@ant-design/icons";
import styles from "./form.module.css";
import Utils from "common/Utils";
import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";
import { updateProfile } from "api/account.api";
import { useDispatchRoot } from "store/store";
import { setProfile } from "store/controls/LoginEpic";
import SYSTEM_CONSTANTS from "common/constants";

interface IProps {
  avatarUrl: string | null;
  setAvatarUrl: (value: string | null) => void;
  profile: IAccount | null;
  handleCloseModal?: () => void;
}
export const ProfileForm = ({
  avatarUrl,
  setAvatarUrl,
  profile,
  handleCloseModal,
}: IProps) => {
  const [profileForm] = useForm();
  const dispatch = useDispatchRoot();

  const props: UploadProps = {
    name: "file",
    multiple: true,
    maxCount: 1,
    accept: "image/*",
    action: `${SYSTEM_CONSTANTS.API_URL}/Issue/issues/uploadImage`,
    // action: "https://sit.srv.anybim.vn/license_manager/users/current/avatar",
    headers: {
      Authorization: Utils.token ? `Bearer ${Utils.token}` : "",
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} upload thành công.`);
        setAvatarUrl(`${SYSTEM_CONSTANTS.API_URL}${info.file.response}`);
      } else if (status === "error") {
        message.error(`${info.file.name} upload không thành công.`);
        !avatarUrl && setAvatarUrl(null);
      }
    },
  };

  useEffect(() => {
    if (profile) {
      profileForm.setFields(
        Utils.objectToAntFormData({
          ...profile,
          fullName: `${profile.lastname} ${profile.firstname}`,
          gender: profile.profile?.gender,
          address: profile.profile?.address,
        })
      );
    }
  }, [profile, profileForm]);

  const handleSubmit = (values: any) => {
    const postValue: IUpdateProfile = {
      ...profile,
      firstname: Utils.getName(values.fullName).firstName.trim(),
      lastname: Utils.getName(values.fullName).lastName ?? "",
      email: values.email,
      phoneNumber: values.phoneNumber,
      avatarUrl: avatarUrl ?? undefined,
      profile: {
        ...profile?.profile,
        gender: values.gender,
        address: values.address,
      },
    };
    updateProfile(postValue).subscribe((res) => {
      if (res) {
        dispatch(setProfile(res));
        message.success(`Cập nhật thông tin thành công`);
        handleCloseModal && handleCloseModal();
      } else {
        message.success(`Cập nhật thông tin không thành công`);
      }
    });
  };

  return (
    <Form
      labelCol={{ span: 8 }}
      labelAlign="left"
      form={profileForm}
      onFinish={handleSubmit}
      labelWrap={true}
    >
      <Typography.Title level={4} style={{ marginBlock: 10 }}>
        Thông tin cá nhân
      </Typography.Title>
      <div className={styles.profileFormContainer}>
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Form.Item label={"Họ và tên"} name={"fullName"}>
            <Input />
          </Form.Item>
          <Form.Item label={"Email"} name={"email"}>
            <Input />
          </Form.Item>
          <Form.Item label={"Số điện thoại"} name={"phoneNumber"}>
            <Input />
          </Form.Item>
          <Form.Item label={"Giới tính"} name={"gender"}>
            <Select
              options={[
                {
                  label: "Nam",
                  value: "Male",
                },
                {
                  label: "Nữ",
                  value: "Female",
                },
                {
                  label: "Khác",
                  value: "Other",
                },
              ]}
            />
          </Form.Item>
          <Form.Item label={"Địa chỉ"} name={"address"}>
            <Input />
          </Form.Item>
        </div>
        <div className={styles.uploadContainer}>
          <div className={styles.uploadWrapper}>
            <div style={{ borderRadius: "50%", overflow: "hidden" }}>
              <Image
                width={200}
                height={200}
                alt="avatar"
                style={{ objectFit: "cover", borderRadius: "50%" }}
                src={
                  avatarUrl ||
                  "https://i.pinimg.com/564x/44/2f/82/442f825e875dc31be3d0d109e4f2d11c.jpg"
                }
              ></Image>
            </div>
            <Upload showUploadList={false} {...props}>
              <div className={styles.uploadIconWrapper}>
                <CameraFilled style={{ color: "white" }} />
              </div>
            </Upload>
          </div>
        </div>
      </div>
      <Form.Item
        style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
      >
        <Button type="primary" htmlType="submit">
          Lưu thông tin
        </Button>
      </Form.Item>
    </Form>
  );
};
