import { combineReducers } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { BootstrapEpics, bootstrapReducer } from "./controls/bootstrap.slice";
import { LoginEpics, loginReducer } from "./controls/LoginEpic";
import { ControlEpics, controlReducer } from "./slice/controlSlice";
import { BookingEpics, bookingReducer } from "./slice/BookingSlice";
import { GroupEpics, groupReducer } from "./slice/GroupSlice";
import {
  BookingRevenueEpics,
  bookingRevenueReducer,
} from "./slice/BookingRevenueSlice";
import { AgentsEpics, agentsReducer } from "./slice/AgentsSlice";
import {
  RouterStationBusEpics,
  routerStationBusReducer,
} from "./slice/RouterStationBus";
import { NightAuditEpics, nightAuditReducer } from "./slice/NightAuditorSlice";
import { DriverEpics, driverReducer } from "./slice/DriverSlice";
import { MotorbikeEpics, motorbikeReducer } from "./slice/MotorbikeSlice";
import {
  RoomTemplateEpics,
  roomTemplateReducer,
} from "./slice/RoomTemplateSlice";
import {
  OperatorDriverEpics,
  operatorDriverReducer,
} from "./slice/DriverOperatorSlice";
import {
  OperatorMotorbikeEpics,
  operatorMotorbikeReducer,
} from "./slice/MotorbikeOperatorSlice";
import { SupplierEpics, supplierReducer } from "./slice/SupplierSlice";
import { HangHoaEpics, hangHoaReducer } from "./slice/HangHoaSlice";
import {
  OperatorTicketEpics,
  operatorTicketReducer,
} from "./slice/TIcketOperatorSlice";
import { nightBookingEpics, nightBookingReducer } from "./slice/NightBooking.slice";

const rootReducer = combineReducers({
  login: loginReducer,
  bootstrap: bootstrapReducer,
  control: controlReducer,
  booking: bookingReducer,
  group: groupReducer,
  bookingRevenue: bookingRevenueReducer,
  agents: agentsReducer,
  routerStationBus: routerStationBusReducer,
  nightAuditor: nightAuditReducer,
  nightBooking: nightBookingReducer,
  driver: driverReducer,
  motorbike: motorbikeReducer,
  roomTemplate: roomTemplateReducer,
  operatorDriver: operatorDriverReducer,
  operatorMotorbike: operatorMotorbikeReducer,
  supplier: supplierReducer,
  hangHoa: hangHoaReducer,
  operatorTicket: operatorTicketReducer,
});

export const rootEpic = combineEpics(
  ...LoginEpics,
  ...BootstrapEpics,
  ...ControlEpics,
  ...BookingEpics,
  ...GroupEpics,
  ...BookingRevenueEpics,
  ...AgentsEpics,
  ...RouterStationBusEpics,
  ...NightAuditEpics,
  ...DriverEpics,
  ...MotorbikeEpics,
  ...RoomTemplateEpics,
  ...OperatorDriverEpics,
  ...OperatorMotorbikeEpics,
  ...SupplierEpics,
  ...HangHoaEpics,
  ...OperatorTicketEpics,
  ...nightBookingEpics
);
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
