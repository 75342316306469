import { Steps } from "antd";
import styles from "./Booking.module.css";
import { BookingDetail } from "./components/BookingDetail";
import { BillingInfo } from "./components/BillingInfo";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { generateDockerId, setBookingStep } from "store/slice/BookingSlice";
import { BookingStepEnum } from "common/define-types";
import { BookingResult } from "./components/BookingResult";
import { useEffect, useState } from "react";
import Utils from "common/Utils";

export const Booking = () => {
  const [hasInvalidTicket, setHasInvalidTicket] = useState(false);
  const selectActiveBookingStep = useSelectorRoot(
    (state) => state.booking.bookingStep
  );
  const selectBookingResult = useSelectorRoot(
    (state) => state.booking.bookingResult
  );
  const selectComboToursSelected = useSelectorRoot(
    (state) => state.booking.selectedComboTours
  );
  // const selectToursSelected = useSelectorRoot(
  //   (state) => state.booking.selectedTours
  // );
  const selectEditingBooking = useSelectorRoot(
    (state) => state.booking.editingBooking
  );
  const dispatch = useDispatchRoot();

  const onChange = (value: number) => {
    dispatch(setBookingStep(value));
  };

  useEffect(() => {
    if (selectEditingBooking === null) {
      dispatch(generateDockerId());
    }
  }, [dispatch, selectEditingBooking]);

  useEffect(() => {
    setHasInvalidTicket(false);
    selectComboToursSelected.forEach((tour) => {
      if (tour.tickets) {
        tour.tickets.forEach((ticket) => {
          if (
            // !Utils.isValid(ticket.email) ||
            !Utils.isValid(ticket.fullName) ||
            // !Utils.isValid(ticket.phone) ||
            // !Utils.isValid(ticket.passportId) ||
            !Utils.isValid(ticket.startDate)
          ) {
            setHasInvalidTicket(true);
          }
        });
      }
    });
  }, [selectComboToursSelected]);

  const renderStepContent = (step: number) => {
    switch (step) {
      case BookingStepEnum.PAYMENT:
        return <BillingInfo />;
      case BookingStepEnum.COMPLETED:
        return <BookingResult />;
      default:
        return <BookingDetail />;
    }
  };

  return (
    <section className={styles.bookingContainer}>
      <div className={styles.progressContainer}>
        <Steps
          current={selectActiveBookingStep}
          className="booking-progress"
          onChange={onChange}
          items={[
            {
              title: "Chi tiết booking",
              description: "Điền thông tin khách",
              disabled: selectBookingResult,
            },
            {
              title: "Thanh toán",
              description: "Điền thông tin người thanh toán và phương thức",
              disabled: selectBookingResult || hasInvalidTicket,
            },
            {
              title: "Hoàn thành",
              description: "Hiển thị danh sách khách",
              disabled: !selectBookingResult,
            },
          ]}
        />
      </div>
      {renderStepContent(selectActiveBookingStep)}
    </section>
  );
};
