import { Tooltip } from "antd";
import styles from "../MotorbikeOperator.module.css";
interface IProps {
  iconSrc: string;
  isSelected: boolean;
  handleClick?: () => void;
  color: string;
  tooltip?: string;
  count: number;
}
export const FilterButton = ({
  iconSrc,
  isSelected,
  handleClick,
  color,
  tooltip,
  count,
}: IProps) => {
  return true ? (
    <Tooltip title={tooltip} color={color}>
      <div
        className={styles.filterButton}
        style={{ borderColor: isSelected ? color : "#D9D9D9" }}
        onClick={handleClick}
      >
        <span style={{ color, fontSize: "1.3rem", fontWeight: 600 }}>
          {count}
        </span>
        <img src={iconSrc} alt="bike" />
      </div>
    </Tooltip>
  ) : (
    <div
      className={styles.filterButton}
      style={{ borderColor: isSelected ? color : "#D9D9D9" }}
      onClick={handleClick}
    >
      <span style={{ color, fontSize: "1.3rem", fontWeight: 600 }}>
        {count}
      </span>
      <img src={iconSrc} alt="bike" />
    </div>
  );
};
