import { Typography } from "antd";
import Utils from "common/Utils";
import styles from "./Billing.module.css";
import BusIcon from "image/icon/bus-icon.svg";
import dayjs from "dayjs";
import { IBusTicket } from "common/define-types";
import { useSelectorRoot } from "store/store";
import { PhoneOutlined } from "@ant-design/icons";

interface IProps {
  busTicket: IBusTicket;
  isQrTicket?: boolean;
}
export const BusTicket = ({ busTicket, isQrTicket = false }: IProps) => {
  const selectLocations = useSelectorRoot((state) => state.booking.locations);
  const selectRouterStationBuses = useSelectorRoot(
    (state) => state.routerStationBus.routerStationBuses
  );
  const busInfo = selectRouterStationBuses?.find(
    (bus) => bus.id === busTicket.routerStationBusId
  );
  const locationStart = selectLocations.find(
    (location) =>
      location.id === busInfo?.dmuC_Router_Station?.dmuC_Router.localtionStartId
  );
  const locationEnd = selectLocations.find(
    (location) =>
      location.id === busInfo?.dmuC_Router_Station?.dmuC_Router.localtionEndId
  );
  return (
    <div className={styles.totalItem} style={{ rowGap: "0px" }}>
      <div className={styles.busAndMoneyContainer}>
        <div className={styles.busContainer}>
          <img src={BusIcon} alt="bus" />
          <div className={styles.busInfoWrapper}>
            <Typography.Text 
              strong 
              className="size12"
              // style={{ whiteSpace: "nowrap" }}
            >{`${locationStart?.name || ""
              } → ${locationEnd?.name || ""}`}</Typography.Text>
          </div>
        </div>
        <div
          style={{
            width: "27%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Typography.Text
            strong
            className="size12"
            // style={{ wordBreak: "normal" }}
          >{`${Utils.formatCurrency(busTicket.price)}`}</Typography.Text>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          marginLeft: "26px",
        }}
      >
        <Typography.Text
          strong
          className="size12"
          // style={{ wordBreak: "normal", textAlign: "right" }}
        >
          {
            <Typography.Text strong style={{ wordBreak: "normal" }}>{`( ${dayjs(
              busInfo?.date
            ).format("HH:mm")} )`}</Typography.Text>
          }{" "}
          {`${isQrTicket
              ? dayjs(busTicket.dateTime).format("DD/MM/YYYY")
              : Utils.formatCurrency(busTicket.price)
            }`}
        </Typography.Text>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          marginLeft: "26px",
        }}
      >
        <span>
          <PhoneOutlined style={{ marginRight: "5px" }} />
          {isQrTicket ? (
            <Typography.Text 
              strong
              className="size12"  
            >{`${busInfo?.dmuC_Router_Station?.dmuC_Station.phone || "Trống"
              }`}</Typography.Text>
          ) : (
            <Typography.Text 
              strong
              className="size12"  
            >{`${dayjs(busTicket.dateTime).format(
              "DD/MM/YYYY"
            )}`}</Typography.Text>
          )}
        </span>
        <Typography.Text
          strong
          className="size12"
        >{`${busInfo?.dmuC_Router_Station?.dmuC_Station.name}_${busInfo?.dmuC_Bus.description}`}</Typography.Text>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          marginLeft: "26px",
        }}>
        {busTicket.vitriDon && (
          <Typography.Text
            className="size12"
          >{`Pickup: ${busTicket.vitriDon}`}</Typography.Text>
        )}
        {busTicket.linkGGMap && (
          <Typography.Link
            target="_blank"
            href={`${busTicket.linkGGMap || ""}`}
            className="size12"
          >{`${busTicket.linkGGMap || ""}`}</Typography.Link>
        )}
      </div>
    </div>
  );
};
