import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAssignRole,
  ICreateDriver,
  IDriver,
  IDriverFilterValue,
  IDriverParams,
  IDriversResult,
  IUpdateDriver,
  RoleEnum,
  RootEpic,
} from "common/define-types";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import {
  createDriver,
  deleteDriverById,
  getAllDrivers,
  updateDriver,
} from "api/driver.api";
import {
  assignRoleForAccount,
  createAccount,
  deleteAccount,
  getAccountsByIds,
  updateAccount,
} from "api/account.api";

export interface DriverState {
  isLoading: boolean;
  isSubmitting: boolean;
  addModalOpen: boolean;
  drivers: IDriver[] | [];
  errMsg: string | null;
  driversResult: IDriversResult | null;
  editingDriver: IDriver | null;
  editingDriverAccount: IAccount | null;
  deletingDriverId: string | null;
  filterValue: IDriverFilterValue | null;
}

const initialState: DriverState = {
  isLoading: false,
  isSubmitting: false,
  addModalOpen: false,
  drivers: [],
  errMsg: null,
  driversResult: null,
  editingDriver: null,
  deletingDriverId: null,
  editingDriverAccount: null,
  filterValue: {
    search: "",
  },
};

export const driverSlice = createSlice({
  name: "driver",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchDrivers: (state, action: PayloadAction<IDriverParams | undefined>) => {
      state.isLoading = true;
      state.addModalOpen = false;
      state.editingDriver = null;
      state.deletingDriverId = null;
      state.errMsg = null;
    },
    setDrivers: (state, action: PayloadAction<IDriver[]>) => {
      state.drivers = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
      state.deletingDriverId = null;
      state.editingDriver = null;
    },
    setErrMsg: (state, action: PayloadAction<string>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
      state.deletingDriverId = null;
      state.editingDriver = null;
    },
    setDriversResult: (state, action: PayloadAction<IDriversResult>) => {
      state.driversResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    editDriver: (state, action: PayloadAction<IDriver | null>) => {
      state.editingDriver = action.payload;
    },
    setEditingDriverAccount: (
      state,
      action: PayloadAction<IAccount | null>
    ) => {
      state.editingDriverAccount = action.payload;
    },
    saveDriver: (state, action: PayloadAction<IUpdateDriver>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    addDriver: (state, action: PayloadAction<ICreateDriver>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    deleteDriver: (state, action: PayloadAction<string>) => {
      state.deletingDriverId = action.payload;
      state.errMsg = null;
    },
    setAddModalOpen: (state, action: PayloadAction<boolean>) => {
      state.addModalOpen = action.payload;
    },
    assignRole: (state, action: PayloadAction<IAssignRole>) => {},
    setDriverFilterValue: (
      state,
      action: PayloadAction<IDriverFilterValue>
    ) => {
      state.filterValue = {
        ...state.filterValue,
        ...action.payload,
      };
    },
  },
});
const getDrivers$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchDrivers.match),
    switchMap((re) => {
      return getAllDrivers({ ...re.payload }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const drivers = res.results;
            return [
              driverSlice.actions.setDrivers(drivers ?? []),
              driverSlice.actions.setDriversResult(res),
            ];
          } else {
            return [driverSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          driverSlice.actions.setErrMsg("Có lỗi xảy ra lấy danh sách tài xế"),
        ])
      );
    })
  );
const getDriversWhenFilter$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setDriverFilterValue.match),
    switchMap((re) => {
      return getAllDrivers({
        // ...state$.value.driver.driversResult,
        ...re.payload,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const drivers = res.results;
            return [
              driverSlice.actions.setDrivers(drivers ?? []),
              driverSlice.actions.setDriversResult(res),
            ];
          } else {
            return [driverSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          driverSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách tài xế"
          ),
        ])
      );
    })
  );
const getDriverAccount$: RootEpic = (action$) =>
  action$.pipe(
    filter(editDriver.match),
    switchMap((re) => {
      return getAccountsByIds(re.payload ? [re.payload.accountId] : []).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const accounts = res.results.length > 0 ? res.results[0] : null;
            return [driverSlice.actions.setEditingDriverAccount(accounts)];
          } else {
            return [driverSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          driverSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy thông tin tài khoản"
          ),
        ])
      );
    })
  );
const addDrivers$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(addDriver.match),
    switchMap((re) => {
      return createAccount({
        firstname: re.payload.firstName,
        lastname: re.payload.lastName,
        phoneNumber: re.payload.phone,
        password: re.payload.password,
        userName: re.payload.userName,
        email: re.payload.email,
        profile: {
          gender: re.payload.sex,
          address: re.payload.address,
        },
      }).pipe(
        mergeMap((accRes: any) => {
          if (accRes && !accRes?.response?.error && accRes.id) {
            return createDriver([{ ...re.payload, accountId: accRes.id }]).pipe(
              mergeMap((res: any) => {
                if (res && !res?.response?.error) {
                  return [
                    driverSlice.actions.assignRole({
                      userId: accRes.id,
                      roleName: RoleEnum.DRIVER,
                    }),
                  ];
                } else {
                  return [driverSlice.actions.setErrMsg(res?.response.error)];
                }
              }),
              catchError((e: AjaxError) => [
                driverSlice.actions.setErrMsg("Có lỗi xảy ra khi thêm tài xế"),
              ])
            );
          } else {
            return [driverSlice.actions.setErrMsg(accRes?.response.error)];
          }
        }),
        catchError((e: AjaxError) => {
          // if (e.status === 400) {
          //   return [
          //     driverSlice.actions.setErrMsg(
          //       "Email hoặc tên đăng nhập đã tồn tại"
          //     ),
          //   ];
          // }
          if (e.status === 401) {
            return [
              driverSlice.actions.setErrMsg(
                "Không được cấp quyền tạo tài khoản"
              ),
              driverSlice.actions.setAddModalOpen(false),
            ];
          }
          return [
            driverSlice.actions.setErrMsg(
              e.response?.Message ?? "Có lỗi xảy ra khi tạo tài khoản"
            ),
            // driverSlice.actions.setAddModalOpen(false),
          ];
        })
      );
    })
  );
const assignRole$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(assignRole.match),
    switchMap((re) => {
      return assignRoleForAccount(re.payload).pipe(
        mergeMap((accRes: any) => {
          if (accRes && !accRes?.response?.error) {
            if (
              state$.value.driver.driversResult &&
              state$.value.driver.filterValue
            ) {
              return [
                driverSlice.actions.fetchDrivers({
                  ...state$.value.driver.driversResult,
                  ...state$.value.driver.filterValue,
                }),
              ];
            }
            return [driverSlice.actions.fetchDrivers()];
          } else {
            return [driverSlice.actions.setErrMsg(accRes?.response.error)];
          }
        }),
        catchError((e: AjaxError) => {
          if (e.status === 401) {
            return [driverSlice.actions.setErrMsg("Không được cấp quyền")];
          }
          return [
            driverSlice.actions.setErrMsg(
              e.response?.Message ?? "Có lỗi xảy ra"
            ),
          ];
        })
      );
    })
  );
const saveDrivers$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveDriver.match),
    switchMap((re) => {
      if (state$.value.driver.editingDriverAccount !== null) {
        return updateAccount({
          ...state$.value.driver.editingDriverAccount,
          userName: re.payload.userName,
          email: re.payload.email,
          password:
            re.payload.password !== ""
              ? re.payload.password
              : state$.value.driver.editingDriverAccount.password,
        }).pipe(
          mergeMap((accRes: any) => {
            if (accRes && !accRes?.response?.error) {
              return updateDriver(re.payload).pipe(
                mergeMap((res: any) => {
                  if (res && !res?.response?.error) {
                    if (
                      state$.value.driver.driversResult &&
                      state$.value.driver.filterValue
                    ) {
                      return [
                        driverSlice.actions.fetchDrivers({
                          ...state$.value.driver.driversResult,
                          ...state$.value.driver.filterValue,
                        }),
                      ];
                    }
                    return [driverSlice.actions.fetchDrivers()];
                  } else {
                    return [driverSlice.actions.setErrMsg(res?.response.error)];
                  }
                }),
                catchError((e: AjaxError) => [
                  driverSlice.actions.setErrMsg("Có lỗi xảy ra"),
                ])
              );
            } else {
              return [driverSlice.actions.setErrMsg(accRes?.response.error)];
            }
          }),
          catchError((e: AjaxError) => {
            if (e.status === 401) {
              return [driverSlice.actions.setErrMsg("Không được cấp quyền")];
            }
            return [
              driverSlice.actions.setErrMsg(
                "Có lỗi xảy ra khi lưu thông tin tài khoản"
              ),
            ];
          })
        );
      } else
        return [driverSlice.actions.setErrMsg("Không có thông tin tài khoản")];
    })
  );
const deleteDrivers$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteDriver.match),
    switchMap((re) => {
      return deleteDriverById(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return deleteAccount(
              state$.value.driver.drivers.filter(
                (driver) => driver.id === re.payload
              )[0].accountId
            ).pipe(
              mergeMap((res1: any) => {
                if (res1 && !res1?.response?.error) {
                  if (
                    state$.value.driver.driversResult &&
                    state$.value.driver.filterValue
                  ) {
                    return [
                      driverSlice.actions.fetchDrivers({
                        ...state$.value.driver.driversResult,
                        ...state$.value.driver.filterValue,
                      }),
                    ];
                  }
                  return [driverSlice.actions.fetchDrivers()];
                } else {
                  return [driverSlice.actions.setErrMsg(res1?.response.error)];
                }
              }),
              catchError((e: AjaxError) => {
                if (
                  state$.value.driver.driversResult &&
                  state$.value.driver.filterValue
                ) {
                  return [
                    driverSlice.actions.fetchDrivers({
                      ...state$.value.driver.driversResult,
                      ...state$.value.driver.filterValue,
                    }),
                    driverSlice.actions.setErrMsg(
                      "Có lỗi xảy ra khi xóa tài khoản"
                    ),
                  ];
                }
                return [
                  driverSlice.actions.fetchDrivers(),
                  driverSlice.actions.setErrMsg(
                    "Có lỗi xảy ra khi xóa tài khoản"
                  ),
                ];
              })
            );
          } else {
            return [driverSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          driverSlice.actions.setErrMsg("Có lỗi xảy ra"),
        ])
      );
    })
  );

export const {
  fetchDrivers,
  setDrivers,
  setErrMsg,
  editDriver,
  setEditingDriverAccount,
  addDriver,
  deleteDriver,
  saveDriver,
  setAddModalOpen,
  assignRole,
  setDriverFilterValue,
} = driverSlice.actions;

export const DriverEpics = [
  getDrivers$,
  addDrivers$,
  saveDrivers$,
  deleteDrivers$,
  getDriverAccount$,
  assignRole$,
  getDriversWhenFilter$,
];

export const driverReducer = driverSlice.reducer;
