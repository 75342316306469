import { Bar } from "react-chartjs-2";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);
export interface IBarData {
  title?: string;
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor?: string;
    }[];
  };
}
interface IProps {
  barData: IBarData;
  showDataLabel?: boolean;
}
export const BarChart = ({ barData, showDataLabel = false }: IProps) => {
  const { width } = useWindowDimensions();
  const MAX_CHAR_LABEL = width / 85;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      key={barData.title}
    >
      <Bar
        id={barData.title}
        data={{
          labels: barData.data.labels.map((label) =>
            label.length > MAX_CHAR_LABEL
              ? `${label.slice(0, MAX_CHAR_LABEL)}...`
              : label
          ),
          datasets: barData.data.datasets,
        }}
        options={{
          plugins: {
            title: {
              display: !!barData.title,
              text: barData.title ?? "",
              font: (ctx) => ({ size: 16 }),
            },
            datalabels: {
              display: function (context) {
                return showDataLabel;
              },
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
              title: {
                align: "center",
              },
            },
            y: {
              stacked: true,
            },
          },
        }}
      />
    </div>
  );
};
