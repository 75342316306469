import { Typography, Checkbox, Button, notification, Spin, Tabs } from "antd";
import styles from "../Booking.module.css";
import { useSelectorRoot } from "store/store";
import { useEffect, useState } from "react";
import Utils from "common/Utils";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
// import moment from "moment";
import CustomerForm from "./CustomerForm";
import BusForm, { IBusFilterValue } from "./BusForm";
import ExtraServiceTab from "./ExtraService/ExtraServiceTab";
import {
  ComboTourItemType,
  ExtraServiceCode,
  IBusTicket,
  IComboTour,
  ISelectedTicket,
  ITicket_ExtraService,
} from "common/define-types";
import { useWatch, useForm } from "antd/es/form/Form";
import { getAllComboTours } from "api/comboTour.api";
dayjs.extend(utc);
interface IProps {
  readOnly?: boolean;
  onSave: (args: { ticket: ISelectedTicket; isApplyAll: boolean }) => void;
  hasInGroupCheck?: boolean;
}
//[14-12-2023][Phuc Thinh][Feature/14901]
//Change Tour to TourCombo
export const TicketCard = ({
  readOnly,
  onSave,
  hasInGroupCheck = true,
}: IProps) => {
  const [customerForm] = useForm();
  const [busForm] = useForm();
  const [extraForm] = useForm();
  const [isApplyAll, setIsApplyAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInGroup, setIsInGroup] = useState(false);
  const [arrivalBusFilterValue, setArrivalBusFilterValue] =
    useState<IBusFilterValue>({
      locationFromId: "",
      locationToId: Utils.HaGiangLocationId,
      date: null,
      price: 0,
    });
  const [departBusFilterValue, setDepartBusFilterValue] =
    useState<IBusFilterValue>({
      locationFromId: Utils.HaGiangLocationId,
      locationToId: "",
      date: null,
      price: 0,
    });
  const selectTicketSelected = useSelectorRoot(
    (state) => state.booking.selectedTicket
  );

  const selectEditingBooking = useSelectorRoot(
    (state) => state.booking.editingBooking
  );
  const isEditing =
    selectEditingBooking !== null && selectEditingBooking !== undefined;

  const selectComboTours = useSelectorRoot((state) => state.booking.comboTours);
  const selectLocations = useSelectorRoot((state) => state.booking.locations);
  const selectRouterStationBuses = useSelectorRoot(
    (state) => state.routerStationBus.routerStationBuses
  );
  const selectDockerId = useSelectorRoot((state) => state.booking.dockerId);
  // const isTicketOncoming =
  //   isEditing && selectTicketSelected
  //     ? moment
  //         .utc(
  //           selectEditingBooking.tickets.find(
  //             (ticket) => ticket.id === selectTicketSelected.id
  //           )?.startDate || selectTicketSelected.startDate
  //         )
  //         .local()
  //         .subtract(1, "day")
  //         .startOf("day")
  //         .isBefore(moment().endOf("day"))
  //     : false;
  // dich vu them
  const selectExtraService = useSelectorRoot(
    (state) => state.booking.extraServices
  );
  const selectExtraServiceSelected = useSelectorRoot(
    (state) => state.booking.selectedExtraServices
  );
  const selectRoomTemplates = useSelectorRoot(
    (state) => state.roomTemplate.roomTemplates
  );
  const hangHoaMotors = useSelectorRoot((state) => state.hangHoa.motorbikes);

  const ticketDriverPrice = selectTicketSelected?.comboTour.ListItem.find(
    (item) => item.ItemType === ComboTourItemType.DriverPrice
  );
  const ticketCountDay: number = ticketDriverPrice
    ? typeof ticketDriverPrice.CountDay === "string"
      ? parseInt(ticketDriverPrice.CountDay)
      : ticketDriverPrice.CountDay || 4
    : 4;
  const hasBusCombo =
    !!selectTicketSelected &&
    selectTicketSelected.comboTour.ListItem?.filter(
      (item) => item.ItemType === ComboTourItemType.Bus
    ).length > 0;

  useEffect(() => {
    if (selectTicketSelected && selectLocations && selectRouterStationBuses) {
      setIsLoading(true);
      // customer form
      customerForm.setFields(
        Utils.objectToAntFormData({
          ...selectTicketSelected,
          fullName: selectTicketSelected.fullName ?? "",
          phone: selectTicketSelected.phone ?? "",
          passportId: selectTicketSelected.passportId ?? "",
          email: selectTicketSelected.email ?? "",
          isSpecial: !!selectTicketSelected.isSpecial,
          specialPrice: selectTicketSelected.price,
        })
      );
      busForm.setFields(
        Utils.objectToAntFormData({
          startDate: dayjs.utc(selectTicketSelected.startDate) ?? "",
        })
      );

      //bus form
      if (selectTicketSelected.busTicketC1) {
        // const foundFromBusCombo = selectTicketSelected.comboTour.ListItem.find(item => 
        //     item.ItemType === ComboTourItemType.Bus && 
        //     item.DMUC_Router_Station?.DMUC_Router.LocaltionEndId === Utils.HaGiangLocationId);
        const foundFromBus = selectRouterStationBuses.find(
          (bus) =>
            bus.id === selectTicketSelected.busTicketC1?.routerStationBusId
        );
        // const fromLocationId = hasBusCombo ?
        //   foundFromBusCombo?.DMUC_Router_Station?.DMUC_Router.LocaltionStartId || "" : 
        //   foundFromBus?.dmuC_Router_Station?.dmuC_Router.localtionStartId || "";
        const fromLocationId = foundFromBus?.dmuC_Router_Station?.dmuC_Router.localtionStartId || "";
        
        busForm.setFields(
          Utils.objectToAntFormData({
            from: fromLocationId,
            fromBus: fromLocationId
              ? selectTicketSelected.busTicketC1?.routerStationBusId
              : "",
            arrivalDate:
              dayjs.utc(selectTicketSelected.busTicketC1?.dateTime) ?? "",
            vitriDonC1: selectTicketSelected.busTicketC1.vitriDon,
            linkGGMapC1: selectTicketSelected.busTicketC1.linkGGMap,
          })
        );
        setArrivalBusFilterValue({
          locationFromId: fromLocationId || "",
          locationToId: Utils.HaGiangLocationId,
          date: dayjs(selectTicketSelected.startDate),
          price: selectTicketSelected.busTicketC1.price ?? 0,
        });
      } else {
        busForm.setFields(
          Utils.objectToAntFormData({
            from: null,
            fromBus: null,
            arrivalDate: dayjs(selectTicketSelected.startDate)
              .startOf("day")
              .isBefore(dayjs().endOf("day"))
              ? dayjs(selectTicketSelected.startDate)
              : dayjs(selectTicketSelected.startDate).subtract(1, "day"),
            vitriDonC1: undefined,
            linkGGMapC1: undefined,
          })
        );
        setArrivalBusFilterValue({
          locationFromId: "",
          locationToId: Utils.HaGiangLocationId,
          date: null,
          price: 0,
        });
      }
      if (selectTicketSelected.busTicketC2) {
        // const foundToBusCombo = selectTicketSelected.comboTour.ListItem.find(item => 
        //   item.ItemType === ComboTourItemType.Bus && 
        //   item.DMUC_Router_Station?.DMUC_Router.LocaltionStartId === Utils.HaGiangLocationId);
        const foundToBus = selectRouterStationBuses.find(
          (bus) =>
            bus.id === selectTicketSelected.busTicketC2?.routerStationBusId
        );
        // const toLocationId = hasBusCombo ?
        // foundToBusCombo?.DMUC_Router_Station?.DMUC_Router.LocaltionEndId || '':
        // foundToBus?.dmuC_Router_Station?.dmuC_Router.localtionEndId || '';
        const toLocationId = foundToBus?.dmuC_Router_Station?.dmuC_Router.localtionEndId || '';

        busForm.setFields(
          Utils.objectToAntFormData({
            goBus: toLocationId
              ? selectTicketSelected.busTicketC2?.routerStationBusId
              : "",
            destination: toLocationId,
            departDate:
              dayjs.utc(selectTicketSelected.busTicketC2?.dateTime) ?? "",
            vitriDonC2: selectTicketSelected.busTicketC2.vitriDon,
            linkGGMapC2: selectTicketSelected.busTicketC2.linkGGMap,
          })
        );
        setDepartBusFilterValue({
          locationFromId: Utils.HaGiangLocationId,
          locationToId: toLocationId || "",
          date: dayjs.utc(selectTicketSelected.startDate),
          price: selectTicketSelected.busTicketC2.price ?? 0,
        });
      } else {
        busForm.setFields(
          Utils.objectToAntFormData({
            destination: null,
            goBus: null,
            departDate: dayjs
              .utc(selectTicketSelected.startDate)
              //[1-12-2023] [Phuc Thinh]
              //Đổi ngày về default thành endDate của Tour
              .add(ticketCountDay - 1, "day"),
            vitriDonC2: undefined,
            linkGGMapC2: undefined,
          })
        );
        setDepartBusFilterValue({
          locationFromId: Utils.HaGiangLocationId,
          locationToId: "",
          date: null,
          price: 0,
        });
      }

      // extra service form
      if (
        selectTicketSelected.datA_TOUR_CHHangHoaDichVuKhacs &&
        selectTicketSelected.datA_TOUR_CHHangHoaDichVuKhacs.length > 0
      ) {
        const datA_TOUR_CHHangHoaDichVuKhacs =
          selectTicketSelected.datA_TOUR_CHHangHoaDichVuKhacs;
        let extraServiceValue = {};
        selectExtraService.forEach((service) => {
          const foundData_Tour_Service = datA_TOUR_CHHangHoaDichVuKhacs.find(
            (item) => item.dmuC_CHHangHoaDichVuKhacId === service.id
          );
          if (foundData_Tour_Service) {
            switch (service.code) {
              case ExtraServiceCode.PARKING:
                extraServiceValue = {
                  ...extraServiceValue,
                  DV1_price: foundData_Tour_Service.price,
                  DV1_note: foundData_Tour_Service.ghiChu,
                };
                break;
              case ExtraServiceCode.LUGGAGE:
                extraServiceValue = {
                  ...extraServiceValue,
                  DV2_price: foundData_Tour_Service.price,
                  DV2_note: foundData_Tour_Service.ghiChu,
                };
                break;
              case ExtraServiceCode.CHANGE_ROOM:
                extraServiceValue = {
                  ...extraServiceValue,
                  roomTemplateIds: datA_TOUR_CHHangHoaDichVuKhacs
                    .filter(
                      (item) => item.dmuC_CHHangHoaDichVuKhacId === service.id
                    )
                    .map((item) => item.roomTemplateId),
                };
                break;
              case ExtraServiceCode.CHANGE_VEHICLE:
                extraServiceValue = {
                  ...extraServiceValue,
                  dmuC_HangHoaId: foundData_Tour_Service.dmuC_HangHoaId,
                  dmuC_HangHoa_price: foundData_Tour_Service.price || 0,
                };
                break;
              default:
                break;
            }
          }
        });

        extraForm.setFields(Utils.objectToAntFormData(extraServiceValue));
      } else {
        extraForm.resetFields();
      }

      if (!!selectTicketSelected.dockerId) setIsInGroup(true);
      else setIsInGroup(false);

      setIsApplyAll(false);
    } else {
      customerForm.resetFields();
      busForm.resetFields();
      extraForm.resetFields();
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }, [
    selectTicketSelected,
    busForm,
    customerForm,
    extraForm,
    selectExtraService,
    selectLocations,
    selectRouterStationBuses,
    ticketCountDay,
    hangHoaMotors,
    hasBusCombo
  ]);

  const startDate =
    useWatch("startDate", busForm) || dayjs(selectTicketSelected?.startDate);

  const handleApplyChange = (e: CheckboxChangeEvent) => {
    setIsApplyAll(e.target.checked);
  };

  const handleSave = async () => {
    if (selectTicketSelected) {
      const customerValue = await customerForm
        .validateFields()
        .catch((err) => console.log(err));
      const busValue = await busForm
        .validateFields()
        .catch((err) => console.log(err));
      const extraServiceValue = await extraForm
        .validateFields()
        .catch((err) => {
          console.log(err);
          // notification.warning({
          //   message: "Kiểm tra lại thông tin dịch vụ thêm",
          // });
          // return;
        });

      if (!customerValue || !busValue) {
        notification.warning({
          message: "Thông tin chung không được bỏ trống",
        });
        return;
      }

      const submit = (price: number) => {
        let busTicketC1Value: IBusTicket | null = null;
        if (busValue && busValue.fromBus && busValue.arrivalDate) {
          busTicketC1Value = {
            ticketId: selectTicketSelected.id,
            routerStationBusId: busValue.fromBus ?? "",
            dateTime: dayjs(busValue.arrivalDate).toISOString() ?? "",
            price: arrivalBusFilterValue.price,
            vitriDon: busValue.vitriDonC1,
            linkGGMap: busValue.linkGGMapC1,
          };
        }
        let busTicketC2Value: IBusTicket | null = null;
        if (busValue && busValue.goBus && busValue.departDate) {
          busTicketC2Value = {
            ticketId: selectTicketSelected.id,
            routerStationBusId: busValue.goBus ?? "",
            dateTime: dayjs(busValue.departDate).toISOString(),
            price: departBusFilterValue.price,
            vitriDon: busValue.vitriDonC2,
            linkGGMap: busValue.linkGGMapC2,
          };
        }

        // dich vu them
        if (
          Utils.countKeysWithValues(selectExtraServiceSelected, [true]) > 0 &&
          !extraServiceValue
        ) {
          notification.warning({
            message: "Kiểm tra lại thông tin dịch vụ thêm",
          });
          return;
        }
        let datA_TOUR_CHHangHoaDichVuKhacs: ITicket_ExtraService[] = [];
        if (extraServiceValue) {
          datA_TOUR_CHHangHoaDichVuKhacs = Utils.mapValueToPayload_extraService(
            extraServiceValue,
            selectExtraService,
            selectExtraServiceSelected,
            selectRoomTemplates,
            selectComboTours.find(
              (comboTour) => comboTour.Id === selectTicketSelected.comboTourId
            )
          );
        }

        // console.log(busValue?.startDate);
        let ticketPayload: ISelectedTicket = {
          ...selectTicketSelected,
          ...customerValue,
          startDate: busValue?.startDate.toISOString(),
          arrivalDate: busTicketC1Value?.dateTime ?? "",
          departDate: busTicketC2Value?.dateTime ?? "",
          busTicketC1: busTicketC1Value,
          busTicketC2: busTicketC2Value,
          dockerId: isInGroup
            ? selectTicketSelected.dockerId || selectDockerId
            : undefined,
          price: price,
          datA_TOUR_CHHangHoaDichVuKhacs,
        };

        onSave({
          ticket: ticketPayload,
          isApplyAll: isApplyAll,
        });
      };

      setIsLoading(true);
      // if ticket is special, dont need to call API get price
      if (customerValue.isSpecial) {
        submit(customerValue.specialPrice);
        setIsLoading(false);
        return;
      }

      getAllComboTours({
        dateTime: dayjs(busValue?.startDate).toISOString(),
      }).subscribe(
        (res) => {
          if (res) {
            const comboTours: IComboTour[] = res;
            const comboTourId = selectTicketSelected.comboTourId;
            const foundComboTour = comboTours.find(
              (comboTour) => comboTour.Id === comboTourId
            );
            if (!foundComboTour) {
              notification.error({ message: "Có lỗi xảy ra khi lấy giá vé" });
              return;
            }
            submit(foundComboTour.Price);
          }
        },
        (err) => {
          notification.error({ message: "Có lỗi xảy ra khi lấy giá vé" });
          setIsLoading(false);
        },
        () => setIsLoading(false)
      );
    }
  };

  const handleBusFormChange = (changedValue: any, allValues: any) => {
    if (selectTicketSelected) {
      // change arrival, depart Date when startDate changed
      setArrivalBusFilterValue({
        ...arrivalBusFilterValue,
        locationFromId: allValues.from,
        date: allValues.startDate,
      });
      setDepartBusFilterValue({
        ...departBusFilterValue,
        locationToId: allValues.destination,
        date: allValues.startDate,
      });
      if (
        (changedValue.from ||
        !allValues.from ||
        changedValue.startDate ||
        !allValues.startDate ) &&
        !hasBusCombo
      ) {
        busForm.setFieldValue("fromBus", null);
        busForm.setFieldValue("vitriDonC1", null);
        busForm.setFieldValue("linkGGMapC1", null);
      }
      if (
        (changedValue.destination ||
        !allValues.destination ||
        changedValue.startDate ||
        !allValues.startDate) && 
        !hasBusCombo
      ) {
        busForm.setFieldValue("goBus", null);
        busForm.setFieldValue("vitriDonC2", null);
        busForm.setFieldValue("linkGGMapC2", null);
      }
      if (changedValue.startDate) {
        busForm.setFieldValue(
          "arrivalDate",
          // dayjs(changedValue.startDate)
          //   .startOf("day")
          //   .isBefore(dayjs().endOf("day"))
          //   ? dayjs(changedValue.startDate) :
          dayjs(changedValue.startDate).subtract(1, "day")
        );
        busForm.setFieldValue(
          "departDate",
          //[1-12-2023] [Phuc Thinh]
          //Đổi ngày về default thành endDate của Tour
          dayjs(changedValue.startDate).add(ticketCountDay - 1, "day")
        );
      }
      // validate arrival, depart Date
      if (allValues.startDate) {
        if (changedValue.arrivalDate) {
          if (dayjs(changedValue.arrivalDate).isAfter(allValues.startDate)) {
            notification.error({
              message: "Ngày đến không thể xảy ra sau ngày khởi hành",
            });
            busForm.setFieldValue(
              "arrivalDate",
              dayjs(allValues.startDate).subtract(1, "day").isBefore(dayjs())
                ? dayjs()
                : dayjs(allValues.startDate).subtract(1, "day")
            );
          }
        }
        if (changedValue.departDate) {
          if (dayjs(changedValue.departDate).isBefore(allValues.startDate)) {
            notification.error({
              message: "Ngày đi không thể xảy ra trước ngày khởi hành",
            });
            busForm.setFieldValue(
              "departDate",
              dayjs(allValues.startDate).add(
                //[1-12-2023] [Phuc Thinh]
                //Đổi ngày về default thành endDate của Tour
                ticketCountDay - 1,
                "day"
              )
            );
          }
        }
      }
    }
  };

  return (
    <div className={`${styles.ticketCard_Container}`} data-test-id={'ticket_Card'}>
      <div className={`${styles.ticketCard}`}>
        {isLoading && (
          <div className={styles.loadingOverlay}>
            <Spin />
          </div>
        )}
        <div className={styles.ticketCard_header}>
          <Typography.Text strong className={styles.ticketId}>{`Khách: ${
            selectTicketSelected?.name ?? ""
          }`}</Typography.Text>
          {hasInGroupCheck && (
            <Checkbox
              checked={isInGroup}
              onChange={(e: CheckboxChangeEvent) => {
                setIsInGroup(e.target.checked);
              }}
              disabled={readOnly}
              style={{ whiteSpace: "nowrap" }}
            >
              Nhóm bạn
            </Checkbox>
          )}
        </div>
        <div className={`${styles.inforWrapper}`}>
          <Tabs
            defaultActiveKey="1"
            className="ticketCard_tabs"
            renderTabBar={(props, TabNavList) => (
              <TabNavList {...props} mobile={true} />
            )}
            items={[
              {
                label: "Thông tin chung",
                key: "1",
                children: (
                  <div className={`${styles.scrollContainer} customScroll`}>
                    <CustomerForm form={customerForm} readOnly={readOnly} />
                    <BusForm
                      form={busForm}
                      arrivalBusFilterValue={arrivalBusFilterValue}
                      departBusFilterValue={departBusFilterValue}
                      setArrivalBusFilterValue={setArrivalBusFilterValue}
                      setDepartBusFilterValue={setDepartBusFilterValue}
                      isApplyAll={isApplyAll}
                      handleApplyChange={handleApplyChange}
                      handleBusFormChange={handleBusFormChange}
                      isEditing={isEditing}
                      // isTicketOncoming={isTicketOncoming}
                      readOnly={readOnly}
                      hasBus_comboTourId={
                        hasBusCombo && selectTicketSelected ? 
                        selectTicketSelected.comboTourId : 
                        undefined 
                      }
                    />
                  </div>
                ),
                forceRender: true,
              },
              {
                label: `Dịch vụ thêm ${
                  selectExtraServiceSelected &&
                  Utils.countKeysWithValues(selectExtraServiceSelected, [
                    true,
                  ]) > 0
                    ? `( ${Utils.countKeysWithValues(
                        selectExtraServiceSelected,
                        [true]
                      )} )`
                    : ""
                }`,
                key: "2",
                children: (
                  <div className={`${styles.scrollContainer} customScroll`}>
                    {selectTicketSelected && startDate && (
                      <ExtraServiceTab
                        form={extraForm}
                        ticketStartDate={
                          selectTicketSelected && startDate
                            ? startDate.toISOString()
                            : ""
                        }
                      />
                    )}
                  </div>
                ),
                forceRender: true,
              },
            ]}
          />
        </div>

        <div className={styles.btnContainer}>
          <Button
            htmlType="submit"
            type="primary"
            onClick={handleSave}
            disabled={readOnly}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};
