import { Typography, InputNumber } from "antd";

interface IProps {
  value: number;
  onChange: (value: string | null) => void;
  disabled?: boolean;
}
export const GasMoney = ({ value, onChange, disabled }: IProps) => {
  const MAX_MONEY = 5000000;
  const MIN_MONEY = 0;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
      }}
    >
      <Typography style={{ fontWeight: "400" }}>Tổng tiền: </Typography>
      <InputNumber
        controls={false}
        value={value.toString()}
        onChange={onChange}
        stringMode={true}
        max={MAX_MONEY.toString()}
        min={MIN_MONEY.toString()}
        disabled={disabled}
        formatter={(value) =>
          `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
        }
        parser={(value) => (value ? value?.replaceAll(".", "") : "0")}
      />
    </div>
  );
};
