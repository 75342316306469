import { DatePicker, Input, Typography, notification } from "antd";
import styles from "./MotorbikeOperator.module.css";
import { MotorbikeTable } from "./components/MotorbikeTable";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { ChangeEvent, useEffect, useState } from "react";
import {
  clearFilterValue,
  fetchMotorbikes,
  setFilterValue,
} from "store/slice/MotorbikeOperatorSlice";
import AvailableBikeIcon from "image/icon/available-bike-icon.svg";
import LeaveJasmineBikeIcon from "image/icon/leave-jasmine-bike.svg";
import RunningBikeIcon from "image/icon/running-bike-icon.svg";
import ReturnJasmineBikeIcon from "image/icon/return-jasmine-bike.svg";
import UnavailableBikeIcon from "image/icon/unavailable-bike.svg";
import { FilterButton } from "./components/FilterButton";
import dayjs, { Dayjs } from "dayjs";
import {
  IOperatorMotorbikeCountValue,
  IOperatorMotorbikeStatus,
} from "common/define-types";
import Utils from "common/Utils";

export const filterButtons: {
  type: IOperatorMotorbikeStatus;
  tooltip: string;
  icon: string;
  color: string;
  valueKey: keyof IOperatorMotorbikeCountValue;
}[] = [
  {
    type: IOperatorMotorbikeStatus.AVAILABLE,
    tooltip: "Available",
    icon: AvailableBikeIcon,
    color: "#52C41A",
    valueKey: "countAvailable",
  },
  {
    type: IOperatorMotorbikeStatus.START,
    tooltip: "Từ Jasmine đi",
    icon: LeaveJasmineBikeIcon,
    color: "#40A9FF",
    valueKey: "countStart",
  },
  {
    type: IOperatorMotorbikeStatus.RUNNING,
    tooltip: "Đang đi tour",
    icon: RunningBikeIcon,
    color: "#40A9FF",
    valueKey: "countRunning",
  },
  {
    type: IOperatorMotorbikeStatus.END,
    tooltip: "Đi về Jasmine",
    icon: ReturnJasmineBikeIcon,
    color: "#40A9FF ",
    valueKey: "countEnd",
  },
  {
    type: IOperatorMotorbikeStatus.UNAVAILABLE,
    tooltip: "Unavailable",
    icon: UnavailableBikeIcon,
    color: "#FF4D4F",
    valueKey: "countUnavailable",
  },
];
export const MotorbikeOperator = () => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const selectFilterValue = useSelectorRoot(
    (state) => state.operatorMotorbike.filterValue
  );
  const selectCountValue = useSelectorRoot(
    (state) => state.operatorMotorbike.countValue
  );
  const selectErrMsg = useSelectorRoot((state) => state.motorbike.errMsg);
  const dispatch = useDispatchRoot();

  useEffect(() => {
    dispatch(
      fetchMotorbikes({
        pageSize: 25,
      })
    );
    dispatch(clearFilterValue());
  }, [dispatch]);

  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({
        message: selectErrMsg,
      });
    }
  }, [selectErrMsg]);

  const handleFilterClick = (type: IOperatorMotorbikeStatus) => {
    if (type === selectFilterValue.statusBike) {
      dispatch(
        setFilterValue({
          ...selectFilterValue,
          statusBike: undefined,
        })
      );
    } else {
      dispatch(
        setFilterValue({
          ...selectFilterValue,
          statusBike: type,
        })
      );
    }
  };

  const handleChangeDate = (value: Dayjs) => {
    dispatch(
      setFilterValue({
        ...selectFilterValue,
        dateTime: value.toISOString(),
      })
    );
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(
        setFilterValue({
          ...selectFilterValue,
          search: query,
        })
      );
    }, 800);
    setTimer(timeoutId);
  };

  return (
    <div className={styles.motorbikeContainer}>
      <div className={styles.tableHeader}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            flex: 1,
            flexWrap: "wrap",
          }}
        >
          <Typography.Text
            strong
            style={{ fontSize: 20, marginRight: 20, whiteSpace: "nowrap" }}
          >
            Quản lý xe máy
          </Typography.Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 10,
            }}
          >
            <DatePicker
              style={{ minWidth: 180 }}
              value={dayjs(selectFilterValue.dateTime)}
              onChange={(value) => value && handleChangeDate(value)}
              allowClear={false}
              format={Utils.dateFormat}
            />
            <Input.Search
              placeholder="Tìm kiếm xe máy"
              style={{ width: 180 }}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className={`${styles.filterButtons} customScroll`}>
          {filterButtons.map((button) => (
            <FilterButton
              {...button}
              iconSrc={button.icon}
              key={`filter-${button.type}`}
              isSelected={
                selectFilterValue.statusBike !== undefined &&
                selectFilterValue.statusBike === button.type
              }
              handleClick={() => handleFilterClick(button.type)}
              count={
                selectCountValue &&
                selectCountValue[button.valueKey] !== undefined
                  ? selectCountValue[button.valueKey]
                  : 0
              }
            />
          ))}
        </div>
      </div>
      <MotorbikeTable />
    </div>
  );
};
