import {
  Button,
  Checkbox,
  Modal,
  PaginationProps,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { EditOutlined } from "@ant-design/icons";
import styles from "../TicketOperator.module.css";
import moment from "moment";
import Utils from "common/Utils";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import {
  checkInTicket,
  fetchOperatorTickets,
  saveBookingCollectionFee,
  saveBookingTicket,
  saveIssueCollectionFee,
  setCollectFeeBooking,
  setCollectIssueFeeTicket,
  uncheckInTicket,
} from "store/slice/TIcketOperatorSlice";
import {
  IExtraService,
  IPaymentMethodBooking,
  ISelectedTicket,
  ITicketOperatorItem,
  ThuHoStatus,
  TourStatus,
} from "common/define-types";
import CollectionFeeModal from "./CollectionFeeModal";
import { useState, useEffect } from "react";
import { getAllExtraServices } from "api/extraService.api";
import { selectTicket } from "store/slice/BookingSlice";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import EditTicketModal from "./EditTicket/EditTicketModal";
import CollectIssueFeeModal from "./CollectIssueFee/CollectIssueFeeModal";
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const TicketOperatorTable = () => {
  const [extraServices, setExtraServices] = useState<IExtraService[]>([]);
  const { height } = useWindowDimensions();
  const INVALID_DATE = "0001-01-01T00:00:00";
  const [modal, modalContext] = Modal.useModal();
  const dispatch = useDispatchRoot();
  const selectLoading = useSelectorRoot(
    (state) => state.operatorTicket.isLoading
  );
  const selectOpTickets = useSelectorRoot(
    (state) => state.operatorTicket.operator_tickets
  );
  const selectTicketResult = useSelectorRoot(
    (state) => state.operatorTicket.ticketResult
  );
  const selectCollectFeeBooking = useSelectorRoot(
    (state) => state.operatorTicket.collectFeeBooking
  );
  const selectCollectIssueFeeTicket = useSelectorRoot(
    (state) => state.operatorTicket.collectIssueFeeTicket
  );
  const selectTicketSelected = useSelectorRoot(
    (state) => state.booking.selectedTicket
  );
  const selectComboTours = useSelectorRoot((state) => state.booking.comboTours);

  useEffect(() => {
    getAllExtraServices().subscribe(
      (res) => {
        if (res && res.length) setExtraServices(res);
      },
      (err) => console.log(err)
    );
  }, []);

  // [5-12-2023][Phuc Thinh] [add checkbox for checkin ticket]
  // add handle click
  // [8-12-2023][Phuc Thinh]
  // prevent multiple click
  const handleClickCheckin = (
    record: ITicketOperatorItem,
    isChecked: boolean
  ) => {
    Utils.throttle(() => {
      modal.confirm({
        title: "Check-in",
        content: `Bạn có chắc chắn muốn ${
          isChecked ? "hủy check-in" : "check-in"
        }  cho vé ${record.name}`,
        onOk: () =>
          isChecked ? handleUnCheckin(record) : handleCheckin(record),
      });
    }, 500);
  };

  const handleCheckin = (record: ITicketOperatorItem) => {
    dispatch(checkInTicket(record.id));
  };
  const handleUnCheckin = (record: ITicketOperatorItem) => {
    dispatch(uncheckInTicket(record.id));
  };

  const Row = (props: RowProps) => {
    const id = props["data-row-key"];
    const foundRecord = selectOpTickets.find((ticket) => ticket.id === id);
    const hasNotPaidCollectionFee =
      foundRecord && foundRecord.statusThuHo === ThuHoStatus.NOT_YET;
    const style: React.CSSProperties = {
      ...props.style,
      backgroundColor: hasNotPaidCollectionFee ? "#F5222D33" : "#ffffff",
    };
    const className = hasNotPaidCollectionFee ? "collectionFee-row" : "";

    return <tr {...props} style={style} className={className} />;
  };

  const columns: ColumnsType<ITicketOperatorItem> = [
    {
      title: "Mã booking",
      dataIndex: "bookingCode",
      width: 150,
      render: (value, record) => {
        return (
          <Typography.Link
            underline
            onClick={() => dispatch(setCollectFeeBooking(record))}
            style={{ cursor: "pointer" }}
          >
            {value}
          </Typography.Link>
        );
      },
    },
    {
      title: "Mã vé",
      dataIndex: "name",
      width: 150,
      render: (value, record) => {
        return record.daThanhToanIssue ? (
          <>{value}</>
        ) : (
          <Tooltip title="Thu tiền phát sinh">
            <Typography.Link
              underline
              onClick={() => dispatch(setCollectIssueFeeTicket(record))}
              style={{
                cursor: "pointer",
                color: "#F9A000",
              }}
            >
              {value}
            </Typography.Link>
          </Tooltip>
        );
      },
    },
    {
      title: "Ngày đi tour",
      dataIndex: "startDate",
      width: 130,
      render: (value) => !!value && moment(value).format(Utils.dateFormat),
    },
    {
      title: "Mã đại lý",
      dataIndex: "agentCode",
      width: 130,
    },
    {
      title: "Người bán vé",
      ellipsis: true,
      width: 130,
      render: (_, record) => (
        <>{(record.sellerLastName || "") + (record.sellerFirstName || "")}</>
      ),
    },
    {
      title: "Jasmine thu hộ",
      dataIndex: "jasmineKeepMoney",
      width: 130,
      render: (value, record) => (
        <>{!!value ? Utils.formatCurrency(value) : 0}</>
      ),
    },
    {
      title: "Ngày đặt vé",
      dataIndex: "createdTime",
      width: 130,
      render: (value) => !!value && moment(value).format(Utils.dateFormat),
    },
    {
      title: "Tên khách hàng",
      dataIndex: "fullName",
      width: 200,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      width: 140,
    },
    {
      title: "Ngày bus đón",
      dataIndex: "busStartDate",
      width: 130,
      render: (value) =>
        !!value &&
        value !== INVALID_DATE &&
        moment(value).format(Utils.dateFormat),
    },
    {
      title: "Loại bus đón",
      dataIndex: "busStartName",
      width: 150,
    },
    // [11-12-2023][Phuc Thinh][Feature/14833]
    // add vitriDon, linkGGMap column
    {
      title: "Vị trí Bus đón chiều đi",
      width: 200,
      render: (_, record) => {
        const { busTicketC1 } = record;
        if (!busTicketC1) return "";
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography.Text>{busTicketC1.vitriDon}</Typography.Text>
            <Typography.Link target="_blank" href={`${busTicketC1.linkGGMap}`}>
              {busTicketC1.linkGGMap}
            </Typography.Link>
          </div>
        );
      },
    },
    {
      title: "Ngày bus trả",
      dataIndex: "busEndDate",
      width: 130,
      render: (value) =>
        !!value &&
        value !== INVALID_DATE &&
        moment(value).format(Utils.dateFormat),
    },
    {
      title: "Loại bus trả",
      dataIndex: "busEndName",
      width: 150,
    },
    {
      title: "Vị trí Bus đón chiều về",
      width: 200,
      render: (_, record) => {
        const { busTicketC2 } = record;
        if (!busTicketC2) return "";
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography.Text>{busTicketC2.vitriDon}</Typography.Text>
            <Typography.Link target="_blank" href={`${busTicketC2.linkGGMap}`}>
              {busTicketC2.linkGGMap}
            </Typography.Link>
          </div>
        );
      },
    },
    {
      title: "Dịch vụ thêm",
      width: 150,
      render(_, record) {
        if (
          record.datA_TOUR_CHHangHoaDichVuKhacs &&
          record.datA_TOUR_CHHangHoaDichVuKhacs.length > 0
        ) {
          const filteredService = extraServices.filter((service) => {
            return record.datA_TOUR_CHHangHoaDichVuKhacs?.some(
              (item) => item.dmuC_CHHangHoaDichVuKhacId === service.id
            );
          });
          return (
            <>{filteredService.map((service) => service.name).join(", ")}</>
          );
        }
        return <></>;
      },
    },
    // [5-12-2023][Phuc Thinh] [add checkbox for checkin ticket]
    // add column for checkin ticket
    {
      title: "Check-in",
      dataIndex: "tourStatus",
      width: 100,
      align: "center",
      render: (value, record) => {
        // checked in tourStatus
        const isTicketStarted = moment
          .utc(record.startDate)
          .endOf("day")
          .isBefore(moment().startOf("day"));
        const isChecked = [
          TourStatus.CheckIn,
          TourStatus.Running,
          TourStatus.EndTour,
        ].some((status) => status === value);
        return (
          <Checkbox
            checked={isChecked}
            // [8-12-2023][Phuc Thinh]
            // prevent multiple click
            onClick={(e) =>
              (!e.detail || e.detail === 1) &&
              handleClickCheckin(record, isChecked)
            }
            disabled={
              isTicketStarted ||
              [TourStatus.Running, TourStatus.EndTour].some(
                (status) => status === record.tourStatus
              )
            }
          />
        );
      },
    },
    {
      title: "",
      width: 70,
      fixed: "right",
      render(_, record) {
        // [7-12-2023][Phuc Thinh][Feature/14861]
        // disable edit button when ticket started
        const isTicketStarted = moment
          .utc(record.startDate)
          .endOf("day")
          .isBefore(moment().startOf("day"));
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isTicketStarted ||
            [TourStatus.Running, TourStatus.EndTour].some(
              (status) => status === record.tourStatus
            ) ? (
              <Tooltip title="Vé đã bắt đầu">
                <Button
                  icon={
                    <EditOutlined
                      style={{
                        color: "grey",
                      }}
                    />
                  }
                  type="text"
                  title="Chỉnh sửa vé"
                  disabled={true}
                />
              </Tooltip>
            ) : (
              <Button
                icon={
                  <EditOutlined
                    style={{
                      color: "rgb(24, 144, 255)",
                    }}
                  />
                }
                type="text"
                title="Chỉnh sửa vé"
                onClick={() => {
                  const foundComboTour = selectComboTours.find(
                    (comboTour) => comboTour.Id === record.comboTourId
                  );
                  if (foundComboTour)
                    dispatch(
                      selectTicket({
                        ...record,
                        comboTour: foundComboTour,
                      })
                    );
                }}
              />
            )}
          </div>
        );
      },
    },
  ];
  const handleChangePage: PaginationProps["onChange"] = (page) => {
    if (selectTicketResult) {
      dispatch(
        fetchOperatorTickets({
          ...selectTicketResult,
          page,
        })
      );
    }
  };

  const handleUpdateBookingCollectFee = (
    paymentMethodBookings: IPaymentMethodBooking[]
  ) => {
    if (selectCollectFeeBooking) {
      dispatch(
        saveBookingCollectionFee({
          bookingId: selectCollectFeeBooking.bookingId,
          payload: paymentMethodBookings,
          ticketId: selectCollectFeeBooking.id,
        })
      );
    }
  };

  const handleUpdateIssueFee = (
    paymentMethodBookings: IPaymentMethodBooking[]
  ) => {
    if (selectCollectIssueFeeTicket) {
      dispatch(
        saveIssueCollectionFee({
          paymentMethods: paymentMethodBookings,
          ticketId: selectCollectIssueFeeTicket.id,
        })
      );
    }
  };

  const handleUpdateBookingTicket = (
    ticketUpdated: ISelectedTicket,
    paymentMethodBookingsConnect?: IPaymentMethodBooking[],
    paymentMethodBookingsDisable?: IPaymentMethodBooking[]
  ) => {
    if (selectTicketSelected) {
      dispatch(
        saveBookingTicket({
          paymentMethodBookingsConnect,
          paymentMethodBookingsDisable,
          ticketsUpdate: ticketUpdated,
        })
      );
    }
  };

  return (
    <>
      {modalContext}
      {!!selectTicketSelected && (
        // [06-12-2023][Phuc Thinh] [Feature/14861]
        // Add Edit Ticket Component
        // [15-12-2023][Phuc Thinh] [Feature/14861]
        // Add API for Edit Ticket
        <EditTicketModal
          open={!!selectTicketSelected}
          handleClose={() => dispatch(selectTicket(null))}
          currentTicket={selectOpTickets.find(
            (ticket) => ticket.id === selectTicketSelected.id
          )}
          handleSubmit={(...args) => handleUpdateBookingTicket(...args)}
        />
      )}
      {selectCollectFeeBooking && (
        <CollectionFeeModal
          collectFeeBooking={selectCollectFeeBooking}
          handleClose={() => dispatch(setCollectFeeBooking(null))}
          handleSubmit={(payload) => handleUpdateBookingCollectFee(payload)}
        />
      )}
      {selectCollectIssueFeeTicket && (
        <CollectIssueFeeModal
          collectIssueFeeTicket={selectCollectIssueFeeTicket}
          handleClose={() => dispatch(setCollectIssueFeeTicket(null))}
          handleSubmit={(payload) => handleUpdateIssueFee(payload)}
        />
      )}
      <Table
        dataSource={selectOpTickets}
        columns={columns}
        className={`${styles.table} ticketTable horizontalDragScroll customScrollTable`}
        scroll={{ x: 1050, y: height - 380 }}
        components={{
          body: {
            row: Row,
          },
        }}
        pagination={{
          current: selectTicketResult ? selectTicketResult.page : 1,
          pageSize: selectTicketResult ? selectTicketResult.pageSize : 10,
          total: selectTicketResult ? selectTicketResult.queryCount : 0,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
        onChange={(_, filters) => console.log(filters)}
        rowKey={"id"}
        loading={selectLoading}
        //[1-12-2023] [Phuc Thinh]
        //Thêm phần summary cho table
        summary={(pageData) => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <Typography.Text strong>Tổng số khách </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="center">
                  <Typography.Text>
                    {selectTicketResult?.queryCount}
                  </Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </>
  );
};

export default TicketOperatorTable;
