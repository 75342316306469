import { useState } from "react";
import { Avatar, Divider, Dropdown, Skeleton, Space, Typography } from "antd";
import styles from "./HeaderSection.module.css";
// import DefaultAvatar from "../../image/default_avatar.png";
import LogoutSvg from "../../image/icon/logout-icon.svg";
import SettingsSvg from "../../image/icon/settings-icon.svg";
import type { MenuProps } from "antd";
import { ProfileItem } from "./ProfileItem";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { logout } from "store/controls/LoginEpic";
import { ProfileModal } from "components/Modal/ProfileModal";
import React from "react";

interface IProps {
  isAgentNav?: boolean;
}
export const ProfileSection = ({ isAgentNav = false }: IProps) => {
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const dispatch = useDispatchRoot();
  const selectProfile = useSelectorRoot((state) => state.login.profile);
  const selectLoading = useSelectorRoot((state) => state.login.loading);

  const handleLogout = () => {
    dispatch(logout());
  };
  const menuStyle = {
    boxShadow: "none",
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <ProfileItem label="Settings" imgSrc={SettingsSvg} href="/settings" />
      ),
      key: "0",
      onClick: () => setProfileModalOpen(true),
    },
    {
      label: (
        <ProfileItem
          label="Logout"
          imgSrc={LogoutSvg}
          href="/logout"
          style={{ color: "red" }}
        />
      ),
      key: "1",
      onClick: handleLogout,
    },
  ];

  return (
    <div className={styles.profileSection}>
      {!selectLoading ? (
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          overlayStyle={{ zIndex: 10 }}
          getPopupContainer={(triggerNode) =>
            triggerNode.parentElement as HTMLElement
          }
          dropdownRender={(menu) => (
            <div className={styles.profileDropdown}>
              <Space
                style={{
                  width: "100%",
                  padding: ".75rem 1rem",
                  rowGap: 8,
                }}
              >
                <Avatar
                  size={50}
                  src={
                    selectProfile?.avatarUrl ||
                    "https://i.pinimg.com/564x/44/2f/82/442f825e875dc31be3d0d109e4f2d11c.jpg"
                  }
                  style={{ outline: "1px solid #5277b8", outlineOffset: 1 }}
                />
                {selectProfile && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography.Text strong>
                      {`${selectProfile?.lastname} ${selectProfile?.firstname}`}
                    </Typography.Text>
                    <Typography.Text style={{ color: "grey" }}>
                      {selectProfile?.email}
                    </Typography.Text>
                  </div>
                )}
              </Space>

              <Divider style={{ margin: "4px 0" }} />

              {React.cloneElement(menu as React.ReactElement, {
                style: menuStyle,
              })}
            </div>
          )}
        >
          <Avatar
            size={40}
            className={styles.mainAvatar}
            src={
              selectProfile?.avatarUrl ||
              "https://i.pinimg.com/564x/44/2f/82/442f825e875dc31be3d0d109e4f2d11c.jpg"
            }
          />
        </Dropdown>
      ) : (
        <Skeleton.Avatar active={true} size={40} shape={"circle"} />
      )}
      <ProfileModal
        open={profileModalOpen}
        handleClose={() => setProfileModalOpen(false)}
      />
    </div>
  );
};
