import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { IGroupTicket, JobTicketStatusEnum } from "common/define-types";
import React, { useCallback } from "react";
import { checkTickets, uncheckTickets } from "store/slice/GroupSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";

interface IProps {
  groupTicket: IGroupTicket;
}
export const GroupTicketCheckbox = ({ groupTicket }: IProps) => {
  const dispatch = useDispatchRoot();
  const selectGroupsTickets = useSelectorRoot(
    (state) => state.group.groupTickets
  );
  const isChecked = useSelectorRoot((state) =>
    state.group.groupTicketsSelected.some(
      (selectedTicket) => selectedTicket.id === groupTicket.id
    )
  );
  const handleCheckTicket = useCallback(
    (e: CheckboxChangeEvent) => {
      if (e.target.checked) {
        if (!!groupTicket.dockerId) {
          dispatch(
            checkTickets(
              selectGroupsTickets.filter(
                (gTicket) => gTicket.dockerId === groupTicket.dockerId
              )
            )
          );
        } else {
          dispatch(checkTickets([groupTicket]));
        }
      } else {
        dispatch(uncheckTickets([groupTicket]));
      }
    },
    [dispatch, groupTicket, selectGroupsTickets]
  );
  return (
    <Checkbox
      checked={isChecked}
      onChange={handleCheckTicket}
      disabled={groupTicket.status === JobTicketStatusEnum.BREAKUPTOUR}
    />
  );
};
