import { Select, Checkbox, Divider, Button, Popconfirm, Empty } from "antd";
import { useEffect, useState } from "react";
import { setSelectedService } from "store/slice/BookingSlice";
import { useDispatchRoot, useSelectorRoot } from "store/store";

const ExtraServiceSelect = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [checkedServices, setCheckedServices] = useState<any>({});
  const dispatch = useDispatchRoot();

  const selectExtraServices = useSelectorRoot(
    (state) => state.booking.extraServices
  );
  const selectSelectedServices = useSelectorRoot(
    (state) => state.booking.selectedExtraServices
  );

  useEffect(() => {
    setCheckedServices(selectSelectedServices);
  }, [selectSelectedServices, isDropdownOpen]);

  const toggleService = (serviceKey: string) => {
    setCheckedServices({
      ...checkedServices,
      [serviceKey]: !checkedServices[serviceKey],
    });
  };

  const handleSave = () => {
    dispatch(
      setSelectedService({
        ...selectSelectedServices,
        ...checkedServices,
      })
    );
  };

  return (
    <Select
      dropdownRender={() => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {selectExtraServices && selectExtraServices.length > 0 ? (
            <>
              <div
                className={`customScroll`}
                style={{ maxHeight: " 200px", overflowY: "auto" }}
              >
                {selectExtraServices.map((service) => (
                  <Checkbox
                    checked={checkedServices[service.code]}
                    style={{
                      marginInlineStart: 0,
                      padding: 5,
                      display: "flex",
                      gap: 3,
                      fontSize: 15,
                      fontWeight: 500,
                    }}
                    onChange={(e: any) => toggleService(service.code)}
                    key={`${service.code}`}
                  >
                    {`${service.name}`}
                  </Checkbox>
                ))}
              </div>
              <Divider style={{ marginBlock: 5 }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 5,
                }}
              >
                <div></div>
                <Popconfirm
                  title="Lưu thay đổi"
                  onConfirm={handleSave}
                  getPopupContainer={(trigger) =>
                    trigger.parentElement as HTMLElement
                  }
                >
                  <Button type="primary">Apply</Button>
                </Popconfirm>
              </div>
            </>
          ) : (
            <Empty />
          )}
        </div>
      )}
      value={`${
        checkedServices
          ? Object.values(checkedServices).filter((value) => value === true)
              .length
          : 0
      } loại dịch vụ được chọn`}
      style={{ minWidth: 200 }}
      open={isDropdownOpen}
      onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
    />
  );
};

export default ExtraServiceSelect;
