import { Typography, Button, Popconfirm } from "antd";
import styles from "../Agents.module.css";
import {
  PhoneOutlined,
  MailOutlined,
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { deleteAgentAccount, editAgentAccount } from "store/slice/AgentsSlice";
import { RoleEnum } from "common/define-types";
interface IProps {
  account: IAccount;
}
export const AgentAccountItem = ({ account }: IProps) => {
  const dispatch = useDispatchRoot();
  const selectAgentSelected = useSelectorRoot(
    (state) => state.agents.agentSelected
  );
  const selectDeletingAgentAccountId = useSelectorRoot(
    (state) => state.agents.deletingAgentAccountId
  );
  const userRole = useSelectorRoot((state) => state.login.role);
  const handleEditAccount = (e: any) => {
    e.stopPropagation();
    dispatch(editAgentAccount(account));
  };
  const handleDeleteAccount = () => {
    dispatch(
      deleteAgentAccount({
        accountId: account.id,
        agentId: selectAgentSelected ? selectAgentSelected.id : "",
      })
    );
  };
  return (
    <div className={`${styles.agentAccountItem}`}>
      <div className={styles.agentItem_wrapper}>
        <div
          className={styles.spaceBetween}
          style={{ justifyContent: "flex-start" }}
        >
          <Typography.Text
            strong
            style={{ fontSize: 20, fontWeight: 700 }}
          >{`${account.lastname} ${account.firstname}`}</Typography.Text>
        </div>
        <div className={styles.spaceBetween}>
          <div className={styles.textWithIcon}>
            <PhoneOutlined style={{ fontSize: 20 }} rotate={90} />
            <Typography.Text
              strong
            >{` ${account.phoneNumber}`}</Typography.Text>
          </div>
          <div className={styles.textWithIcon} style={{ maxWidth: "55%" }}>
            <MailOutlined style={{ fontSize: 20 }} />
            <Typography.Text
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              strong
            >
              {` ${account.email}`}
            </Typography.Text>
          </div>
        </div>
        <div className={styles.textWithIcon}>
          <UserOutlined style={{ fontSize: 20 }} />
          <Typography.Text strong>{` ${account.userName}`}</Typography.Text>
        </div>
      </div>
      <div className={styles.btnContainer} style={{ flexBasis: 72 }}>
        <Button
          icon={<EditOutlined style={{ fontSize: 20, color: "#1890FF" }} />}
          type="text"
          onClick={handleEditAccount}
          disabled={
            selectAgentSelected?.isErase ||
            userRole !== RoleEnum.ADMIN ||
            selectAgentSelected === null
          }
        />
        <Popconfirm
          title="Xóa tài khoản"
          description="Bạn chắc chắn muốn xóa tài khoản này?"
          onConfirm={handleDeleteAccount}
          okButtonProps={{
            disabled:
              selectAgentSelected?.isErase ||
              userRole !== RoleEnum.ADMIN ||
              selectAgentSelected === null,
            loading: selectDeletingAgentAccountId === account.id,
          }}
        >
          <Button
            icon={<DeleteOutlined style={{ fontSize: 20, color: "red" }} />}
            type="text"
          />
        </Popconfirm>
      </div>
    </div>
  );
};
