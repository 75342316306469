import { Typography } from "antd";
import { IGroupTicket } from "common/define-types";
import styles from "../Bus.module.css";

interface IProps {
  ticket: IGroupTicket;
}
export const TicketOption = ({ ticket }: IProps) => {
  return (
    <div className={styles.busOption}>
      <div className={styles.busInfo}>
        <Typography.Text strong>{ticket.fullName}</Typography.Text>
        <Typography.Text italic>{ticket.email ?? ""}</Typography.Text>
      </div>
      <Typography.Text strong className={styles.busPrice}>
        {`Phone: ${ticket.phone ?? "Trống"}`}
      </Typography.Text>
    </div>
  );
};
