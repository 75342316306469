import { Form, FormInstance, Input, Modal } from "antd";
import styles from "../DriverOperator.module.css";
interface IProps {
  open: boolean;
  handleClose: () => void;
  handleOk: () => void;
  form: FormInstance<any>;
}
export const NoteDriverModal = ({
  open,
  handleClose,
  form,
  handleOk,
}: IProps) => {
  return (
    <Modal
      open={open}
      onCancel={handleClose}
      onOk={handleOk}
      okText="Lưu"
      cancelText="Hủy"
      title={"Ghi chú tài xế"}
    >
      <Form labelCol={{ span: 24 }} form={form}>
        <div className={styles.formItemGroup}>
          <Form.Item label={"Thưởng (đ)"} name={"reward"}>
            <Input />
          </Form.Item>
          <Form.Item label={"Phạt (đ)"} name={"fine"}>
            <Input />
          </Form.Item>
        </div>
        <Form.Item label={"Ghi chú"} name={"note"}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
