import { Button, Input, Modal, Tooltip, Typography, notification } from "antd";
import styles from "./Driver.module.css";
import { DriverTable } from "./components/DriverTable";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  addDriver,
  fetchDrivers,
  setAddModalOpen,
  setDriverFilterValue,
} from "store/slice/DriverSlice";
import { useForm } from "antd/es/form/Form";
import { DriverForm } from "./components/DriverForm";
import Utils from "common/Utils";
import { RoleEnum } from "common/define-types";
import dayjs from "dayjs";

export const Driver = () => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const [addDriverForm] = useForm();
  const dispatch = useDispatchRoot();
  const addModalOpen = useSelectorRoot((state) => state.driver.addModalOpen);
  const selectIsSubmitting = useSelectorRoot(
    (state) => state.driver.isSubmitting
  );
  const selectErrMsg = useSelectorRoot((state) => state.driver.errMsg);
  const userRole = useSelectorRoot((state) => state.login.role);
  const selectIsRefreshing = useSelectorRoot(
    (state) => state.login.isRefreshing
  );
  const selectDriverFilterValue = useSelectorRoot(
    (state) => state.driver.filterValue
  );

  const getDrivers = useCallback(() => {
    dispatch(fetchDrivers({ pageSize: 25 }));
  }, [dispatch]);

  useEffect(() => {
    !selectIsRefreshing && getDrivers();
  }, [getDrivers, selectIsRefreshing]);

  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({
        message: selectErrMsg,
      });
    }
  }, [selectErrMsg]);

  const handleAddDriver = async () => {
    const driverValue = await addDriverForm.validateFields().catch((err) => {
      console.log(err);
      notification.error({ message: "Vui lòng nhập đủ thông tin" });
    });
    if (!driverValue) return;
    const getName = Utils.getName(driverValue.name);
    dispatch(
      addDriver({
        ...driverValue,
        firstName: getName.firstName.trim(),
        lastName: getName.lastName,
        dateStartWork: dayjs(driverValue.dateStartWork).toISOString(),
      })
    );
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(
        setDriverFilterValue({
          ...selectDriverFilterValue,
          search: e.target.value,
        })
      );
    }, 1000);
    setTimer(timeoutId);
  };
  return (
    <div className={styles.driverContainer}>
      <div className={styles.tableHeader}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 10,
            flexDirection: "row",
          }}
        >
          <Typography.Text strong style={{ fontSize: 20 }}>
            Danh sách tài xế
          </Typography.Text>
          <div>
            <Input.Search
              placeholder="Tìm kiếm tài xế"
              onChange={handleSearch}
              allowClear
            />
          </div>
        </div>
        <div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              dispatch(setAddModalOpen(true));
              addDriverForm.resetFields();
            }}
            disabled={userRole !== RoleEnum.ADMIN}
          >
            Thêm mới tài xế
          </Button>
          {userRole !== RoleEnum.ADMIN && (
            <Tooltip title={"Bạn không được cấp quyền tạo tài khoản"}>
              <InfoCircleOutlined
                style={{
                  fontSize: 14,
                  marginLeft: 8,
                  color: "#7dccfc",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          )}
        </div>
      </div>
      <DriverTable />
      {addModalOpen ? (
        <Modal
          title={
            <Typography.Text strong style={{ fontSize: 20 }}>
              Thêm mới tài xế
            </Typography.Text>
          }
          centered
          open={addModalOpen}
          onCancel={() => {
            dispatch(setAddModalOpen(false));
            addDriverForm.resetFields();
          }}
          destroyOnClose={true}
          onOk={handleAddDriver}
          okText="Thêm"
          okButtonProps={{ loading: selectIsSubmitting }}
        >
          <DriverForm form={addDriverForm} />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};
