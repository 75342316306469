import { Route, Switch } from "react-router-dom";
import { AgentLayout } from "pages/Agent/AgentLayout";
import { AdminLayout } from "pages/Admin/AdminLayout";
import Utils from "common/Utils";
import {
  fetchProfile,
  getRoles,
  logout,
  setLoginSuccess,
} from "store/controls/LoginEpic";
import { RoleEnum, Token_ResponseToken } from "common/define-types";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelectorRoot } from "store/store";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import { pushBreadcrum } from "store/slice/controlSlice";
import { useHistory, useLocation } from "react-router-dom";
import { Routes, AgentRoutes } from "pages/Routes";
import { notification } from "antd";

export const Main = () => {
  const authenticated = useSelectorRoot((state) => state.login.isSuccess);
  const userRole = useSelectorRoot((state) => state.login.role);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  history.listen((e) => {
    createBreadcrum(e.pathname);
  });

  const createBreadcrum = useCallback(
    (path: string) => {
      var data: ItemType[] = [];
      if (path === "/") {
        data.push({ title: "Bảng điều khiển" });
      } else {
        var isDone = false;
        for (var i of [...Routes, ...AgentRoutes]) {
          if (i.items && i.items.length > 0) {
            for (var child of i.items) {
              if (
                child.routeProps &&
                path.startsWith(child.routeProps?.path as string)
              ) {
                data.push({ title: i.label });
                data.push({
                  title: (
                    <a
                      href={(child.routeProps?.path ?? "/") as string}
                      style={{ color: "#000" }}
                    >
                      {child.label}
                    </a>
                  ),
                });
                isDone = true;
                break;
              }
            }
          }
          if (isDone) break;
        }
      }
      dispatch(pushBreadcrum(data));
    },
    [dispatch]
  );
  useEffect(() => {
    if (!authenticated) {
      Utils.getValueLocalStorage(Utils.constant.token).then(
        async (token: Token_ResponseToken) => {
          if (token != null) {
            var user = await Utils.getValueLocalStorage(Utils.constant.user);
            if (!user) history.replace("/login");
            dispatch(
              setLoginSuccess({
                status: true,
                token: token.access_token,
                user,
                refresh_token: token.refresh_token,
              })
            );
            dispatch(getRoles());
            dispatch(fetchProfile());
            Utils.profile = JSON.parse(user.profile);
          } else {
            history.replace("/login");
          }
        }
      );
      createBreadcrum(location.pathname);
    }
  }, [authenticated, dispatch, history, location.pathname, createBreadcrum]);

  const handleUnauthorized = useCallback(() => {
    dispatch(logout());
    notification.destroy();
    notification.warning({
      message: "Tài khoản không được cấp quyền để truy cập",
    });
  }, [dispatch]);

  useEffect(() => {
    if (authenticated) {
      if (userRole) {
        if (userRole === RoleEnum.ADMIN || userRole === RoleEnum.SYSTEM) {
          history.replace("/jasmine/dashboard");
        } else if (userRole === RoleEnum.NIGHTAUDITOR) {
          history.replace("/jasmine/night-auditor");
        } else if (userRole === RoleEnum.AGENT) {
          history.replace("/tours");
        } else {
          handleUnauthorized();
        }
      } else {
        handleUnauthorized();
      }
    }
  }, [userRole, history, handleUnauthorized, authenticated]);
  return (
    <Switch>
      <Route path="/jasmine/:path?" exact component={AdminLayout} />
      <Route path="/" component={AgentLayout} />
    </Switch>
  );
};
