import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { IParams, IHangHoaItem, RootEpic } from "common/define-types";
import { AjaxError } from "rxjs/ajax";
import { getAllHangHoa } from "api/hanghoa.api";

export interface HangHoaState {
  isLoading: boolean;
  isSubmitting: boolean;
  motorbikes: IHangHoaItem[];
  errMsg: string | null;
}

const initialState: HangHoaState = {
  isLoading: false,
  isSubmitting: false,
  errMsg: null,
  motorbikes: [],
};

export const hangHoaSlice = createSlice({
  name: "hangHoa",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchHangHoaMotorbikes: (state, action: PayloadAction<IParams>) => {
      state.isLoading = true;
      state.errMsg = null;
    },
    setHangHoaMotorbikes: (state, action: PayloadAction<IHangHoaItem[]>) => {
      state.motorbikes = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
    },
  },
});

const fetchHangHoaMotorbikes$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchHangHoaMotorbikes.match),
    switchMap((re) => {
      return getAllHangHoa(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const motorbikes = res.results;
            return [
              hangHoaSlice.actions.setHangHoaMotorbikes(motorbikes ?? []),
            ];
          } else {
            return [hangHoaSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          hangHoaSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách điểm phát sinh"
          ),
        ])
      );
    })
  );

export const { fetchHangHoaMotorbikes, setHangHoaMotorbikes, setErrMsg } =
  hangHoaSlice.actions;

export const HangHoaEpics = [fetchHangHoaMotorbikes$];

export const hangHoaReducer = hangHoaSlice.reducer;
