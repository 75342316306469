import { Modal, Tabs } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { ProfileForm } from "components/Form/ProfileForm";
import styles from "./Modal.module.css";
import { useEffect, useState } from "react";
import { useSelectorRoot } from "store/store";
import { SecurityForm } from "components/Form/SecurityForm";

interface IProps {
  open: boolean;
  handleClose?: () => void;
}
export const ProfileModal = ({ open, handleClose }: IProps) => {
  const selectProfile = useSelectorRoot((state) => state.login.profile);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(
    selectProfile?.avatarUrl ?? null
  );

  useEffect(() => {
    if (selectProfile && selectProfile.avatarUrl) {
      setAvatarUrl(selectProfile.avatarUrl);
    }
  }, [selectProfile]);

  return (
    <Modal
      footer={null}
      centered
      open={open}
      onCancel={handleClose}
      className={styles.profileModalWrapper}
      destroyOnClose={true}
    >
      <Tabs
        items={[
          {
            label: (
              <span>
                <UserOutlined />
                Thông tin cá nhân
              </span>
            ),
            key: "1",
            children: (
              <ProfileForm
                avatarUrl={avatarUrl}
                setAvatarUrl={(value) => setAvatarUrl(value)}
                profile={selectProfile}
                handleCloseModal={handleClose}
              />
            ),
          },
          {
            label: (
              <span>
                <LockOutlined />
                An toàn và bảo mật
              </span>
            ),
            key: "2",
            children: <SecurityForm handleCloseModal={handleClose} />,
          },
        ]}
      />
    </Modal>
  );
};
