import { BarChartOutlined, LoadingOutlined } from "@ant-design/icons";
import { Col, Result, Row, notification } from "antd";
import { getAllAgentsForDashboard, getAllAgentsOfTopDays } from "api/agent.api";
import Utils from "common/Utils";
import { IAgentWithSummarized } from "common/define-types";
import { BarChart, IBarData } from "components/Chart/BarChart";
import { IPieData, PieChart } from "components/Chart/PieChart";
import moment from "moment";
import { useEffect, useState } from "react";

export const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [barData, setBarData] = useState<IBarData | null>(null);
  const [topDayBarData, setTopDayBarData] = useState<IBarData | null>(null);
  const [piesData, setPiesData] = useState<IPieData[] | null>(null);
  // const barData = {
  //   title: "Top 10 đại lý có doanh thu cao nhất",
  //   data: {
  //     labels: ["A", "B", "C", "A", "B", "C", "A", "B", "C", "A", "B", "C"],
  //     datasets: [
  //       {
  //         label: "Doanh thu vé",
  //         data: [10, 20, 50, 10, 20, 50, 10, 20, 50, 10, 20, 50],
  //       },
  //       {
  //         label: "Doanh thu bus",
  //         data: [60, 30, 10, 60, 30, 10, 60, 30, 10, 60, 30, 10],
  //       },
  //     ],
  //   },
  // };
  // const pieData = {
  //   title: "Pie chart",
  //   data: [
  //     {
  //       label: "Doanh thu vé",
  //       value: 30,
  //       bgColor: "#337CCF",
  //     },
  //     {
  //       label: "Doanh thu bus",
  //       value: 20,
  //       bgColor: "#FF9B50",
  //     },
  //   ],
  // };
  useEffect(() => {
    setIsLoading(true);
    getAllAgentsForDashboard({
      dateTime: moment.utc().toISOString(),
    }).subscribe(
      (res) => {
        if (res && res.results) {
          const agents: IAgentWithSummarized[] = res.results;
          const bar = {
            title: `Top ${agents.length} đại lý có doanh thu cao nhất`,
            data: {
              labels: agents.map((agent) => agent.name),
              datasets: [
                {
                  label: "Doanh thu vé",
                  data: agents.map(
                    (agent) => agent.summarizedAgentMonthlyDto.ticketRevenue
                  ),
                },
                {
                  label: "Doanh thu bus",
                  data: agents.map(
                    (agent) => agent.summarizedAgentMonthlyDto.busRevenue
                  ),
                },
              ],
            },
          };
          const pies = [
            {
              title: "Tổng doanh thu",
              data: [
                {
                  label: "Doanh thu vé",
                  value: agents.reduce(
                    (prev, currentAgent) =>
                      prev +
                      currentAgent.summarizedAgentMonthlyDto.ticketRevenue,
                    0
                  ),
                  bgColor: "#337CCF",
                },
                {
                  label: "Doanh thu bus",
                  value: agents.reduce(
                    (prev, currentAgent) =>
                      prev + currentAgent.summarizedAgentMonthlyDto.busRevenue,
                    0
                  ),
                  bgColor: "#FF9B50",
                },
              ],
            },
            {
              title: "Jasmine nhận được và hoa hồng trả đại lý",
              data: [
                {
                  label: "Jasmine nhận được",
                  value: agents.reduce(
                    (prev, currentAgent) =>
                      prev + currentAgent.summarizedAgentMonthlyDto.refund,
                    0
                  ),
                  bgColor: "#337CCF",
                },
                {
                  label: "Hoa hồng trả đại lý",
                  value: agents.reduce(
                    (prev, currentAgent) =>
                      prev + currentAgent.summarizedAgentMonthlyDto.bonus,
                    0
                  ),
                  bgColor: "#FF9B50",
                },
              ],
            },
          ];
          setBarData(bar);
          setPiesData(pies);
        }
      },
      (err) => notification.error({ message: "Có lỗi xảy ra" })
    );
    const topDay = 10;
    getAllAgentsOfTopDays({
      dateTime: moment.utc().toISOString(),
      topDay,
    }).subscribe(
      (res) => {
        if (res) {
          const orderedRes = [...res].sort((a: any, b: any) => {
            if (!a.dateTime || !a.dateTime) return 0;
            if (moment(a.dateTime).isBefore(b.dateTime)) return -1;
            return 1;
          });
          const topDayBbar = {
            title: `Doanh thu ${topDay} ngày gần nhất`,
            data: {
              labels: orderedRes.map((item: any) =>
                moment.utc(item.dateTime).format(Utils.dateFormat)
              ),
              datasets: [
                {
                  label: "Tổng doanh thu",
                  data: orderedRes.map((item: any) => {
                    const results: IAgentWithSummarized[] =
                      item.agentDtos.results;
                    return results.reduce(
                      (prev, currentAgent) =>
                        prev + currentAgent.summarizedAgentMonthlyDto.revenue,
                      0
                    );
                  }),
                },
              ],
            },
          };
          setTopDayBarData(topDayBbar);
        }
      },
      (err) => {
        notification.error({ message: "Có lỗi xảy ra" });
        console.log(err);
        setIsLoading(false);
      },
      () => setIsLoading(false)
    );
  }, []);
  return (
    <Row gutter={[10, 50]}>
      <Col span={16} {...{ xs: 24, sm: 24, xl: 16 }}>
        {barData && <BarChart barData={barData} />}
        {topDayBarData && <BarChart barData={topDayBarData} />}
      </Col>
      {piesData && piesData.length > 0 && (
        <Col span={8} {...{ xs: 24, sm: 24, xl: 8 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {piesData.map((pieData) => (
              <PieChart key={pieData.title} pieData={pieData} />
            ))}
          </div>
        </Col>
      )}
      {isLoading ? (
        <Col span={24}>
          <Result
            icon={<LoadingOutlined spin />}
            title="Đang tải dữ liệu...!"
          />
        </Col>
      ) : (
        !barData &&
        (!piesData || piesData?.length === 0) && (
          <Col span={24}>
            <Result
              icon={<BarChartOutlined />}
              title="Hiện tại chưa có dữ liệu!"
            />
          </Col>
        )
      )}
    </Row>
  );
};
