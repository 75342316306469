import {
  Typography,
  Input,
  Tooltip,
  Empty,
  notification,
  Popconfirm,
} from "antd";
import styles from "../Booking.module.css";
import { ArrowUpOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { TourTicket } from "./TourTicket";
import { ISelectedComboTour, ITicket } from "common/define-types";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import {
  addTicketsToTour,
  removeTicketFromTour,
  selectTicket,
  unbookTour,
} from "store/slice/BookingSlice";
import Utils from "common/Utils";
import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
interface IProps {
  tour: ISelectedComboTour;
}
//[14-12-2023][Phuc Thinh][Feature/14901]
//Change Tour to TourCombo
export const Tour = ({ tour }: IProps) => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const [countTickets, setCountTickets] = useState<number>(1);
  const dispatch = useDispatchRoot();
  const selectTicketSelected = useSelectorRoot(
    (state) => state.booking.selectedTicket
  );
  const selectCurrentAgent = useSelectorRoot(
    (state) => state.booking.currentAgent
  );
  const selectEditingBooking = useSelectorRoot(
    (state) => state.booking.editingBooking
  );
  const isTicketValid = (ticket: ITicket) => {
    if (
      // !Utils.isValid(ticket.email) ||
      !Utils.isValid(ticket.fullName) ||
      // !Utils.isValid(ticket.phone) ||
      // !Utils.isValid(ticket.passportId) ||
      !Utils.isValid(ticket.startDate)
    )
      return false;
    return true;
  };

  useEffect(() => {
    setCountTickets(tour.tickets ? tour.tickets.length : 1);
  }, [tour]);

  const handleAddTicket = () => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      const newTicket: ITicket = Utils.getDefaultTicket({
        agent: selectCurrentAgent,
        index: 0,
        comboTour: tour,
      });
      dispatch(
        addTicketsToTour({
          tickets: [newTicket],
          combotour: tour,
        })
      );
    }, 700);
    setTimer(timeoutId);
  };

  const handleDeleteTicket = (ticketId: string) => {
    dispatch(
      removeTicketFromTour({
        ticketId,
        combotour: tour,
      })
    );
    if (selectTicketSelected && selectTicketSelected.id === ticketId) {
      dispatch(selectTicket(null));
    }
  };

  const handleUnbookTour = () => {
    if (tour) {
      if (
        tour.tickets &&
        tour.tickets.some((ticket) => ticket.id === selectTicketSelected?.id)
      ) {
        dispatch(selectTicket(null));
      }
      dispatch(unbookTour(tour));
    }
  };

  const handleChangeCountTickets = (e: ChangeEvent<HTMLInputElement>) => {
    const newCount = parseInt(e.currentTarget.value);
    // if (newCount > 15) {
    //   notification.warning({ message: "Chỉ được tối đa 15 vé" });
    //   return;
    // }
    setCountTickets(newCount);
  };

  const handleAddTicketsByLength = (newLength: string | undefined) => {
    if (!newLength) {
      setCountTickets(tour.tickets?.length ?? 0);
      return;
    }
    const newCount = parseInt(newLength);
    const prevCount = tour.tickets ? tour.tickets.length : 0;

    if (newCount < prevCount) {
      notification.warning({ message: "Không thể nhập số lượng vé nhỏ hơn" });
      setCountTickets(tour.tickets?.length ?? 0);
      return;
    }
    const newTickets = new Array(newCount - prevCount)
      .fill(null)
      .map((_, index) => {
        const defaultTicket: ITicket = Utils.getDefaultTicket({
          agent: selectCurrentAgent,
          index,
          comboTour: tour,
        });
        return defaultTicket;
      });

    dispatch(
      addTicketsToTour({
        tickets: newTickets,
        combotour: tour,
      })
    );
  };

  return (
    <div className={styles.tour}>
      <div className={styles.tourInfo}>
        <div style={{ minWidth: 150 }}>
          <Popconfirm
            title="Xóa tour"
            description={"Bạn có chắc chắc muốn xóa tour này ?"}
            onConfirm={(e: any) => {
              e?.stopPropagation();
              if (
                !!selectEditingBooking &&
                tour.tickets?.some((ticket) =>
                  Utils.checkOncomingTicket(ticket)
                )
              ) {
                notification.warning({ message: "Tour có chứa vé sát giờ" });
                return;
              }
              handleUnbookTour();
            }}
            onCancel={(e: any) => e?.stopPropagation()}
          >
            <MinusCircleOutlined
              style={{
                fontSize: 16,
                color: "red",
                marginRight: 8,
                cursor: "pointer",
              }}
            />
          </Popconfirm>
          {/* <Typography.Text style={{ fontSize: 18, fontWeight: 700 }}>
            Hà Giang Tour:
          </Typography.Text> */}
          <Typography.Text
            style={{
              textTransform: "uppercase",
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            {` ${tour?.Title || ""} ${tour?.SubTitle || ""} `}
            <Typography.Text style={{ color: 'grey', fontSize: 14 }} italic>{tour.Description}</Typography.Text>
          </Typography.Text>
        </div>
        <div className={styles.ticketInfo}>
          <span>Số lượng khách</span>
          <Input
            type="number"
            className={"ticketQty"}
            onChange={handleChangeCountTickets}
            onPressEnter={(e) =>
              handleAddTicketsByLength(e.currentTarget.value)
            }
            onBlur={(e) => handleAddTicketsByLength(e.target.value)}
            suffix={
              <Tooltip title={"Thêm khách"}>
                <ArrowUpOutlined
                  onClick={handleAddTicket}
                  style={{ fontSize: 10, cursor: "pointer" }}
                />
              </Tooltip>
            }
            style={{ width: 60 }}
            value={countTickets}
          />
        </div>
      </div>
      <div className={`${styles.tourTickets} customScroll`}>
        {/* {tickets.length > 0 ? (
          tickets.map((ticket) => (
            <TourTicket ticket={ticket} tourId={tour.id} />
          )) */}
        {tour.tickets && tour.tickets.length > 0 ? (
          [...tour.tickets]
            .sort((a, b) => {
              if (a.createdTime && b.createdTime) {
                const aCreatedTime = moment(a.createdTime);
                const bCreatedTime = moment(b.createdTime);
                return aCreatedTime.isSame(bCreatedTime)
                  ? 0
                  : aCreatedTime.isBefore(bCreatedTime)
                  ? -1
                  : 1;
              }
              return 0;
            })
            .map((ticket, index) => (
              <TourTicket
                ticket={ticket}
                tour={tour}
                key={`${tour.Id}-${index}`}
                isActive={ticket.id === selectTicketSelected?.id}
                isValid={isTicketValid(ticket)}
                handleDelete={handleDeleteTicket}
              />
            ))
        ) : (
          <div className={styles.emptyContainer}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"No ticket"}
            />
          </div>
        )}
      </div>
    </div>
  );
};
