import { Select, notification } from "antd";
import { useEffect, useState } from "react";
import { BusOption } from "./components/BusOption";
import dayjs, { Dayjs } from "dayjs";
// import { fetchRouterStationBusData } from "store/slice/RouterStationBus";
import { IRouterStationBus } from "common/define-types";
import { getAllRouterStationBuses } from "api/routerStationBus.api";
import { useSelectorRoot } from "store/store";
import moment from "moment";
interface IProps {
  value?: string;
  onChange?: (arg: IRouterStationBus | undefined) => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  allowClear?: boolean;
  filterValue: {
    locationFromId: string;
    locationToId: string;
    date: Dayjs | null;
    dockerId: string | null;
  };
  testId: string;
}
const { Option } = Select;
export const BusSelect = ({
  value,
  onChange = () => {},
  style,
  readOnly,
  filterValue,
  allowClear = true,
  testId,
}: IProps) => {
  const selectRouterStationBuses = useSelectorRoot(state => state.routerStationBus.routerStationBuses)
  const [buses, setBuses] = useState<IRouterStationBus[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const getTime = (bus: IRouterStationBus) => {
    const formatUnit = (value: number | string) => {
      const input = typeof value === "string" ? parseInt(value) : value;
      if (input < 10) {
        return `0${input}`;
      }
      return value;
    };
    if (bus.date) {
      const date = moment.utc(bus.date);
      return {
        hour: formatUnit(date.hours().toString()) ,
        minute: formatUnit(date.minutes().toString()) ,
      };
    }
    return {
      hour: formatUnit(0),
      minute: formatUnit(0) ,
    };
  };
  useEffect(() => {
    if (
      filterValue.locationFromId === "" ||
      filterValue.locationToId === "" ||
      !filterValue.locationFromId ||
      !filterValue.locationToId
    ) {
      setBuses([]);
      return;
    }
    if (filterValue.dockerId) {
      const busesRes: IRouterStationBus[] = selectRouterStationBuses;
      const newBuses = busesRes.filter(
        (routerStaBus) =>
          routerStaBus.dmuC_Router_Station?.dmuC_Router.localtionStartId ===
            filterValue.locationFromId &&
          routerStaBus.dmuC_Router_Station?.dmuC_Router.localtionEndId ===
            filterValue.locationToId &&
          routerStaBus.dockerId?.toLowerCase().includes(filterValue.dockerId?.toLowerCase() || '')
      );
      setBuses(newBuses);
      return;
    }
    setLoading(true);
    getAllRouterStationBuses({
      dateTime: dayjs(filterValue.date).toISOString(),
      dockerId: 'nocombo'
    }).subscribe(
      (res) => {
        if (res) {
          const busesRes: IRouterStationBus[] = res;
          const newBuses = busesRes.filter(
            (routerStaBus) =>
              routerStaBus.dmuC_Router_Station?.dmuC_Router.localtionStartId ===
                filterValue.locationFromId &&
              routerStaBus.dmuC_Router_Station?.dmuC_Router.localtionEndId ===
                filterValue.locationToId
          );
          setBuses(newBuses);
        }
      },
      (err) => {
        notification.error({ message: "Không thể lấy danh sách bus" });
        setBuses([]);
        setLoading(false);
      },
      () => {
        // finally
        setLoading(false);
      }
    );
  }, [filterValue, selectRouterStationBuses]);

  return (
    <Select
      // options={selectBuses.map((bus) => ({
      //   label: bus.name,
      //   value: bus.description,
      // }))}
      data-test-id={testId}
      loading={loading}
      value={value}
      style={style}
      onChange={(value) => onChange(buses.find((bus) => bus.id === value))}
      optionLabelProp="label"
      allowClear={allowClear}
      open={modalOpen}
      onDropdownVisibleChange={(open) => setModalOpen(open)}
      notFoundContent={
        filterValue.locationFromId === "" ||
        filterValue.locationToId === "" ||
        !filterValue.locationFromId ||
        !filterValue.locationToId
          ? "Vui lòng chọn địa điểm"
          : "Chưa có bus"
      }
      disabled={readOnly}
    >
      {buses.map((bus) => (
        <Option label={`${bus.dmuC_Bus.description} - ${bus.dmuC_Router_Station?.dmuC_Station?.name} - ${getTime(bus).hour}:${getTime(bus).minute}`} value={bus.id} key={bus.id}>
          <BusOption bus={bus} />
        </Option>
      ))}
    </Select>
  );
};
