import { Divider, Typography } from "antd";
import { ISelectedComboTour } from "common/define-types";
import styles from "./Billing.module.css";
import moment from "moment";
import Utils from "common/Utils";
import { BusTicket } from "./BusTicket";
import ExtraServiceTicket from "./ExtraServiceTicket";
interface IProps {
  tour: ISelectedComboTour;
}
//[14-12-2023][Phuc Thinh][Feature/14901]
//Change Tour to TourCombo
export const TourDetailItem = ({ tour }: IProps) => {
  return (
    <div className={styles.ticketsWrapper}>
      {tour.tickets &&
        tour.tickets.length > 0 &&
        tour.tickets.map((ticket, index) => (
          <div className={styles.ticketWrapper} key={ticket.id}>
            <div className={styles.totalItem}>
              <Typography.Text
                title={`Vé ${index + 1}: ${ticket.name}`}
                style={{
                  maxWidth: 160,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >{`Vé ${index + 1}: ${ticket.name}`}</Typography.Text>
              <Typography.Text
                style={{ whiteSpace: "nowrap" }}
              >{`Ngày bắt đầu: ${moment(ticket.startDate).format(
                Utils.dateFormat
              )}`}</Typography.Text>
            </div>
            <div className={styles.totalItem}>
              <Typography.Text>{`Tên: ${
                ticket.fullName ?? ""
              }`}</Typography.Text>
              <Typography.Text>{`Phone: ${
                ticket.phone ?? ""
              }`}</Typography.Text>
            </div>
            <div className={styles.totalItem}>
              <Typography.Text strong>{`Vé tour: `}</Typography.Text>
              <Typography.Text strong>{`${Utils.formatCurrency(
                ticket.price
              )}`}</Typography.Text>
            </div>
            {(ticket.busTicketC1 || ticket.busTicketC2) && (
              <Divider
                style={{
                  borderBlockStartColor: "rgba(5, 5, 5, 0.56)",
                  width: "80%",
                  marginInline: "auto",
                  minWidth: "80%",
                  marginBlock: 6,
                }}
              />
            )}
            {ticket.busTicketC1 && (
              <BusTicket busTicket={ticket.busTicketC1} isQrTicket={true} />
            )}
            {ticket.busTicketC2 && (
              <BusTicket busTicket={ticket.busTicketC2} isQrTicket={true} />
            )}
            {ticket.datA_TOUR_CHHangHoaDichVuKhacs &&
              ticket.datA_TOUR_CHHangHoaDichVuKhacs.length > 0 && (
                <>
                  <Divider
                    style={{
                      borderBlockStartColor: "rgba(5, 5, 5, 0.56)",
                      width: "80%",
                      marginInline: "auto",
                      minWidth: "80%",
                      marginBlock: 6,
                    }}
                  />
                  <ExtraServiceTicket
                    tickets_extraService={ticket.datA_TOUR_CHHangHoaDichVuKhacs}
                  />
                </>
              )}
          </div>
        ))}
    </div>
  );
};
