import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IMotorbikesResult,
  IOperatorMotorbike,
  IOperatorMotorbikeCountValue,
  IOperatorMotorbikeFilterValue,
  IParams,
  IUpdateOperatorMotorbike,
  RootEpic,
} from "common/define-types";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import {
  createNoteMotorbikeByDay,
  getMotorbikesByDay,
  updateNoteMotorbikeByDay,
} from "api/motorbike.api";
import { ISaveBrokeMotorbikeTicket } from "api/types/motorbikeTicket";
import { saveBrokeMotorbikeTickets } from "api/motorbikeTicket.api";
import dayjs from "dayjs";
export interface MotorbikeState {
  isLoading: boolean;
  isSubmitting: boolean;
  motorbikes: IOperatorMotorbike[] | [];
  errMsg: string | null;
  motorbikesResult: IMotorbikesResult | null;
  editingMotorbike: IOperatorMotorbike | null;
  countValue: IOperatorMotorbikeCountValue | null;
  filterValue: IOperatorMotorbikeFilterValue;
}

const initialState: MotorbikeState = {
  isLoading: false,
  isSubmitting: false,
  motorbikes: [],
  errMsg: null,
  motorbikesResult: null,
  editingMotorbike: null,
  countValue: null,
  filterValue: {
    dateTime: dayjs().toISOString(),
  },
};

export const operatorMotorbikeSlice = createSlice({
  name: "operatorMotorbike",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchMotorbikes: (state, action: PayloadAction<IParams | undefined>) => {
      state.isLoading = true;
      state.isSubmitting = false;
      state.editingMotorbike = null;
      state.errMsg = null;
    },
    setMotorbikes: (state, action: PayloadAction<IOperatorMotorbike[]>) => {
      state.motorbikes = action.payload;
      state.isLoading = false;
    },
    setErrMsg: (state, action: PayloadAction<string>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
    },
    setMotorbikesResult: (state, action: PayloadAction<IMotorbikesResult>) => {
      state.motorbikesResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    editMotorbike: (
      state,
      action: PayloadAction<IOperatorMotorbike | null>
    ) => {
      state.editingMotorbike = action.payload;
    },
    saveMotorbike: (state, action: PayloadAction<IUpdateOperatorMotorbike>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    saveBrokeMotorbikeTicket: (
      state,
      action: PayloadAction<ISaveBrokeMotorbikeTicket[]>
    ) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    setFilterValue: (
      state,
      action: PayloadAction<IOperatorMotorbikeFilterValue>
    ) => {
      state.filterValue = action.payload;
    },
    setCountValue: (
      state,
      action: PayloadAction<IOperatorMotorbikeCountValue | null>
    ) => {
      state.countValue = action.payload;
    },
    clearFilterValue: (state) => {
      state.filterValue = {
        ...state.filterValue,
        search: "",
        statusBike: undefined,
      };
    },
  },
});
const getMotorbikes$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchMotorbikes.match),
    switchMap((re) => {
      return getMotorbikesByDay({
        // ...state$.value.operatorMotorbike.motorbikesResult,
        ...state$.value.operatorMotorbike.filterValue,
        ...re.payload,
        pageSize: re.payload?.pageSize || 25,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const { motorbikeManagerDto, ...rest } = res;
            const motorbikes = motorbikeManagerDto?.results;
            return [
              operatorMotorbikeSlice.actions.setMotorbikes(motorbikes ?? []),
              operatorMotorbikeSlice.actions.setMotorbikesResult(
                motorbikeManagerDto
              ),
              operatorMotorbikeSlice.actions.setCountValue(rest),
            ];
          } else {
            return [
              operatorMotorbikeSlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          operatorMotorbikeSlice.actions.setErrMsg("Có lỗi xảy ra"),
        ])
      );
    })
  );
const fetchWhenFilter$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setFilterValue.match),
    switchMap((re) => {
      return [operatorMotorbikeSlice.actions.fetchMotorbikes(re.payload)];
    })
  );
const saveMotorbikes$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveMotorbike.match),
    switchMap((re) => {
      if (re.payload.id)
        return updateNoteMotorbikeByDay(re.payload.id, re.payload).pipe(
          mergeMap((res: any) => {
            if (res && !res?.response?.error) {
              if (state$.value.operatorMotorbike.motorbikesResult) {
                return [
                  operatorMotorbikeSlice.actions.fetchMotorbikes(
                    state$.value.operatorMotorbike.motorbikesResult
                  ),
                ];
              }
              return [operatorMotorbikeSlice.actions.fetchMotorbikes()];
            } else {
              return [
                operatorMotorbikeSlice.actions.setErrMsg(res?.response.error),
              ];
            }
          }),
          catchError((e: AjaxError) => [
            operatorMotorbikeSlice.actions.setErrMsg(
              "Có lỗi xảy ra khi cập nhật note"
            ),
          ])
        );
      else
        return createNoteMotorbikeByDay(re.payload).pipe(
          mergeMap((res: any) => {
            if (res && !res?.response?.error) {
              if (state$.value.operatorMotorbike.motorbikesResult) {
                return [
                  operatorMotorbikeSlice.actions.fetchMotorbikes(
                    state$.value.operatorMotorbike.motorbikesResult
                  ),
                ];
              }
              return [operatorMotorbikeSlice.actions.fetchMotorbikes()];
            } else {
              return [
                operatorMotorbikeSlice.actions.setErrMsg(res?.response.error),
              ];
            }
          }),
          catchError((e: AjaxError) => [
            operatorMotorbikeSlice.actions.setErrMsg("Có lỗi xảy ra tạo note"),
          ])
        );
    })
  );

const saveBrokeMotorbikeTicket$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveBrokeMotorbikeTicket.match),
    switchMap((re) => {
      return saveBrokeMotorbikeTickets(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            if (state$.value.operatorMotorbike.motorbikesResult) {
              return [
                operatorMotorbikeSlice.actions.fetchMotorbikes(
                  state$.value.operatorMotorbike.motorbikesResult
                ),
              ];
            }
            return [operatorMotorbikeSlice.actions.fetchMotorbikes()];
          } else {
            return [
              operatorMotorbikeSlice.actions.setErrMsg(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          operatorMotorbikeSlice.actions.setErrMsg("Có lỗi xảy ra"),
        ])
      );
    })
  );

export const {
  fetchMotorbikes,
  setMotorbikes,
  setErrMsg,
  saveMotorbike,
  editMotorbike,
  saveBrokeMotorbikeTicket,
  setFilterValue,
  clearFilterValue,
} = operatorMotorbikeSlice.actions;

export const OperatorMotorbikeEpics = [
  getMotorbikes$,
  saveMotorbikes$,
  saveBrokeMotorbikeTicket$,
  fetchWhenFilter$,
];

export const operatorMotorbikeReducer = operatorMotorbikeSlice.reducer;
