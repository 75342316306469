import { Button, Layout, Menu, Modal, Typography } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { RouteObj } from "../Routes";
import styles from "./LeftMenu.module.css";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import { AlignLeftOutlined, MenuOutlined } from "@ant-design/icons";
import { useState } from "react";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { BookingStepEnum, TourStepEnum } from "common/define-types";
import { clearBooking } from "store/slice/BookingSlice";

const { Text } = Typography;
interface SVGProps {
  $filled?: boolean;
  $colorText?: string;
}
const SVG = styled(ReactSVG)<SVGProps>`
  & svg rect {
    stroke: ${(props) => (props.$filled ? "#1677ff" : props.$colorText)};
  }
  & svg ellipse {
    stroke: ${(props) => (props.$filled ? "#1677ff" : props.$colorText)};
  }
  & svg path {
    stroke: ${(props) => (props.$filled ? "#1677ff" : props.$colorText)};
  }
`;
interface IProps {
  routes: RouteObj[];
}
export const LeftMenu = ({ routes }: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatchRoot();
  const [isCollapse, setIsCollapse] = useState(false);
  const { width } = useWindowDimensions();
  const { tourStep, bookingStep } = useSelectorRoot((state) => state.booking);
  const [modal, contextHolder] = Modal.useModal();

  const navigate = ({ key }: any) => {
    const successNavigate = () => {
      history.push(key);
      if (width < 1000) setIsCollapse(true);
    };
    if (location.pathname.startsWith(`/tours`)) {
      if (
        tourStep === TourStepEnum.DETAILS &&
        (bookingStep === BookingStepEnum.BOOKING_DETAIL ||
          bookingStep === BookingStepEnum.PAYMENT)
      ) {
        modal.confirm({
          title: "Xác nhận rời khỏi trang đặt vé ?",
          content: "Thông tin vé của bạn sẽ không được lưu lại",
          okText: "Đồng ý",
          cancelText: "Không",
          onCancel: () => {},
          onOk: successNavigate,
        });
      } else {
        successNavigate();
      }
    } else {
      if (key.startsWith(`/tours`)) {
        dispatch(clearBooking());
      }
      successNavigate();
    }
  };
  const activeKey = (): string => {
    for (var route of routes) {
      if (location.pathname.startsWith(`${route.routeProps?.path as string}`)) {
        return route.routeProps?.path as string;
      }
      for (var child of route.items ?? []) {
        if (
          location.pathname.startsWith(`${child.routeProps?.path as string}`)
        ) {
          return child.routeProps?.path as string;
        }
      }
    }
    return "/";
  };
  const activeGroupKey = (): string => {
    for (var route of routes) {
      if (location.pathname.startsWith(`${route.routeProps?.path as string}`)) {
        console.log(route.routeProps?.path);
        return route.routeProps?.path as string;
      }
      for (var child of route.items ?? []) {
        if (
          location.pathname.startsWith(`${child.routeProps?.path as string}`)
        ) {
          console.log(route.routeProps?.path);
          return route.routeProps?.path as string;
        }
      }
    }
    return "/";
  };

  const rederItem = (route: RouteObj) =>
    route.isRoot ? (
      <Menu.Item
        key={route.routeProps ? (route.routeProps.path as string) : undefined}
        icon={route.icon ? <img src={route.icon} alt="icon" /> : undefined}
      >
        <Text
          style={{
            color:
              activeKey() === (route.routeProps?.path as string)
                ? "#0046D1"
                : route.color,
          }}
          strong
        >
          {route.label}
        </Text>
      </Menu.Item>
    ) : (
      route.items &&
      route.items.length > 0 && (
        <Menu.SubMenu
          key={route.routeProps ? (route.routeProps.path as string) : undefined}
          icon={
            route.icon ? (
              <SVG
                src={route.icon}
                $filled={activeGroupKey() === route.routeProps?.path}
                $colorText={route.color ?? "#000"}
              ></SVG>
            ) : undefined
          }
          title={<Text strong>{route.label}</Text>}
        >
          {route.items &&
            route.items.map((child) => (
              <Menu.Item
                key={
                  child.routeProps
                    ? (child.routeProps.path as string)
                    : undefined
                }
                icon={
                  child.icon ? (
                    <img src={child.icon} alt="icon"></img>
                  ) : undefined
                }
              >
                <Text
                  style={{
                    color:
                      activeKey() === (child.routeProps?.path as string)
                        ? "#0046D1"
                        : child.color,
                  }}
                >
                  {child.label}
                </Text>
              </Menu.Item>
            ))}
        </Menu.SubMenu>
      )
    );

  const render = () => (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingTop: 4,
          position: "relative",
          width: "100%",
        }}
      >
        {/* {isCollapse ? (
          <Button
            onClick={() => setIsCollapse(false)}
            className={styles.collapseBtn}
            icon={<MenuOutlined />}
          />
        ) : ( */}
        <Button
          onClick={() => setIsCollapse(true)}
          type="text"
          icon={<AlignLeftOutlined rotate={180} />}
        />
        {/* )} */}
      </div>
      <Menu
        onSelect={navigate}
        className={`${styles.LeftMenu} leftMenu`}
        style={{ display: isCollapse ? "none" : "block" }}
        selectedKeys={[activeKey()]}
        mode="inline"
      >
        {routes.map((route) => rederItem(route))}
      </Menu>
    </>
  );

  return (
    <>
      {isCollapse && (
        <Button
          onClick={() => setIsCollapse(false)}
          className={styles.collapseBtn}
          icon={<MenuOutlined />}
        />
      )}
      <Layout.Sider
        width={215}
        collapsedWidth={0}
        collapsed={isCollapse}
        className={`${styles.sider} customScroll`}
        breakpoint="sm"
        trigger={null}
      >
        {render()}
      </Layout.Sider>
      {contextHolder}
    </>
  );
};
