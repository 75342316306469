import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Utils from "common/Utils";

Chart.register(ChartDataLabels);
export interface IPieData {
  title?: string;
  data: {
    label: string;
    value: number;
    bgColor?: string;
  }[];
}
interface IProps {
  pieData: IPieData;
  showTotal?: boolean;
}
export const PieChart = ({ pieData, showTotal = true }: IProps) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      key={pieData.title}
    >
      <Pie
        id={pieData.title}
        data={{
          labels: pieData.data.map((item) => item.label),
          datasets: [
            {
              data: pieData.data.map((item) => item.value),
              backgroundColor: pieData.data.map((item) => item.bgColor),
              borderWidth(ctx) {
                if (ctx.dataset.data[ctx.dataIndex] === 0) return 0;
                let total = 0;
                const data = ctx.dataset.data;
                data.forEach((item) => {
                  if (typeof item === "number") total += item;
                });
                return ctx.dataset.data[ctx.dataIndex] / total === 1 ? 0 : 1;
              },
            },
          ],
        }}
        options={{
          plugins: {
            title: {
              display: !!pieData.title,
              text: `${pieData.title} ${showTotal && Utils.formatCurrency(pieData.data.reduce((prev, currentItem) => prev + currentItem.value, 0))}` ?? "",
              font: (ctx) => ({ size: 16 }),
            },
            legend: {
              align: "start",
              fullSize: false,
              labels: {
                boxWidth: 50,
                font: {
                  size: 13,
                },
                generateLabels(chart) {
                  const data = chart.data;
                  const legend = chart.legend;
                  if (
                    data &&
                    legend &&
                    data.labels?.length &&
                    data.datasets.length
                  ) {
                    const {
                      labels: { pointStyle, color },
                    } = legend.options;

                    return data.labels.map((label: any, i: number) => {
                      const meta = chart.getDatasetMeta(0);
                      const style = meta.controller.getStyle(i, false);
                      const dataSet = meta.controller.getDataset();
                      const dataItem: any =
                        typeof dataSet.data[i] === "number"
                          ? dataSet.data[i]
                          : 0;

                      return {
                        text: `${label}: ${Utils.formatCurrency(dataItem)}`,
                        fillStyle: style.backgroundColor,
                        strokeStyle: style.borderColor,
                        fontColor: color,
                        lineWidth: style.borderWidth,
                        pointStyle: pointStyle,
                        hidden: !chart.getDataVisibility(i),

                        // Extra data used for toggling the correct item
                        index: i,
                      };
                    });
                  }
                  return [];
                },
              },
              onClick: () => {},
            },
            datalabels: {
              color: "white",
              formatter(value, context) {
                if (value === 0) return "";
                let total = 0;
                const data = context.dataset.data;
                data.forEach((item) => {
                  if (typeof item === "number") total += item;
                });
                return `${((value / total) * 100).toFixed(1)} %`;
              },
              clamp: true,
            },
          },
          responsive: true,
        }}
      />
    </div>
  );
};
