import SYSTEM_CONSTANTS from 'common/constants';
import HttpClient from './http-client';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AjaxError} from 'rxjs/ajax';
import { INightBookingFilterValue } from 'common/define-types';
import Utils from 'common/Utils';

const api = `${SYSTEM_CONSTANTS.API_URL}/NightAudit`;

export const getNightAuditGroupById = (groupId: string): Observable<any> => {
    return HttpClient.get(`${api}/nightAudit/groups/${groupId}`).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};

export const confirmNightAuditGroup = (groupId: string, body: IConfirmAuditGroup): Observable<any> => {
    return HttpClient.put(`${api}/nightAudit/groups/${groupId}/confirm`, JSON.stringify(body), {
        headers: {
            'Content-Type': 'application/json',
        },
    }).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};
export const getNightAuditBooking = (params?: INightBookingFilterValue): Observable<any> => {
    return HttpClient.get(`${api}/booking?${Utils.getParamsString(params || {})}`).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};
export const getNightAuditBookingSummarize = (): Observable<any> => {
    return HttpClient.get(`${api}/booking/summarize`).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};
export const confirmNightAuditBooking = (): Observable<any> => {
    return HttpClient.post(`${api}/booking`, JSON.stringify({}), {
        headers: {
            'Content-Type': 'application/json',
        },
    }).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};
export const getAvailabelChangeBusinessDate = (): Observable<any> => {
    return HttpClient.get(`${api}/availabelChangeBusinessDate`).pipe(
        map(
            res => res,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};
