import {
  IGroupTicket,
  IJobTicket,
  JobTicketStatusEnum,
  JobTicketType,
} from "common/define-types";
import styles from "../Operation.module.css";
import { Checkbox, Divider, Typography } from "antd";
import DriverIcon from "image/icon/driver-icon-green.svg";
import MotorbikeIcon from "image/icon/motorbike-icon-blue.svg";
import DisabledDriverIcon from "image/icon/driver-icon-grey.svg";
import DisabledMotorbikeIcon from "image/icon/motorbike-icon-grey.svg";
import { PhoneOutlined } from "@ant-design/icons";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { checkJobTickets, uncheckJobTickets } from "store/slice/GroupSlice";
import { useMemo } from "react";

interface IProps {
  ticket: IJobTicket;
  isCancelTour?: boolean;
  listHasJobTicketDriver?: boolean;
  listHasJobTicketMotor?: boolean;
  isMissingTourLead?: boolean;
}
export const Customer = ({
  ticket,
  isCancelTour = false,
  listHasJobTicketDriver = false,
  listHasJobTicketMotor = false,
  isMissingTourLead = false,
}: IProps) => {
  const selectGroupTickets = useSelectorRoot(
    (state) => state.group.groupTickets
  );
  const ticketInfor: IGroupTicket | null =
    selectGroupTickets.filter((gTicket) => gTicket.id === ticket.ticketId)
      .length > 0
      ? selectGroupTickets.filter(
          (gTicket) => gTicket.id === ticket.ticketId
        )[0]
      : selectGroupTickets.length > 0
      ? selectGroupTickets[0]
      : null;
  const selectDrivers = useSelectorRoot((state) => state.group.allDrivers);
  const selectMotorbikes = useSelectorRoot(
    (state) => state.group.allMotorbikes
  );
  const selectAdditionalMotorbikes = useSelectorRoot(
    (state) => state.motorbike.additionalMotor
  );
  const allMotorOptions = useMemo(() => {
    return [...selectMotorbikes, ...selectAdditionalMotorbikes];
  }, [selectMotorbikes, selectAdditionalMotorbikes]);
  const selectJobTickets = useSelectorRoot((state) => state.group.jobTickets);
  const dispatch = useDispatchRoot();

  const handleCheckJobTicket = (
    e: CheckboxChangeEvent,
    jobTicketType: JobTicketType
  ) => {
    const foundJobTicket = selectJobTickets.find(
      (jTicket) =>
        jTicket.ticketId === ticket.ticketId && jTicket.type === jobTicketType
    );
    if (foundJobTicket) {
      if (e.target.checked) {
        dispatch(checkJobTickets([foundJobTicket]));
      } else {
        dispatch(uncheckJobTickets([foundJobTicket]));
      }
    }
  };

  const renderCheckbox = (jobTicketType: JobTicketType) => {
    const foundJobTicket = selectJobTickets.find(
      (jTicket) =>
        jTicket.ticketId === ticket.ticketId && jTicket.type === jobTicketType
    );
    if (jobTicketType !== JobTicketType.Tickets) {
      if (!foundJobTicket) {
        return (
          <div className={styles.checkboxItem}>
            <Checkbox style={{ opacity: "0", pointerEvents: "none" }} />
          </div>
        );
      }
    }
    return (
      <div className={styles.checkboxItem}>
        <Checkbox
          className="strongBorder"
          checked={foundJobTicket?.isCheck}
          onChange={(e: CheckboxChangeEvent) =>
            handleCheckJobTicket(e, jobTicketType)
          }
          disabled={
            isCancelTour ||
            isMissingTourLead ||
            foundJobTicket?.status === JobTicketStatusEnum.BREAKUPTOUR
          }
        />
      </div>
    );
  };

  return (
    <div
      className={`${styles.customerContainer} ${
        isCancelTour ? styles.disabled : isMissingTourLead ? styles.warning : ""
      }`}
    >
      <div className={styles.customerWrapper}>
        <div className={styles.customerRow}>
          <Typography.Text
            strong
            className={styles.customerEvenColumn}
            style={{ textTransform: "capitalize" }}
            title={(ticketInfor?.fullName ?? "Trống").toLowerCase()}
          >
            {`${(ticketInfor?.fullName ?? "Trống").toLowerCase()}`}
          </Typography.Text>
          <div className={styles.inforWithIcon}>
            <PhoneOutlined
              rotate={90}
              style={{
                fontSize: 20,
                color: isCancelTour ? "#BFBFBF" : "#FBBC04",
              }}
            />
            <span
              className={styles.textEllipsis}
              title={ticketInfor?.phone ?? ""}
            >
              {ticketInfor?.phone ?? "Trống"}
            </span>
          </div>
        </div>
        <Divider style={{ marginBlock: 8 }} />
        <div className={styles.customerRow}>
          <div className={styles.inforWithIcon}>
            <img
              src={isCancelTour ? DisabledDriverIcon : DriverIcon}
              alt="driver"
            />
            <span className={styles.textEllipsis}>
              {ticket.driverId === undefined
                ? "Tự lái"
                : ticket.driverId === null
                ? "Trống"
                : selectDrivers.filter(
                    (driver) => driver.id === ticket.driverId
                  ).length > 0
                ? selectDrivers.filter(
                    (driver) => driver.id === ticket.driverId
                  )[0].name
                : "Trống"}
            </span>
          </div>
          <div className={styles.inforWithIcon}>
            <img
              src={isCancelTour ? DisabledMotorbikeIcon : MotorbikeIcon}
              alt="motorbike"
            />
            <span className={styles.textEllipsis}>
              {!ticket.motorbikeId
                ? "Xe riêng của khách"
                : allMotorOptions.filter(
                    (motorbike) => motorbike.id === ticket.motorbikeId
                  ).length > 0
                ? allMotorOptions.filter(
                    (motorbike) => motorbike.id === ticket.motorbikeId
                  )[0].motorNumber
                : "Trống"}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.checkboxContainer}>
        {renderCheckbox(ticket.type)}
        {listHasJobTicketDriver && renderCheckbox(JobTicketType.Driver)}
        {listHasJobTicketMotor && renderCheckbox(JobTicketType.Motor)}
      </div>
    </div>
  );
};
