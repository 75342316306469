import { Layout } from "antd";
import { Route, Switch, useLocation } from "react-router-dom";
import { Main } from "./main/Main";
import { Login } from "./Login";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useEffect } from "react";

export const Root = () => {
  const { outerWidth } = useWindowDimensions();
  const location = useLocation();
  // For some reason Safari on iOS returns 0 for window.outerHeight property and window.outerWidth property.

  useEffect(() => {
    //[1-12-2023] [Phuc Thinh]
    //Add drag scroll for class horizontalDragScroll
    const addEventForEle = (ele: HTMLElement) => {
      ele.style.cursor = "grab";

      let pos = { top: 0, left: 0, x: 0, y: 0 };

      const mouseDownHandler = function (e: any) {
        ele.style.cursor = "grabbing";
        ele.style.userSelect = "none";

        pos = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY,
        };

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      };

      const mouseMoveHandler = function (e: any) {
        // How far the mouse has been moved
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        // Scroll the element
        ele.scrollTop = pos.top - dy;
        ele.scrollLeft = pos.left - dx;
      };

      const mouseUpHandler = function () {
        ele.style.cursor = "grab";
        ele.style.removeProperty("user-select");

        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
      };

      // Attach the handler
      ele.addEventListener("mousedown", mouseDownHandler);
    };
    const scrollTables = Array.from(
      document.getElementsByClassName(
        "horizontalDragScroll"
      ) as HTMLCollectionOf<HTMLElement>
    );
    scrollTables.forEach((table) => {
      if (table) {
        let ele: HTMLElement | null = table.querySelector(".ant-table-content");
        let ele2: HTMLElement | null = table.querySelector(".ant-table-body");
        if (ele) {
          addEventForEle(ele);
        }
        if (ele2) {
          addEventForEle(ele2);
        }
      }
    });
  }, [location]);

  return (
    <Layout
      style={{
        height: outerWidth === 0 ? "100lvh" : "100dvh",
        width: "100vw",
        overflow: outerWidth === 0 ? "auto" : "hidden",
      }}
    >
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/" component={Main} />
      </Switch>
    </Layout>
  );
};
