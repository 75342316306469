import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { groupsData, ticketsData } from 'DummyData/groups';
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import {
  GroupStatusEnum,
  IDriver,
  IDriversResult,
  IDriverTicket,
  IGroup,
  IGroupJob,
  IGroupsResult,
  IGroupTicket,
  IGroupTicketsParams,
  IGroupTicketsResult,
  IIssuesResult,
  IJob,
  IJobTicket,
  IMotorbike,
  IMotorbikesResult,
  IMotorbikeTicket,
  IParams,
  ISaveDriverTicket,
  ISaveMotorbikeTicket,
  ISelectJob,
  IssueStatus,
  IssueType,
  JobDateEnum,
  JobTicketStatusEnum,
  RootEpic,
} from "common/define-types";
import {
  assignGroupIdForTickets,
  createGroup,
  deleteGroupById,
  getAllGroups,
  getDriversByGroupId,
  getGroupById,
  getIssuesByGroupId,
  getMotorbikesByGroupId,
  turnOnGroup,
  unassignTicketOnGroupId,
  updateGroup,
} from "api/group.api";
import { AjaxError } from "rxjs/ajax";
import { getTicketsByGroupId, updateJobTicket } from "api/ticket.api";
import { ICreateGroup, IUpdateGroup } from "api/types/group";
import moment from "moment";
import { SegmentedValue } from "antd/es/segmented";
import {
  approveIssueByIds,
  createIssue,
  rejectIssueByIds,
  updateIssue,
} from "api/issue.api";
import { createDriverTicket, updateDriverTicket } from "api/driverTicket.api";
import {
  createMotorbikeTicket,
  updateMotorbikeTicket,
} from "api/motorbikeTicket.api";
import { IIssue, ICreateIssue, IUpdateIssue } from "api/types/issue";
import Utils from "common/Utils";
import { getAllDrivers } from "api/driver.api";
import { getAllMotorbikes } from "api/motorbike.api";

export interface GroupState {
  isLoading: boolean;
  isGroupDataLoading: boolean;
  isSubmitting: boolean;
  isSubmittingIsuseId: string | null;
  isCreatingIssue: boolean;
  isIssueCreated: boolean;
  isApprovingIssue: boolean;
  isRejectingIssue: boolean;
  isJobTicketSubmitting: boolean;
  isTicketLoading: boolean;
  isJobLoading: boolean;
  isIssueLoading: boolean;
  isJobTicketsLoading: boolean;
  isOnlyStartDate: boolean;
  groupsResult: IGroupsResult | null;
  groupTicketsResult: IGroupTicketsResult | null;
  groups: IGroup[];
  groupsIndex: number[];
  groupTickets: IGroupTicket[];
  groupSelected: IGroup | null;
  groupTicketsSelected: IGroupTicket[] | [];
  unGroupTicketCount: number;
  errMsg: string | null;
  operationErrMsg: string | null;
  issueErrMsg: string | null;
  filterDate: moment.Moment | null;
  deletingGroupId: string | null;
  jobTours: IGroupJob[] | [];
  jobGroupSelected: IGroupJob | null;
  jobTourSelected: IJob | null;
  jobTickets: IJobTicket[] | [];
  jobDateSelected: JobDateEnum | SegmentedValue;
  groupIssue: IIssue[];
  groupDrivers: IDriver[];
  groupMotorbikes: IMotorbike[];
  issuesResult: IIssuesResult | null;
  driversResult: IDriversResult | null;
  motorbikesResult: IMotorbikesResult | null;
  detailViewingIssue: IIssue | null;
  selectedDate: string;
  groupStartingId: string | null;
  groupSelectedCount: any;
  allMotorbikes: IMotorbike[];
  allDrivers: IDriver[];
}
export interface IUpdateGroupTicket {
  tickets: IGroupTicket[];
  group: IGroup;
}

const initialState: GroupState = {
  isLoading: false,
  isGroupDataLoading: false,
  isSubmitting: false,
  isSubmittingIsuseId: null,
  isJobTicketSubmitting: false,
  isCreatingIssue: false,
  isIssueCreated: false,
  isApprovingIssue: false,
  isRejectingIssue: false,
  isTicketLoading: false,
  isJobLoading: false,
  isIssueLoading: false,
  isJobTicketsLoading: false,
  isOnlyStartDate: true,
  errMsg: null,
  operationErrMsg: null,
  issueErrMsg: null,
  groupsResult: null,
  groupTicketsResult: null,
  groups: [],
  groupsIndex: [],
  groupTickets: [],
  groupSelected: null,
  groupTicketsSelected: [],
  unGroupTicketCount: 0,
  filterDate: null,
  deletingGroupId: null,
  jobTours: [],
  jobGroupSelected: null,
  jobTourSelected: null,
  jobTickets: [],
  jobDateSelected: JobDateEnum.ALL,
  groupIssue: [],
  issuesResult: null,
  groupDrivers: [],
  driversResult: null,
  motorbikesResult: null,
  groupMotorbikes: [],
  detailViewingIssue: null,
  selectedDate: new Date().toISOString(),
  groupStartingId: null,
  groupSelectedCount: {
    countTicket: 0,
    countDriver: 0,
    countMotor: 0,
  },
  allDrivers: [],
  allMotorbikes: [],
};

export const groupSlice = createSlice({
  name: "group",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchGroupData: (state, action: PayloadAction<IParams | undefined>) => {
      state.isLoading = true;
      state.errMsg = null;
    },
    fetchGroupTickets: (
      state,
      action: PayloadAction<IGroupTicketsParams | undefined>
    ) => {
      state.isTicketLoading = true;
      state.errMsg = null;
    },
    fetchAdditionalGroupData: (state, action: PayloadAction<IGroup>) => {
      state.isGroupDataLoading = true;
      state.errMsg = null;
    },
    createNewGroup: (state, action: PayloadAction<ICreateGroup>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    createGroupAndAssignTicket: (
      state,
      action: PayloadAction<ICreateGroup>
    ) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    deleteGroup: (state, action: PayloadAction<string>) => {
      state.deletingGroupId = action.payload;
    },
    deleteGroupFullfilled: (state) => {
      state.deletingGroupId = null;
      state.errMsg = null;
    },
    setGroups: (state, action: PayloadAction<IGroup[]>) => {
      state.groups = [...action.payload];
      state.groupsIndex = [
        ...action.payload.map((group) => Utils.getIndexInGroupName(group.name)),
      ];
      state.isLoading = false;
      state.errMsg = null;
    },
    setGroupsTickets: (state, action: PayloadAction<IGroupTicket[]>) => {
      if (action.payload) {
        state.groupTickets = [...action.payload];
      }
      state.isTicketLoading = false;
      state.errMsg = null;
    },
    setGroupsResult: (state, action: PayloadAction<IGroupsResult>) => {
      state.groupsResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    setGroupTicketsResult: (
      state,
      action: PayloadAction<IGroupTicketsResult>
    ) => {
      state.groupTicketsResult = action.payload;
      state.isTicketLoading = false;
      state.errMsg = null;
    },
    setFilterDate: (state, action: PayloadAction<moment.Moment | null>) => {
      state.filterDate = action.payload;
      state.isLoading = true;
    },
    setJobTours: (state, action: PayloadAction<IGroupJob[]>) => {
      state.jobTours = action.payload;
      state.isJobLoading = false;
      state.isJobTicketSubmitting = false;
    },
    setGroupDrivers: (state, action: PayloadAction<IDriver[]>) => {
      state.groupDrivers = action.payload;
      state.errMsg = null;
      state.isGroupDataLoading = false;
    },
    setGroupMotorbikes: (state, action: PayloadAction<IMotorbike[]>) => {
      state.groupMotorbikes = action.payload;
      state.errMsg = null;
      state.isGroupDataLoading = false;
    },
    setDriversResult: (state, action: PayloadAction<IDriversResult>) => {
      state.driversResult = action.payload;
      state.isGroupDataLoading = false;
      state.errMsg = null;
    },
    setMotorbikesResult: (state, action: PayloadAction<IMotorbikesResult>) => {
      state.motorbikesResult = action.payload;
      state.isGroupDataLoading = false;
      state.errMsg = null;
    },
    addGroups: (state, action: PayloadAction<IGroup>) => {
      state.groups = [...state.groups, action.payload];
      state.isSubmitting = false;
    },
    selectGroup: (state, action: PayloadAction<IGroup | null>) => {
      if (action.payload) {
        state.isJobLoading = true;
        state.isIssueLoading = true;
        state.isJobTicketsLoading = true;
      }
      state.groupSelected = action.payload;
      state.groupTicketsSelected = [];
      state.isTicketLoading = true;
      state.errMsg = null;
      state.operationErrMsg = null;
      state.isSubmitting = false;
    },
    startGroup: (state, action: PayloadAction<string>) => {
      state.groupStartingId = action.payload;
      state.errMsg = null;
      state.operationErrMsg = null;
    },
    startGroupSuccessfully: (state, action: PayloadAction<IGroup>) => {
      state.errMsg = null;
      state.operationErrMsg = null;
      state.groupStartingId = null;
      const newGroup = action.payload;
      if (state.groupSelected && state.groupSelected.id === newGroup.id) {
        state.groupSelected = {
          ...state.groupSelected,
          status: newGroup.status,
        };
      }
      state.groups = state.groups.map((group) =>
        group.id === newGroup.id
          ? {
              ...group,
              status: newGroup.status,
            }
          : group
      );
    },
    checkAllTickets: (state, action: PayloadAction<IGroupTicket[] | []>) => {
      state.groupTicketsSelected = [...action.payload];
    },
    checkTickets: (state, action: PayloadAction<IGroupTicket[] | []>) => {
      const groupTicketsSelected = state.groupTicketsSelected;
      const filteredPayload = action.payload.filter(
        (ticket) =>
          !groupTicketsSelected.some((gTicket) => ticket.id === gTicket.id)
      );
      state.groupTicketsSelected = [
        ...groupTicketsSelected,
        ...filteredPayload,
      ];
    },
    uncheckTickets: (state, action: PayloadAction<IGroupTicket[] | []>) => {
      const groupTicketsSelected = state.groupTicketsSelected;
      const newGroupTicketsSelected = groupTicketsSelected.filter(
        (gTicket) => !action.payload.some((ticket) => ticket.id === gTicket.id)
      );
      state.groupTicketsSelected = newGroupTicketsSelected;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.isLoading = false;
      state.isSubmitting = false;
      state.isTicketLoading = false;
      state.deletingGroupId = null;
    },
    setOperationErrMsg: (state, action: PayloadAction<string | null>) => {
      state.operationErrMsg = action.payload;
      state.isJobLoading = false;
      state.isIssueLoading = false;
      state.isJobTicketsLoading = false;
      state.deletingGroupId = null;
      state.isJobTicketSubmitting = false;
    },
    setGroupForTickets: (state, action: PayloadAction<IUpdateGroupTicket>) => {
      state.isTicketLoading = true;
      state.isLoading = true;
    },
    removeTicketFromGroup: (
      state,
      action: PayloadAction<{ ticket: IGroupTicket; group: IGroup }>
    ) => {
      state.isTicketLoading = true;
    },
    setJobDateSelected: (
      state,
      action: PayloadAction<JobDateEnum | SegmentedValue>
    ) => {
      state.jobDateSelected = action.payload;
    },
    setUnGroupTicketsCount: (state, action: PayloadAction<number>) => {
      state.unGroupTicketCount = action.payload;
    },
    setJobTourSelected: (state, action: PayloadAction<ISelectJob>) => {
      state.isJobTicketsLoading = true;
      state.jobGroupSelected = action.payload.jobGroup;
      state.jobTourSelected = action.payload.jobTour;
      state.errMsg = null;
    },
    setJobTickets: (state, action: PayloadAction<IJobTicket[]>) => {
      state.isJobTicketsLoading = false;
      state.isJobTicketSubmitting = false;
      state.errMsg = null;
      state.jobTickets = action.payload;
    },
    saveGroup: (state, action: PayloadAction<IUpdateGroup>) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    saveJobTickets: (state, action: PayloadAction<IJobTicket[]>) => {
      state.isJobTicketSubmitting = true;
    },
    checkJobTickets: (state, action: PayloadAction<IJobTicket[]>) => {
      if (state.jobTourSelected) {
        const jobTickets = state.jobTickets;
        const newJobTickets: IJobTicket[] = jobTickets?.map((jobTicket) => ({
          ...jobTicket,
          isCheck:
            jobTicket.status !== JobTicketStatusEnum.BREAKUPTOUR &&
            action.payload.some((jTicket) => jTicket.id === jobTicket.id)
              ? true
              : jobTicket.isCheck,
        }));
        state.jobTickets = newJobTickets;
      }
    },
    uncheckJobTickets: (state, action: PayloadAction<IJobTicket[]>) => {
      if (state.jobTourSelected) {
        const jobTickets = state.jobTickets;
        const newJobTickets: IJobTicket[] = jobTickets?.map((jobTicket) => ({
          ...jobTicket,
          isCheck:
            jobTicket.status !== JobTicketStatusEnum.BREAKUPTOUR &&
            action.payload.some((jTicket) => jTicket.id === jobTicket.id)
              ? false
              : jobTicket.isCheck,
        }));
        state.jobTickets = newJobTickets;
      }
    },
    createNewIssue: (state, action: PayloadAction<ICreateIssue>) => {
      state.isCreatingIssue = true;
      state.isIssueCreated = false;
      state.errMsg = null;
    },
    saveDriverTicket: (state, action: PayloadAction<ISaveDriverTicket>) => {
      state.errMsg = null;
    },
    saveMotorbikeTicket: (
      state,
      action: PayloadAction<ISaveMotorbikeTicket>
    ) => {
      state.errMsg = null;
    },
    setDriverTicketByTicketId: (
      state,
      action: PayloadAction<{ ticketId: string; dTickets: IDriverTicket[] }>
    ) => {
      const ticketId = action.payload.ticketId;
      const dTickets = action.payload.dTickets;
      state.groupTickets = state.groupTickets.map((gTicket) =>
        gTicket.id === ticketId
          ? {
              ...gTicket,
              driver_Tickets: dTickets,
            }
          : gTicket
      );
    },
    setMotorbikeTicketByTicketId: (
      state,
      action: PayloadAction<{ ticketId: string; mTickets: IMotorbikeTicket[] }>
    ) => {
      const ticketId = action.payload.ticketId;
      const mTickets = action.payload.mTickets;
      state.groupTickets = state.groupTickets.map((mTicket) =>
        mTicket.id === ticketId
          ? {
              ...mTicket,
              motorbike_Ticket: mTickets,
            }
          : mTicket
      );
    },
    // deleteDriverTicket: (state, action: PayloadAction<{ticketId: string, driverTicketId: string}>) => {
    //     state.errMsg = null;
    // },
    // deleteMotorbikeTicket: (state, action: PayloadAction<{ticketId: string, motorbikeTicketId: string}>) => {
    //     state.errMsg = null;
    // },
    createNewIssueFullfilled: (state, action: PayloadAction<IIssue>) => {
      state.isCreatingIssue = false;
      state.isIssueCreated = true;
      state.errMsg = null;
      state.groupIssue = [...state.groupIssue, action.payload];
    },
    createNewIssueFailed: (state, action: PayloadAction<string | null>) => {
      state.isCreatingIssue = false;
      state.isIssueCreated = false;
      state.issueErrMsg = action.payload;
    },
    setIssues: (state, action: PayloadAction<IIssue[]>) => {
      state.groupIssue = action.payload;
      state.isIssueLoading = false;
    },
    setDetailViewingIssue: (state, action: PayloadAction<IIssue | null>) => {
      state.detailViewingIssue = action.payload;
    },
    setIssuesResult: (state, action: PayloadAction<IIssuesResult>) => {
      state.issuesResult = action.payload;
    },
    saveIssue: (state, action: PayloadAction<IUpdateIssue>) => {
      state.isSubmittingIsuseId = action.payload.issueId;
      state.issueErrMsg = null;
    },
    approveIssue: (state, action: PayloadAction<string>) => {
      state.isApprovingIssue = true;
      state.issueErrMsg = null;
    },
    rejectIssue: (state, action: PayloadAction<string>) => {
      state.isRejectingIssue = true;
      state.issueErrMsg = null;
    },
    setIssueById: (state, action: PayloadAction<IIssue>) => {
      const newIssue = action.payload;
      state.groupIssue = state.groupIssue.map((issue) =>
        issue.id === newIssue.id ? newIssue : issue
      );
      state.isSubmittingIsuseId = null;
      state.isApprovingIssue = false;
      state.isRejectingIssue = false;
    },
    setIssueByIds: (state, action: PayloadAction<IIssue[]>) => {
      const newIssues = action.payload;
      state.groupIssue = state.groupIssue.map((issue) =>
        newIssues.filter((nIssue) => nIssue.id === issue.id).length > 0
          ? newIssues.filter((nIssue) => nIssue.id === issue.id)[0]
          : issue
      );
      state.isSubmittingIsuseId = null;
      state.isApprovingIssue = false;
      state.isRejectingIssue = false;
    },
    setSelectedDate: (state, action: PayloadAction<string>) => {
      state.selectedDate = action.payload;
    },
    setIsOnlyStartDate: (state, action: PayloadAction<boolean>) => {
      state.isOnlyStartDate = action.payload;
    },
    saveIssueFailed: (state, action: PayloadAction<string | null>) => {
      state.isSubmittingIsuseId = null;
      state.issueErrMsg = action.payload;
    },
    resetIssueState: (state) => {
      state.isCreatingIssue = false;
      state.isIssueCreated = false;
      state.issueErrMsg = null;
    },
    setGroupSelectedCount: (state, action: PayloadAction<any>) => {
      state.groupSelectedCount = {
        ...state.groupSelectedCount,
        ...action.payload,
      };
    },
    fetchUngroupTickets: (state, action: PayloadAction) => {},
    fetchAllDrivers: (state, action: PayloadAction<IParams>) => {},
    fetchAllMotorbikes: (state, action: PayloadAction<IParams>) => {},
    setAllDrivers: (state, action: PayloadAction<IDriver[]>) => {
      state.allDrivers = action.payload;
    },
    setAllMotorbikes: (state, action: PayloadAction<IMotorbike[]>) => {
      state.allMotorbikes = action.payload;
    },
  },
});

const fetchGroup$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchGroupData.match || setFilterDate.match),
    switchMap((re) => {
      return getAllGroups({
        ...re.payload,
        isOnlyStartDate: state$.value.group.isOnlyStartDate,
        startDate: moment(state$.value.group.selectedDate)
          .startOf("day")
          .add(7, "hours")
          .toISOString(),
        endDate: moment(state$.value.group.selectedDate)
          .endOf("day")
          .add(7, "hours")
          .toISOString(),
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const groupsResult = res;
            const groupSelected = state$.value.group.groupSelected;
            const isOnlyStartDate = state$.value.group.isOnlyStartDate;
            const actionsWhenGetGroupSuccess = [
              groupSlice.actions.setGroups(groupsResult.results),
              groupSlice.actions.setGroupsResult(groupsResult),
            ];
            if (isOnlyStartDate) {
              if (
                moment(state$.value.group.selectedDate)
                  .endOf("day")
                  .isBefore(moment())
              ) {
                if (groupsResult.results.length > 0) {
                  if (
                    !!groupSelected &&
                    groupsResult.results.find(
                      (group: IGroup) => group.id === groupSelected.id
                    )
                  )
                    return [
                      ...actionsWhenGetGroupSuccess,
                      groupSlice.actions.selectGroup(groupSelected),
                    ];
                  return [
                    ...actionsWhenGetGroupSuccess,
                    groupSlice.actions.selectGroup(groupsResult.results[0]),
                  ];
                } else {
                  return [
                    ...actionsWhenGetGroupSuccess,
                    groupSlice.actions.setGroupsTickets([]),
                  ];
                }
              } else {
                if (groupsResult.results.length > 0) {
                  if (groupSelected === null) {
                    return [
                      ...actionsWhenGetGroupSuccess,
                      groupSlice.actions.selectGroup(null),
                    ];
                  } else {
                    if (
                      !!groupSelected &&
                      groupsResult.results.find(
                        (group: IGroup) => group.id === groupSelected.id
                      )
                    )
                      return [
                        ...actionsWhenGetGroupSuccess,
                        groupSlice.actions.selectGroup(groupSelected),
                      ];
                    return [
                      ...actionsWhenGetGroupSuccess,
                      groupSlice.actions.selectGroup(groupsResult.results[0]),
                    ];
                  }
                } else {
                  return [
                    ...actionsWhenGetGroupSuccess,
                    groupSlice.actions.selectGroup(null),
                  ];
                }
              }
            } else {
              if (groupsResult.results.length > 0) {
                if (
                  !!groupSelected &&
                  groupsResult.results.find(
                    (group: IGroup) => group.id === groupSelected.id
                  )
                )
                  return [
                    ...actionsWhenGetGroupSuccess,
                    groupSlice.actions.selectGroup(groupSelected),
                  ];
                else {
                  return [
                    ...actionsWhenGetGroupSuccess,
                    groupSlice.actions.selectGroup(groupsResult.results[0]),
                  ];
                }
              } else {
                return [
                  ...actionsWhenGetGroupSuccess,
                  groupSlice.actions.setGroupsTickets([]),
                  groupSlice.actions.selectGroup(null),
                  groupSlice.actions.setJobTours([]),
                  groupSlice.actions.setJobTickets([]),
                ];
              }
            }
          } else {
            return [groupSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách groups"
          ),
        ])
      );
    })
  );
const fetchGroupTickets$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchGroupTickets.match),
    switchMap((re) => {
      if (
        moment(state$.value.group.selectedDate)
          .endOf("day")
          .isBefore(moment()) &&
        !state$.value.group.groupSelected
      ) {
        return [groupSlice.actions.setGroupsTickets([])];
      }
      return getTicketsByGroupId(
        state$.value.group.groupSelected
          ? state$.value.group.groupSelected.id
          : null,
        {
          ...re.payload,
          startDate: moment(state$.value.group.selectedDate)
            .startOf("day")
            .toISOString(),
          endDate: moment(state$.value.group.selectedDate)
            .endOf("day")
            .toISOString(),
        }
      ).pipe(
        mergeMap((res: any) => {
          const hasFilterValue =
            !!re.payload &&
            !Utils.isObjectEmpty({
              search: re.payload.search,
              tourId: re.payload.tourId,
              agentId: re.payload.agentId,
            });
          if (res && !res?.response?.error && res.results) {
            const setUnGroupTicketsCountAction = state$.value.group
              .groupSelected
              ? []
              : hasFilterValue
              ? []
              : [groupSlice.actions.setUnGroupTicketsCount(res.queryCount)];
            const groupsTickets = res.results;
            return [
              ...setUnGroupTicketsCountAction,
              groupSlice.actions.setGroupsTickets(groupsTickets ?? []),
              groupSlice.actions.setGroupTicketsResult(res),
            ];
          } else {
            return [groupSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.setErrMsg("Có lỗi xảy ra khi lấy danh sách khách"),
        ])
      );
    })
  );
const fetchWhenSelectDate$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setSelectedDate.match),
    mergeMap((re) => {
      const startDate = moment(re.payload)
        .startOf("day")
        .add(7, "hours")
        .toISOString();
      const endDate = moment(re.payload)
        .endOf("day")
        .add(7, "hours")
        .toISOString();
      return [
        // groupSlice.actions.fetchGroupTickets({
        //     ...state$.value.group.groupTicketsResult,
        //     startDate,
        //     endDate,
        // }),
        groupSlice.actions.fetchGroupData({
          ...state$.value.group.groupsResult,
          startDate,
          endDate,
        }),
      ];
    })
  );
const startGroup$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(startGroup.match),
    mergeMap((re) => {
      return turnOnGroup(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const newGroup = res;
            return [groupSlice.actions.startGroupSuccessfully(newGroup)];
          } else {
            return [groupSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => {
          const errMsg =
            e.status === 412 ||
            e.response?.Message ===
              "The Group không có lái hoặc xe have not to be null"
              ? "Group chứa vé chưa được gán xe hoặc gán xế"
              : "Có lỗi xảy ra khi bắt đầu group";
          return [groupSlice.actions.setErrMsg(errMsg)];
        })
      );
    })
  );
const fetchAdditionalGroupData$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchAdditionalGroupData.match),
    switchMap((re) => {
      return getDriversByGroupId(re.payload.id).pipe(
        mergeMap((driverRes: any) => {
          if (driverRes && !driverRes?.response?.error && driverRes.results) {
            const drivers = driverRes.results;
            const actionsWhenDriverSuccess = [
              groupSlice.actions.setGroupDrivers(drivers ?? []),
              groupSlice.actions.setDriversResult(driverRes),
            ];
            return getMotorbikesByGroupId(re.payload.id).pipe(
              mergeMap((motorbikeRes: any) => {
                if (
                  motorbikeRes &&
                  !motorbikeRes?.response?.error &&
                  motorbikeRes.results
                ) {
                  const motorbikes = motorbikeRes.results;
                  return [
                    ...actionsWhenDriverSuccess,
                    groupSlice.actions.setGroupMotorbikes(motorbikes ?? []),
                    groupSlice.actions.setMotorbikesResult(motorbikeRes),
                  ];
                } else {
                  return [
                    ...actionsWhenDriverSuccess,
                    groupSlice.actions.setGroupMotorbikes([]),
                    groupSlice.actions.setErrMsg(motorbikeRes?.response.error),
                  ];
                }
              }),
              catchError((e: AjaxError) => [
                ...actionsWhenDriverSuccess,
                groupSlice.actions.setGroupMotorbikes([]),
                groupSlice.actions.setErrMsg(
                  e?.response?.Message || "Có lỗi xảy ra khi lấy danh sách xe"
                ),
              ])
            );
          } else {
            return [
              groupSlice.actions.setGroupDrivers([]),
              groupSlice.actions.setErrMsg(driverRes?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.setGroupDrivers([]),
          groupSlice.actions.setGroupMotorbikes([]),
          groupSlice.actions.setErrMsg(
            "Có lỗi xảy ra khi lấy danh sách tài xế"
          ),
        ])
      );
    })
  );
const fetchWhenSelectGroup$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(selectGroup.match),
    switchMap((re) => {
      if (
        moment(state$.value.group.selectedDate)
          .endOf("day")
          .isBefore(moment()) &&
        !re.payload
      ) {
        return [groupSlice.actions.setGroupsTickets([])];
      }
      return getTicketsByGroupId(re.payload ? re.payload.id : null, {
        startDate: moment(state$.value.group.selectedDate)
          .startOf("day")
          .add(7, "hours")
          .toISOString(),
        endDate: moment(state$.value.group.selectedDate)
          .endOf("day")
          .add(7, "hours")
          .toISOString(),
      }).pipe(
        mergeMap((res1: any) => {
          return getGroupById(re.payload ? re.payload.id : null).pipe(
            mergeMap((res2: any) => {
              if (res2 && !res2?.response?.error && res2.jobs) {
                const jobs = res2.jobs || [];
                const foundJobGroup = jobs.find(
                  (job: IGroupJob) =>
                    job.id === state$.value.group.jobGroupSelected?.id
                );
                const foundJobTour = jobs
                  .find(
                    (job: IGroupJob) =>
                      job.id === state$.value.group.jobGroupSelected?.id
                  )
                  ?.jobs.find(
                    (job: IJob) =>
                      job.id === state$.value.group.jobTourSelected?.id
                  );
                const setUnGroupTicketsCountAction = re.payload
                  ? [
                      groupSlice.actions.fetchAdditionalGroupData(re.payload),
                      groupSlice.actions.setJobTours(jobs),
                      groupSlice.actions.setJobTourSelected(
                        !!state$.value.group.jobGroupSelected &&
                          !!state$.value.group.jobTourSelected &&
                          foundJobGroup &&
                          foundJobTour
                          ? {
                              jobGroup: foundJobGroup,
                              jobTour: foundJobTour,
                            }
                          : {
                              jobGroup: jobs.find(
                                (job: IGroupJob) => job.stt === "1"
                              ),
                              jobTour: jobs
                                .find((job: IGroupJob) => job.stt === "1")
                                .jobs.find((job: IJob) => job.stt === "1"),
                            }
                      ),
                    ]
                  : [
                      groupSlice.actions.setUnGroupTicketsCount(
                        res1.queryCount ?? 0
                      ),
                      groupSlice.actions.setJobTours([]),
                      groupSlice.actions.setJobTourSelected({
                        jobGroup: null,
                        jobTour: null,
                      }),
                    ];
                let jobDateSelect = JobDateEnum.ALL;
                if (res2.status === GroupStatusEnum.RUNNING) {
                  const startDate = moment(res2.startDate)
                    .startOf("day")
                    .subtract(1, "minute");
                  const today = moment().endOf("day");
                  if (moment(res2.businessDate).isBefore(startDate)) {
                    jobDateSelect = JobDateEnum.DAY1;
                  }
                  if (moment(res2.businessDate).isBetween(startDate, today)) {
                    jobDateSelect =
                      moment(res2.businessDate).diff(res2.startDate, "days") +
                        2 <=
                      res2.dmuC_Tour?.countDay
                        ? moment(res2.businessDate).diff(
                            res2.startDate,
                            "days"
                          ) + 2
                        : res2.dmuC_Tour?.countDay;
                  }
                }
                const actionsWhenGetGroupSuccess = [
                  groupSlice.actions.setGroupsTickets(res1.results ?? []),
                  groupSlice.actions.setGroupTicketsResult(res1),
                  ...setUnGroupTicketsCountAction,
                  groupSlice.actions.setJobDateSelected(jobDateSelect),
                  groupSlice.actions.setGroupSelectedCount({
                    countTicket: res2.countTicket,
                    countDriver: res2.countDriver,
                    countMotor: res2.countMotor,
                  }),
                ];
                if (re.payload) {
                  return getIssuesByGroupId(re.payload.id).pipe(
                    mergeMap((issueRes: any) => {
                      if (
                        issueRes &&
                        !issueRes?.response?.error &&
                        issueRes.results
                      ) {
                        const issues = issueRes.results;
                        return [
                          ...actionsWhenGetGroupSuccess,
                          groupSlice.actions.setIssues(issues ?? []),
                          groupSlice.actions.setIssuesResult(issueRes),
                        ];
                      } else {
                        return [
                          ...actionsWhenGetGroupSuccess,
                          groupSlice.actions.setErrMsg(
                            issueRes?.response.error
                          ),
                        ];
                      }
                    }),
                    catchError((e: AjaxError) => [
                      groupSlice.actions.setErrMsg(
                        "Có lỗi xảy ra khi lấy danh sách phát sinh"
                      ),
                    ])
                  );
                } else {
                  return [...actionsWhenGetGroupSuccess];
                }
              } else {
                return [
                  groupSlice.actions.setJobTours([]),
                  groupSlice.actions.setJobTourSelected({
                    jobGroup: null,
                    jobTour: null,
                  }),
                  groupSlice.actions.setUnGroupTicketsCount(
                    res1.queryCount ?? 0
                  ),
                  groupSlice.actions.setGroupsTickets(res1.results ?? []),
                  groupSlice.actions.setGroupTicketsResult(res1),
                  groupSlice.actions.setOperationErrMsg(res2?.response.error),
                ];
              }
            }),
            catchError((e: AjaxError) => {
              const setUnGroupTicketsCountAction = re.payload
                ? [
                    groupSlice.actions.setOperationErrMsg(
                      "Không thể lấy thông tin của group"
                    ),
                  ]
                : [
                    groupSlice.actions.setUnGroupTicketsCount(
                      res1.queryCount ?? 0
                    ),
                  ];
              return [
                ...setUnGroupTicketsCountAction,
                groupSlice.actions.setGroupsTickets(res1.results ?? []),
                groupSlice.actions.setGroupTicketsResult(res1),
                groupSlice.actions.setJobTours([]),
                groupSlice.actions.setJobDateSelected(JobDateEnum.ALL),
                groupSlice.actions.setJobTourSelected({
                  jobGroup: null,
                  jobTour: null,
                }),
                groupSlice.actions.setIssues([]),
                groupSlice.actions.setGroupDrivers([]),
                groupSlice.actions.setGroupMotorbikes([]),
              ];
            })
          );
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.setErrMsg("Không thể lấy danh sách khách"),
          groupSlice.actions.setOperationErrMsg(null),
        ])
      );
    })
  );
const createGroup$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(createNewGroup.match),
    switchMap((re) => {
      return createGroup(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.id) {
            //  const group = res;
            return getTicketsByGroupId(null, {
              startDate: moment(state$.value.group.selectedDate)
                .startOf("day")
                .add(7, "hours")
                .toISOString(),
              endDate: moment(state$.value.group.selectedDate)
                .endOf("day")
                .add(7, "hours")
                .toISOString(),
            }).pipe(
              mergeMap((res2: any) => {
                if (
                  res2 &&
                  !res2?.response?.error &&
                  res2.queryCount !== undefined
                ) {
                  return [
                    groupSlice.actions.fetchGroupData(),
                    groupSlice.actions.setUnGroupTicketsCount(res2.queryCount),
                  ];
                } else {
                  return [groupSlice.actions.setErrMsg(res2?.response.error)];
                }
              }),
              catchError((e: AjaxError) => [
                groupSlice.actions.fetchGroupData(),
              ])
            );
          } else {
            return [groupSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.setErrMsg(
            e?.response?.Message || "Không thể tạo group"
          ),
        ])
      );
    })
  );
const fetchUngroupTickets$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchUngroupTickets.match),
    switchMap((re) => {
      return getTicketsByGroupId(null, {
        startDate: moment(state$.value.group.selectedDate)
          .startOf("day")
          .add(7, "hours")
          .toISOString(),
        endDate: moment(state$.value.group.selectedDate)
          .endOf("day")
          .add(7, "hours")
          .toISOString(),
      }).pipe(
        mergeMap((res) => {
          if (res && res.queryCount) {
            return [groupSlice.actions.setUnGroupTicketsCount(res.queryCount)];
          }
          return [];
        })
      );
    })
  );
const createGroupAndAssignTickets$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(createGroupAndAssignTicket.match),
    switchMap((re) => {
      return createGroup(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.id) {
            const group = res;
            return [
              groupSlice.actions.setGroupForTickets({
                tickets: state$.value.group.groupTicketsSelected,
                group,
              }),
              groupSlice.actions.addGroups(group),
            ];
          } else {
            return [groupSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.setErrMsg(
            e?.response?.Message || "Không thể tạo group"
          ),
        ])
      );
    })
  );
const deleteGroup$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(deleteGroup.match),
    switchMap((re) => {
      return deleteGroupById(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const deleteStatus = res;
            if (deleteStatus === 1) {
              const actionsWhenFullfiled = [];
              const newGroups = state$.value.group.groups.filter(
                (group) => group.id !== state$.value.group.deletingGroupId
              );
              if (
                !newGroups.some(
                  (group) => group.id === state$.value.group.groupSelected?.id
                )
              ) {
                actionsWhenFullfiled.push(groupSlice.actions.selectGroup(null));
              }
              return [
                ...actionsWhenFullfiled,
                groupSlice.actions.setGroups(newGroups),
                groupSlice.actions.deleteGroupFullfilled(),
              ];
            } else {
              return [groupSlice.actions.setErrMsg("Có lỗi xảy ra")];
            }
          } else {
            return [groupSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.setErrMsg(
            e?.response?.Message || "Có lỗi xảy ra khi xóa group"
          ),
        ])
      );
    })
  );

const fetchJobTourItem$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setJobTourSelected.match),
    switchMap((re) => {
      if (
        re.payload.jobTour &&
        state$.value.group.groupSelected &&
        state$.value.group.groupTickets?.length > 0
      ) {
        // return getJobTourOnGroupId(
        //     state$.value.group.groupSelected ? state$.value.group.groupSelected.id : '',
        //     re.payload.jobTour ? re.payload.jobTour.jobItemId : ''
        //     ).pipe(
        //      mergeMap((res: any) => {
        //         if (res && !res?.response?.error) {
        // const tickets: IGroupTicket[] = res.tickets ?? [];
        // const jobTickets: IJobTicket[] = res.jobs.length > 0 ? res.jobs[0].jobs[0].jobTickets : [];
        const tickets: IGroupTicket[] = state$.value.group.groupTickets ?? [];
        const jobTickets: IJobTicket[] = re.payload.jobTour.jobTickets ?? [];
        if (jobTickets.length > 0 && tickets.length > 0) {
          return [
            groupSlice.actions.setJobTickets(
              jobTickets.map((jTicket) => ({
                ...jTicket,
                driverId:
                  jTicket.isDriver === "YES"
                    ? Utils.getDriverIdOfListInItem(
                        tickets.filter(
                          (ticket) => ticket.id === jTicket.ticketId
                        )[0]
                      )
                    : undefined,
                motorbikeId:
                  Utils.getMotorbikeIdOfListInItem(
                    tickets.filter(
                      (ticket) => ticket.id === jTicket.ticketId
                    )[0]
                  ) || undefined,
              }))
            ),
          ];
        }
        return [groupSlice.actions.setJobTickets([])];
        //        } else {
        //            return [
        //                groupSlice.actions.setErrMsg(res?.response.error),
        //            ];
        //        }
        //      }),
        //      catchError((e: AjaxError) => [
        //       groupSlice.actions.setErrMsg("Có lỗi xảy ra khi lấy danh sách vé của job"),
        //      ]),
        //  );
      } else {
        return [
          groupSlice.actions.setErrMsg(null),
          groupSlice.actions.setJobTickets([]),
        ];
      }
    })
  );

const assignGroup$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setGroupForTickets.match),
    switchMap((re) => {
      return assignGroupIdForTickets(re.payload).pipe(
        mergeMap((res1: any) => {
          return getTicketsByGroupId(null, {
            startDate: moment(state$.value.group.selectedDate)
              .startOf("day")
              .add(7, "hours")
              .toISOString(),
            endDate: moment(state$.value.group.selectedDate)
              .endOf("day")
              .add(7, "hours")
              .toISOString(),
          }).pipe(
            mergeMap((res2: any) => {
              console.log("hi:", res2);
              if (
                res2 &&
                !res2?.response?.error &&
                res2.queryCount !== undefined
              ) {
                return [
                  groupSlice.actions.fetchGroupData(),
                  groupSlice.actions.setUnGroupTicketsCount(res2.queryCount),
                  // groupSlice.actions.selectGroup(re.payload.group),
                ];
              } else {
                return [groupSlice.actions.setErrMsg(res2?.response.error)];
              }
            }),
            catchError((e: AjaxError) => [
              groupSlice.actions.fetchGroupData(),
              // groupSlice.actions.selectGroup(re.payload.group),
              groupSlice.actions.setErrMsg(
                "Có lỗi xảy ra khi lấy danh sách vé chưa gắn nhóm"
              ),
            ])
          );
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.setErrMsg(
            e?.response?.Message || "Có lỗi xảy ra khi gán khách"
          ),
        ])
      );
    })
  );
const unassignTicketOnGroup$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(removeTicketFromGroup.match),
    switchMap((re) => {
      const selectedTickets = state$.value.group.groupTicketsSelected;
      const { ticket, group } = re.payload;
      return unassignTicketOnGroupId({
        ticketId: ticket.id,
        groupId: group.id,
      }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res === 1) {
            // 1 === true
            return [
              groupSlice.actions.fetchGroupData(),
              groupSlice.actions.fetchUngroupTickets(),
              groupSlice.actions.checkTickets(
                selectedTickets.filter(
                  (selectedTicket) => selectedTicket.id !== ticket.id
                )
              ),
            ];
          }
          return [groupSlice.actions.setErrMsg("Có lỗi xảy ra khi hủy gán vé")];
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.setErrMsg(
            e?.response?.Message || "Có lỗi xảy ra khi hủy gán vé"
          ),
        ])
      );
    })
  );
const saveGroup$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveGroup.match),
    switchMap((re) => {
      return updateGroup(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            const newGroup: IGroup | null = state$.value.group.groupSelected
              ? {
                  ...state$.value.group.groupSelected,
                  ...res,
                }
              : null;
            if (re.payload.leaderId && newGroup) {
              newGroup.leaderId = re.payload.leaderId;
            }
            return [
              groupSlice.actions.fetchGroupData(),
              groupSlice.actions.selectGroup(newGroup),
            ];
          } else {
            return [groupSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.setErrMsg(
            e?.response?.Message || "Có lỗi xảy ra lưu thông tin của group"
          ),
        ])
      );
    })
  );
const saveJobTickets$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveJobTickets.match),
    switchMap((re) => {
      return updateJobTicket(re.payload, JobTicketStatusEnum.PASS).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [
              groupSlice.actions.selectGroup(state$.value.group.groupSelected),
              groupSlice.actions.setJobTourSelected({
                jobGroup: state$.value.group.jobGroupSelected,
                jobTour: state$.value.group.jobTourSelected,
              }),
            ];
          } else {
            return [groupSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.setErrMsg(
            e?.response?.Message || "Có lỗi xảy ra khi cập nhật"
          ),
        ])
      );
    })
  );
const createNewIssue$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(createNewIssue.match),
    switchMap((re) => {
      return createIssue(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.id) {
            const createdIssue = res;
            const fetchGroupActions =
              re.payload.type === IssueType.CANCELTOUR
                ? [
                    groupSlice.actions.selectGroup(
                      state$.value.group.groupSelected
                    ),
                  ]
                : [];
            if (re.payload.status === IssueStatus.Approve) {
              return [
                groupSlice.actions.createNewIssueFullfilled(createdIssue),
                groupSlice.actions.approveIssue(createdIssue.id),
                ...fetchGroupActions,
              ];
            }
            return [
              groupSlice.actions.createNewIssueFullfilled(createdIssue),
              ...fetchGroupActions,
            ];
          } else {
            return [
              groupSlice.actions.createNewIssueFailed(res?.response.error),
            ];
          }
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.createNewIssueFailed(
            e?.response?.Message || "Không tạo được phát sinh"
          ),
        ])
      );
    })
  );
const saveIssue$: RootEpic = (action$) =>
  action$.pipe(
    filter(saveIssue.match),
    switchMap((re) => {
      return updateIssue(re.payload).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.id) {
            return [groupSlice.actions.setIssueById(res)];
          } else {
            return [groupSlice.actions.saveIssueFailed(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.saveIssueFailed("Có lỗi xảy ra khi lưu thông tin"),
        ])
      );
    })
  );
const approveIssue$: RootEpic = (action$) =>
  action$.pipe(
    filter(approveIssue.match),
    switchMap((re) => {
      return approveIssueByIds([re.payload]).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.length) {
            return [groupSlice.actions.setIssueByIds(res)];
          } else {
            return [groupSlice.actions.saveIssueFailed(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.saveIssueFailed("Có lỗi xảy ra khi duyệt"),
        ])
      );
    })
  );
const rejectIssue$: RootEpic = (action$) =>
  action$.pipe(
    filter(rejectIssue.match),
    switchMap((re) => {
      return rejectIssueByIds([re.payload]).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [groupSlice.actions.setIssueByIds(res)];
          } else {
            return [groupSlice.actions.saveIssueFailed(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.saveIssueFailed(
            "Có lỗi xảy ra khi từ chối phát sinh"
          ),
        ])
      );
    })
  );
const saveDriverTicket$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveDriverTicket.match),
    switchMap((re) => {
      if (re.payload.driverTicketId) {
        return updateDriverTicket({
          ticketId: re.payload.ticketId ?? undefined,
          driverId: re.payload.driverId,
          driverTicketId: re.payload.driverTicketId,
        }).pipe(
          mergeMap((res: any) => {
            if (res && !res?.response?.error) {
              if (re.payload.ticketId && res.length) {
                return [
                  groupSlice.actions.setDriverTicketByTicketId({
                    ticketId: re.payload.ticketId,
                    dTickets: res,
                  }),
                ];
              } else {
                return [];
              }
            } else {
              return [groupSlice.actions.setErrMsg(res?.response.error)];
            }
          }),
          catchError((e: AjaxError) => [
            groupSlice.actions.setErrMsg(
              e?.response?.Message || "Có lỗi xảy ra khi lưu thông tin"
            ),
          ])
        );
      } else {
        return createDriverTicket({
          ticketId: re.payload.ticketId ?? "",
          driverId: re.payload.driverId,
        }).pipe(
          mergeMap((res: any) => {
            if (res && !res?.response?.error) {
              if (re.payload.ticketId) {
                return [
                  groupSlice.actions.setDriverTicketByTicketId({
                    ticketId: re.payload.ticketId,
                    dTickets: res.length || res.length === 0 ? res : [res],
                  }),
                ];
              } else {
                return [];
              }
            } else {
              return [groupSlice.actions.setErrMsg(res?.response.error)];
            }
          }),
          catchError((e: AjaxError) => [
            groupSlice.actions.setErrMsg(
              e?.response?.Message || "Có lỗi xảy ra khi lưu thông tin"
            ),
          ])
        );
      }
    })
  );
const saveMotorbikeTicket$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(saveMotorbikeTicket.match),
    switchMap((re) => {
      if (re.payload.motorbikeTicketId) {
        return updateMotorbikeTicket({
          ...re.payload,
          motorbikeId: re.payload.motorbikeId,
          motorbikeTicketid: re.payload.motorbikeTicketId,
          ticketId: re.payload.ticketId ?? undefined,
        }).pipe(
          mergeMap((res: any) => {
            if (res && !res?.response?.error) {
              if (re.payload.ticketId && res.length) {
                return [
                  groupSlice.actions.setMotorbikeTicketByTicketId({
                    ticketId: re.payload.ticketId,
                    mTickets: res,
                  }),
                ];
              } else {
                return [];
              }
            } else {
              return [groupSlice.actions.setErrMsg(res?.response.error)];
            }
          }),
          catchError((e: AjaxError) => [
            groupSlice.actions.setErrMsg(
              e?.response?.Message || "Có lỗi xảy ra khi lưu thông tin"
            ),
          ])
        );
      } else {
        return createMotorbikeTicket({
          ...re.payload,
          motorbikeId: re.payload.motorbikeId,
          ticketId: re.payload.ticketId ?? "",
        }).pipe(
          mergeMap((res: any) => {
            if (res && !res?.response?.error) {
              if (re.payload.ticketId) {
                return [
                  groupSlice.actions.setMotorbikeTicketByTicketId({
                    ticketId: re.payload.ticketId,
                    mTickets: res.length || res.length === 0 ? res : [res],
                  }),
                ];
              } else {
                return [];
              }
            } else {
              return [groupSlice.actions.setErrMsg(res?.response.error)];
            }
          }),
          catchError((e: AjaxError) => [
            groupSlice.actions.setErrMsg(
              e?.response?.Message || "Có lỗi xảy ra khi lưu thông tin"
            ),
          ])
        );
      }
    })
  );

const getDrivers$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchAllDrivers.match),
    switchMap((re) => {
      return getAllDrivers({ ...re.payload }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const drivers = res.results;
            return [groupSlice.actions.setAllDrivers(drivers ?? [])];
          } else {
            return [groupSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.setErrMsg("Có lỗi xảy ra lấy danh sách tài xế"),
        ])
      );
    })
  );
const getMotorbikes$: RootEpic = (action$) =>
  action$.pipe(
    filter(fetchAllMotorbikes.match),
    switchMap((re) => {
      return getAllMotorbikes({ ...re.payload }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const motorbikes = res.results;
            return [groupSlice.actions.setAllMotorbikes(motorbikes ?? [])];
          } else {
            return [groupSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          groupSlice.actions.setErrMsg("Có lỗi xảy ra lấy danh sách xe máy"),
        ])
      );
    })
  );

export const {
  addGroups,
  createNewGroup,
  createGroupAndAssignTicket,
  selectGroup,
  checkAllTickets,
  checkTickets,
  uncheckTickets,
  fetchGroupData,
  fetchAdditionalGroupData,
  fetchGroupTickets,
  setGroupDrivers,
  setGroupMotorbikes,
  deleteGroup,
  deleteGroupFullfilled,
  setGroupForTickets,
  removeTicketFromGroup,
  setGroups,
  setFilterDate,
  setJobTours,
  setJobDateSelected,
  setJobTourSelected,
  setErrMsg,
  setOperationErrMsg,
  checkJobTickets,
  uncheckJobTickets,
  saveGroup,
  saveJobTickets,
  createNewIssue,
  createNewIssueFailed,
  createNewIssueFullfilled,
  setIssues,
  resetIssueState,
  saveIssue,
  setIssueById,
  saveDriverTicket,
  saveMotorbikeTicket,
  setMotorbikeTicketByTicketId,
  setDriverTicketByTicketId,
  setGroupsResult,
  setGroupTicketsResult,
  setDriversResult,
  setMotorbikesResult,
  setIssuesResult,
  setDetailViewingIssue,
  approveIssue,
  rejectIssue,
  setIssueByIds,
  setSelectedDate,
  setJobTickets,
  setIsOnlyStartDate,
  startGroup,
  setGroupSelectedCount,
  fetchAllDrivers,
  fetchAllMotorbikes,
  fetchUngroupTickets,
  // deleteDriverTicket,
  // deleteMotorbikeTicket
} = groupSlice.actions;

export const GroupEpics = [
  fetchGroup$,
  fetchWhenSelectGroup$,
  createGroup$,
  deleteGroup$,
  assignGroup$,
  unassignTicketOnGroup$,
  saveGroup$,
  saveJobTickets$,
  createNewIssue$,
  fetchAdditionalGroupData$,
  saveIssue$,
  saveDriverTicket$,
  saveMotorbikeTicket$,
  fetchGroupTickets$,
  approveIssue$,
  rejectIssue$,
  fetchWhenSelectDate$,
  fetchJobTourItem$,
  createGroupAndAssignTickets$,
  startGroup$,
  getDrivers$,
  getMotorbikes$,
  fetchUngroupTickets$,
];

export const groupReducer = groupSlice.reducer;
