import { Button, Checkbox, Spin, Typography } from "antd";
// import Utils from "common/Utils";
import styles from "../NightAuditor.module.css";
import { useSelectorRoot } from "store/store";
import {
  IGroupTicket,
  IssueStatus,
  IssueType,
  ThanhToanIssueStatus,
} from "common/define-types";
import { IIssue } from "api/types/issue";
import Utils from "common/Utils";

interface IProps {
  readOnly?: boolean;
  issue: IIssue;
  groupTickets: IGroupTicket[];
  handleConfirm?: (issue: IIssue) => void;
  handleReject?: (issue: IIssue) => void;
  handleViewDetail?: (issue: IIssue) => void;
  handleCheckPaymentStatus?: (issue: IIssue, checked: boolean) => void;
  isPaid?: boolean;
}
export const ChangeRoomItem = ({
  issue,
  readOnly,
  groupTickets,
  handleConfirm,
  handleReject,
  handleViewDetail,
  handleCheckPaymentStatus,
  isPaid = true,
}: IProps) => {
  const selectRoomTemplates = useSelectorRoot(
    (state) => state.roomTemplate.roomTemplates
  );
  const ticketInfo = groupTickets.find(
    (ticket) => ticket.id === issue.ticketId
  );
  const roomTemplateInfo = selectRoomTemplates.find(
    (room) => room.id === issue.roomTemplateId
  );
  const selectIsSubmittingIssueId = useSelectorRoot(
    (state) => state.group.isSubmittingIsuseId
  );

  return (
    <Spin
      spinning={
        selectIsSubmittingIssueId !== null &&
        selectIsSubmittingIssueId === issue.id
      }
    >
      <div className={`${styles.issueItem} ${isPaid ? "" : styles.notPaid}`}>
        <div className={styles.issueItem_wrapper}>
          {[IssueType.CHANGEROOM].some((type) => type === issue.type) && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Checkbox
                style={{ transform: "translateX(8px)" }}
                checked={
                  issue.thanhToanIssueStatus === ThanhToanIssueStatus.NOT_YET
                }
                disabled={readOnly}
                onChange={(e) =>
                  handleCheckPaymentStatus &&
                  handleCheckPaymentStatus(issue, e.target.checked)
                }
              >
                Chưa thanh toán
              </Checkbox>
            </div>
          )}
          <div className={styles.issueItem_item}>
            <Typography.Text
              strong
              className={styles.issueItem_issueName}
            >{`Đổi phòng`}</Typography.Text>
            <Typography.Text
              style={{
                textAlign: "right",
                color: Utils.getIssueTime(issue).color,
              }}
              title={Utils.getIssueTime(issue).time.format(
                "DD/MM/YYYY HH:mm:ss"
              )}
            >
              {Utils.getIssueTime(issue).text}
            </Typography.Text>
          </div>
          <div className={styles.issueItem_item}>
            <Typography.Text
              style={{
                maxWidth: "60%",
              }}
            >
              {roomTemplateInfo ? roomTemplateInfo.name : ""}
            </Typography.Text>
            <Typography.Text>
              {ticketInfo ? ticketInfo.fullName : ""}
            </Typography.Text>
            {/* <Typography.Text>
            {Utils.formatCurrency(
              roomTemplateInfo ? roomTemplateInfo.price : 0
            )}
          </Typography.Text> */}
          </div>
        </div>
        {!readOnly && (
          <div className={styles.issueItem_btnGroup}>
            <div className={styles.issueItem_btns}>
              <Button
                className={styles.issueItem_confirmBtn}
                onClick={() => handleConfirm && handleConfirm(issue)}
                disabled={issue.status !== IssueStatus.Init}
                loading={
                  selectIsSubmittingIssueId !== null &&
                  selectIsSubmittingIssueId === issue.id
                }
              >
                {issue.status === IssueStatus.Approve ||
                issue.status === IssueStatus.ComfirmByAdmin
                  ? "Đã duyệt"
                  : "Duyệt"}
              </Button>
              <Button
                danger
                disabled={issue.status !== IssueStatus.Init}
                type="primary"
                onClick={() => handleReject && handleReject(issue)}
                loading={
                  selectIsSubmittingIssueId !== null &&
                  selectIsSubmittingIssueId === issue.id
                }
              >
                {issue.status === IssueStatus.Reject ? "Đã từ chối" : "Từ chối"}
              </Button>
            </div>
            <Button
              type="primary"
              onClick={() => handleViewDetail && handleViewDetail(issue)}
            >
              Chi tiết
            </Button>
          </div>
        )}
      </div>
    </Spin>
  );
};
