import { Modal, Typography } from "antd";
import { IssueStatus, IssueType } from "common/define-types";
import { IIssue } from "api/types/issue";
import { VehicleBreakForm } from "components/Form/VehicleBreakForm";
import { LeftTourForm } from "components/Form/LeftTourForm";
import { ChangeRoomForm } from "components/Form/ChangeRoomForm";
import { useForm } from "antd/es/form/Form";
import styles from "./Modal.module.css";
import OuterGasPumpForm from "components/Form/OuterGasPumpForm";
import ExtraSleptForm from "components/Form/ExtraSleptForm";
import ExtraMealForm from "components/Form/ExtraMealForm";
interface IProps {
  open: boolean;
  issue: IIssue;
  handleClose: () => void;
}

export const IssueDetailModal = ({ open, handleClose, issue }: IProps) => {
  const [issueForm] = useForm();
  // const [leftTourForm] = useForm();
  // const [changeRoomForm] = useForm();

  const renderIssusItemByType = (issue: IIssue) => {
    switch (issue.type) {
      case IssueType.BREAKDOWN_MOTORBIKE:
        return <VehicleBreakForm form={issueForm} detailIssue={issue} />;
      case IssueType.CANCELTOUR:
        return <LeftTourForm form={issueForm} detailIssue={issue} />;
      case IssueType.CHANGEROOM:
      case IssueType.CHANGE_ROOM_DVT:
        return <ChangeRoomForm form={issueForm} detailIssue={issue} />;
      case IssueType.OUTER_FUEL:
        return <OuterGasPumpForm form={issueForm} detailIssue={issue} />;
      case IssueType.EXTRA_SLEPT:
      case IssueType.EXTRA_SLEPT_DVT:
        return <ExtraSleptForm form={issueForm} detailIssue={issue} />;
      case IssueType.EXTRA_MEAL:
        return <ExtraMealForm form={issueForm} detailIssue={issue} />;
      default:
        return <></>;
    }
  };
  const renderTitleByType = (issue: IIssue) => {
    switch (issue.type) {
      case IssueType.BREAKDOWN_MOTORBIKE:
        return "Hỏng xe";
      case IssueType.CANCELTOUR:
        return "Bỏ tour";
      case IssueType.CHANGEROOM:
        return "Đổi phòng";
      case IssueType.CHANGE_ROOM_DVT:
        return "Đổi phòng ( dịch vụ thêm )";
      case IssueType.OUTER_FUEL:
        return "Đổ xăng lẻ";
      case IssueType.EXTRA_SLEPT:
        return "Ngủ thêm";
      case IssueType.EXTRA_SLEPT_DVT:
        return "Ngủ thêm ( dịch vụ thêm )";
      case IssueType.EXTRA_MEAL:
        return "Khách ăn thêm";
      default:
        return "";
    }
  };
  const renderStatus = (issue: IIssue) => {
    switch (issue.status) {
      case IssueStatus.Approve:
        return {
          color: "#52C41A",
          text: "Đã duyệt",
        };
      case IssueStatus.Reject:
        return {
          color: "#ff4d4f",
          text: "Đã từ chối",
        };
      case IssueStatus.Init:
        return {
          color: "#1677ff",
          text: "Khởi tạo",
        };
      case IssueStatus.ComfirmByAdmin:
        return {
          color: "#52C41A",
          text: "Đã kiểm toán",
        };
      default:
        return {
          color: "#1677ff",
          text: "Khởi tạo",
        };
    }
  };

  return (
    <Modal
      open={open}
      footer={null}
      title={
        <>
          <span>{`${renderTitleByType(issue)}`}</span>
          <Typography.Text
            style={{ color: renderStatus(issue).color, marginLeft: 4 }}
          >{`- ${renderStatus(issue).text}`}</Typography.Text>
        </>
      }
      onCancel={handleClose}
      centered
      style={{ marginBlock: 10 }}
    >
      <div className={`${styles.issueModal} customScroll`}>
        {renderIssusItemByType(issue)}
      </div>
    </Modal>
  );
};
