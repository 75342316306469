import { DatePicker, Input, Typography, notification } from "antd";
import styles from "./DriverOperator.module.css";
import { DriverTable } from "./components/DriverTable";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { ChangeEvent, useEffect, useState } from "react";
import {
  clearSearch,
  fetchDrivers,
  setDriverFilterValue,
  setErrMsg,
} from "store/slice/DriverOperatorSlice";
import dayjs from "dayjs";
import Utils from "common/Utils";

export const DriverOperator = () => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const dispatch = useDispatchRoot();
  const selectDriverFilterValue = useSelectorRoot(
    (state) => state.operatorDriver.filterValue
  );
  const selectErrMsg = useSelectorRoot((state) => state.operatorDriver.errMsg);

  useEffect(() => {
    if (selectErrMsg) {
      notification.error({ message: selectErrMsg });
      dispatch(setErrMsg(null));
    }
  }, [selectErrMsg, dispatch]);

  useEffect(() => {
    dispatch(
      fetchDrivers({
        dateTime: new Date().toISOString(),
        pageSize: 25,
      })
    );
    dispatch(clearSearch());
  }, [dispatch]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(
        setDriverFilterValue({
          ...selectDriverFilterValue,
          search: e.target.value,
        })
      );
    }, 800);
    setTimer(timeoutId);
  };
  return (
    <div className={styles.driverContainer}>
      <div className={styles.tableHeader}>
        <div>
          <Typography.Text strong style={{ fontSize: 20, marginRight: 20 }}>
            Quản lý tài xế
          </Typography.Text>
          <DatePicker
            format={Utils.dateFormat}
            allowClear={false}
            value={
              selectDriverFilterValue && selectDriverFilterValue.dateTime
                ? dayjs(selectDriverFilterValue.dateTime)
                : null
            }
            onChange={(value) =>
              dispatch(
                setDriverFilterValue({
                  ...selectDriverFilterValue,
                  dateTime: value?.toISOString(),
                })
              )
            }
          />
        </div>
        <div>
          <Input.Search
            placeholder="Tìm kiếm tài xế"
            onChange={handleSearch}
            allowClear
          />
        </div>
      </div>
      <DriverTable />
    </div>
  );
};
