import { Modal, Typography, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { MethodForm } from "./MethodForm";
import styles from "./Billing.module.css";
import Utils from "common/Utils";
import { IUpdatePaymentMethodBooking } from "api/types/booking";
import { IPaymentMethodBooking } from "common/define-types";

interface IProps {
  open: boolean;
  handleClose: () => void;
  isRefundModal?: boolean; // or in debt
  handleSubmit: (
    paymentMethodBookingsConnect?: IUpdatePaymentMethodBooking[],
    paymentMethodBookingsDisable?: IUpdatePaymentMethodBooking[]
  ) => void;
  total: number;
}
export const PaymentDetailModal = ({
  open,
  handleClose,
  handleSubmit,
  isRefundModal = false,
  total,
}: IProps) => {
  const [payAmount, setPayAmount] = useState(0);
  const [methodForm] = useForm();
  const handleBeforeSubmit = async () => {
    if (total - payAmount > 0) {
      notification.info({
        message: `Bạn phải nhập đủ số tiền ${
          isRefundModal ? "hoàn" : "cần"
        } trả`,
      });
      return;
    }
    const paymentMethodBookingsValue = await methodForm.validateFields();
    const paymentMethodBookings: IPaymentMethodBooking[] = [];
    for (const key in paymentMethodBookingsValue) {
      if (
        paymentMethodBookingsValue[key] &&
        paymentMethodBookingsValue[key] > 0
      ) {
        paymentMethodBookings.push({
          // [4-12-2023] [Phuc Thinh] [Update paymentMethodBooking interface]
          paymentCode: key,
          amount: paymentMethodBookingsValue[key],
        });
      }
    }
    // if (total !== 0 && paymentMethodBookings.length === 0) {
    //   notification.info({
    //     message: "Bạn chưa nhập các phương thức thanh toán",
    //   });
    //   return;
    // }

    if (isRefundModal) {
      handleSubmit(undefined, paymentMethodBookings);
    } else {
      if (total === 0) {
        handleSubmit();
      } else {
        handleSubmit(paymentMethodBookings, undefined);
      }
    }
  };
  return (
    <Modal
      centered
      open={open}
      onCancel={handleClose}
      onOk={handleBeforeSubmit}
      className="payDetailModal"
      okText="Hoàn thành"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          padding: "12px 24px",
          backgroundColor: isRefundModal ? "#D6E4FF" : "#D9F7BE",
          marginBottom: 8,
        }}
      >
        {isRefundModal ? (
          <>
            <Typography.Text
              strong
              style={{ fontSize: 20 }}
            >{`Tiền cần hoàn: ${Utils.formatCurrency(
              total - payAmount
            )}`}</Typography.Text>
            <Typography.Text>Chọn phương thức hoàn trả</Typography.Text>
          </>
        ) : (
          <>
            <Typography.Text
              strong
              style={{ fontSize: 20 }}
            >{`Tiền cần thanh toán: ${Utils.formatCurrency(
              total - payAmount
            )}`}</Typography.Text>
            <Typography.Text>Chọn phương thức thanh toán</Typography.Text>
          </>
        )}
      </div>
      <div className={`${styles.payDetailModal} customScroll`}>
        <MethodForm
          totalAmount={total > 0 ? total : -total}
          setPayAmount={(amount) => setPayAmount(amount)}
          payAmount={payAmount}
          paymentForm={methodForm}
          hasDebtItem={false}
        />
      </div>
    </Modal>
  );
};
