import { Empty } from "antd";

interface IProps {
  message?: string;
}
export const EmptyHolder = ({ message }: IProps) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Empty description={`${message || "No data"}`} />
    </div>
  );
};
