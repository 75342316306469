import {
  // Input,
  Pagination,
  PaginationProps,
  Select,
  Spin,
  Typography,
  notification,
} from "antd";
import styles from "../NightAuditor.module.css";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { GroupItem } from "./GroupItem";
import { useEffect, useRef } from "react";
import { EmptyHolder } from "components/Empty/EmptyHolder";
import { GroupRunningDateEnum } from "common/define-types";
import {
  selectGroup,
  setGroupRunningDateSelected,
  setGroupsResult,
} from "store/slice/NightAuditorSlice";
import Utils from "common/Utils";
// import dayjs from "dayjs";

const groupRunningDateOptions = [
  {
    value: GroupRunningDateEnum.ALL,
    label: `All`,
  },
  {
    value: GroupRunningDateEnum.DAY1,
    label: `Ngày 1`,
  },
  {
    value: GroupRunningDateEnum.DAY2,
    label: `Ngày 2`,
  },
  {
    value: GroupRunningDateEnum.DAY3,
    label: `Ngày 3`,
  },
  {
    value: GroupRunningDateEnum.DAY4,
    label: `Ngày 4`,
  },
  {
    value: GroupRunningDateEnum.END_DAY,
    label: `Group đang về`,
  },
];
export const GroupList = () => {
  const selectGroups = useSelectorRoot((state) => state.nightAuditor.groups);
  const selectGroupSelected = useSelectorRoot(
    (state) => state.nightAuditor.groupSelected
  );
  const selectLoading = useSelectorRoot(
    (state) => state.nightAuditor.isLoading
  );
  const selectErrMsg = useSelectorRoot((state) => state.nightAuditor.errMsg);
  const selectGroupRunningDateSelected = useSelectorRoot(
    (state) => state.nightAuditor.groupRunningDateSelected
  );
  const selectGroupsResult = useSelectorRoot(
    (state) => state.nightAuditor.groupsResult
  );
  const dispatch = useDispatchRoot();
  const groups = selectGroups.filter((group) => {
    // const startDate = moment(group.startDate);
    // const endDate = moment(group.startDate).add(
    //   group.dmuC_Tour.countDay - 1,
    //   "day"
    // );
    // const today = moment();
    // if (today.isBetween(startDate, endDate)) {
    if (
      selectGroupRunningDateSelected === GroupRunningDateEnum.ALL ||
      selectGroupRunningDateSelected === GroupRunningDateEnum.END_DAY
    )
      return true;
    else {
      return (
        Utils.getDayFromBusinessDate(group) === selectGroupRunningDateSelected
      );
    }
    // }
    // return true;
    // return group.status === GroupStatusEnum.RUNNING;
  });
  const groupsRefs = useRef<HTMLDivElement[]>([]);
  groupsRefs.current = [];

  const addToRefs = (el: HTMLDivElement) => {
    if (el && !groupsRefs.current.includes(el)) {
      groupsRefs.current.push(el);
    }
  };

  useEffect(() => {
    const selectedGroupRef = groupsRefs.current.find(
      (ref) => ref.ariaSelected === "true"
    );
    if (selectedGroupRef)
      selectedGroupRef.scrollIntoView({
        block: "center",
        inline: "center",
      });
    // console.log(bookingsRefs.current.map((ref) => ref.ariaSelected === "true"));
  }, [selectGroupSelected]);

  const handleChangePagi: PaginationProps["onChange"] = (page) => {
    if (selectGroupsResult) {
      dispatch(
        setGroupsResult({
          ...selectGroupsResult,
          page: page,
        })
      );
    }
  };

  useEffect(() => {
    if (selectErrMsg) {
      notification.destroy();
      notification.error({
        message: selectErrMsg,
      });
    }
  }, [selectErrMsg]);

  const handleChangeDate = (value: string | number) => {
    dispatch(setGroupRunningDateSelected(value));
    if (value === GroupRunningDateEnum.END_DAY) return;
    const newGroups = selectGroups.filter((group) => {
      // const startDate = moment(group.startDate);
      // const endDate = moment(group.startDate).add(
      //   group.dmuC_Tour.countDay - 1,
      //   "day"
      // );
      // const today = moment();
      // if (today.isBetween(startDate, endDate)) {
      if (
        value === GroupRunningDateEnum.ALL ||
        value === GroupRunningDateEnum.END_DAY
      )
        return true;
      else {
        return Utils.getDayFromBusinessDate(group) === value;
      }
      // }
      // return false;
    });
    if (newGroups.length > 0) {
      console.log(newGroups, value);

      dispatch(selectGroup(newGroups[0]));
    } else {
      dispatch(selectGroup(null));
    }
  };

  return (
    <div
      className={`${styles.listContainer} ${styles.listContainer_group}`}
      style={{ minWidth: "400px", maxWidth: "60rem" }}
    >
      <div className={styles.listContainer_header}>
        <Typography.Text strong style={{ fontSize: 20 }}>
          Danh sách groups
        </Typography.Text>
        {/* <Input.Search placeholder="Tìm kiếm" style={{ maxWidth: 160 }} /> */}
      </div>
      <div className={styles.groupRunningDate}>
        <Typography.Text>Ngày đang chạy:</Typography.Text>
        <Select
          options={groupRunningDateOptions}
          value={selectGroupRunningDateSelected}
          onChange={handleChangeDate}
          style={{ width: "160px" }}
        />
      </div>
      <div className={styles.listWrapper}>
        <div
          className={`${styles.listWrapper_List} ${styles.listWrapper_List_group} customScroll`}
          style={{ paddingRight: 15 }}
        >
          {selectLoading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin />
            </div>
          ) : groups && groups.length > 0 ? (
            groups.map((group) => (
              <GroupItem
                group={group}
                key={group.id}
                isActive={
                  selectGroupSelected !== null &&
                  selectGroupSelected.id === group.id
                }
                ref={addToRefs}
              />
            ))
          ) : (
            <EmptyHolder message="No groups" />
          )}
        </div>
      </div>
      <div className={styles.listContainer_footer}>
        <Pagination
          total={groups.length}
          pageSize={selectGroupsResult ? selectGroupsResult.pageSize : 10}
          current={selectGroupsResult ? selectGroupsResult.page : 1}
          showTotal={(total, range) => {
            return `Hiển thị ${
              range[1] === 0 && range[1] - range[0] === 0
                ? 0
                : range[1] - range[0] + 1
            }/${total} nhóm`;
          }}
          onChange={handleChangePagi}
        />
      </div>
    </div>
  );
};
