import SYSTEM_CONSTANTS from 'common/constants';
import HttpClient from './http-client';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AjaxError} from 'rxjs/ajax';

const api = `${SYSTEM_CONSTANTS.API_URL}/CodePay`;

export const getCodePayById = (id: string): Observable<any> => {
    return HttpClient.get(`${api}/${id}`).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};
export const getAllCodePay = (): Observable<any> => {
    return HttpClient.get(api).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};





