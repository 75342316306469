import { Button, Form, Input, Typography, message } from "antd";
import { useForm } from "antd/es/form/Form";
import styles from "./form.module.css";
// import { DeleteOutlined } from "@ant-design/icons";
import { updatePassword } from "api/account.api";

interface IProps {
  handleCloseModal?: () => void;
}
export const SecurityForm = ({ handleCloseModal }: IProps) => {
  const [resetPasswordForm] = useForm();

  const handleResetPassword = (values: any) => {
    if (values) {
      updatePassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      }).subscribe(
        (res) => {
          message.success(`Cập nhật mật khẩu thành công`);
          handleCloseModal && handleCloseModal();
        },
        (err) => console.log(err)
      );
    }
  };
  return (
    <section>
      <div className={styles.profileFormContainer}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            maxWidth: 600,
          }}
        >
          <Form
            labelCol={{ span: 10 }}
            labelAlign="left"
            form={resetPasswordForm}
            labelWrap={true}
            onFinish={handleResetPassword}
          >
            <Typography.Title level={4} style={{ marginBlock: 10 }}>
              Đổi mật khẩu
            </Typography.Title>
            <Form.Item
              label="Mật khẩu cũ"
              name={"oldPassword"}
              rules={[
                {
                  required: true,
                  message: "Không được bỏ trống",
                },
                {
                  pattern: new RegExp(
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
                  ),
                  message:
                    "Mật khẩu có tối thiểu 8 ký tự, phải bao gồm ít nhất 1 ký tự hoa, 1 ký tự thường, 1 chữ số và 1 ký tự đặc biệt (@$!%*?&)",
                },
              ]}
            >
              <Input.Password placeholder="Nhập lại mật khẩu cũ của bạn" />
            </Form.Item>
            <Form.Item
              label="Mật khẩu mới"
              name={"newPassword"}
              rules={[
                {
                  required: true,
                  message: "Không được bỏ trống",
                },
                {
                  pattern: new RegExp(
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
                  ),
                  message:
                    "Mật khẩu có tối thiểu 8 ký tự, phải bao gồm ít nhất 1 ký tự hoa, 1 ký tự thường, 1 chữ số và 1 ký tự đặc biệt (@$!%*?&)",
                },
              ]}
            >
              <Input.Password placeholder="Nhập mật khẩu muốn thay đổi" />
            </Form.Item>
            <Form.Item
              label="Xác nhận mật khẩu mới"
              name={"newRePassword"}
              rules={[
                {
                  required: true,
                  message: "Không được bỏ trống",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Mật khẩu không giống nhau")
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Nhập lại mật khẩu muốn thay đổi" />
            </Form.Item>
            <Form.Item
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button type="primary" htmlType="submit">
                Lưu thông tin
              </Button>
            </Form.Item>
          </Form>
        </div>
        {/* <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Typography.Title level={4} style={{ marginBlock: 10 }}>
            Xóa tài khoản
          </Typography.Title>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 20,
            }}
          >
            <Typography.Text>
              Xóa tài khoản này khỏi Jasmine vĩnh viễn ?
            </Typography.Text>
            <Button danger icon={<DeleteOutlined style={{ color: "red" }} />}>
              Xóa tài khoản
            </Button>
          </div>
        </div> */}
      </div>
    </section>
  );
};
