import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  RootEpic,
  ICommonResult,
  NightBookingRevenue,
  INightBookingFilterValue,
  INightBookingTicket,
} from "common/define-types";
import { filter, switchMap, mergeMap, catchError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { confirmNightAuditBooking, getAvailabelChangeBusinessDate, getNightAuditBooking, getNightAuditBookingSummarize } from "api/nightaudit.api";
dayjs.extend(utc);
export interface NightBookingState {
  isLoading: boolean;
  isRevenueLoading: boolean;
  isSubmitting: boolean;
  nightBookingTickets: INightBookingTicket[];
  errMsg: string | null;
  nightBookingResult: ICommonResult | null;
  nightBookingsRevenue: NightBookingRevenue | null;
  warningModalOpen: boolean;
//   collectFeeBooking: INightBookingItem | null;
  filterValue: INightBookingFilterValue;
}

const initialState: NightBookingState = {
  isLoading: false,
  isRevenueLoading: false,
  isSubmitting: false,
  nightBookingTickets: [],
  errMsg: null,
  nightBookingResult: null,
  nightBookingsRevenue: null,
  warningModalOpen: false,
  filterValue: {
    
  },
};

export const nightBookingSlice = createSlice({
  name: "nightBooking",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchAll: (
        state,
        action: PayloadAction<{ bookingFilter?: INightBookingFilterValue } | undefined>
      ) => {
        state.isSubmitting = false;
        state.errMsg = null;
    },
    fetchNightBookings: (
      state,
      action: PayloadAction<INightBookingFilterValue | undefined>
    ) => {
      state.isLoading = true;
      state.errMsg = null;
    },
    setNightBookings: (state, action: PayloadAction<INightBookingTicket[]>) => {
      state.nightBookingTickets = action.payload;
      state.isLoading = false;
    },
    fetchNightBookingsRevenue: (
      state,
      action: PayloadAction
    ) => {
      state.isRevenueLoading = true;
      state.errMsg = null;
    },
    setNightBookingsRevenue: (state, action: PayloadAction<NightBookingRevenue>) => {
      state.nightBookingsRevenue = action.payload;
      state.isLoading = false;
    },
    setWarningModalOpen: (state, action: PayloadAction<boolean>) => {
      state.warningModalOpen = action.payload;
    },
    checkAvailableChangeBusinessDate: (state, action: PayloadAction) => {
      state.warningModalOpen = false;
    },
    setErrMsg: (state, action: PayloadAction<string | null>) => {
      state.errMsg = action.payload;
      state.isLoading = false; 
      state.isSubmitting = false; 
    },
    setNightBookingResult: (
      state,
      action: PayloadAction<ICommonResult>
    ) => {
      state.nightBookingResult = action.payload;
      state.isLoading = false;
      state.errMsg = null;
    },
    auditNightBooking: (
      state,
      action: PayloadAction
    ) => {
      state.isSubmitting = true;
      state.errMsg = null;
    },
    setFilterValue: (
      state,
      action: PayloadAction<INightBookingFilterValue>
    ) => {
      state.filterValue = action.payload;
    },
    // setCollectFeeBooking: (
    //   state,
    //   action: PayloadAction<INightBookingItem | null>
    // ) => {
    //   state.collectFeeBooking = action.payload;
    // },
    // saveBookingCollectionFee: (
    //   state,
    //   action: PayloadAction<{ticketId: string, payload: IPaymentMethodBooking[]}>
    // ) => {
    //   state.isSubmitting = true;
    // },
    // clearFilterValue: (state) => {
    //   state.filterValue = {
    //     ...state.filterValue,
    //     search: "",
    //     BookingDate: undefined,
    //     BusStartDate: undefined,
    //     BusEndDate: undefined,
    //     StatusThuHo: undefined
    //   };
    // },
  },
});
const fetchAll$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchAll.match),
    switchMap((re) => {
      return [
        nightBookingSlice.actions.fetchNightBookings(re.payload?.bookingFilter),
        nightBookingSlice.actions.fetchNightBookingsRevenue(),
      ]
    })
  );
const fetchNightBookings$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchNightBookings.match),
    switchMap((re) => {
        return getNightAuditBooking({
            ...state$.value.nightBooking.filterValue,
            ...re.payload,
            pageSize: re.payload?.pageSize || 25
        }).pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error && res.results) {
            const { results, ...rest } = res;
            return [
              nightBookingSlice.actions.setNightBookings(results ?? []),
              nightBookingSlice.actions.setNightBookingResult(rest),
            ];
          } else {
            return [nightBookingSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          nightBookingSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi thông tin các booking"
          ),
        ])
      );
    })
  );
const fetchNightBookingsRevenue$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(fetchNightBookingsRevenue.match),
    switchMap((re) => {
      return getNightAuditBookingSummarize().pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [
              nightBookingSlice.actions.setNightBookingsRevenue(res),
            ];
          } else {
            return [nightBookingSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          nightBookingSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra khi thông tin doanh thu"
          ),
        ])
      );
    })
  );
const fetchWhenFilter$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(setFilterValue.match),
    switchMap((re) => {
      return [nightBookingSlice.actions.fetchNightBookings(re.payload)];
    })
  );
const auditNightBooking$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(auditNightBooking.match),
    switchMap((re) => {
      return confirmNightAuditBooking().pipe(
        mergeMap((res: any) => {
          if (res && !res?.response?.error) {
            return [
              nightBookingSlice.actions.fetchAll({
                bookingFilter: state$.value.nightBooking.filterValue
              }),
              nightBookingSlice.actions.checkAvailableChangeBusinessDate()
            ];
          } else {
            return [nightBookingSlice.actions.setErrMsg(res?.response.error)];
          }
        }),
        catchError((e: AjaxError) => [
          nightBookingSlice.actions.setErrMsg(
            e.response?.Message || "Có lỗi xảy ra kiểm toán"
          ),
        ])
      );
    })
  );
const checkAvailableChangeBussinessDate$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(checkAvailableChangeBusinessDate.match),
    switchMap((re) => {
      return getAvailabelChangeBusinessDate().pipe(
        mergeMap((res: any) => {
            if (res === false) {
                return [
                    nightBookingSlice.actions.setWarningModalOpen(true),
                ];
            }
            return []
        }),
        catchError((e: AjaxError) => [
        //   nightBookingSlice.actions.setErrMsg(
        //     e.response?.Message || "Có lỗi xảy ra lấy thông tin "
        //   ),
        ])
      );
    })
  );

export const {
  fetchNightBookings,
  setNightBookings,
  setErrMsg,
  setNightBookingResult,
  fetchAll,
  fetchNightBookingsRevenue,
  setNightBookingsRevenue,
  setFilterValue,
  auditNightBooking,
  setWarningModalOpen,
  checkAvailableChangeBusinessDate
} = nightBookingSlice.actions;

export const nightBookingEpics = [
    fetchNightBookings$, 
    fetchNightBookingsRevenue$,
    fetchAll$,
    fetchWhenFilter$,
    auditNightBooking$,
    checkAvailableChangeBussinessDate$
];

export const nightBookingReducer = nightBookingSlice.reducer;
