import { useDispatchRoot, useSelectorRoot } from "store/store";
import styles from "../NightAuditorBooking.module.css";
import SumarizeItem from "./SumarizeItem";
import { NightBookingRevenue } from "common/define-types";
import { setFilterValue } from "store/slice/NightBooking.slice";

const SumarizeSection = () => {
  const dispatch = useDispatchRoot()
  const selectNightBookingFilter = useSelectorRoot(state => state.nightBooking.filterValue);
  const selectNightBookingRevenue = useSelectorRoot(state => state.nightBooking.nightBookingsRevenue)
  const list: {
    label: string,
    key: keyof NightBookingRevenue;
    isActive?: boolean;
    onClick?: (args?: any) => void;
  }[] = [
    {
      label: "Tổng số booking",
      key: "totalBookings",
    },
    {
      label: "Tổng số vé",
      key: "totalTicket",
    },
    {
      label: "Tổng tiền thu hộ (đ)",
      key: "thuHoRevenue",
      isActive: selectNightBookingFilter.onlyThuHo,
      onClick: () => dispatch(setFilterValue({
        ...selectNightBookingFilter,
        onlyThuHo: selectNightBookingFilter && selectNightBookingFilter.onlyThuHo ? 
        !selectNightBookingFilter.onlyThuHo : 
        true
      }))
    },
    {
      label: "Tổng tiền vé (đ)",
      key: "ticketRevenue",
    },
    {
      label: "Tổng tiền Bus đi (đ)",
      key: "leaveBusRevenue",
    },
    {
      label: "Tổng tiền Bus đến (đ)",
      key: "arrivedBusRevenue",
    },
    {
      label: "Tổng tiền dịch vụ thêm (đ)",
      key: "dichVuThemRevenue",
    },
  ];
  return (
    <div className={`${styles.sumarizeSection} customScroll`}>
      {list.map((item) => (
        <SumarizeItem {...item} value={selectNightBookingRevenue ? selectNightBookingRevenue[item.key] : 0} />
      ))}
    </div>
  );
};

export default SumarizeSection;
