import SYSTEM_CONSTANTS from 'common/constants';
import HttpClient from './http-client';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AjaxError} from 'rxjs/ajax';
import { IParams } from 'common/define-types';

const api = `${SYSTEM_CONSTANTS.API_URL}/Location`;

export const getLocationById = (id: string): Observable<any> => {
    return HttpClient.get(`${api}/${id}`).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};

export const getAllLocations = (params?: IParams): Observable<any> => {
    if (params) {
        const { page, pageSize } = params;
        return HttpClient.get(`${api}?
        ${ page ? `page=${page}&` : ''} 
        ${ pageSize ? `size=${pageSize}&` : ''}`).pipe(
            map(
                res => (res as any) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    else {
        return HttpClient.get(`${api}`).pipe(
            map(
                res => (res as any) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
};
