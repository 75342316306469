import SYSTEM_CONSTANTS from 'common/constants';
import HttpClient from './http-client';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AjaxError} from 'rxjs/ajax';
import { IParams } from 'common/define-types';
import { ICreateMotorbikeTicket, ISaveBrokeMotorbikeTicket, IUpdateMotorbikeTicket } from './types/motorbikeTicket';

const api = `${SYSTEM_CONSTANTS.API_URL}/MotorbikeTicket`;

export const createMotorbikeTicket = (
    body: ICreateMotorbikeTicket
  ): Observable<any> => {
      return HttpClient.post(`${api}`, JSON.stringify([body]), {
          headers: {
              'Content-Type': 'application/json',
          },
      }).pipe(
          map(
              res => (res as any) || null,
              catchError((e: AjaxError) => throwError(e)),
          ),
      );
};

export const updateMotorbikeTicket = (
  { motorbikeTicketid, ...rest }: IUpdateMotorbikeTicket
): Observable<any> => {
    return HttpClient.put(`${api}?id=${motorbikeTicketid}`, JSON.stringify(rest), {
        headers: {
            'Content-Type': 'application/json',
        },
    }).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};
export const getMotorbikeTicketById = (id: string): Observable<any> => {
    return HttpClient.get(`${api}/${id}`).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};

export const getAllMotorbikeTickets = (params?: IParams): Observable<any> => {
    if (params) {
        const { page, pageSize } = params;
        return HttpClient.get(`${api}?
        ${ page ? `page=${page}&` : ''} 
        ${ pageSize ? `size=${pageSize}&` : ''}`).pipe(
            map(
                res => (res as any) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
    else {
        return HttpClient.get(`${api}`).pipe(
            map(
                res => (res as any) || null,
                catchError((e: AjaxError) => throwError(e)),
            ),
        );
    }
};
export const deleteMotorbikeTicketById = (id: string): Observable<any> => {
    return HttpClient.delete(`${api}/${id}`).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};
export const saveBrokeMotorbikeTickets = (body: ISaveBrokeMotorbikeTicket[]): Observable<any> => {
    return HttpClient.put(`${api}/error`, JSON.stringify(body)).pipe(
        map(
            res => (res as any) || null,
            catchError((e: AjaxError) => throwError(e)),
        ),
    );
};
