import { Select } from "antd";
import { useEffect, useState } from "react";
import { getAllAgents } from "api/agent.api";
import { IAgent } from "api/types/agent";

interface IProps {
  value?: string;
  onChange?: (arg: string) => void;
  style?: React.CSSProperties;
  readOnly?: boolean;
  //   acceptStatus?: JobTicketStatusEnum[];
}
// const { Option } = Select;
export const AgentSelect = ({
  value,
  onChange = () => {},
  style,
  readOnly = false,
}: //   acceptStatus = [],
IProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [agents, setAgents] = useState<IAgent[]>([]);

  useEffect(() => {
    getAllAgents().subscribe(
      (res) => res && setAgents(res.results),
      (err) => console.log(err)
    );
  }, []);

  return (
    <Select
      options={agents.map((agent) => ({
        label: agent.name,
        value: agent.id,
      }))}
      value={value}
      style={style}
      open={dropdownOpen}
      onDropdownVisibleChange={(open) => setDropdownOpen(open)}
      onChange={(value) => onChange(value)}
      disabled={readOnly}
      optionLabelProp="label"
      placeholder='-- Chọn đại lý --'
      allowClear
      onClick={(e: any) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {/* {options
        .filter(
          (gTicket) =>
            acceptStatus.length === 0 ||
            acceptStatus.some(
              (status) => status === gTicket.status || !dropdownOpen
            )
        )
        .map((ticket) => (
          <Option label={ticket.fullName} value={ticket.id} key={ticket.id}>
            <TicketOption ticket={ticket} />
          </Option>
        ))} */}
    </Select>
  );
};
