import { Layout } from "antd";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { Routes } from "../Routes";
import { PrivateRoute } from "../../components/Routes";
import { WaitOverlay } from "../../components/overlay/WaitOverlay";
import { LeftMenu } from "pages/leftMenu/LeftMenu";
import { HeaderSection } from "components/Header/HeaderSection";
import { Login } from "pages/Login";
import { useEffect } from "react";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import {
  fetchAllDrivers,
  fetchAllMotorbikes,
  fetchGroupData,
} from "store/slice/GroupSlice";
import {
  clearBooking,
  fetchComboTours,
  fetchExtraService,
  fetchLocation,
  fetchPriceReqs,
  fetchTours,
} from "store/slice/BookingSlice";
import { RoleEnum } from "common/define-types";
import { fetchRoomTemplates } from "store/slice/RoomTemplateSlice";
import moment from "moment";
import { fetchJobItems } from "store/slice/SupplierSlice";
import { fetchHangHoaMotorbikes } from "store/slice/HangHoaSlice";
import { fetchRouterStationBusData } from "store/slice/RouterStationBus";
import Utils from "common/Utils";

const { Content } = Layout;
export const AdminLayout = () => {
  const dispatch = useDispatchRoot();
  const selectIsLoginSuccess = useSelectorRoot(
    (state) => state.login.isSuccess
  );
  const selectIsRefreshing = useSelectorRoot(
    (state) => state.login.isRefreshing
  );
  const selectIsOnlyStartDate = useSelectorRoot(
    (state) => state.group.isOnlyStartDate
  );
  const userRole = useSelectorRoot((state) => state.login.role);
  const filteredRoutes = Routes.filter((route) =>
    route.isRoot ? route.rootPermission?.some((r) => r === userRole) : true
  );
  const layoutRoutes = filteredRoutes.map((route) =>
    route.isRoot
      ? route
      : {
          ...route,
          items: route.items?.filter((item) =>
            item.rootPermission?.some((r) => r === userRole)
          ),
        }
  );
  const renderMain = () => {
    var routerProps: {
      label: string;
      routerProps: RouteProps;
      rootPermission?: RoleEnum[];
    }[] = [];
    for (var item of layoutRoutes) {
      if (item.items && item.items.length > 0) {
        for (var child of item.items) {
          if (child.routeProps) {
            routerProps.push({
              label: child.label,
              routerProps: child.routeProps,
              rootPermission: child.rootPermission,
            });
          }
        }
      }
      if (item.routeProps) {
        routerProps.push({
          label: item.label,
          routerProps: item.routeProps,
          rootPermission: item.rootPermission,
        });
      }
    }
    return routerProps.map(({ label, rootPermission, routerProps }) => (
      <PrivateRoute
        allowedRoles={[...(rootPermission ?? [RoleEnum.ALL])]}
        key={`${label}`}
        label={label}
        {...routerProps}
      />
    ));
  };

  useEffect(() => {
    if (selectIsLoginSuccess && !selectIsRefreshing) {
      const today = moment().toISOString();
      dispatch(fetchTours());
      dispatch(fetchComboTours({ dateTime: today }));
      dispatch(fetchPriceReqs({ dateTime: today }));
      dispatch(
        fetchAllDrivers({ pageSize: Utils.RANGE_PAGESIZE.MAX_INTERGER })
      );
      dispatch(
        fetchAllMotorbikes({ pageSize: Utils.RANGE_PAGESIZE.MAX_INTERGER })
      );
      dispatch(fetchRoomTemplates());
      dispatch(fetchJobItems({ typeJobItem: 1 }));
      dispatch(clearBooking());
      dispatch(fetchLocation());
      dispatch(fetchRouterStationBusData({ dateTime: today }));
      dispatch(fetchExtraService());
      dispatch(fetchRoomTemplates());
      dispatch(fetchHangHoaMotorbikes({ typeHangHoa: 1 }));
    }
  }, [dispatch, selectIsLoginSuccess, selectIsRefreshing]);

  useEffect(() => {
    if (selectIsLoginSuccess && !selectIsRefreshing) {
      dispatch(fetchGroupData());
    }
  }, [
    dispatch,
    selectIsLoginSuccess,
    selectIsRefreshing,
    selectIsOnlyStartDate,
  ]);

  return (
    <Layout style={{ height: "100%", overflowY: "hidden" }}>
      <HeaderSection />
      <Content>
        <Layout style={{ height: "100%", position: "relative" }}>
          <LeftMenu routes={layoutRoutes} />
          <Switch>
            <Route exact path="/jasmine/login" component={Login} />
            {renderMain()}
            <Redirect to="/jasmine/dashboard" />
          </Switch>
        </Layout>
      </Content>
      <WaitOverlay />
    </Layout>
  );
};
