import { Form, FormInstance, Input } from "antd";
import styles from "../Agents.module.css";

interface IProps {
  form?: FormInstance<any>;
  isEdit?: boolean;
}
export const AgentAccountForm = ({ form, isEdit = false }: IProps) => {
  return (
    <Form
      name="agentAccount"
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Form.Item
        label={"Tên nhân viên của đại lý"}
        name={"employeeName"}
        rules={[
          {
            required: true,
            message: "Không được bỏ trống",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <div className={styles.spaceBetween} style={{ gap: 8 }}>
        <Form.Item
          label={"Số điện thoại"}
          name={"phoneNumber"}
          style={{ flex: 1 }}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
            {
              pattern: new RegExp(/(84|0[3|5|7|8|9])+([0-9]{8})\b/g),
              message: "Phải là số điện thoại VN",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Email"}
          name={"email"}
          style={{ flex: 1 }}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
            {
              type: "email",
              message: "Phải là email hợp lệ",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
      <div className={styles.spaceBetween} style={{ gap: 8 }}>
        <Form.Item
          label={"Tài khoản"}
          name={"userName"}
          style={{ flex: 1 }}
          rules={[
            {
              required: true,
              message: "Không được bỏ trống",
            },
          ]}
        >
          <Input readOnly={isEdit} />
        </Form.Item>
        <Form.Item
          label={"Mật khẩu"}
          name={"password"}
          style={{ flex: 1 }}
          rules={[
            {
              required: !isEdit,
              message: "Không được bỏ trống",
            },
            {
              pattern: new RegExp(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
              ),
              message:
                "Mật khẩu có tối thiểu 8 ký tự, phải bao gồm ít nhất 1 ký tự hoa, 1 ký tự thường, 1 chữ số và 1 ký tự đặc biệt (@$!%*?&)",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </div>
    </Form>
  );
};
