import { Result } from "antd";

export const NotAllowed = () => {
  return (
    <div
      style={{
        padding: "0 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "auto",
        height: "100%",
        width: "100%",
      }}
      className="customScroll"
    >
      <Result
        status="403"
        title="403"
        subTitle="Bạn không quyền truy cập trang này"
      />
    </div>
  );
};
