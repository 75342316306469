import { Form, Typography } from "antd";
import styles from "./BookingResult.module.css";
import { PayeeForm } from "../Billing/PayeeForm";
import Utils from "common/Utils";
import { useSelectorRoot } from "store/store";
import { useEffect } from "react";
import { IBookingTicket } from "common/define-types";

export const PayeeInfor = () => {
  const [payeeForm] = Form.useForm();
  const selectBookingResult = useSelectorRoot(
    (state) => state.booking.bookingResult
  );

  const calculateTotal = () => {
    let total = 0;
    if (selectBookingResult && selectBookingResult.tickets.length) {
      const tickets = selectBookingResult.tickets
        .map((ticket: IBookingTicket) => ({
          ...ticket,
          price: ticket.price || 0,
        }))
        .filter((ticket: IBookingTicket) => ticket.isErase !== true);
      tickets.forEach((ticket: any) => {
        if (ticket && ticket.price) {
          total += ticket.price;
        }
      });
    }
    return total;
  };

  const calculateBusTotal = () => {
    let total = 0;
    if (selectBookingResult && selectBookingResult.tickets.length) {
      let tickets: IBookingTicket[] = selectBookingResult.tickets.filter(
        (ticket: IBookingTicket) => ticket.isErase !== true
      );
      tickets.forEach((ticket) => {
        if (ticket.busTicketC1) {
          total += ticket.busTicketC1.price;
          // total += Utils.getBusPrice(
          //   ticket.busTicketC1.routerStationBusId,
          //   routerStationBus
          // );
        }
        if (ticket.busTicketC2) {
          total += ticket.busTicketC2.price;
          // total += Utils.getBusPrice(
          //   ticket.busTicketC2.routerStationBusId,
          //   routerStationBus
          // );
        }
      });
    }
    return total;
  };
  const calculateExtraServiceTotal = () => {
    let total = 0;
    let tickets: IBookingTicket[] = selectBookingResult.tickets.filter(
      (ticket: IBookingTicket) => ticket.isErase !== true
    );
    tickets.forEach((ticket) => {
      if (
        ticket.datA_TOUR_CHHangHoaDichVuKhacs &&
        ticket.datA_TOUR_CHHangHoaDichVuKhacs.length > 0
      ) {
        ticket.datA_TOUR_CHHangHoaDichVuKhacs.forEach((service) => {
          total += service.price;
        });
      }
    });
    return total;
  };

  // const calculatePaidAmount = () => {
  //   let total = 0;
  //   selectBookingResult.paymentMethodBookings.forEach(
  //     (paymentMethod: IPaymentMethodBooking) => {
  //       // if (paymentMethod.paymethod?.code !== "90008")
  //       total += paymentMethod.amount;
  //     }
  //   );
  //   return total;
  // };
  useEffect(() => {
    if (selectBookingResult) {
      const payName = selectBookingResult.userPayName;
      const { firstName, lastName } = Utils.getName(payName);
      payeeForm.setFields(
        Utils.objectToAntFormData({
          lastName: lastName,
          firstName: firstName,
          userPayPhone: selectBookingResult.userPayPhone,
          userPayEmail: selectBookingResult.userPayEmail,
          code: selectBookingResult.code,
          note: selectBookingResult.note,
        })
      );
    }
  }, [selectBookingResult, payeeForm]);
  return (
    <div className={styles.bigCard} style={{ width: 444 }}>
      <Typography.Title level={4} className={styles.bigCard_title}>
        Thông tin người thanh toán
      </Typography.Title>
      <div className={styles.scrollWrapper} style={{ minHeight: 250 }}>
        <div
          className={`${styles.scrollContainer} customScroll`}
          style={{ paddingInline: 0 }}
        >
          <PayeeForm form={payeeForm} readOnly={true} />
        </div>
      </div>
      <div className={styles.invoiceContainer}>
        <div className={styles.spaceBetween}>
          <Typography.Text
            strong
            style={{ fontSize: 20 }}
          >{`Tổng hóa đơn`}</Typography.Text>
          <Typography.Text strong style={{ fontSize: 20 }}>
            {Utils.formatCurrency(
              calculateTotal() +
                calculateBusTotal() +
                calculateExtraServiceTotal()
            )}
          </Typography.Text>
        </div>
        {/* <Divider style={{ marginBlock: 10 }} />
        <div className={styles.spaceBetween} style={{marginBlock: 5}}>
          <Typography.Text strong>Đã thanh toán</Typography.Text>
          <Typography.Text strong>
            {Utils.formatCurrency(paymentMethodBookingAmount)}\
          </Typography.Text>
        </div>
        <div className={styles.spaceBetween} style={{marginBlock: 5}}>
          <Typography.Text strong>Còn nợ</Typography.Text>
          <Typography.Text strong>
            {Utils.formatCurrency(debtAmount)}\
          </Typography.Text>
        </div> */}
      </div>
    </div>
  );
};
