import { DatePicker, Input, Typography, notification } from "antd";
import styles from "./AgentOperator.module.css";
import { AgentTable } from "./components/AgentTable";
import { ChangeEvent, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { getAllAgentsByMonth } from "api/agent.api";
import {
  IAgentWithSummarized,
  ICommonResult,
  IParams,
} from "common/define-types";

export const AgentOperator = () => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [agentsResult, setAgentsResult] = useState<ICommonResult | null>(null);
  const [agents, setAgents] = useState<IAgentWithSummarized[]>([]);
  const [search, setSearch] = useState<string>("");
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    getAllAgentsByMonth({ dateTime: new Date().toISOString() }).subscribe(
      (res) => {
        if (res) {
          const { results, ...rest } = res;
          setAgents(results);
          setAgentsResult(rest);
        }
      },
      (err) =>
        notification.error({
          message: "Có lỗi xảy ra khi lấy thông tin đại lý",
        })
    );
  }, []);

  const fetchAgentsByMonth = (params: IParams) => {
    getAllAgentsByMonth({ search, ...params }).subscribe(
      (res) => {
        if (res) {
          const { results, ...rest } = res;
          setAgents(results);
          setAgentsResult(rest);
        }
      },
      (err) =>
        notification.error({
          message: "Có lỗi xảy ra khi lấy thông tin đại lý",
        })
    );
  };

  const handleChangeDate = (value: Dayjs) => {
    setSelectedDate(value);
    fetchAgentsByMonth({
      dateTime: value.toISOString(),
      ...agentsResult,
    });
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      fetchAgentsByMonth({
        ...agentsResult,
        dateTime: selectedDate.toISOString(),
        search: e.target.value,
      });
    }, 800);
    setTimer(timeoutId);
  };

  const handleChangePage = (page: number) => {
    fetchAgentsByMonth({
      ...agentsResult,
      dateTime: selectedDate.toISOString(),
      page,
    });
  };
  return (
    <div className={styles.agentContainer}>
      <div className={styles.tableHeader}>
        <div>
          <Typography.Text strong style={{ fontSize: 20, marginRight: 20 }}>
            Quản lý đại lý
          </Typography.Text>
          <DatePicker
            picker="month"
            value={selectedDate}
            onChange={(value) => value && handleChangeDate(value)}
            allowClear={false}
          />
        </div>
        <div>
          <Input.Search
            placeholder="Tìm kiếm đại lý"
            allowClear
            onChange={handleSearch}
            value={search}
          />
        </div>
      </div>
      <AgentTable
        dataSource={agents}
        handleChangePage={handleChangePage}
        agentResult={agentsResult}
      />
    </div>
  );
};
