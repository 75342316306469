import { Form, InputNumber, Typography } from "antd";
import Utils from "common/Utils";
import { DriverTicketType, IDriverTicket } from "common/define-types";
import styles from "../NightAuditor.module.css";
import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useDispatchRoot, useSelectorRoot } from "store/store";
import { setDriverTickets } from "store/slice/NightAuditorSlice";
import moment from "moment";

interface IProps {
  readOnly?: boolean;
  driverTicket: IDriverTicket;
}
export const DriverItem = ({ readOnly, driverTicket }: IProps) => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();
  const [driverForm] = useForm();
  const dispatch = useDispatchRoot();
  const selectGroupSelected = useSelectorRoot(
    (state) => state.nightAuditor.groupSelected
  );
  const selectGroupDriverTickets = useSelectorRoot(
    (state) => state.nightAuditor.groupSelectedDriverTickets
  );
  const selectDrivers = useSelectorRoot((state) => state.group.allDrivers);
  const driverInfo = selectDrivers.find(
    (driver) => driver.id === driverTicket.driverId
  );

  useEffect(() => {
    if (driverTicket) {
      driverForm.setFields(
        Utils.objectToAntFormData({
          // day: 1,
          day: driverTicket.day,
          salaryDate: driverInfo ? driverInfo?.salaryDate : 0,
          carSalary:
            driverTicket.carSalary !== null &&
            driverTicket.carSalary !== undefined
              ? driverTicket.carSalary
              : 100000,
        })
      );
    }
  }, [driverTicket, driverForm, driverInfo]);

  const handleFormChange = (changedValue: any, allValue: any) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      dispatch(
        setDriverTickets(
          selectGroupDriverTickets.map((dTicket) =>
            dTicket.id === driverTicket.id
              ? {
                  ...driverTicket,
                  day: allValue.day > 1 ? 1 : Math.round(allValue.day * 4) / 4,
                  carSalary: allValue.carSalary,
                }
              : dTicket
          )
        )
      );
    }, 400);
    setTimer(timeoutId);
  };

  return (
    <div className={styles.issueItem}>
      <div className={styles.issueItem_wrapper}>
        <div className={styles.issueItem_item}>
          <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Typography.Text strong style={{ fontSize: 20 }}>
              {driverInfo ? driverInfo?.name : "Trống"}
            </Typography.Text>
            <Typography.Text italic style={{ color: "#6b6060b3" }}>
              {`Ngày ${
                moment
                  .utc(driverTicket.date)
                  .local()
                  .diff(
                    moment.utc(selectGroupSelected?.startDate).local(),
                    "days"
                  ) + 1
              }`}
            </Typography.Text>
          </div>
          <Typography.Text strong>
            {Utils.formatCurrency(
              driverTicket.day * (driverInfo ? driverInfo?.salaryDate : 0)
              // driverTicket.day * (driverInfo ? driverInfo?.salaryDate : 0)
            )}
          </Typography.Text>
        </div>
        <Form
          form={driverForm}
          style={{ width: "100%" }}
          className="noMargin_form-item"
          onValuesChange={handleFormChange}
        >
          <div className={styles.issueItem_item}>
            <Typography.Text>Ngày công: </Typography.Text>
            <Form.Item name={"day"}>
              <InputNumber max={1} min={0} step={"0.25"} />
            </Form.Item>
          </div>
          <div className={styles.issueItem_item}>
            <Typography.Text>Tiền công / 1 ngày: </Typography.Text>
            <Form.Item name="salaryDate">
              <InputNumber
                readOnly
                controls={false}
                formatter={(value) =>
                  `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                }
                parser={(value) =>
                  parseInt(value ? value?.replaceAll(".", "") : "0")
                }
              />
            </Form.Item>
          </div>
          {driverTicket &&
            driverTicket.type === DriverTicketType.HAS_TICKET &&
            driverTicket.isDriverMotor === true && (
              <div className={styles.issueItem_item}>
                <Typography.Text>Phí xe riêng: </Typography.Text>
                <Form.Item name="carSalary">
                  <InputNumber
                    controls={false}
                    formatter={(value) =>
                      `${value
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
                    }
                    parser={(value) =>
                      parseInt(value ? value?.replaceAll(".", "") : "0")
                    }
                  />
                </Form.Item>
              </div>
            )}
        </Form>
      </div>
    </div>
  );
};
